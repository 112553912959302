@import '../../../../../styles/variables';

.input-wrap {
  display: flex;
  align-items: center;
  position: relative;

  i {
    pointer-events: none;
    color: $primary;
    font-size: 20px;
    position: absolute;
    left: 10px;
  }

  .input {
    padding-left: 40px;
    margin: 0;
    height: 35px;
    transition: all .3s ease;
    border-radius: 5px;
    
    &:not(:focus) {
      border-color: transparent;
    }

    &:hover:not(:focus) {
      background-color: $light-gray;
      border-color: $light-gray;
      cursor: pointer;
    }
  }
}