@import "styles/variables";

.general-settings-section {
  max-width: 720px;
  width: 100%;
  font-size: 14px;

  .frequency-select {
    width: 240px;
  }

  .info-row {
    display: flex;
    align-items: center;
    background-color: $almost-white;
    padding: 15px 10px;

    .icon {
      margin-right: 10px;
      font-size: 18px;
    }
  }
}

.svr-farmfeed-trigger {
  padding: 10px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  @media screen and (max-width: 40em) {
    flex-direction: column;
  }

  .svr-ff-radio-group {
    min-width: 350px;
  }

  .title {
    padding-right: 15px;

    .description {
      color: $nice-grey;
    }
  }
}

.disabled-section {
  position: relative;

  &:after {
    z-index: 1;
    content: "";
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
  }
}

.categories-section {
  padding-left: 60px;
  max-width: 720px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .header-btn {
      min-width: fit-content;
      margin-left: 15px;
    }
  }

  .search-box {
    width: 100%;
    max-width: unset;
    height: 40px;
  }
}

.additional-section {
  padding-left: 60px;
  max-width: 720px;

  .toggler {
    margin: 0;
  }
}
