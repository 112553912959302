@import '../../../styles/variables';

.symptom-level-badge {
  color: $white;
  min-width: 50px;
  padding: 2px 6px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  font-weight: 600;
}

.low {
  background-color: $brand-blue;
}

.very-low {
  background-color: #91BED4;
}

.medium {
  background-color: $brand-orange;
}

.high {
  background-color: #F2836B;
}

.very-high {
  background-color: $brand-red;
}
