@import '../../../../styles/variables';

.delivery-date {
  background-color: $light-yellow;
  border-bottom: 1px solid rgba(150, 150, 150, 0.3);
  font-size: 12px;
  text-align: center;
  color: $black;
  padding: 5px;
}
