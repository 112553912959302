.DropdownComponent {
  position: relative;
  display: block;

  .dropdown {
    min-width: 180px;
    font-weight: 400;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    top: 20px;
    transition: opacity .2s, transform .2s;
    margin-right: 10px;
    margin-top: 10px;

    $right: var(--triangleOffset, auto);

    &:before {
      right: $right !important;
    }

    &:not(.active) {
      pointer-events: none;
    }

    ul {
      text-align: left;

      li {
        padding: 0;

        &:hover {
          background-color: #f3f3f3;
        }
      }

      a {
        font-weight: normal;
        font-size: 13px;
        white-space: nowrap;
        padding: 4px 10px;

        &:hover {
          color: inherit;
        }
      }
    }
  }

  .dropdown-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(30, 38, 62, 0.5);
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s linear;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }
  }
}
