@import "../../../styles/variables";

.CompanySwitcher {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  width: 100%;
  padding: 5px;
  cursor: pointer;

  &:hover {
    .fa.dropdown-trigger {
      background-color: rgba(0, 0, 0, .2);
    }
  }

  &.isDropdownOpen {
    background-color: rgba(97, 104, 121, .4);
    border-radius: 5px;

    &.compact {
      border-radius: 50%;
    }
  }

  &.compact {
    justify-content: flex-end;
    width: 45px;
    margin-left: auto;
    margin-right: -3px;

    .company-avatar {
      margin-right: 0;
      position: absolute;
    }

    .company-name {
      opacity: 0;
    }

    .dropdown-trigger.fa {
      opacity: 0;
    }

    .companies-dropdown .dropdown {
      right: -270px;
    }
  }

  .company-avatar {
    margin-right: 10px;
    transition: .3s;
  }

  .company-name {
    flex: 1 0 auto;
    width: 130px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    transition: .3s;
  }

  .fa.dropdown-trigger {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
    transition: background ease .2s, opacity .3s;
    border-radius: 50%;

    &:hover {
      background-color: rgba(0, 0, 0, .2);
    }
  }

  .companies-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .dropdown {
      top: 35px;
      right: -90px;
      width: 300px;

      .dropdown-header {
        text-align: center;
        font-size: 14px;
      }

      .dropdown-search {
        padding: 10px;
        border-bottom: 1px solid #d8d8d8;

        .search-box {
          max-width: none !important;
        }

        .search-icon {
          margin-right: 5px;
          font-size: 14px;
        }
      }

      .dropdown-menu {
        padding: 0;
        margin: 0;
        max-height: 400px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-clip: padding-box;
          background-color: rgba($dark-gray, 0.8);
          border-radius: 6px;
          border-width: 1px 1px 1px 3px;
        }

        a {
          padding: 0;
          margin: 0;
        }

        .company-item {
          padding: 10px 20px;
          border-bottom: 1px solid #d8d8d8;
          cursor: pointer;

          &:last-child {
            border-bottom: none;
          }

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          .item-name {
            flex: 1 auto;
            font-size: 14px;
            white-space: normal;
          }

          .fa {
            margin-left: 20px;
            flex-shrink: 0;
            font-size: 16px;
            font-weight: bold;
            color: $primary;
            margin-right: 0;
            min-width: 0;
            display: none;
          }

          &.current {
            .fa {
              display: block;
            }
          }
        }
      }
    }
  }

  .trigger-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
