@import '../../../styles/variables';

.toggle-switcher {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
}

.text {
  font-size: 14px;
  font-weight: 600;
}

.checked {
  color: $primary;
}

.unchecked {
  color: $dark-gray;
}

.description {
  font-size: 14px;
  color: #8c8c8c;
}

.children {
  margin-top: 10px;
}
