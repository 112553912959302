@import '../../../styles/variables';

.disease-container {
  display: flex;
  flex-direction: column;

  .disease-types {
    margin-bottom: 5px;
    padding: 0;
  }

  .divider {
    margin-bottom: 15px;
    margin-top: 15px;

    hr {
      display: block;
      margin: 0;
      padding: 0;
      border: 0;
      height: 1px;
      background: #ddd;
      width: 100%;
    }
  }

  .disease-type-field {
    margin-bottom: 10px;
    margin-top: 10px;

    label {
      width: 50%;
    }

    .disease-type-label {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .notify-users {
    margin: 5px 0 15px;
  }

  .disease-note {
    margin: 0;
  }

  .choose-disease-field {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .disease-name {
      position: relative;
      flex-grow: 1;
      height: 40px;
      border: 2px solid $light-gray;
      border-radius: 2px;
      background-color: $white;
      font-size: 14px;
      padding: 7px 10px;
    }

    .disease-icon-box {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      border-left: 2px solid $light-gray;
      background-color: $background;

      .icon {
        font-size: 16px;
        color: $primary;
        padding: 10px 14px;
      }
    }

    .remove-icon-block {
      margin-left: 5px;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);
      }

      .remove-icon {
        padding: 9px;
        font-size: 22px;
      }
    }
  }
}
