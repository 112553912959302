@import "../../../styles/variables";

.AudioRecorder {

  @media screen and (min-width: 64em) {
    width: 500px;
  }

  .preloader {
    margin-top: 90px;
  }

  .center-box-header {
    background: transparent;
    background-image: linear-gradient(0deg, rgb(255, 94, 58) 3%, rgb(247, 132, 69) 103%);
    color: $white;

    .close-center-box {
      color: $white !important;
    }
  }

  .audio-recorder {
    padding: 0 !important;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 auto;

    .audio-visualizer {
      padding: 0;
      flex: 1 1 auto;
      width: 100%;
      min-height: 0;
      max-height: 200px;
      height: 0;
      border-bottom: 1px solid $light-gray;
      transition: min-height .4s ease;

      &.recording {
        min-height: 100px;
      }
    }

    .audio-controls {
      padding: 20px;
      flex: 1 1 auto;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .audio-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 20px 0 10px;

        .control-button {
          border-radius: 50%;
          width: 55px;
          height: 55px;
          padding: 0;
          margin: 0;
          color: $almost-black;
          background-color: #F5F5F5;
          border: 1px solid $light-gray;
          transition: background-color .2s ease;

          &.disabled {
            opacity: 1;
            cursor: pointer;
            background-color: $white;
            color: $medium-gray;
            border: 1px solid $medium-gray;
          }

          &.main-control {
            width: 110px;
            height: 110px;
            margin: 0 20px;
            background-color: $primary;
            color: $white;
            font-size: 48px;
            border: 0;
            box-shadow: rgba(0, 0, 0, 0.16) 0 3px 10px, rgba(0, 0, 0, 0.23) 0 3px 10px;
            transition: all .5s ease;

            &.primary {
              background-color: transparent;
              background-image: linear-gradient(0deg, rgb(255, 94, 58) 3%, rgb(247, 132, 69) 103%);
            }

            &.secondary {
              background-color: transparent;
              background-image: linear-gradient(0deg, rgb(0, 105, 161) 23%, rgb(62, 130, 201) 103%);
            }
          }
        }
      }

      .recording-time {
        text-align: center;
        font-size: 28px;
      }
    }
  }

  .record-preview {
    background-color: $background;
    border-bottom: 1px solid $light-gray;
    padding: 30px 20px;
  }

  .record-info {
    padding: 20px;
    width: 100%;
    flex: 1 1 auto;

    .filename-input {
      .tag {
        margin-bottom: 5px;
        font-weight: 600;
      }
    }

    .comment-box {
      margin: 20px 0 0 0;
    }
  }
}
