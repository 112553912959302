.draft-reports-row {
  padding-top: 30px;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  min-height: 230px;

  .add-report-card {
    position: relative;
    max-width: 195px;
    min-height: 100%;
    border: 1px solid #ddd;
    flex: 1 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: white;

    .circle {
      cursor: pointer;
      width: 85px;
      height: 85px;
      border-radius: 50%;
      background-image: linear-gradient(0deg, #ff5e3a 3%, #f78445 100%);
      background-position: center center;
      transition: 0.3s;

      &:hover {
        height: 95px;
        width: 95px;
      }
    }

    .plus-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 25px;
      font-weight: 600;
      color: white;
    }
  }

  @media screen and (max-width: 40em) {
    min-height: 200px;

    .add-report-card {
      max-width: 165px;
    }
  }
}
