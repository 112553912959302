.wrapperActivate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;

  .textInfo {
    padding-bottom: 2rem;
    span{
      font-family: Open Sans;
      font-size: 14px;
      color: #8c8c8c;
      text-decoration: none solid rgb(140, 140, 140);
      line-height: 24px;
      text-align: center;
    }
  }

  .imageNoReady{
    width: 342px;
    height: 164px;
    margin: 0;
  }
  button {
    width: max-content;

    span {
      padding: 0.8rem;
    }
  }
}
