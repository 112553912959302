@import '../../../../../styles/variables';

.input-label {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
}

.powered-by {
  color: $dark-gray;
  font-size: 14px;
  float: right;
}

.select-container {
  position: relative;
  height: 40px;
}

.select {
  padding-right: 40px;
}

.autocomplete-dropdown-container {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: $light-gray;
  box-sizing: border-box;
  max-height: 200px;
  margin-top: 3px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
  overflow: scroll;
}

.option {
  border-bottom: 1px solid $light-gray;
  padding: 10px 15px;
  font-size: 14px;
  color: #000;
  color: initial;
  cursor: pointer;
}

.suggestion-item {
  cursor: pointer;
  border-bottom: 1px solid $light-gray;
  padding: 10px 15px;
  font-size: 14px;
  color: #000;
  &:hover {
    background-color: #fafafa;
  }
}

.suggestion-item--active {
  background-color: $light-blue;
}

.input-wrap {
  display: flex;
  .placeholder {
    position: absolute;
    color: $medium-gray;
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 10px;
    overflow: hidden;
    font-size: 14px;
    padding-right: 36px;
  }
  .arrow-zone {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    border: 2px solid $light-gray;
    border-radius: 3px;
    box-sizing: border-box;
    background: #f7f7f7;
    font-size: 14px;
    -webkit-transition: box-shadow .5s,border-color .25s ease-in-out;
    transition: box-shadow .5s,border-color .25s ease-in-out;
    cursor: pointer;

    i {
      height: 36px;
      display: flex;
      width: 36px;
      vertical-align: middle;
      font-weight: bold;
      color: $primary;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }
  }
}

.is-focused {
  .select {
    border: 2px solid $primary !important;
  }

  .arrow-zone {
    border: 2px solid $primary !important;
    cursor: text !important;
  }
}

.current-item {
  position: relative;
  background-color: $light-blue;

  &:after {
    content: '\f012';
    position: absolute;
    right: 15px;
    color: $brand-blue;
    font: normal normal normal 16px/1 'EveryPig';
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 600;
  }
}
