@import "../../../styles/variables.scss";

.logistics-icon-wrapper {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  font-size: 35px;

  .icon {
    height: 100%;
    width: 100%;
  }

  .times-icon {
    position: absolute;
    color: $punch;
    font-size: 18px;
  }

  &.isDropdownView {
    width: 30px;
    height: 30px;
    font-size: 21px;

    .times-icon {
      font-size: 11px;
    }

    .sales-results-icon {
      transform: scale(0.6);
    }
  }

  &.sale.loading_created_production,
  &.sale.loading_edited_production,
  &.sale.loading_edited_confirmation_production {
    background-color: $primary;
  }

  &.arrival.loading_created_production,
  &.arrival.loading_edited_production,
  &.arrival.loading_edited_confirmation_production {
    background-color: $brand-blue;
  }

  &.transfer.loading_created_production,
  &.transfer.loading_edited_production,
  &.transfer.loading_edited_confirmation_production {
    &.in {
      background-color: $brand-orange;
    }

    &.out {
      background-color: $regent-gray;
    }
  }

  &.loading_created_production,
  &.loading_edited_production,
  &.loading_edited_confirmation_production {
    &.out {
      transform: rotateY(180deg);
    }
  }

  &.loading_canceled_production {
    color: $alto;
  }

  &.sales_result_created_production {
    color: $primary;
  }

  .sales-results-icon {
    width: 27px;
    height: 35px;
  }
}
