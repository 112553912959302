@import '../../../../styles/variables';

.tenant-assets-list {
  background-color: $white;
  border-top: 1px solid #ddd;
  margin: 20px 10px 60px;
  position: relative;

  &.all-assets-loaded {
    margin-bottom: 10px;
  }
}

.asset-item-row {
  height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  position: relative;

  &.not-viewed:before {
    content: '';
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border-top: 15px solid $primary;
    border-right: 15px solid transparent;
    position: absolute;
    z-index: 1;
  }

  .icon-column {
    flex: 0 0 50px;
    text-align: center;
  }

  .upload-progress {
    background-color: $medium-gray;
    height: 5px;
    width: 100%;
    margin: 5px auto;
    position: relative;

    .progress-value {
      background-color: $primary;
      height: 5px;
      width: 0;
      transition: width .4s ease;
    }
  }

  .asset-name-column {
    flex: 1;
    font-size: 12px;
    min-width: 0;

    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $primary;
    }

    time {
      color: $dark-gray;
    }
  }

  .action-column {
    flex: 0 0 60px;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;

    .action-icon {
      font-size: 22px;
      color: $dark-gray;
      margin: auto;
    }
  }
}
