@import '../../../../styles/variables';

.CheckboxColumn {
  text-align: left;

  .text-center {
    text-align: center;
  }

  @media screen and (max-width: 63.9375em) {
    &.collapsible-value {
      padding: 0;

      .circle-checkbox-column {
        width: 100%;
        position: relative;
        cursor: pointer;
        font-size: 13px;

        .checkbox-label {
          margin: 0;
          width: calc(100% - 60px);
          border-right: 1px solid $light-gray;
          padding: 5px 7px;
        }

        .icon {
          position: absolute;
          left: calc(100% - 40px);
          top: calc(50% - 10px);

          .fa {
            left: 0;
            top: 0;
            transform: translateX(0);
          }
        }
      }
    }
  }
}
