@import "../../../../../styles/variables";

.UserMarker {
  box-shadow: rgba(0, 0, 0, 0.4) 0 5px 10px;
  border-radius: 50%;
  background-color: $transparent;
  border: 2px solid rgba(255, 255, 255, .75);
  z-index: 3;

  &.active {
    z-index: 4;
  }
}
