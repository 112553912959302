.schedule-updated-modal {
  overflow: hidden;
  max-width: 425px;
  margin-top: 100px;

  .text {
    text-align: center;
    margin: 15px 0;
  }

  .buttons {
    display: flex;

    .btn {
      flex: 1;
      padding: 15px 10px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 0;
      transition: all .25s;
    }

    .leave-btn {
      background-color: var(--color-gray-light);

      &:hover {
        background-color: var(--color-gray-medium);
      }
    }

    .refresh-btn {
      color: white;
      background-color: var(--color-primary);

      &:hover {
        filter: brightness(85%);
      }
    }
  }
}
