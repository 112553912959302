@import '../../../styles/variables';

.FarmTypeLabel {
  position: relative;
  padding: 0 4px;
  font-size: 12px;
  color: white;
  border-radius: 3px;
  background-color: $brand-gray;
  text-transform: uppercase;
  min-width: 16px;
  display: inline-block;
  text-align: center;
  line-height: 18px;
  font-weight: 600;
  box-sizing: border-box;

  &.small {
    font-size: 10px;
    line-height: 15px;
    min-width: 14px;
  }

  &.light {
    border: 1px solid $medium-gray;
    background-color: $almost-white;
    color: $dark-gray;
  }
}
