@import '../../../../../styles/variables';

.FarmUserRolesManageTable {

  .flex-data-table {
    .table-item {
      .table-item-block {
        .collapsible-value {
          padding: 7px 10px;

          &.with-checkbox {
            padding: 0;
          }
        }
      }
    }
  }

  @media (min-width: 64em) {
    margin: 0 -20px 20px;

    .column-username {
      flex: 1 1 120px;
    }

    .column-user-role {
      flex: 1 1 80px;
    }
  }
}
