.BarnSheetsGroups {

  .FlexTableWrapper.scrollable .FlexTable {
    min-width: 1300px;
  }

  .export-csv-btn {
    margin: 13px 10px;
    color: var(--color-primary);
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    display: flex;
  }

  .group-id-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (max-width: 63.9375em) {
    .FlexTableWrapper.scrollable .FlexTable {
      min-width: auto;
    }
  }
}
