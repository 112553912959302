.items-line {
  display: inline-block;
}

.hint {
  display: inline-block;
  cursor: pointer;
  position: relative;
  text-decoration: underline;
}

.hint-body {
  white-space: normal;
  max-width: 250px;
  line-height: calc(1em + 10px);
  padding: 10px;
}
