@import 'styles/variables';

.body {

  .info-block {
    margin-top: 10px;

    .info-row {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .row-item {

        &:last-child {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        &.values {

          .target {
            float: right;
          }
        }

        .value {
          font-size: 20px;
          font-weight: 600;
        }

        .label {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 600;
        }

        .target {
          font-size: 12px;
          color: $brand-gray;
        }
      }
    }
  }

  .notes {
    display: flex;
    justify-content: space-between;
  }

  .divider {
    margin-top: 20px;
    height: 1px;
    width: 100%;
    background-color: #ddd;
  }
}
