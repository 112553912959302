@import "styles/variables";

.category-header {
  display: flex;
  align-items: center;

  .btn-group {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 14px;
    color: $nice-grey;
  }

  .btn {
    cursor: pointer;
    margin-left: 10px;

    &:hover {
      text-decoration: underline;
    }
  }
}
