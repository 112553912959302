@import '../../../../styles/variables';

img.img-thumb {
  max-width: 25px;
  max-height: 25px;
}

.tenant-asset-icon {

  &.img-icon,
  &.pdf-icon {
    color: $brand-red;
  }

  &.doc-icon,
  &.video-icon {
    color: $brand-blue;
  }

  &.link-icon {
    color: $dark-gray;
    font-size: 20px;
  }

  &.doc-icon,
  &.pdf-icon,
  &.video-icon {
    font-size: 25px;

    &.isBigger {
      font-size: 82px;
    }
  }

  &.img-icon,
  &.link-icon {

    &.isBigger {
      font-size: 60px;
    }
  }
}
