@import '../../../styles/variables';

.suggestion-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 25px;
  background-color: $white;
  font-size: 12px;
  padding: 0 10px;
  color: #757575;
  min-height: 35px;

  .magic-loop-img {
    width: 30px;
    margin-right: 5px;
  }
}
