.picker-row {
  border-top: 1px solid var(--color-gray-light);
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  position: relative;

  &.is-selected {
    opacity: 0.5;
  }

  .select-btn {
    margin-left: auto;
  }

  .icons-column {
    margin-left: auto;
    position: relative;
    width: 25px;
    height: 25px;
    color: var(--color-gray-dark);

    .checked-icon,
    .unchecked-icon {
      position: absolute;
      transition: opacity 0.2s ease;
    }

    .checked-icon {
      opacity: 0;
      color: var(--color-primary);
    }

    .unchecked-icon {
      opacity: 1;
    }

    &.is-selected {
      .checked-icon {
        opacity: 1;
      }

      .unchecked-icon {
        color: var(--color-primary);
      }
    }
  }
}
