@import '../../../../styles/variables';

.EfficacyFeed {

  .feed-container {
    max-width: 622px;
    margin: 20px auto 100px;
    position: relative;
    font-size: 14px;
    padding: 0 10px;

    > .panel:not(:last-child) {
      &:after {
        content: '';
        display: block;
        width: 3px;
        height: 30px;
        background-color: $light-gray;
        position: absolute;
        top: 100%;
        left: 25px;
      }
    }
  }

  .preloader {
    z-index: 1 !important;
  }

  .panel {
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: $white;
    margin-bottom: 20px;
    padding: 15px;
    overflow: visible;
    position: relative;
  }

  .efficacy-result {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .efficacy-icon {
      font-size: 22px;
    }

    .description {
      flex: 1;
      margin: 0 10px;
    }

    &.effective {
      background-color: $light-blue;

      .efficacy-icon {
        color: $brand-blue;
      }
    }

    &.ineffective {
      background-color: $light-orange;

      .efficacy-icon {
        color: $brand-red;
      }
    }
  }

  .treatment-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $light-gray;

    .treatment-icon {
      flex-shrink: 0;
      font-size: 22px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 50%;
      overflow: hidden;
      background-color: $light-gray;
      color: $black;
      margin-right: 10px;
    }

    .treatment-info {
      flex: 1;
      margin-right: 10px;
    }
  }

  .inner-panel {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .view-checkup-btn {
    width: 100%;
    border: 0 !important;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .trigger-icon {
    color: $black;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    font-size: 14px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .efficacy-response {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $light-gray;
    background-color: #FFFCEC;

    &.better { background-color: $light-blue; }
    &.same { background-color: $light-yellow; }
    &.worse { background-color: $light-orange; }

    i {
      font-size: 24px;

      &.red { color: $brand-red; }
      &.orange { color: $brand-orange; }
      &.blue { color: $brand-blue; }
    }

    .description {
      flex: 1;
      margin: 0 10px;
    }
  }
}
