@import "styles/variables";

.submit-stepper {
  height: 40px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .step {
    flex: 1;
    max-width: 250px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      .circle-wrapper {
        &::after {
          display: none;
        }
      }
    }

    .circle-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .name {
        color: $black;
        position: absolute;
        top: 100%;
        width: max-content;
        text-align: center;
        font-weight: 600;
        font-size: 10px;
      }

      &.filled {
        &::after {
          background-color: $primary;
        }
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        height: 1px;
        background-color: #ddd;
        left: 50%;
        top: 10px;
      }

      .step-circle {
        background-color: white;
        z-index: 1;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        color: white;
        font-weight: 600;

        &.filled {
          background-color: $primary;
          border-color: $primary;
        }

        &.touched {
          border-color: $primary;
        }
      }
    }
  }
}

// MEDIA

.submit-stepper {
  @media (max-width: 40em) {
    width: 40px;
    margin-bottom: 0;
    align-items: center;

    .step {
      .circle-wrapper {
        .step-circle {
          font-size: 7px;
          width: 10px;
          height: 10px;
        }

        &::after {
          top: 5px;
        }
      }
    }

    .name {
      display: none;
    }
  }
}
