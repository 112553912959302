@import 'styles/variables';

.profile-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (max-width: $mobile-width) {
    flex-direction: column;

    .text-block {
      margin: 10px 0 0 0;
      align-items: center;
    }
  }
}

.avatar-placeholder {
  width: 50px;
  height: 50px;
  background-color: $light-gray;
  border-radius: 50%;
}

.text-block {
  margin: 0 0 0 10px;
  width: 200px;
  height: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
}

.text-line {
  margin: 5px 0;
  background-color: $light-gray;
  width: 100%;
  border-radius: 3px;
  display: flex;
  flex: 1;

  &.half {
    width: 50%;
  }
}
