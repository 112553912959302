@import '../../../../styles/variables';

.filter-item-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.filter-item-header {
  display: flex;
  align-items: center;
  min-height: $main-header-height;
  padding: 0 10px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: $white;

  .close-icon {
    position: absolute;
    padding: 10px;
  }

  .title {
    flex: 1;
    text-align: center;
    font-weight: 600;
  }
}

.search-box-wrapper {
  padding: 10px;
  position: sticky;
  position: -webkit-sticky;
  top: $main-header-height;
  background-color: $white;

  .search-box {
    background-color: $background;
  }
}

.filter-item-header,
.search-box-wrapper,
.resources-list {
  border-bottom: 1px solid $light-gray;
}
