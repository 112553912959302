@import "../../../styles/variables";

.UserAvatar {
  position: relative;
  display: inline-block;
  text-align: center;
  flex-shrink: 0;

  .avatar-image,
  .avatar-letters {
    background: $brand-gray;
    border-radius: 50%;
    overflow: hidden;
  }

  .avatar-image {
    vertical-align: bottom;
  }

  .avatar-letters {
    color: $white;
    text-transform: uppercase;
  }

  .notification-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 5px;
    border-radius: 50%;
    width: 50%;
    height: 50%;
    right: 0;
    bottom: 0;
    color: $white;

    &.open_group,
    &.request_close_group {
      background-color: $brand-blue;
    }

    &.ff_comment {
      background-color: $aquamarine;
    }

    &.mention,
    &.direct_post,
    &.tenant_resource,
    &.svr_mention,
    &.svr_note_mention {
      background-color: $brand-orange;
    }

    &.diagnosis_alert,
    &.health_alert {
      background-color: $primary;
    }
  }

  .edit-avatar {
    display: block;
    width: 100%;
    height: 50%;
    bottom: 0;
    position: absolute;
    background-color: rgba(black, 0.5);
    cursor: pointer;
    border-radius: 0 0 100px 100px;
    transition: 0.3s;

    input {
      display: none;
    }

    &:hover {
      background-color: rgba(black, 0.7);
    }

    .edit-btn {
      z-index: 1;
      cursor: pointer;
      color: white;
      display: flex;
      padding-top: 10px;
      align-items: center;
      justify-content: center;
    }

    .camera-icon {
      font-size: 18px;
    }

    .edit-mode {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(black, 0.5);
      clip-path: circle(50% at center top);
      top: 50%;
      left: 0;

      &:hover {
        background-color: rgba(black, 0.7);
      }
    }
  }
}
