.CompanyFarmsRolesManageTable {

  @media (min-width: 64em) {
    margin: 0 -20px 20px;

    .column-farm-name {
      flex: 1 1 120px;
    }

    .column-roles {
      flex: 1 1 120px;
    }
  }

  .flex-data-table {
    .table-item {
      .table-item-block {
        .collapsible-value {
          padding: 7px 10px;

          &.with-checkbox {
            padding: 0;
          }
        }
      }
    }
  }

  .user-info .info {
    padding-left: 10px;

    .name {
      display: flex;
      flex-wrap: wrap;
      line-height: 16px;
      word-break: break-word;

      .farm-name {
        padding-right: 10px;
        font-weight: 600;
        margin: 2px 0;
      }

      .farm-type {
        margin: 2px 0;
      }
    }
  }
}
