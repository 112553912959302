@import '../../../styles/variables';

.warning-text {
  font-size: 24px;
  padding: 0 30px 20px;

  & > div {

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .brand-orange {
    color: $brand-orange;
  }

  .brand-blue {
    color: $brand-blue;
  }
}

.timestamp {
  padding: 10px 0 20px;
  font-size: 12px;
  color: $dark-gray;
}

.warning-collapse-box {
  margin: 15px 0;

  .warning-group-info {
    padding: 7px 10px;
  }
}
