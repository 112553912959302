.pig-group-movements {
  max-width: 622px;
  margin: 0 auto 50px;
  position: relative;
  font-size: 14px;
  padding: 0 10px;
  clear: both;

  .group-activity {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 20px;

      &::after {
        content: '';
        display: block;
        width: 3px;
        height: 30px;
        background-color: var(--color-gray-light);
        position: absolute;
        top: 100%;
        left: 25px;
      }
    }
  }

  .delivery-icon {
    margin: 0 5px;
    font-size: 12px;
  }

  .panel-text {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .waypoint {
    display: block;
    width: 100%;
    position: relative;
    height: 100px;
  }

  .preloader {
    margin-top: 10px;
  }
}
