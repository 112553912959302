@import '../../../styles/variables';

.birthday-congrats-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  background-image: linear-gradient(rgb(255, 255, 255) 3%, rgb(230, 242, 250) 103%);
  justify-content: center;
  text-align: center;
  border-radius: 5px 5px 0 0;

  .congrats-icon {
    width: 100px;
  }

  .birthday-boy-full-name {
    display: inline-block;
    font-size: 14px;
    margin-top: 10px;
  }

  .congrats-icon-message {
    width: 54px;
    margin-top: -6px;
  }

  .greeting-message {
    font-size: 40px;
    color: $brand-blue;
    margin-top: 20px;
    font-weight: 300;
    display: inline;
  }

  .greeting-text {
    font-size: 20px;
    font-weight: 400;
    margin-top: 15px;
  }

  @media screen and (max-width: $mobile-width-large) {

    .greeting-message {
      font-size: 32px;
    }

    .greeting-text {
      font-size: 18px;
    }
  }
}

