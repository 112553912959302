.group-feed-container {
  margin: 0 auto 100px;
  padding: 20px 10px;

  .panel-text {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
