@import '../../../styles/variables';

.empty-sections-block {
  border: 1px solid $light-gray;
  border-radius: 5px;
  background-color: $background;
  overflow: visible;
  position: relative;
  margin: 10px 0 20px;
}

.empty-sections-line {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray;
  padding: 10px;
  opacity: 0.6;

  &:last-child {
    border-bottom: none;
  }

  @media all and (max-width: 812px) {
    padding: 4px 10px;
  }
}

