@import '../../../../styles/variables';

.graph-tooltip {
  color: $dark-gray;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px;
  font-size: 12px;
  line-height: 1.5;
  align-items: flex-start;
  background-color: white;
  border: 1px solid $light-gray;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 20px;
  border-radius: 3px;

  &.antibiotic {
    display: inline-block;

    .antibiotic-title {
      text-align: start;
      color: $black;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .antibiotic-legend-wrapper {
      display: flex;
      align-items: center;

      .antibiotic-legend {
        height: 10px;
        width: 10px;
        border-radius: 2px;
        margin-right: 5px;
      }

      .antibiotic-legend-text {
        white-space: nowrap;
      }
    }
  }

  .title-balloon {
    padding-top: 15px;
  }

  .current-value {
    color: $black;
    font-size: 18px;
    font-weight: 600;
  }


  .tooltip-date {
    display: flex;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    color: black;
    margin: 10px;
  }

  .values-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    padding-left: 25px;
    white-space: nowrap;
    position: relative;
    margin-bottom: 10px;
    .value {
      font-size: 11px;
      color: $dark-gray;
    }

    &::after {
      display: block;
      content: '';
      height: 15px;
      width: 15px;
      position: absolute;
      left: 3px;
      top: 3px;
      border-radius: 2px;
      background-color: $brand-blue;
    }
  }

  .orange::after{
    background-color: $primary;
  }

  .growth-rate-block {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-size: 14px;

    &.negative {
      color: $brand-red;
    }

    &.positive {
      color: $brand-blue;
    }

    i {
      font-size: 9px;
      font-weight: 600;
      margin-right: 5px;
    }
  }
}

.line-balloon {
  font-size: 12px;
  font-weight: 600;
  color: white;
  background-color: #1E263E;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 3px 10px;
}
