@import "../../../styles/variables";

.DateInput {
  position: relative;

  .birthdate-field {
    display: flex;

    input.mobile-input,
    input {
      width: 100%;
      height: 40px;
      border: 2px solid $light-gray;
      padding-left: 10px;
      margin-bottom: 15px;
      cursor: pointer;
      -webkit-appearance: none;
      box-shadow: none;
      border-radius: 3px;
    }

    input.mobileDateInput {
      position: absolute;
      left: 0;
      color: transparent;
      background: transparent;
      border: none;
    }
  }

  .masked-input {
    position: relative;
    width: 100%;

    &.labeled {

      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .clear-date {
    z-index: 1;
    position: absolute;
    bottom: 25px;
    right: 15px;
    font-size: 14px;
    cursor: pointer;
  }

  .icon-label {
    z-index: 1;
    bottom: 16px;
    right: 0;
    padding: 5px;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: #fafafa;
    border: 2px solid #ddd;
    border-left: 0;
  }
}
