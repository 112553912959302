@import "../../../../../styles/variables";

.survey-box {
  min-height: 210px;
  max-width: 820px;
  padding: 0 20px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .survey-item {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px dashed #ddd;

    .survey-body {
      display: flex;
      margin-top: 10px;
      align-items: flex-start;

      @media screen and (max-width: 85em) {
        flex-wrap: wrap;
      }
    }

    &:first-child {

      .survey-body {
        margin-top: 0;
      }
    }

    .survey-item-box {
      flex-direction: column;
      justify-content: center;
      margin-right: 10px;

      .survey-date {
        width: 100%;
      }

      &.frequency {
        width: 110px;
      }

      &.start-date {
        width: 143px;
      }

      @media screen and (max-width: 85em) {

        &:first-child {
          margin-top: 0;
        }

        margin-top: 10px;
      }

      @media screen and (max-width: 50em) {

        &.frequency,
        &.start-date {
          width: 100%;
          max-width: none;
          margin-right: 0;
        }
      }

      &.hidden {
        visibility: hidden;
      }

      &:first-child {
        width: 100%;
        flex: 1;

        @media screen and (max-width: 85em) {
          width: 100%;
          flex: auto;
          margin-right: 0;
        }
      }

      &:last-child {
        min-width: auto;
        margin-right: 0;
      }

      &.remove-btn {
        max-width: 40px;
        cursor: pointer;
        font-size: 25px;
        align-self: flex-end;
        display: flex;

        @media screen and (max-width: 50em) {
          width: 100%;
          align-items: center;
          max-width: none;
        }

        i {
          display: flex;
          align-items: center;
          border-radius: 5px;
          transition: .2s;
          height: 40px;
          width: 40px;
          padding: 10px;

          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }

      .saved {

        input {
          background-color: $almost-white;
        }
      }
    }
  }

  .survey-labels {
    display: flex;
    padding-bottom: 5px;
  }

  .add-survey {
    align-self: flex-start;
    margin-top: 16px;
    color: $primary;
    font-weight: 400 !important;

    &:hover,
    &:focus,
    &:active {
      color: $primary !important;
    }
  }

  .btn-group {
    margin-left: auto;
    padding-bottom: 20px;
    font-size: 14px;

    button {
      margin-left: 10px;
    }

    @media screen and (max-width: 45em) {
      margin: 0;
      display: flex;
      width: 100%;
      flex-direction: column;

      button {
        font-size: 15px;
        margin-bottom: 10px;
        margin-left: 0;
      }
    }
  }
}
