.GrowthRateBlock {
  border-radius: 20px;
  font-size: 14px;
  background-color: var(--color-blue-light);
  color: var(--color-gray-dark);
  padding: 2px 4px;

  &.negative {
    background-color: rgb(255, 232, 223);
    color: var(--color-red-brand);
  }

  &.positive {
    background-color: rgb(227, 239, 245);
    color: var(--color-blue-brand);
  }

  &.is-colors-inverted {
    &.negative {
      background-color: rgb(255, 232, 223);
      color: var(--color-blue-brand);
    }

    &.positive {
      background-color: rgb(227, 239, 245);
      color: var(--color-red-brand);
    }
  }

  i {
    font-size: 9px;
    font-weight: 600;
    margin-right: 5px;
  }

  &.transparent,
  &.transparent.negative,
  &.transparent.positive {
    background-color: transparent;

  }

  &.small {
    font-size: 9px;
  }
}
