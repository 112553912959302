@import '../../../../styles/variables';

.field-select-row {
  display: flex;
  align-items: center;

  .field-select {
    width: 100%;
    margin-bottom: 15px;
  }

  :global(.Select-control) {
    margin: 0;
  }
}
