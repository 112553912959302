@import '../../../styles/variables';

.MobileList {

  .MobileListRow {
    padding: 10px 15px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light-gray;

    .dropdown-icon {
      color: $brand-gray;
      font-size: 25px;
      cursor: pointer;
    }
  }
}
