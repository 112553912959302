@import '../../../../../styles/variables';

.profile-collapse-section {

  .section-data {
    border-top: 1px solid $light-gray;
    padding: 20px 30px 30px;
  }

  .forgot-pass {
    font-size: 14px;
  }

  .orange-icon {
    color: $brand-orange;
  }
}

