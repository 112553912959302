@import '../../../styles/variables';

.tooltip-content {
  position: relative;

  .rc-tooltip-inner {
    padding: 0;
  }

  .placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: $white;
    z-index: -1;
    opacity: 0;
    transition: opacity .2s ease-in;

    &.active {
      z-index: 1;
      opacity: 1;
    }
  }

  .line-value {
    font-size: 22px;
    font-weight: 300;
    min-height: 30px;
    width: 90px;
    min-width: 90px;
    text-align: end;
    margin-right: 15px;
  }

  .line-label {
    text-align: left;
  }

  svg {
    width: 100%;
  }

  .info-row {
    display: flex;
    min-height: 60px;
    align-items: center;

    .treatment-icon {
      font-size: 36px;
      padding: 0 10px;
    }

    .row-header {
      padding-left: 10px;
    }

    .name-details {
      color: $dark-gray;
      font-size: 12px;

      .nickname {
        color: black;
      }

      .types {
        color: $primary;
        text-transform: capitalize;
      }
    }
  }

  .name-row {
    display: flex;
    align-items: center;

    .name {
      font-size: 16px;
      margin-right: 5px;
    }
  }

  .types {
    text-transform: capitalize;
  }

  .data-line-row {
    display: flex;
    align-items: center;
    line-height: normal;
    padding: 5px 10px;
    text-align: center;
    border-top: 1px solid $light-gray;

    &.last-line {
      border-bottom: 1px solid $light-gray;
    }
  }

  .manage-activity-row {
    display: flex;
    padding: 16px 5px 1px;

    .manage-btn {
      width: 335px;
      display: flex;
      height: 35px;
      justify-content: center;
      align-items: center;
      border: 1px solid $light-gray;
      border-radius: 5px;
      cursor: pointer;
      font-size: 13px;
      color: $dark-gray;
      font-weight: 600;
      transition: .3s time;

      .icon {
        margin-right: 5px;
        font-size: 20px;
      }

      &:hover {
        background-color: $almost-white;
      }
    }
  }
}
