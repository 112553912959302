.menu {
  position: absolute;
  z-index: 1;
  top: 100%;
  margin-top: 10px;
  left: 0;
  transition: opacity 0.3s ease;
  border-radius: 5px;
  opacity: 0;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0 -1px 12px 0;
  background-color: white;
  visibility: hidden;
  padding-top: 20px;
  min-width: 230px;

  &.opened {
    visibility: visible;
    opacity: 1;
  }

  .section-title {
    padding: 0 20px 10px;
    color: var(--color-black);
    font-size: 14px;
  }

  .checkbox-item {
    padding: 0 20px 10px;
    display: block;
  }

  .checkbox-label {
    opacity: 0.5;
    font-size: 14px;
    color: var(--color-black);
    font-weight: 600;
    padding-left: 5px;

    &.checked {
      opacity: 1;
    }
  }

  .day-picker-line {
    font-size: 12px;
    border-radius: 5px;
    padding: 0 20px 10px;

    .nav-icon {
      font-size: 12px;
      color: var(--color-black);
      padding-top: 2px;
    }

    :global {
      .CalendarMonth_table tbody {
        border: none;
      }

      .CalendarDay__default {
        border: none;
        padding: 0;
        border-radius: 50%;
        font-size: 12px;
        line-height: 29px; // for vertical centering, size 30x29 when daySize = 30
      }

      .CalendarDay__selected,
      .CalendarDay__selected_span,
      .CalendarDay__selected:active,
      .CalendarDay__selected:hover {
        background: var(--color-primary);
        color: white;
      }

      .CalendarDay__hovered_span {
        background: #ffe8df;
        color: var(--color-black);
      }

      .CalendarMonth_caption {
        font-size: 14px;
        color: var(--color-primary);
        padding-top: 10px;
      }

      .DayPicker_weekHeader {
        color: var(--color-black);
        top: 40px;
        opacity: 0.5;
      }

      .DayPickerNavigation_button__horizontal {
        top: 9px;
      }

      .DayPickerKeyboardShortcuts_show {
        display: none;
      }
    }
  }

  .value-input-line {
    padding: 0 20px 10px;
    font-size: 14px;
    color: var(--color-black);
    display: flex;
    align-items: baseline;
  }

  .value-input {
    width: 90px;
    height: 30px;
    margin-bottom: 0;
    margin-right: 10px;
    display: inline-block;
    font-weight: normal;

    &.longer {
      width: 150px;
    }
  }

  .value-async-select {
    margin: 0 20px 10px;

    &.multi {
      margin-bottom: 0;

      .multi-label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
      }

      :global {
        .Select {
          .Select-control {
            width: 170px;
          }
        }
      }
    }

    :global {
      .Select-control {
        height: 30px;
        width: 150px;

        .Select-arrow-zone {
          font-size: 12px;
          padding: 0 9px;
        }
      }

      .Select-placeholder {
        height: 30px;
        line-height: 30px;
        font-weight: normal;
      }

      .Select-input {
        height: 30px;
      }

      .Select-value {
        line-height: 30px;
        cursor: pointer;
      }

      .Select-menu-outer {
        max-width: 150px;
        max-height: 160px;
        overflow: hidden;

        .Select-menu {
          max-height: 158px;

          .Select-option {
            padding: 5px 10px;
            font-weight: normal;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }

  .from-or-to {
    flex: 1;
    opacity: 0.5;
    color: var(--color-black);
  }

  .value-label {
    font-weight: normal;
  }

  .done-button {
    margin-top: 10px;
    text-align: center;
    height: 40px;
    border-top: 1px solid var(--color-gray-light);
    width: 100%;
  }
}
