@import "../../../../styles/variables";

.tag-tooltip {
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: 5px;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 2;
  width: 350px;
  padding: 10px;
  min-width: 300px;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s, visibility .2s;
  transition-delay: .3s;

  @media screen and (max-width: $mobile-width-small) {
    width: 300px;
  }

  @media screen and (orientation: landscape) and (max-height: $mobile-width-small) {
    width: 300px;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  &.active {
    color: $primary;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border-top: 0;
    border-left: 0;
    top: 100%;
    background-color: $white;
    z-index: -1;
  }

  $left: var(--triangleOffset, 50%);

  &.mobile-top {
    bottom: 100%;
    margin-bottom: 20px;

    &:after {
      left: $left;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &.mobile-bottom {
    top: 100%;
    bottom: auto;
    margin-top: 20px;

    &:after {
      top: 0;
      left: $left;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &.top {
    transform: translateX(-50%);
    left: 50%;
    bottom: 100%;
    margin-bottom: 20px;

    &:after {
      left: $left;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &.left {
    transform: translateY(50%);
    right: 100%;
    bottom: 50%;
    margin-right: 20px;

    &:after {
      right: 0;
      top: 50%;
      transform: translate(50%, -50%) rotate(45deg);
    }
  }

  &.right {
    transform: translateY(50%);
    left: 100%;
    bottom: 50%;
    margin-left: 20px;

    &:after {
      top: 50%;
      transform: translate(-100%, -50%) rotate(45deg);
    }
  }

  &.bottom {
    transform: translateX(-50%);
    top: 100%;
    left: 50%;
    bottom: auto;
    margin-top: 20px;

    &:after {
      top: 0;
      left: $left;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &.default {
    top: 15px;

    &:after {
      display: none;
    }
  }
}
