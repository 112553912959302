@import '../../../styles/variables';

.button {
  position: relative;

  &.transparent.transparent-btn,
  &.transparent.transparent-btn:focus {
    color: $primary;
  }

  &.three-dots-btn {
    padding: 0;
    margin-bottom: 0;

    i {
      color: $dark-gray;
      font-size: 24px;
      cursor: pointer;
      padding: 5px;
    }

    @media screen and (max-width: $mobile-width) {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  &.sticky-footer {
    padding: 15px 0;
    width: 80%;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 6px 1px;
  }
}
