@import '../../../../styles/variables';

.TreatmentAntibiotic {

  .subnav-content {
    display: flex;
    justify-content: center;

    .DateRangePickerWrapper {
      z-index: 2;
      margin-right: 15px;
    }

    .select-period {
      width: 170px;
      margin-right: 15px;
    }

    @media screen and (max-width: $tablet-width) {
      width: 100%;
      flex-direction: column;

      .DateRangePickerWrapper {
        margin-right: 0;
      }

      .select-period {
        margin: 10px 0;
        width: 100%;
      }
    }
  }
}
