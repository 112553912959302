.treatments-history-table {
  .product-name-column {
    display: flex;
    align-items: center;

    .icon {
      font-size: 24px;
      margin: 0 10px 0 5px;
    }

    .color-primary {
      color: var(--color-primary);
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .fa-dots-three-horizontal {
      color: var(--color-gray-brand);
      font-size: 25px;
      cursor: pointer;
    }
  }

  .treatment-name {
    .value-amount {
      display: flex;
    }
  }
}
