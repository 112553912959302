@import '../../../styles/variables';

.import-result-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 185px;
  border: 1px solid $light-gray;
  border-radius: 5px;
  padding: 25px;
  background-color: white;
  margin-bottom: 20px;

  .check-icon {
    color: $primary;
    font-size: 50px;
    font-weight: 100;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 40em) {
    font-size: 14px;
    height: auto;
    padding: 10px;

    .check-icon {
      font-size: 30px;
      margin-bottom: 5px;
    }
  }
}

.groupImportPanel {
  display: flex;
  flex-direction: column;
  flex: 1;

  .panelBody {
    flex: 1;
    overflow: auto;

    &.final-step {
      .group-import-block {
        padding: 0;
      }
    }

    .success-message-block {
      margin-top: 20px;
      font-size: 14px;

      .headline,
      .headline i {
        font-weight: 600;
        color: $brand-blue;
      }
    }

    .error-message {
      margin-top: 20px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .group-import-block {
    padding: 15px;
    height: 100%;
    overflow: auto;

    .upload-input-block {
      max-width: 640px;

      .download-link {
        cursor: pointer;
        color: $primary;
        text-decoration: underline;
      }
    }
  }
}
