@import '../../../styles/variables';

.Groups {

  .Subnavigation-wrapper.is-sticky {
    z-index: 4;
  }
  .createGroupBtn{
    display: inline-block;
    background: #ff5e31;
    color: white;
    border: 1px solid #ff5e31;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    vertical-align: middle;
    border-radius: 3px;
    font-size: 0.75rem;
    padding: 0.85em 2em;
  }
}


.GroupsIndexTable {

  .FlexTableWrapper.scrollable .FlexTable {
    min-width: 1300px;
  }

  .mortality-graph {
    flex: 1;

    svg {
      width: 100%;
      max-height: 50px;
    }
  }

  .group-id-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-download-btn {
    display: flex;
    justify-content: center;
  }

  .request_close,
  .opened {
    background-color: $brand-blue;
  }

  .pending_close {
    background-color: $brand-red;
  }

  .awaiting_delivery,
  .scheduled_delivery {
    background-color: $brand-orange;
  }

  .draft {
    background-color: $almost-white;
    color: $dark-gray;
  }

  .download-link {
    color: $primary;
     &:hover {
       cursor: pointer;
     }
  }
}

@media screen and (max-width: 63.9375em) {
  .GroupsIndexTable {
    .FlexTableWrapper.scrollable .FlexTable {
      min-width: auto;
    }
  }
}

@media screen and (max-width: 40em) {
  .edit-shipment-footer {
    z-index: 0;
  }
}
