@import "../../../styles/variables";

.MobileCheckin {
  height: 100%;
  width: 100%;
  background-color: $background;

  .search-box-wrapper {
    background-color: #1E263E;
    padding: 10px 10px;
  }

  .farms-list {

    .checkin-farm-row {
      padding: 10px 10px;
      border-bottom: 1px solid $light-gray;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &.checked-in {
        background-color: $light-orange;
      }

      .checkin-farm {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 10px;

        .checkin-farm-info {
          margin-left: 10px;

          .name {
            font-weight: 600;
            color: $black;
            font-size: 14px;
            line-height: 1.6;
            word-break: break-word;
          }

          .description {
            font-weight: 400;
            color: $almost-black;
            font-size: 12px;
          }
        }
      }

      .button {
        margin: 0;
        flex-shrink: 0;
      }

      .here-label {
        font-size: 12px;
        font-weight: 600;
        padding: 5px;
        color: $primary;
        min-width: 80px;
      }
    }
  }

  .pagination-wrapper {
    padding: 10px 10px 0;
  }
}
