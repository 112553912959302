.filter-section-dropdown {
  position: relative;

  &.has-default-trigger {
    height: 35px;
    border: 1px solid var(--color-gray-light);
    border-radius: 3px;
    background-color: var(--color-gray-light);
    font-size: 13px;
    font-weight: 600;
    margin: 5px 10px 5px 0;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color .3s, color .3s, border-color .3s ease;

    &.opened {
      border-color: var(--color-primary);
      color: var(--color-primary);
      background-color: var(--color-orange-light);
    }

    &.empty-value {
      border-color: var(--color-orange-brand);
      color: var(--color-orange-brand);
      background-color: var(--color-yellow-light);
    }
  }

  &.disabled {
    background-color: var(--color-white-bg);
    font-size: 13px;
    color: var(--color-gray-dark);
    cursor: default;
  }

  .search-label {
    padding: 0 10px;
    flex: 1;
  }

  .filter-icon {
    font-size: 16px;
  }

  .remove-icon {
    font-size: 10px;
    font-weight: 600;
    transition: opacity .2s ease;
    visibility: hidden;
    opacity: 0;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &:hover {

    .remove-icon {
      visibility: visible;
      opacity: 1;
    }
  }
}
