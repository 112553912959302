@import '../../../../styles/variables';

.mention-btn {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    padding: 10px 10px;
    display: inline-block;
    border-radius: 50px;
    transition: all .2s;
    position: relative;
    font-size: 13px;
    background-color: $background;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 9px;
    font-weight: 600;
    border: none;
    cursor: pointer;
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(black,.2);
    color: $black;
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 3px 10px rgba(black, .2);
  }

  &.isDisabled {
    background-color: $light-gray;
    cursor: not-allowed;
    color: $dark-gray;
    box-shadow: none;

    &:hover {
      transform: translateY(0);
      box-shadow: none;
    }

    &:active,
    &:focus {
      transform: translateY(0);
      box-shadow: none;
    }
  }

  &.isActive {
    background-color: $primary;
    color: $white;

    &:hover {
      transform: translateY(0);
    }
  }
}
