.svr-box {
  border: 1px solid var(--color-gray-light);
  margin: 0 10px;
  font-size: 14px;
  border-radius: 5px;
  background: #fff;

  .svr-box-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    margin: 10px 0;

    .farm-info {
      flex: 1 auto;
      margin: 0 10px;

      .manager {
        color: var(--color-gray-dark);
      }
    }

    .farm-label {
      font-size: 10px;
    }
  }

  .svr-box-body {
    margin: 0 10px;
  }
}
