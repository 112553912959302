@import "../../../styles/variables";

.nav-button {
  position: absolute;
  width: 60px;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.nav-prev {
    left: 0;
  }

  &.nav-next {
    right: 0;
  }

  .btn-circle {
    font-size: 15px;
    border-radius: 50%;
    background-color: $white;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .3s ease;
    transform: scale(1);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);

    .btn-circle {
      transform: scale(1.15);
    }
  }
}
