.wrapperApi {
  .test{
    z-index: 999999;
    border: 2px black solid;
    background: red;
  }
  .containerButtons {
    display: flex;
    flex-direction: row;

    .pushBtn {
      display: flex;
      justify-content: center;
      width: 146px;
      height: 35px;
      padding: 10px 0;
      font-weight: 600;
      border: 1px solid #dddddd;
      border-radius: 3px;
      background-image: linear-gradient(0deg, rgba(245, 245, 245, 0.68) 3%, rgba(255, 255, 255, 0.68) 100%);
      background-position: center center;
      font-family: Open Sans;
      font-size: 14px;
      color: #303030;
      text-decoration: none solid rgb(48, 48, 48);
      text-align: center;
      margin-right: 10px;

    }

    img {
      height: 16px;
      width: 16px;
      margin-right: 5px;
    }

    span {
      width: max-content !important;
      white-space: nowrap;
    }

  }
  .FlexTableItem {
    padding-left: 20px;
  }

  .containerResult {
    display: flex;
    align-items: center;

    .itemResult {
      margin-left: 0;
      display: flex;
      width: 80%;
      height: max-content;
      span {
        font-size: 10px;
      }
    }

    :nth-child(2) {
      display: flex;
      width: 15%;
      height: max-content;
      justify-content: flex-end;
    }

    .resultBtn {
      width: 35px;
      height: 35px;
      border: 1px solid #dddddd;
      background: white;

      img {
        height: 18px;
        width: 18px;
        margin: 0px;
      }
    }
  }


  .groupIdName {
    color: #ff5e31;
  }
}
