@import '../../../../styles/variables';

.tenant-assets-table {
  position: relative;
  padding-bottom: 60px;

  &.all-assets-loaded {
    padding-bottom: 20px;
  }
}

.assets-table-panel {
  margin: 30px 30px 10px;
  width: auto;

  .assets-table-body {
    overflow: hidden;

    &.no-bottom-border {
      border-bottom: none;
    }

    .not-viewed:before {
      content: '';
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      border-top: 15px solid $primary;
      border-right: 15px solid transparent;
      position: absolute;
      z-index: 1;
    }

    :global(.collapsible-value .value-amount) {
      font-size: 12px;
    }

    .dropdown-icon {
      color: $brand-gray;
      font-size: 25px;
      cursor: pointer;
    }
  }

  .icon-col {
    flex: 0 0 40px;
    text-align: center;
    margin-right: 10px;
  }

  .asset-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $primary;
    cursor: pointer;
  }
}
