@import "../../../styles/variables";

.AppContainer {
  height: 100%;

  // force IOS inputs {font-size: 16px}
  // to prevent scaling on focus

  &.isIOS {
    select,
    textarea,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='search'] {
      font-size: 16px !important;
    }
  }
}
