@import "styles/variables";

.add-question-btn {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: $nice-grey;
  width: 100%;
  position: relative;
  background-color: white;
  z-index: 2;

  .btn-label {
    cursor: pointer;
    z-index: 1;
    padding: 0 5px;
    background-color: white;
  }

  &:hover {
    color: $primary;

    &::after {
      border-color: $primary;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-bottom: 1px dashed $nice-grey;
  }
}
