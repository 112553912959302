@import '../../../../styles/variables';

.farm-profile {

  .mobile-body-box {
    padding: 10px 10px 20px;
  }

  .user-alert, .progress-box {
    margin: 0 0 10px;
  }

  .enable-btn {
    text-decoration: underline;
    margin: 0 10px 0 auto;
    font-weight: 600;
  }

  @media screen and (min-width: 64em) {
    .mobile-body {
      border: none;
      background-color: transparent;

      .mobile-body-box {
        padding: 0;
      }

      .user-alert {
        margin: 0 0 20px;
      }
    }
  }
}
