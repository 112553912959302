@import '../../../styles/variables';

.load-more {
  margin: 10px auto;
}

.TimeLineSeparator {
  padding: 4px 12px;
  border-radius: 5px;
  background-color: $brand-gray;
  color: $white;
  margin: 15px 10px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;

  &.today {
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(255, 94, 58) 3%, rgb(255, 140, 59) 103%);
  }
}
