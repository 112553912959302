@import "../../../styles/variables";

.radio-group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
  margin: 0;
  font-size: 14px;

  &.disabled label {
    cursor: not-allowed;
  }

  label {
    display: flex;
    height: 40px;
    padding: 0;
    border: 0;
    flex: 1;
    cursor: pointer;

    &:first-child {
      border-left: 0;
    }

    input {
      position: absolute;
      left: -9999px;

      + span {
        background: $background;
        color: $dark-gray;
        font-weight: 600;
        border: 2px solid $light-gray;
        border-width: 2px 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 18px;
        width: 100%;
        text-align: center;
        transition: all .25s;
      }

      &:checked + span {
        color: $white;
        background: $primary;
        border-color: $primary;
      }
    }

    &:first-child input + span {
      border-left-width: 2px;
    }

    &:last-child input + span {
      border-right-width: 2px;
    }
  }
}
