@import "../../../../../styles/variables";

.VetBotAlertModal {
  text-align: center;

  .modal-body {

    .vet-bot-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 50px;
      padding: 20px;
      border: 4px solid $light-gray;
      border-radius: 10px;
      position: relative;

      .vet-bot-image {
        position: relative;
        min-width: 90px;

        image {
          width: 100%;
        }

        .vet-bot-label {
          border-radius: 10px;
          font-size: 13px;
          color: white;
          background-color: #0f182c;
          padding: 2px 15px;
          position: absolute;
          bottom: -10px;
          z-index: 1;
          left: 50%;
          transform: translate(-50%);
        }
      }



      .text {
        font-size: 22px;
        margin-left: 20px;
        text-align: left;
      }

      &::after {
        content: ' ';
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        border: 4px solid $light-gray;
        border-top: 0;
        border-left: 0;
        border-bottom-right-radius: 8px;
        bottom: -18px;
        left: 50px;
        background-color: $white;
        transform: rotate(45deg);
      }

      @media screen and (max-width: 40em) {
        flex-direction: column;

        .text {
          margin-top: 20px;
          text-align: center;
        }

        &::after {
          left: calc(50% - 15px);
        }
      }
    }
  }

  .modal-footer {
    display: flex;
    padding: 0;

    .button {
      flex: 1 1 100%;
      padding: 20px;
      font-size: 18px;
      margin: 0;
      border: none;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 70px;

      i {
        font-size: 32px;
      }
    }
  }
}
