@import "../../../../styles/variables";

.PigGroupDailyCheckup {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;

  .alert-container {
    background: #fffceb;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 13px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    margin-bottom: 10px;
  }

  .checkup-date {
    margin: auto;
  }

  .offline-no-checkup-warning {
    text-align: center;
    padding: 40px 10px;

    .attention-icon {
      display: block;
      padding: 10px 0;
      color: $medium-gray;
      font-size: 48px;
    }

    .message {
      font-size: 18px;
    }
  }

  .checkup-body {
    flex: 1;
    margin-top: 20px;
    margin-bottom: 20px;

    &.pb-100 {
      padding-bottom: 100px;
    }
  }

  .StickyFooter {

    .big-button {
      padding: 20px 0;
      border-radius: 50px;
      box-shadow: rgba(0, 0, 0, 0.3) 0 1px 6px 1px;

      @media screen and (max-width: 40em) {
        padding: 15px 0;
      }
    }
  }
}
