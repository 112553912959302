@import '../../../../../styles/variables';

.AISuggestionModal {
  text-align: center;

  .magic-loop-image {
    max-width: 80px;
  }

  .suggestion-modal-body {
    padding: 50px 50px 0;

    .text {
      font-size: 22px;
      padding-top: 50px;
    }
  }

  .suggestion-modal-footer {
    padding: 40px 0;

    .got-it-btn {
      min-height: 55px;
      min-width: 110px;
    }
  }

  @media screen and (max-width: $mobile-width-large ) {
    .magic-loop-image {
      max-width: 60px;
    }

    .suggestion-modal-body {
      padding: 35px 35px 0;

      .text {
        font-size: 16px;
        padding-top: 35px;
      }
    }

    .suggestion-modal-footer {
      padding: 30px 0;

      .got-it-btn {
        min-height: 50px;
      }
    }
  }
}
