@import '../../../styles/variables';

.redux-toastr.toastr-container {

  &.toastr-message {
    color: black;
  }

  &.isMobile {

    .bottom-center {
      bottom: $main-footer-height;

      @media (orientation: landscape) {
        bottom: $main-footer-height-shrinked;
      }
    }
  }

  .top-center,
  .bottom-center {

    @media screen and (max-width: 400px) {
      left: 0;
      margin-left: 0;
      width: 100%;
      padding: 0 5px;
    }
  }

  .toastr {
    position: relative;
    max-width: calc(100vw - 10px);
    opacity: 1;
    border: 1px solid $light-gray;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 8px;

    &.file-type-error {
      min-height: 110px;
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0 3px 8px !important;

      .toastr-middle-container {
        background-color: rgba(255, 255, 255, .9);
      }
    }

    &.rrt-success {
      border-color: $brand-blue !important;
      background-color: $brand-blue !important;
    }

    &.rrt-warning {
      border-color: #f7a336;
      background-color: #f7a336;
    }

    &.rrt-info {
      border-color: #60bb71;
      background-color: #60bb71;
    }

    &.rrt-error {
      border-color: #db6a64;
      background-color: #db6a64;
    }

    .rrt-left-container,
    .rrt-middle-container,
    .rrt-right-container {
      padding: 0;
      margin: 0;
      width: auto;
    }

    .rrt-left-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;

      .rrt-holder {
        position: relative;
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
        top: 0;
        left: 0;
        line-height: inherit;

        i {
          font-size: 20px;
          color: #fff;
        }
      }
    }

    .rrt-middle-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 45px;
      background-color: rgba(255, 255, 255, .95);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 15px;
      font-family: inherit;

      .rrt-text {
        font-size: 14px;
        color: $black;
      }
    }
  }

  .toastr.dr-bot {
    min-height: 120px;

    .rrt-left-container {
      width: 75px;

      .rrt-holder {
        margin-left: 10px;
      }

      img {
        max-height: 100px;
        max-width: 55px;
      }
    }

    .rrt-middle-container {
      left: 75px;
      padding-right: 25px;

      .rrt-text {
        font-size: 16px;
      }
    }

    .close-toastr {
      width: auto;
      height: auto;
      padding: 10px;
    }
  }

  .top-right {
    top: $main-header-height;
    width: 370px;
  }

  .toastr.web-notification {
    min-height: 90px;
    display: flex;
    align-items: center;
    background: $white;

    .close-toastr {
      height: auto;
      width: auto;

      span {
        right: 5px;
        font-size: 20px;
        left: auto;
        transform: translate(0,0);
      }
    }

    .rrt-left-container {
      display: none;
    }

    .rrt-middle-container {
      position: relative;
      left: 0;
      padding: 10px 25px 10px 10px;
    }
  }
}
