
.Templates {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ModalTemplates {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: #1e263e;
  }

  .Board {
    position: absolute;
    width: 580px;
    height: 440px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    background-color: #ffffff;

    &.redirectBoard{
      height: 370px;
    }

    .header {
      text-decoration: none solid rgb(48, 48, 48);
      min-height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      border: 1px solid #e8e8e8;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #f5f5f5;
      font-family: Open Sans;
      font-size: 18px;
      color: #303030;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .bodyTemplates {
      display: flex;
      padding: 0 50px;
      //height: max-content;
      flex-wrap: wrap;
      height: calc(100% - 70px);

      > :nth-child(3) {
        margin: 30px 0 0 0;
      }

    }
  }

  .nextStepCsv {
    color: #303030;
    &:hover {
      border: 1px solid #ff5e31;
      text-decoration: none solid rgb(255, 94, 49);
      box-shadow: 0px 0px 12px 3px rgba(247, 77, 142, 0.2);
    }
    img {
      width: 29px;
      height: 29px;
    }

  }

  .nextStepMetaFarms, .nextStepFAS {
    cursor: pointer;
    border: 1px solid #dddddd;
    text-decoration: none solid rgb(163, 163, 163);
    box-shadow: none;
    color: #303030;
    &:hover {
      border: 1px solid #68904a;
      box-shadow: 0px 0px 12px 3px rgba(104, 144, 74, 0.2);
    }
  }

  .nextStepFAS {
    &:hover {
      border: 1px solid #2b2c6b;
      box-shadow: 0px 0px 12px 3px rgba(43, 44, 107, 0.3);
    }
  }

}






