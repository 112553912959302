@import "../../../../styles/variables";

.MobileRow {
  border-top: 1px solid $light-gray;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  position: relative;

  &.select-row {
    padding: 7px 10px;
  }

  &.no-events {
    pointer-events: none;

    .name-column {
      color: $brand-gray;
    }

    .icon {
      opacity: .2;
    }
  }

  .name-column {
    flex: 1;
    margin: 0 10px;

    .name {
      font-weight: normal;
    }

    .nickname {
      color: $dark-gray;
      font-size: 12px;
    }
  }

  .select-btn {
    margin-bottom: 0;
    padding: 10px 7px;
  }

  .icon {
    position: relative;
    width: 25px;
    height: 25px;
    color: $dark-gray;

    .checked,
    .unchecked {
      font-size: 25px;
      position: absolute;
    }

    .checked {
      opacity: 0;
      color: $primary;
    }

    .unchecked {
      opacity: 1;
    }

    &.selected {
      .checked {
        opacity: 1;
      }
      .unchecked {
        color: $primary;
      }
    }
  }
}
