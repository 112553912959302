@import "../../../../styles/variables";

.comment-head {
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
  padding: 5px 15px;
  background: $almost-white;
  font-weight: 600;
}

.comment-body {
  font-style: italic;
  line-height: inherit;
  padding: 8px 15px;
  word-wrap: break-word;
  white-space: pre-wrap;
  background-color: $light-yellow;
}
