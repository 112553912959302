@import '../../../styles/variables';

.ChartPlaceholder {
  font-size: 50px;
  color: $dark-gray;

  .container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    align-content: center;

    .text-placeholder {
      font-size: 18px;
    }
  }
}
