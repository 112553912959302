@import "../../../../../styles/variables";

.offline-submit-alert {
  border-radius: 5px;
  overflow: hidden;
  max-width: 365px;
  margin: 0 auto;
  pointer-events: all;

  .offline-submit-body {
    padding: 10px;
    background-color: $white;
    display: flex;
  }

  .offline-bot-doc {
    text-align: center;

    .offline-bot-doc-image {
      width: 52px;
      margin-bottom: -10px;
    }

    .offline-bot-doc-name {
      padding: 2px 10px;
      display: inline;
      background-color: $dark-blue;
      border-radius: 10px;
      color: $white;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .offline-bot-message {
    padding: 0 15px;
    font-size: 16px;
  }

  .bottom-btn {
    width: 100%;
    border-radius: 0 0 3px 3px;
  }
}
