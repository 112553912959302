@import '../../../styles/variables';

.FlexTable {
  position: relative;
  z-index: 1;

  .placeholder {
    text-align: center;
    line-height: 100px;
    color: $dark-gray;
  }

  &:not(.desktop-view) {
    .table-row {
      @media (max-width: 63.9375em) {
        font-size: 13px;
        margin-bottom: 20px;
        border: 1px solid $light-gray;
        border-radius: 5px;
        overflow: hidden;
      }
    }
  }

  .table-row {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $light-gray;
    background: $white;
    font-size: 14px;
    position: relative;
    z-index: 2;

    @media (min-width: 64em) {

      &:hover {
        background-color: $background;

        .FlexTableItem {
          background-color: $background;
        }
      }
    }
  }

  @media (min-width: 64em) {

    &.scrolledLeft .FlexTableItem.fixed-column {
      box-shadow: -1px 0 2px $light-gray;
    }

    &.scrolledRight .FlexTableItem.fixed-column {
      box-shadow: 1px 0 2px $light-gray;
    }

    &.scrolledLeft .FlexTableItem.fixed-column:first-child {
      box-shadow: 7px 0 5px $light-gray;
    }

    &.scrolledRight .FlexTableItem.fixed-column:last-child {
      box-shadow: -7px 0 5px $light-gray;
    }
  }
}

.FlexTableWrapper {

  &.scrollable {
    @media (min-width: 64em) {
      overflow-x: auto;
      overflow-y: hidden;

      .FlexTable {
        min-width: 1000px;

        &.is-tablet {
          min-width: 900px;
        }
      }
    }
  }

  svg {
    width: 100%;
  }
}
