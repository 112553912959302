@import "../../../styles/variables";

.reported-deaths {
  font-size: 14px;

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border-top: 1px solid $light-gray;

    &.highlighted {
      background-color: $light-yellow;
    }

    .name {
      display: inline-block;
      font-weight: 400;
    }

    .value {
      display: inline-block;
      font-weight: 600;
    }
  }
}
