  @import "../../../../styles/variables";

.MonthlyDatePickerMobile {

  .header-dates {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    min-height: 60px;

    .selected-date {
      text-align: center;
      font-size: 18px;
    }
  }

  .MonthlyPicker {
    height: 100%;
    width: 100%;
    position: static;
    margin: 0 auto;
    max-width: 340px;
    box-shadow: none;
  }
}
