.CompanyUserRolesManageTable {

  .flex-data-table {
    .table-item {
      .table-item-block {
        .collapsible-value {
          padding: 7px 10px;

          &.with-checkbox {
            padding: 0;
          }

          .value-amount {
            .user-info {

              .avatar {
                width: 35px;
                height: 35px;
                line-height: 35px;
                min-width: 35px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 64em) {
    margin: 0 -20px 20px;

    .column-username {
      flex: 2 0 120px;
    }

    .column-roles {
      flex: 1 1 120px;
    }
  }
}
