.treatment-protocol-form {
  font-size: 14px;

  .protocol-comment {
    padding: 0 15px;
    max-width: 760px;
    margin-bottom: 15px;

    @media screen and (max-width: 63em) {
      max-width: unset;
    }
  }

  .action-btn-group {
    display: flex;
    align-items: center;

    .submit-btn {
      margin-left: auto;
    }
  }
}
