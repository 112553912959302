.time-range {
  display: flex;
  align-items: center;

  .time-format {
    cursor: pointer;
    font-size: 20px;
    color: var(--color-gray-nice);
    font-weight: 600;
    user-select: none;

    &.active {
      color: var(--color-primary);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .label {
    display: inline-flex;
  }

  .input {
    height: 50px;
    border-radius: 3px 0 0 3px;
    margin: 0;
    min-width: 40px;
    width: 45px;
  }

  .buttons {
    border: 2px solid var(--color-gray-light);
    border-radius: 0 3px 3px 0;
    border-left: none;
    color: var(--color-gray-nice);
    background-color: var(--color-white-bg);
  }
}

.arrows-buttons {
  display: flex;
  flex-direction: column;
  user-select: none;
  cursor: pointer;

  .icon {
    padding: 4px 11px;
    height: 23px;
  }
}
