@import '../../../styles/variables';

.notification-msg {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;

  .nots-avatar {
    margin-right: 10px;
    flex-shrink: 0;
  }

  i {
    &.groups-icon {
      color: $brand-blue;
      font-size: 35px;
      padding: 2px;
    }

    &.clock-icon {
      color: rgb(186, 186, 186);
      font-size: 35px;
      padding: 2px;
    }

    &.warning-icon {
      color: $brand-orange;
      font-size: 35px;
      padding: 2px;
    }
  }

  .toastr-dc-mention {
    font-size: 50px;
    color: $brand-blue;
    position: relative;

    .mention-icon {
      position: absolute;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 50%;
      height: 50%;
      right: 0;
      bottom: 0;
      color: $white;
      background-color: $brand-orange;
    }
  }

  .magic-loop-image {
    width: 35px;
    min-width: 35px;
  }

  :global(.congrats-icon),
  .congrats-icon {
    max-width: 50px;
  }

  :global(.trophy-icon),
  .trophy-icon {
    max-width: 34px;
  }

  :global(.encourage-icon) {
    max-width: 34px;
  }
}
