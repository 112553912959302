@import '../../../styles/variables';

.search-list {
  position: absolute;
  border: 1px solid $light-gray;
  border-radius: 5px;
  margin-top: 3px;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.4);
  min-width: 400px;

  .search-box-wrapper {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid $light-gray;
    padding: 10px;
    background-color: $white;

    .search-box {
      max-width: 100%;
    }
  }

  .list {
    max-height: 290px;
    background-color: white;
    overflow-y: auto;

    .line {
      padding: 10px 20px;
      border-bottom: 1px solid $light-gray;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        cursor: pointer;
        background-color: $almost-white;
      }
    }
  }
}
