@import '../../../../../styles/variables';

.stats-box {
  margin: 15px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 75em) {
    flex-direction: column;

    .stat-item:first-child {
      margin: 0 0 20px;
    }
  }
}

.stat-item {
  flex: 1;

  &:first-child {
    margin-right: 20px;
  }
}

.legend {
  color: $dark-gray;
  font-size: 12px;
  margin-right: 5px;
}

.square {
  width: 12px;
  height: 12px;
  border: 1px solid $black;
  border-radius: 2px;
  background-color: rgb(255, 243, 187);
}
