.Input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 2px solid var(--color-gray-light);
  margin: 0 0 15px;
  font-family: inherit;
  font-size: 14px;
  color: var(--color-black);
  background-color: white;
  border-radius: 3px;
  transition: box-shadow .5s,border-color .25s ease-in-out;
  box-shadow: none;
  -webkit-appearance: none;

  &.no-margin {
    margin: 0;
  }

  &.align-right {
    text-align: right;
  }

  &.bolded {
    font-weight: 600;
  }

  &.has-error,
  &:focus {
    border: 2px solid var(--color-primary);
    background-color: white;
    outline: none;
    box-shadow: 0 0 4px #ff8664;
  }

  &:disabled, &[readOnly] {
    background-color: var(--color-gray-light);
    cursor: not-allowed;
  }

  &::placeholder {
    color: var(--color-gray-medium);
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.input-label {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
}

.input-view {
  width: 100%;
  height: 40px;
  padding: 8px;
  font-size: 14px;
  color: var(--color-black);
  background-color: var(--color-white-almost);
  border-radius: 3px;
}
