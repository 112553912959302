.offline-section {
  max-width: 612px;
  margin: 0 auto;

  &.screen {
    display: flex;
    align-items: center;
    height: 100%;

    .offline-content {
      background: transparent;
      border: none;
    }
  }

  .offline-content {
    text-align: center;
    margin: 10px;
    background: white;
    border-radius: 5px;
    border: 1px solid var(--color-gray-light);
    padding: 30px 10px;
  }

  .offline-icon {
    margin: 0 0 40px;
  }

  .label-text {
    font-size: 18px;
    font-weight: 600;
  }

  .sub-label-text {
    font-size: 16px;
    margin: 0 0 32px;
  }

  .link {
    color: var(--color-primary);
    text-decoration: none;
  }
}
