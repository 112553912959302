@import '../../../../styles/variables';

.TreatmentFeedbackMedication {

  .treatments-feedback-section {
    @media screen and (max-width: 63.9375em) {
      padding: 0;
      margin: 0;

      .treatment-feedback-panel,
      .treatment-feedback-body {
        border-radius: 0;
      }
    }
  }

  .group-name-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;

    .group-name {
      margin: 0 10px;
    }

    .fa-dots-three-horizontal {
      color: $brand-gray;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .feedback-label {
    padding: 2px 8px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $white;

    &.effective {
      background-color: #0E6A9F;
    }

    &.ineffective {
      background-color: #DA372C;
    }
  }

  .ineffective {
    background-color: $light-orange;
  }
}
