@import '../../../styles/variables';

.FarmFeedNullState {

  .null-state-box {
    border: 1px solid $light-gray;
    border-radius: 5px;
    background: $white;
    display: flex;
    margin: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .null-state-placeholder {
      font-size: 24px;
      text-align: center;
      padding: 40px 15px;

      .label-text {
        font-size: 24px;
        font-weight: 600;
      }

      .sub-label-text {
        font-size: 18px;
      }

      .null-state-img {
        min-width: 250px;
        max-height: 380px;
      }
    }
  }
}
