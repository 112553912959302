@import "../../../../styles/variables";

.AssetMap {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;

  .asset-map-content {
    display: flex;
    flex: 1 1 200px;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;

    .map-wrapper {
      position: relative;
      flex: 1 1 200px;

      &.showEmptyMsg {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .preloader {
        top: 5%;
        transform: translate3d(-50%, 0, 0);
      }

      .filter-button {
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 10px;

        .button {
          width: 100%;
          padding: 15px;
          font-size: 16px;
        }
      }

      .empty-message {
        background-color: $dark-blue;
        color: $white;
        border-radius: 15px;
        z-index: 1;
        font-size: 12px;
        font-weight: 600;
        padding: 7px 15px;
        white-space: nowrap;
        opacity: 1;
        transition-property: opacity;
        transition-duration: .5s;
        will-change: opacity;
        display: block;

        .fa {
          margin-right: 10px;
        }

        &.fade-out {
          opacity: 0;
          display: none;
        }
      }
    }

    .form-wrapper {
      background-color: $white;
      flex: 0 0 235px;
      border-right: 1px solid $light-gray;
    }
  }
}
