@import '../../../../styles/variables';

.add-resource-body {
  display: flex;
  justify-content: space-between;
}

.url-input-body {
  min-height: 200px;

  .url-label {
    font-size: 14px;
    margin: 20px 0 5px;
    font-weight: 600;
  }

  .error-text {
    margin-top: 5px;
    font-size: 14px;
    color: $brand-red;
  }

  @media screen and (max-height: $mobile-width-large) {
    min-height: 120px;
  }
}

.add-resource-square {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: border-color .3s, background-color .3s ease;
  margin: 10px;

  &:hover {
    border-color: #ddd;
    border-radius: 8px;
    background-color: $background;
    cursor: pointer;
  }

  .icon {
    font-size: 45px;
    margin-bottom: 15px;
  }

  .file-icon {
    max-width: 35px;
    margin-bottom: 15px;
  }

  span {
    color: $primary;
  }

  @media screen and (max-width: $tablet-width) {
    height: auto;
    width: auto;
    flex: 1;
    padding: 20px 10px;
  }
}
