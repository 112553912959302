@import "../../styles/variables";

.VideoPreviewOverlay {
  position: absolute;
  background-color: rgba(0, 0, 0, .2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &.no-backdrop {
    background-color: transparent;
  }

  .process-loader {
    display: flex;
    flex-direction: column;
    align-items: center;

    .loader-caption {
      display: flex;
      flex-direction: column;
      align-items: center;

      .process-header {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 600;
      }

      .process-tip {
        font-size: 16px;
        color: #777;
      }
    }
  }

  .video-icon {
    display: block;
    color: $white;
    background-color: rgba(48, 48, 48, .7);
    border: 4px solid rgba(255, 255, 255, .8);
    padding: 10px 9px 10px 11px;
    border-radius: 50%;

    &.medium {
      padding: 8px 7px 8px 9px;
    }

    &.small {
      padding: 6px 5px 6px 7px;
    }

    &.simple {
      background-color: transparent;
      border: none;
    }
  }
}
