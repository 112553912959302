@import '../../../../styles/variables';

.CreateTenantForm {

  .company-owner {
    border-bottom: 1px solid $light-gray;
    padding: 20px 0;
    font-size: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  .phone-description {
    font-size: 12px;
  }

  .nickname-icon {
    font-size: 18px;
    color: $medium-gray;
  }
}
