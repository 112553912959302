@import "../../../../styles/variables";

.user-checkins-table {

  @media screen and (max-width: 63.9375em) {
    margin: 0;
    background-color: $white;
  }

  .mobile-table-wrapper {
    margin-bottom: 20px;
    padding: 0 10px;
  }

  .mobile-table {
    margin: 0 -10px 20px;
  }

  .search {
    padding: 10px;
    border-bottom: 1px solid $light-gray;
    background-color: $background;
    margin: 0 -10px;
  }

  .mobile-row {
    padding: 10px 15px 10px 10px;
    display: flex;
    border-bottom: 1px solid $light-gray;

    .user-avatar {
      margin-right: 10px;
      align-self: center;
    }

    .info {
      flex: 1;
    }

    .name {
      font-size: 14px;
      font-weight: 600;
    }

    .description {
      font-size: 11px;
      color: $dark-gray;

      time {
        white-space: nowrap;
      }
    }

    .dropdown-icon {
      color: $brand-gray;
      font-size: 25px;
      cursor: pointer;
      margin-left: auto;
      align-self: center;
    }
  }
}
