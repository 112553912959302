@import "../../../styles/variables";

.AnalyticsDropdown {

  .dropdown-trigger {
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
      padding: 9px 7px 5px;
      border-radius: 50%;
      margin-left: 3px;
      transition: background-color .2s ease;
      font-weight: 600;
      font-size: 11px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .dropdown {
    transform: translateX(100%);
    @media (orientation: landscape) and (max-width: 1024px) {
      width: max-content;
    }
  }

  .responsive-dropdown-container {

    .responsive-dropdown {
      transform: translateX(0);
      top: 100%;
      margin-right: 20px;
      z-index: 9;
      width: calc(100vw - 40px);

      &:before {
        content: '';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
        position: absolute;
        bottom: 100%;
      }

      &.above:before {
        content: '';
        border-top: 10px solid $white;
        border-bottom: none;
        position: absolute;
        top: 100%;
      }

      &.center:before {
        display: none;
      }
    }
  }

  .dropdown {
    &.above {
      margin-bottom: 10px;
      bottom: 100%;
      top: inherit;
    }
  }

  ul.dropdown-menu {
    z-index: 1000;
    padding: 0;

    li {
      border-bottom: 1px solid $light-gray;
      padding: 11px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &.active-option {
        background: $background;
      }

      .option-label {
        flex: 1 auto;
      }

      .view-more-options {
        font-weight: 600;
      }

      &:last-child {
        border: none;
      }

      .fa-check {
        color: $brand-blue;
        padding: 3px 0;
      }

      .fa-angle-down {
        font-weight: 600;
        font-size: 11px;
        padding: 9px 7px 5px;
      }
    }
  }
}
