.treatment-protocol-title {
  display: flex;
  align-items: center;
}

.product-name-column {
  display: flex;
  align-items: center;

  .icon {
    font-size: 24px;
    margin: 0 10px 0 5px;
  }
}

.treatment-protocol-header {
  padding: 15px;
  background-color: white;
  display: flex;
  border-bottom: 1px solid var(--color-gray-light);

  .treatment-protocol-information {
    display: flex;
    align-items: center;
  }

  .header-dropdown {
    margin-left: auto;
  }
}
