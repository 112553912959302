@import "../../../styles/variables";

.CountryPhoneInput {
  position: relative;

  .country-select {
    position: relative;

    .selected-country {
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 2px;
      width: 60px;
      background-color: $background;
      border-right: 2px solid $light-gray;
      display: flex;
      align-items: center;
      cursor: pointer;
      z-index: 1;

      .flag-icon {
        flex: 1 auto;
      }

      .country-arrow {
        font-size: 12px;
        padding-right: 6px;

        &.orange {
          color: $primary;
        }
      }
    }

    .dialling-code {
      color: $dark-gray;
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 62px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      z-index: 1;
      font-size: 14px;

      &.isIOS {
        font-size: 16px;
      }
    }

    .phone-input {
      padding-left: 100px;
      -webkit-transform: translateZ(0px);
    }
  }

  .countries-data {
    position: absolute;
    transition: opacity .4s linear;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    visibility: hidden;

    &.open {
      visibility: visible;
      z-index: 2;
    }

    &.above {
      box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .country-list {
    max-height: 300px;
    width: 100%;
    overflow-y: scroll;
    border: 2px solid $light-gray;
    border-radius: 5px;
    top: 100%;
    margin-top: 2px;
    background-color: $background;

    &.above {
      top: auto;
      margin-bottom: 2px;
      bottom: 100%;
    }

    .country-search {
      width: 100%;
      border-bottom: 1px solid $light-gray;
      padding: 8px 5px;
      min-height: 48px;
      background-color: $white;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 1;

      .search-input {
        margin: 0 auto;
        font-size: 14px;
        max-width: 100%;

        &.SearchBox {
          input[type='search'] {
            min-width: 120px;
          }
        }
      }
    }

    .country {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 48px;
      border-bottom: 1px solid $light-gray;
      padding: 5px 10px;
      cursor: pointer;
      background-color: $white;

      &.selected {
        background-color: $light-orange;
        pointer-events: none;
      }

      &:last-of-type {
        border: none;
      }

      &:hover {
        background-color: rgb(227, 239, 245);
      }

      .country-name {
        padding: 0 5px 0 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .country {
    font-size: 14px;

    .flag-icon {
      min-width: 23px;
      min-height: 18px;
    }

    .country-code {
      color: $dark-gray;
    }

    &.divider{
      margin-top: 10px;
      position: relative;

      &::before {
        content: ' ';
        height: 10px;
        width: 100%;
        border-bottom: 1px solid $light-gray;
        position: absolute;
        top: -10px;
        left: 0;
      }
    }
  }

  &.disabled {
    .country-select .selected-country {
      pointer-events: none;
    }
  }
}
