@import '../../../styles/variables';

.Sources {

  .average-condition-column {
    .value-amount {
      display: flex;
      .arrival-condition-content {
        display: flex;
        align-items: center;
      }
    }
  }

  .dropdown-icon {
    color: $brand-gray;
    font-size: 25px;
    cursor: pointer;
  }

  .condition-label {
    font-weight: 600;
    margin-left: 10px;
    text-transform: capitalize;
    font-size: 12px;

    &.null-state {
      color: $dark-gray;
    }

    &.poor {
      color: $brand-red;
    }

    &.average {
      color: $brand-orange;
    }

    &.good {
      color: $brand-blue;
    }
  }
}
