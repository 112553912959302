@import "../../../styles/variables";

.CircleCheckbox {
  position: relative;
  display: inline-block;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }

  input ~ .icon {
    display: inline-block;
    color: $dark-gray;
    background-color: $white;
    transition: opacity .5s linear;

    .fa {
      font-size: 20px;
      position: absolute;
      left: 0;
      top: 3px;
    }

    .checked {
      opacity: 0;
    }

    .unchecked {
      opacity: 1;
    }
  }

  input[type="radio"] ~ .icon {

    .fa {
      font-size: 25px;
      top: 0;
      margin-left: -2px;
    }
  }

  &.checked input[type="radio"] ~ .icon,
  &.checked input[type="checkbox"] ~ .icon,
  input[type="radio"]:checked ~ .icon,
  input[type="checkbox"]:checked ~ .icon {
    color: $primary;

    .checked {
      opacity: 1;
    }

    .unchecked {
      opacity: 0;
    }
  }

  .checkbox-label {
    font-size: 14px;
    margin-left: 30px;
    font-weight: normal;
    display: inline-block;

    .sub-label {
      display: block;
      font-size: 12px;
      line-height: 1.4;
      color: $almost-black;
    }
  }

  .inner-wrapper {
    position: relative;
  }
}
