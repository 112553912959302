@import '../../../styles/variables';

.ProgressChecklist {
  display: flex;

  .step {
    color: $brand-gray;
    margin-right: 25px;

    .icon {
      width: 40px;
      height: 40px;
      margin: 0 7px;
      border-radius: 50%;
      position: relative;
      border: 2px solid $brand-gray;

      i {
        font-size: 22px;
        font-weight: 100;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .divider {
        position: absolute;
        left: 100%;
        top: 50%;
        width: 42px;
        height: 2px;
        background-color: $brand-gray;
      }
    }

    .step-label {
      font-size: 9px;
      max-width: 54px;
      min-height: 26px;
      text-align: center;
      font-weight: 600;
    }

    &:last-child {
      margin-right: 0;
      .icon .divider {
        display: none;
      }
    }

    &.completed {
      color: $brand-blue;

      .icon {
        border: 2px solid $brand-blue;

        i {
          font-size: 16px;
          font-weight: 600;
        }

        .divider {
          background-color: $brand-blue;
        }
      }
    }
  }
}
