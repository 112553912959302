@import '../../../../styles/variables';

.group-activity-box {
  border: 1px solid $light-gray;
  border-radius: 5px;
  background-color: $background;
  overflow: visible;
  position: relative;
}

.group-activity-line {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray;
  padding: 4px 10px;

  &:last-child {
    border-bottom: none;
  }
}
