.date-column {
  &.flagged {
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 17px 17px 0 0;
      border-color: var(--color-orange-brand) transparent transparent transparent;
    }
  }
}

.actions-column {
  .dropdown-icon {
    font-size: 25px;
  }
}
