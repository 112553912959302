@import '../../../../styles/variables';

.CompanyProfileHeader {
  position: relative;
  background-color: $white;
  padding: 15px;
  border-bottom: 1px solid $light-gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  height: 90px;

  .company-info-block {
    display: flex;
    flex: 1;
    align-items: center;
  }

  @media screen and (min-width: 64em) {

    &.isSticky {
      position: sticky;
      position: -webkit-sticky;
      z-index: 2;
      top: 0;
    }
  }

  .company-information {
    margin-left: 10px;

    a {
      color: $primary;
    }

    .company-name {
      margin-right: 10px;
      font-size: 22px;
    }
  }

  .company-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 63.9375em) {
    flex-direction: column;
    height: 100%;

    .company-info-block {
      flex-direction: column;
    }

    .company-actions {
      margin: 10px auto 0;
      width: 100%;
      flex-direction: column;
    }

    .company-information {
      margin: 5px 0 0;
      text-align: center;

      .vertical-aligner {
        justify-content: center;
      }
    }
  }
}

.CompanyMiniProfileHeader {
  height: 100%;
  flex-shrink: 0;

  .company-name {
    font-size: 14px;
    margin: 0 5px;
  }

  .vertical-divider {
    border-left: 1px solid $light-gray;
    height: 75%;
    margin: 0 20px;
  }
}
