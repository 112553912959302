@import '../../../styles/variables';


.SourceTreatmentFeedback {

  .fa-dots-three-horizontal {
    color: $brand-gray;
    font-size: 25px;
    cursor: pointer;
  }

  .company-header {
    height: auto;

    .dropdown-button {
      padding: 10px 9px;
      margin: 0;

      i {
        font-size: 20px;
        color: $dark-gray;
      }
    }
  }

  .treatments-table-section {
    @media screen and (max-width: 63.9375em) {
      .source-treatment-header {
        border-radius: 0;
      }
      padding: 0;
      margin-top: 0;
    }
  }

  .product-name-column {
    display: flex;
    align-items: center;

    .icon {
      font-size: 24px;
      margin: 0 10px 0 5px;
    }
  }

  .mobile-row {

    .medication-icon {
      margin-right: 10px;
      font-size: 24px;
      color: $black;
    }

    .mobile-treatment-name {
      font-size: 14px;
      font-weight: 600;
    }
  }

}
