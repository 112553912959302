.CheckupLogs {
  font-size: 14px;
  color: var(--color-black);
  margin-top: 10px;
  margin: 0 -20px;

  .contributors {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    .contributor {
      border: 3px solid var(--color-white-bg);
      border-radius: 50%;
      overflow: hidden;
      margin-left: -15px;
    }

    .fa {
      font-size: 12px;
      font-weight: 600;
      transition: transform .3s ease;
      margin-left: 5px;
    }

    &.expanded {
      .fa {
        transform: rotate(180deg);
      }
    }
  }

  button.view-btn {
    border: none;
    border-top: 1px solid var(--color-gray-light);
    border-radius: 0 0 3px 3px;
  }

  .heading {
    background-color: #f7f7f7;
    padding: 3px 20px;
    border-top: 1px solid var(--color-gray-light);
    border-bottom: 1px solid var(--color-gray-light);
  }
}
