@import '../../../../styles/variables';

.BarnSheetsWaterUsageTable {
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .fa-dots-three-horizontal {
      color: $brand-gray;
      font-size: 25px;
      cursor: pointer;
    }
  }

  .percent-change {
    color: $brand-blue;
    font-weight: 600;
  }

  .red {
    color: $brand-red;
  }

  .pink {
    background-color: $light-orange;
  }
}
