@import '../../../../../styles/variables';

.UpdateMortalityReason {

  .reason-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: $white;
    border-bottom: 1px solid $light-gray;
  }

  .reason-name {
    font-size: 20px;
    margin-right: 10px;
  }
}
