@import '../../../../styles/variables';

.SourceCommingledForm {
  margin-left: 40px;
  font-size: 14px;

  .select-commingle-label {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .add-source {
    color: $brand-blue;

    & > span:hover {
      cursor: pointer;
    }
  }

  .source-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .remove-icon {
      margin: 0 5px 15px 5px;
      padding: 9px;
      font-size: 22px;

      & :hover {
        cursor: pointer;
      }
    }

    .search-select {
      width: 100%;
    }
  }

  @media screen and (max-width: 64em) {
    margin-left: 0;
  }
}
