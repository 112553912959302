@import '../../../../../styles/variables';

.profile-collapse-section {

  .section-data {
    border-top: 1px solid $light-gray;
    padding: 20px 30px 30px;
  }

  .nickname-icon {
    font-size: 18px;
    color: $medium-gray;
  }
}

