@import '../../../../styles/variables';

.DatesFilter {
  cursor: pointer;

  .DayPickerNavigation {

    &_button {
      padding: 8px;

      &:hover {
        background-color: $primary;
        border-color: $primary;
        color: $white;

        svg {
          fill: $white;
        }
      }
    }
  }

  .DateRangePicker {
    display: block;

    .DateRangePickerInput {
      overflow: hidden;
      width: 100%;
      min-width: 90px;
      position: relative;
      display: block;
      box-sizing: border-box;
      height: 35px;
      min-height: 35px;
      padding: 0 30px 0 10px;
      margin: 0;
      font-size: 13px;
      color: $black;
      background-color: $white;
      box-shadow: none;
      border-radius: 5px;
      border: 1px solid #dddddd;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      -webkit-appearance: none;

      .DateRangePickerInput_arrow {
        margin: 0 2px;
      }

      .DateInput {
        width: auto;

        &_input {
          height: 27px;
          line-height: 27px;
          max-width: 80px;
          width: 65px;
          font-weight: 400;
          font-size: 13px !important;
          color: $black;
          border: 0;
          box-shadow: none;
          margin: 3px 5px;
          padding: 0;
          cursor: pointer;

          &::placeholder {
            color: $black;
          }
        }

        svg {
          top: 40px !important;
        }
      }

      .DateRangePickerInput_clearDates {
        padding: 0;
        margin: 0 10px 0 0;
        line-height: 12px;
        display: flex;

        &:hover,
        &:focus {
          background: transparent;
        }
      }

      .DateRangePickerInput_calendarIcon {
        position: absolute;
        padding: 0;
        margin: 0 10px 0 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .input-arrow {
        font-size: 14px;
      }
    }

    .DateRangePicker_picker {
      top: 40px !important;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.3) 0 5px 18px;

      .CalendarMonth {

        .js-CalendarMonth__grid {

          td:not(.CalendarDay) {
            border-bottom: none;
          }
        }

        .CalendarDay {
          border: 1px solid $light-gray;
          padding: 0;
          box-sizing: border-box;
          color: #565a5c;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;

          &:hover {
            background: rgb(255, 232, 223);
          }

          &__hovered_span {
            background: #FED2BB;
          }

          &__blocked_out_of_range {
            color: $medium-gray;
            background: $background;
            pointer-events: none;
            border: 1px solid $light-gray;
          }

          &__selected_span {
            background: #FED2BB;
            border: 1px double $dark-gray;
            color: #fff;

            &:hover {
              background: $primary;
            }
          }

          &__selected_start,
          &__selected_end {
            background: $primary;
            border: 1px double $primary;
            color: $white;
            z-index: 2;

            &:hover {
              background: $primary;
            }
          }
        }
      }
    }
  }

  &.focused,
  &.hasValues {

    .DateRangePicker {

      .DateRangePickerInput {
        border-color: $primary;
        background-color: $primary;
        color: $white;

        .DateInput {
          background-color: $primary;

          &_input {
            background-color: $primary;
            color: $white;

            &::placeholder {
              color: $white;
            }
          }
        }

        .DateRangePickerInput_calendarIcon {
          color: $white;
        }

        .DateRangePickerInput_clearDates {

          svg {
            fill: $white;
          }
        }
      }
    }
  }

  &.hasValues {

    .DateRangePicker {

      .DateRangePickerInput {
        border-color: $primary;
        background-color: $primary;
        color: $white;

        .DateInput {
          width: auto;

          &_input {
            width: auto;
          }
        }

        .DateRangePickerInput_calendarIcon {
          display: none;
        }
      }
    }
  }

  &.initialState {

    .DateRangePicker {

      .DateRangePickerInput {

        .DateInput_input {
          width: 50px;

        }

        .DateInput:last-of-type {
          display: none;
        }

        .DateRangePickerInput_arrow {
          display: none;
        }
      }
    }

  }
}
