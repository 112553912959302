@import '../../../styles/variables';

.TextLabel {
  position: relative;
  display: inline-block;
  color: white;
  background-color: $brand-gray;
  padding: 0 5px;
  border-radius: 3px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  margin-left: 5px;
}
