@import '../../../../styles/variables';

.browser-block {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 20px;

  .not-support {
    color: $brand-red;
    font-size: 50px;
  }

  .browser-img {
    height: 50px;
  }

  .browser-desc {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 15px 0 10px;

    .desc {
      color: $nice-grey;
    }

    .not-support {
      color: $brand-red;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
