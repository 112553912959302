.insights-menu {
  height: 100%;
  background-color: var(--color-white-almost);
}

.insights-menu-sections {
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 55px;
  bottom: 0;
}
