@import '../../../styles/variables';

.EveryPigHeader {
  background-color: $white;
  padding: 10px 20px;
  border-bottom: 1px solid $light-gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;

  @media screen and (min-width: 64em) {
    &.isSticky {
      position: sticky;
      position: -webkit-sticky;
      z-index: 2;
      top: 0;
    }
  }

  .everypig-title {
    margin-left: 10px;
  }

  .profile-header-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}
