.wrapperTableImport{

  .DropdownButton{
    .left{
      margin: 0;
    }
    button{
      background-image: linear-gradient(0deg, #ff5e3a 3%, #f78445 100%);
      color: white;
    }
  }

}
