@import '../../../../styles/variables';

.filters-list-mobile {
  height: 100%;
  position: relative;
  background-color: $white;

  .clear-filters-btn {
    font-size: 12px;
    padding: 0 12px;
    line-height: 35px;
  }
}

.entity-selector {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $white;
  z-index: 30;
  transform: translate(-110%);
  transition: .4s transform ease-in-out;

  &.visible {
    transform: translate(0);
  }
}

.filters-header {
  display: flex;
  align-items: center;
  height: $main-header-height;
  padding: 0 10px;
  border-bottom: 1px solid $light-gray;

  .title {
    flex: 1;
    text-align: center;
    font-weight: 600;
  }
}

.save-filters {
  padding: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-top: 1px solid $light-gray;
  background-color: $background;
}

.filters-body {
  padding: 20px;
  font-size: 14px;
}

.isDisabled {
  background-color: rgb(245, 245, 245);
  cursor: not-allowed;
}

.filter-input-box {
  display: flex;
  align-items: center;

  .remove-icon {
    padding: 8px 12px;
    font-size: 20px;
  }
}

.filter-input {
  border: 2px solid $light-gray;
  border-radius: 3px;
  padding: 6px 10px;
  margin: 10px 0;
  position: relative;
  flex: 1;

  &.isDisabled {
    background-color: rgb(245, 245, 245);
    cursor: not-allowed;
  }
}

.select-icon {
  border-left: 2px solid $light-gray;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  padding: 9px 0;
  text-align: center;
  font-weight: 600;
  color: $primary;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: $light-gray;
  margin: 20px 0 10px;
}
