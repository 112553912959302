.search-input {
  border: 1px solid var(--color-gray-light);
  padding: 0 45px 0 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 12px 0;
  color: rgb(48, 48, 48);
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: calc(var(--mobile-header-height) - 10px);

  .search-value {
    margin-left: 10px;
  }

  .close-icon {
    position: absolute;
    padding: 15px;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
  }

  .input-icon,
  .close-icon,
  .search-value {
    font-weight: 600;
  }
}
