@import "styles/variables";

.stat-blocks {
  display: flex;

  @media (max-width: 40em) {
    padding: 18px 18px 0 18px;
  }

  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    height: 123px;
    width: 123px;
    border-radius: 5px;
    border: 1px solid;
    font-weight: 800;

    @media (max-width: 40em) {
      height: 100px;
      width: 100px;
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }

    &.fail {
      border-color: $brand-red;
      color: $brand-red;
    }

    &.pass {
      border-color: $brand-blue;
      color: $brand-blue;
    }

    &.not_available {
      border-color: $dark-gray;
      color: $dark-gray;
    }

    .value {
      font-size: 36px;
    }

    .label {
      text-transform: uppercase;
      font-size: 16px;
    }
  }
}
