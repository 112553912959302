@import '../../../../styles/variables';

.preference-item {
  padding-left: 50px;
  padding-bottom: 30px;

  @media screen and (max-width: $mobile-width) {
    padding-left: 20px;
  }

  .title {
    font-size: 14px;
    color: $dark-gray;
  }

  .divider {
    height: 1px;
    margin: 5px 0 20px;
    background-color: $light-gray;
    width: 100%;
  }
}
