@import "../../../styles/variables";

.DailyCheckupReportDeathReasons {

  .mortality-reason-row {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $light-gray;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .select-mort-reason-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .index-cell {
        flex: 0 0 50px;
        display: flex;
        align-items: center;
        align-self: center;
      }

      .select-mort-reason-wrapper {
        flex: 1 1 160px;

        .Select-control {
          margin-bottom: 0;
        }
      }

      .trash-cell {
        flex: 0 0 45px;
      }
    }

    .death-input label {
      border-bottom: none;
      margin-right: 45px;
    }

    @media screen and (max-width: 63.9375em) {

      .death-input label {
        margin-right: 0;
      }
    }

    .row-index {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #333;
      border-radius: 50%;
      font-size: 14px;
      color: #333;
    }

    .trash-icon {
      padding: 9px;
      margin-left: 5px;
      border-radius: 5px;
      transition: background-color .2s ease;
      cursor: pointer;
      font-size: 22px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .add-mortality-reason {
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    color: $brand-blue;
    background-color: $light-blue;
    line-height: 40px;
  }

  .head-treated-warning strong {
    color: $brand-red;
  }
}
