@import '../../../../../styles/variables';

.UpdateSymptom {
  margin-top: 0;

  .symptom-header {
    min-height: 70px;
    background-color: $white;
    border-bottom: 1px solid $light-gray;
    display: flex;
    align-items: center;
    padding: 10px 15px;

    .symptom-header-name {
      font-size: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .symptom-btn {
      padding: 10px 15px;
      margin-left: auto;
    }
  }

  .row-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .symptom-buttons-row {
    margin-top: 25px;
  }

  .concern-pro-tip {
    font-size: 14px;
  }

  .concern-levels {
    .concern-description {
      font-size: 14px;
      margin-left: 30px;
    }

    .concern {
      margin-top: -2px;
      display: inline-block;
      font-size: 16px;
      font-weight: 600;

      &.very-low {
        color: #91bed4;
      }

      &.low {
        color: $brand-blue;
      }

      &.medium {
        color: $brand-orange;
      }

      &.high {
        color: #F2836B;
      }

      &.very-high {
        color: $brand-red;
      }
    }
  }
}
