
.plus-button {
  position: relative;
  flex: 0 0 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color .2s ease;

  .icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-weight: 600;
    position: absolute;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
