.collapse {
  padding: 0 20px;
  border-bottom: 1px solid #ddd;

  &.group {
    font-size: 13px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 10px;

    .collapse-header {
      padding: 5px 0;
    }

    .collapse-content {
      padding-bottom: 10px;
    }
  }

  .collapse-content {
    font-size: 13px;
    padding-bottom: 15px;
  }

  .collapse-content-row {
    line-height: 24px;
  }

  .collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    padding: 15px 0;
    cursor: pointer;

    .collapse-icon {
      transition: transform 0.35s ease;

      &.opened {
        transform: rotate(90deg);
      }
    }
  }
}
