@import "../../../../../styles/variables";

.transfer-form {

  .transfer-field {

    :global(.label-wrapper) {
      font-weight: 600;
      font-size: 14px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 10px 0;

      @media screen and (max-width: 40em) {
        justify-content: flex-start;
        margin: 0 0 10px;
      }
    }

    :global(.field-input) {
      font-size: 14px;
      font-weight: 600;
      text-align: right;
      border-color: $light-gray;
      margin: 0;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }

      &:focus {
        border-color: $primary;
      }
    }
  }
}
