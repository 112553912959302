@import '../../../styles/variables';

.select-diagnosis-box {
  border-radius: 5px;
  border: 1px solid $light-gray;
  font-size: 14px;
  margin-bottom: 15px;

  .name {
    padding: 15px 12px;
  }

  .select-btn {
    &:global(.button) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
  }
}

.remove-icon {
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}
