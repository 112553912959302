@import "../../../styles/variables";

.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 240px;
  min-width: 240px;
  max-height: 100vh;
  color: #fff;
  background: $sidebar-color;
  -webkit-overflow-scrolling: touch;
  transform: translateX(-$sidebar-width);
  transition: transform .3s, top .3s;
  will-change: transform;
  z-index: 6;
  display: flex;
  flex-direction: column;

  &.is-collapsed {

    .side-content {
      padding: 10px 0;
    }
  }

  .side-header {
    height: $main-header-height;
    padding: 5px 10px;
    background: transparent;
    z-index: 2;

    .name {
      font-size: 14px;
      font-weight: bold;

      .fa-angle-down {
        float: right;
        line-height: 1.5;
      }
    }

    .description {
      font-size: 9px;
    }
  }

  .side-content {
    padding: 0 10px;
    transition: .35s padding;
    flex: 1;
    background-color: rgba(32, 40, 61, .5);
    z-index: 1;

    &.no-padding {
      padding: 0;
    }
  }

  .group {
    height: 8px;

    &:before {
      content: " ";
      display: block;
      position: absolute;
      left: 38px;
      width: 8px;
      height: 8px;
      background-color: #0f182c;
      border-radius: 50%;
    }
  }

  [class*="fa-"] {
    display: inline-block;
    margin-right: 15px;
    font-size: 18px;
    min-width: 24px;
    text-align: center;
  }

  .side-footer {
    font-size: 10px;
    text-align: center;
    width: 100%;
    margin: 0 auto 100px;

    .side-logo {
      display: block;
      width: 120px;
      height: 85px;
      margin: 0 auto 10px auto;
      text-align: center;
      transition: .35s opacity;
    }

    .side-logo-minimized {
      opacity: 0;
      pointer-events: none;
      transition: .35s opacity, .35s transform;
      font-size: 45px;
      color: $primary;
      position: absolute;
      bottom: 65px;
      right: -7px;
      transform: translateY(70px);
      will-change: transform, opacity;
      z-index: 0;
    }
  }

  .collapser {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 55px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #14182B;
    z-index: 1;
    transform: translateZ(0);

    i {
      font-size: 16px;
      color: $light-gray;
      padding: 10px;
      transition: .5s transform ease !important;

      &:hover {
        color: $white;
      }
    }

    .collapser-tooltip {
      opacity: 0;
      position: absolute;
      pointer-events: none;
      top: 8px;
      left: 230px;
      white-space: nowrap;
      padding: 10px;
      background-color: $sidebar-color;
      color: $white;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 600;
      box-shadow: rgba(0, 0, 0, 0.4) 0 10px 18px;
      z-index: 3;
      transition: .35s transform;
      will-change: transform;
    }

    &:hover {
      .collapser-tooltip {
        opacity: 1;
        transform: translate(20px);
      }
    }
  }
}

@media screen and (min-width: 64em) {
  .layout-default {

    .sidebar {
      transform: translateX(0);

      &.is-hidden {
        transform: translate(-$sidebar-width) !important;
        display: block !important; // override .is-hidden class in index.css
      }

      &.is-collapsed {
        transform: translate(-180px);

        .collapser i {
          transform: rotate(180deg);
        }

        .side-footer {

          .side-logo {
            opacity: 0;
            pointer-events: none;
          }

          .side-logo-minimized {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0px);
          }
        }
      }

      &.tablet-expanded {
        box-shadow: rgba(0, 0, 0, 0.3) 0 0 38px;
        z-index: 101; // need 1 more than <ContentOverlay />
      }
    }
  }
}
