.UserDropdown {
  line-height: 30px;

  .dropdown {
    top: 35px;
  }

  .dropdown-header {
    display: flex;
    align-items: center;

    .fa {
      font-size: 14px;
      padding: 10px;
    }
  }

  .dropdown-menu {
    padding: 0;
    margin: 0;
    width: 250px;

    li a {
      margin: 0;
      padding: 12px 20px;
      border-bottom: 1px solid #d8d8d8;
      cursor: pointer;
      font-size: 14px;
    }

    li:last-child a {
      border: none;
    }
  }
}
