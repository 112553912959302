@import '../../../styles/variables';

.status-badge {
  color: $white;
  min-width: 50px;
  padding: 2px 6px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  font-weight: 600;
  background-color: $brand-gray;

  &.small {
    font-size: 10px;
  }
}

.brand-blue {
  background-color: $brand-blue;
}

.brand-gray {
  background-color: $brand-gray;
}

.brand-red {
  background-color: $brand-red;
}

.dark-gray {
  background-color: $dark-gray;
}

.brand-orange {
  background-color: $brand-orange;
}

.aquamarine {
  background-color: $aquamarine;
}

.white-smoke {
  background-color: $almost-white;
  color: $dark-gray;
}
