@import '../../../styles/variables';

.tag,
.tag-hint {
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 14px;

  .tag-icon {
    font-size: 22px;
    margin-right: 8px;
  }

  .magic-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.tag-hint {
  color: $primary;
  border: 1px dotted $primary;
  display: flex;
}

.tag {
  display: flex;
  align-items: center;
  color: $dark-gray;
  border: 1px solid $medium-gray;
  margin-bottom: 10px;
  word-break: break-all;

  .tag-name {
    flex: 1;
    margin-right: 10px;
    color: $black;
  }

  .remove-tag-btn {
    font-size: 12px;
    color: $black;

    &:hover {
      cursor: pointer;
    }
  }
}
