@import '../../../../styles/variables';

.CreateUserFormFields {
  position: relative;
  z-index: 1;

  .nickname-icon {
    color: $medium-gray;
    font-size: 18px;
  }

  .phone-row {
    position: relative;
    z-index: 1;
  }

  .description {
    line-height: 18px;
    font-size: 12px;
    margin-top: 10px;
  }
}

