@import '../../styles/variables';

.layout-minimal {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 40em) {
    background: $dark-blue;
  }

  .wrapper {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    -webkit-overflow-scrolling: touch;
  }

  .container {
    padding: 20px;
    background: #fff;
    margin: 0 auto;
    transition: all .3s;

    @media screen and (min-width: 40em) {
      width: 500px;
      padding: 30px 55px 45px;
    }
  }

  .back {
    position: absolute;
    left: 20px;
    top: 30px;
    font-size: 20px;

    @media screen and (min-width: 40em) {
      left: 30px;
      top: 30px;
    }
  }

  h1 {
    margin: 20px auto;
    padding: 0 20px;
    color: $dark-blue;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    transition: .5s all;
  }

  label {
    color: $dark-blue;
    font-weight: 600;
    display: block;
    margin: 0;
    font-size: 14px;
    line-height: 1.8;

    .tag {
      display: inline-block;
      margin: 2px 0;
    }
  }

  .layout-minimal-logo {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;

    img {
      display: block;
      width: 170px;
      height: 120px;
      margin: 0 auto;

      &.logo-mobile {
        display: none;
      }

      @media (max-width: 639px) {
        display: none;

        &.logo-mobile {
          display: block;
        }
      }

    }
  }

  .lm-button {
    margin-top: 20px;
    width: 100%;
    min-height: 55px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .minimal-footer {
    text-align: center;
    color: #b5b5b6;
    font-size: 12px;
    margin-top: 30px;
    max-width: 300px;
  }

  .privacy-policy {
    display: block;
    color: $primary;
  }

  .accept {
    color: $primary;
  }
}
