@import '../../../../styles/variables';

.importBtnEmpty, .importTableBtn {
  display: inline-block;
  background: white;
  color: #ff5e31;
  border: 1px solid #ff5e31;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  vertical-align: middle;
  border-radius: 3px;
  padding: 0.85em 2em;
  margin: 10px 1rem 20px 0;
  font-size: 0.9rem;
}

.importTableBtn {
  margin: 0 1rem 0 0;
  font-size: 0.75rem;
  padding: 0.85em 3em;
}

.AdminUserProfile {


  .mobile-body-box {
    padding: 10px 10px 20px;
  }

  .user-alert, .progress-box {
    margin: 0 0 10px;
  }

  .message-box-btn {
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
    text-decoration: underline;
    margin-right: 10px;
    margin-left: auto;
  }

  @media screen and (min-width: 64em) {
    .mobile-body {
      border: none;
      background-color: transparent;

      .mobile-body-box {
        padding: 0;
      }

      .user-alert {
        margin: 0 0 20px;
      }
    }
  }
}
