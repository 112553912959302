@import "../../../../styles/variables";

.CreateShipmentMobileWizard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: $white;
  flex: 1;

  .wizard-body {
    width: 100%;
    flex: 1;
    padding: 20px;
    overflow: auto;
  }

  .wizard-footer {
    width: 100%;
    display: flex;
    padding: 0;
    border-top: 1px solid $light-gray;
    position: sticky;
    bottom: 0;

    .button {
      flex: 1 1 100%;
      padding: 20px;
      font-size: 18px;
      margin: 0;
      border: none;
      border-radius: 0;
    }
  }

  .step {

    .step-description {
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .date-picker {
      width: 100%;
      padding: 0 20px;

      .SingleDatePickerInput {
        height: auto;

        .DateInput {
          padding: 8px 6px;
        }
      }
    }

    .barns-selector {
      border: 1px solid $light-gray;
      border-radius: 5px;
      background-color: $background;
      overflow: hidden;

      .barn-checkbox {
        width: 100%;
        padding: 18px 20px;
        border-bottom: 1px solid $light-gray;

        &.checked {
          background-color: $light-orange;
        }

        &:last-child {
          border-bottom: none;
        }

        .checkbox-label {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .labeled-input {
      height: 50px;
    }

    .calendar-icon {
      font-size: 18px;
      color: $primary;
      padding: 0 5px;
    }

    .condition-selector {
      display: block;
      border: 1px solid $light-gray;
      border-radius: 5px;
      background-color: $background;
      overflow: hidden;

      .condition-type {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 18px 20px;
        border: none;
        border-bottom: 1px solid $light-gray;
        font-size: 18px;
        font-weight: 600;

        i {
          margin-right: 15px;
          font-size: 35px;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .summary-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      border-top: 1px solid $light-gray;
      padding: 8px 5px;

      .desc {
        text-align: right;
        min-width: 120px;
        margin-right: 20px;
        font-weight: 600;
      }

      .value {
        flex: 1;
      }

      .link {
        color: $primary;
        cursor: pointer;
      }

      .condition-label {
        font-size: 14px;
        text-transform: uppercase;
        padding: 2px 6px;
        border-radius: 4px;
        color: white;
        font-weight: 600;

        &.good { background-color: $secondary; }
        &.avg { background-color: $brand-orange; }
        &.poor { background-color: $brand-red; }
      }
    }
  }

  .header-title {
    font-size: 16px;
    font-weight: 600;
    margin: 15px 0;
  }

  .wizard-progress-bar {
    height: 8px;
    border-radius: 4px;
    width: 100%;
    min-width: 150px;
    background: rgba(255, 255, 255, .4);
    position: relative;
    overflow: hidden;
    margin-top: 8px;

    .wizard-progress {
      height: 100%;
      background-color: $white;
      transition: width .5s ease;
    }
  }

  .datepicker-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: $white;
  }
}
