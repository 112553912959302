.user-filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s, opacity .4s, visibility .4s ease-in;
  will-change: height;

  &.visible {
    visibility: visible;
    opacity: 1;
    height: auto;
    min-height: 55px;
    transform: scaleY(1);
    padding: 5px 10px;
  }

  .filter-btn {
    margin: 13px 10px;
    color: var(--color-primary);
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
  }

  .icon-btn {
    margin: 0 5px;
  }
}
