@import '../../../../../styles/variables';

.SetPoints {

  .setpoint-table-cell {

    &:not(:first-child) {
      border-left: 1px solid $light-gray;
    }

    .collapsible-column-name {
      text-transform: none;
      font-size: 13px;
      border-bottom: 1px solid $light-gray;

      .sort-column {
        justify-content: center;
      }
    }
  }

  .temperature-table {

    .table-row:last-child {
      border-bottom: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .setpoint-table-cell {
        border-bottom-left-radius: 5px;
      }
    }
  }

  .weight-column {
    background-color: $background;
    border-bottom-left-radius: 5px;
  }

  .edit-buttons {

    .reset-btn {
      margin-right: 10px;
    }
  }

  .farmtype-temp-input {
    padding: 0;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px 0 inset;
    width: 100px;
    height: 30px;
    text-align: center;
    transition: border-color, box-shadow .25s;

    &:focus {
      border-color: rgb(255, 94, 49);
      box-shadow: rgba(255, 94, 49, 0.6) 0 1px 7px 0 inset;
    }
  }
}
