
.SiteVisitReportFarms {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;

  .filters {
    padding: 0 15px;
    z-index: 1;
    position: relative;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .farms-panel {
    padding: 0 10px;
  }

  .farms-panel-title {
    min-height: 60px;

    h1 {
      font-size: 22px;
    }
  }

  .farms-panel-title,
  .farms-panel-body {
    padding: 15px 20px;
  }

  &.has-mobile-filters {

    .farms-panel {
      padding-top: 15px;
    }
  }

  @media screen and (max-width: 40em) {

    .farms-panel {
      padding: 0 15px;
    }
  }

  @media screen and (max-width: 63.9375em) {
    padding-top: 15px;

    &.has-mobile-filters {
      padding-top: 0;
    }
  }
}
