@import '../../../styles/variables';

.ProgressBox {

  &.panel {
    font-size: 16px;
    border-radius: 5px;
    margin: 20px 0;
    border: none;

    @media screen and (max-width: 63.9375em) {
      font-size: 14px;
    }

    .panel-heading {
      height: auto;
      background: $brand-blue;
      color: #fff;
      padding: 10px 20px;
    }

    .panel-body {
      padding: 0;
      border: 1px solid $light-gray;

      >div:not(:first-child) {
        border-top: 1px solid $light-gray;
      }

      .progress-row {

        &-incomplete, &-complete {
          padding: 10px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-incomplete {
          background: $light-yellow;

          a {
            font-size: 11px;
            padding: 5px 10px 7px;
          }
        }

        &-complete {
          background: $light-blue;
          color: $brand-blue;

          &.progress-row-bordered {
            border-top: 1px solid $light-gray;
          }
        }
      }
    }
  }
}
