@import 'styles/variables';

.AdminPanel {
  height: 100%;
}

.status-badge {
  align-self: center;
  padding: 0 5px;
  font-size: 11px;
  text-align: center;
  display: inline-block;
  color: white;
  height: 20px;
  width: 20px;
  min-width: 20px;
  line-height: 20px;
  background-color: $brand-blue;
  border-radius: 3px;
}

.yellow {
  color: #FFC94D;
}

.filters-section {
  position: relative;

  @media (max-width: 63.9375em) {
    margin-bottom: 20px;
  }

  .mobile-filter {
    width: 100%;
    margin: 0;
    font-weight: 600;
  }
}

.field-description {
  font-size: 14px;
  line-height: 18px;
  margin-top: -10px;
  margin-bottom: 15px;
  display: block;
}

form .has-error input {
  background-color: $light-orange;
  border-color: $brand-red;
}

.status-color {

  &-red {
    color: $brand-red; // incomplete
  }

  &-yellow {
    color: $brand-orange; // pending
  }

  &-blue {
    color: $brand-blue; // active
  }

  &-grey {
    color: $dark-gray; // disabled
  }
}

.status-bg-color {

  &-red {
    background-color: $brand-red; // incomplete
  }

  &-yellow {
    background-color: $brand-orange; // pending
  }

  &-blue {
    background-color: $brand-blue; // active
  }

  &-grey {
    background-color: $brand-gray; // disabled
  }
}

.fa-dots-three-horizontal {
  color: $brand-gray;
  font-size: 25px;
  cursor: pointer;
}
