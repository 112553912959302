@import "styles/variables";

.svr-media-uploader {
  position: relative;

  .header-section {
    height: 60px;
    font-weight: 600;
    padding: 0 20px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    font-size: 16px;
    border-bottom: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    @media (max-width: 40em) {
      height: 50px;
      border-bottom: none;
    }
  }

  .dropzone {
    width: 100%;

    &.dropzone-active {
      height: 500px;

      .body-section {
        border-color: $primary;
      }
    }
  }

  .browse-mobile-btn {
    display: none;

    @media (max-width: 40em) {
      display: block;
      height: 45px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .body-section {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: $background;
    min-height: 200px;
    border: 2px dashed #ddd;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 40em) {
      min-height: auto;
      border: none;
    }

    .browse-btn {
      cursor: pointer;
      color: $primary;

      &:hover {
        text-decoration: underline;
      }
    }

    .previews-section {
      max-height: 300px;
      overflow: auto;
    }

    .preview-row {
      border-top: 1px solid #ddd;
      margin-top: auto;
      padding: 10px;
      height: 80px;
      display: flex;
      align-items: center;

      @media (max-width: 40em) {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;

        .file-info {
          font-size: 12px;
        }

        &:first-child {
          border-top: none;
        }
      }

      .file-wrapper {
        position: relative;
        background-color: rgba(0, 0, 0, 0.2);
        margin-right: 10px;
        max-width: 100px;
        text-align: center;
        height: 100%;
        width: 100%;

        .video-triangle {
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7.5px 0 7.5px 12px;
          border-color: transparent transparent transparent white;
        }

        .preview-image {
          cursor: pointer;
          width: 100px;
          height: 100%;
          position: relative;
          background-size: cover;

          &:hover {
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              background-color: rgba(black, 0.3);
            }
          }
        }
      }

      .file-info {
        font-weight: 600;
        align-self: flex-start;

        .file-name {
          word-break: break-all;
        }

        .desc {
          font-size: 11px;
          font-weight: normal;
          color: $dark-gray;
        }
      }

      .icon {
        color: $dark-gray;
        font-size: 22px;
        padding-right: 10px;
        margin-left: auto;
        cursor: pointer;

        &:hover {
          color: $black;
        }
      }
    }

    .upload-placeholder {
      padding: 30px 0;
      font-weight: 600;
      color: $dark-gray;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .upload-icon {
        font-size: 50px;
        color: #d1d1d1;
        transition: color 0.3s ease;
      }

      @media (max-width: 40em) {
        display: none;
      }
    }
  }
}
