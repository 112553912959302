@import 'styles/variables';

.FarmFeedSearch {
  display: flex;
  z-index: 1;
  border-radius: 5px;
  background-color: transparent;
  flex: 0 0 590px;

  &.active {
    z-index: 4;
  }

  @media (max-width: $mobile-width) {
    &.active {
      flex: 1 1 590px;
      background: var(--color-blue-dark);
      padding: 0 10px;
    }
  }

  .init-search {
    color: var(--color-gray-light);
    font-size: 24px;
    margin-left: 10px;
    cursor: pointer;
  }

  .autocomplete {
    position: relative;
    height: 45px;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-grow: 1;

    .cancel-search {
      background: var(--color-blue-dark);
      z-index: 3;
      display: flex;
      height: 100%;

      button {
        margin-left: 15px;
        color: var(--color-primary);
        font-size: 12px;
      }
    }

    .search-label {
      position: absolute;
      top: 0; left: 0;
      width: 50px;
      color: white;
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: 100;
      opacity: 1;
      font-size: 20px;
    }

    i.clear {
      font-size: 14px;
      color: var(--color-black);
    }

    .input-wrapper {
      padding: 0;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      display: flex;
      flex-grow: 1;

      .advanced-search-link {
        position: absolute;
        font-size: 13px;
        color: #8c8c8c;
        right: 45px;
        text-decoration: underline;
        cursor: pointer;
      }

      .clear {
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        cursor: pointer;
        padding: 16px;
        opacity: 0;
        pointer-events: none;
        font-weight: bold;
        color: white;
        font-size: 13px;
      }

      .react-autosuggest__container {
        width: 100%;

        &--open {
          position: relative;

          @media screen and (max-width: $mobile-width) {
            position: static;
          }
        }

        input {
          border: none;
          width: 100%;
          color: white;
          height: 45px;
          margin: 0;
          box-shadow: none;
          display: flex;
          flex-grow: 1;
          font-size: 14px;
          padding-left: 50px;
          padding-right: 40px;
          background-color: rgba(white, 0.2);
          border-radius: 3px;
          font-weight: 600;

          &::placeholder {
            color: white;
            font-size: 14px;
          }
          @media screen and (max-width: $mobile-width) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }

      .react-autosuggest__suggestions-container--open {
        position: absolute;
        min-width: 590px;
        top: 50px;
        left: 0;
        border: 1px solid var(--color-gray-medium);
        border-radius: 5px;
        background: var(--color-white-bg);
        max-height: 600px;
        overflow-y: auto;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
        z-index: 20;

        @media screen and (max-width: $mobile-width) {
          top: 55px;
          width: 100%;
          left: 0;
        }
        @media screen and (max-width: 612px) {
          min-width: 0;
        }
      }

      .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px;
        border-top: 1px solid var(--color-gray-light);
        font-size: 14px;

        &:hover {
          background: whitesmoke;
        }

        &--first {
          border-top: 0;
        }
      }

      .react-autosuggest__section-container {
        border-top: 1px solid var(--color-gray-light);
        border-bottom: 1px solid var(--color-gray-light);
        margin-top: 10px;
        background-color: white;

        &:first-child {
          border-top: 0;
          margin-top: 0;
        }

        &:last-child {
          border-top: none;
          border-bottom: none;
          margin-top: 0;

          .react-autosuggest__suggestion {
            background-color: var(--color-blue-light);
            color: var(--color-blue-brand);
          }
        }
      }

      .react-autosuggest__section-container--first {
        border-top: 0;
      }

      .react-autosuggest__section-container {
        .react-autosuggest__suggestion--highlighted {
          background: whitesmoke;
        }
      }
    }

    &.complete {

      .fa-search {
        color: var(--color-black);
      }

      .input-wrapper {
        border-radius: 3px;
        border-color: transparent;
        background-color: white;

        input {
          color: var(--color-black);
        }

        .clear {
          opacity: 1;
          pointer-events: initial;
          color: var(--color-black);
        }
      }
    }
  }

  .overlay {
    display: block;
    position: fixed;
    top: $main-header-height;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-black);
    opacity: 0;
    z-index: 0;
    pointer-events: none;
    transition: opacity .2s;

    @supports (margin-top: env(safe-area-inset-top)) {
      margin-top: env(safe-area-inset-top);
    }

    &.visible {
      opacity: .5;
      pointer-events: all;
    }

    &.top-offset {
      top: $main-header-height + $header-notification-height;
    }

    @media screen and (max-width: $mobile-width) {
      left: 0;
    }
  }
}
