@import '../../../styles/variables';

.NumberStepInput.numeric {

  label {
    border-top: none;
  }

  &:last-child label {
    border-bottom: none;
  }

  .label-wrapper {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .step-buttons {
    overflow: hidden;

    input {
      text-align: center;
      margin: 0;
      border-radius: 0;
    }

    span {
      background-color: $almost-white;
      border: 0;
      margin: 0;
      color: $primary;
      min-width: 60px;

      @media screen and (max-width: 63.9375em) {
        min-width: 80px;
      }

      height: 40px;
      border-radius: 0;

      &.minus {
        border-radius: 6px 0 0 6px;
        border: 2px solid $light-gray;
        border-right: none;

        &::before {
          width: 15px;
        }
      }

      &.plus {
        border-radius: 0 6px 6px 0;
        border: 2px solid $light-gray;
        border-left: none;

        &::before {
          width: 15px;
        }
        &::after {
          height: 15px;
        }
      }
    }
  }

  &.disabled {

    .step-buttons {
      opacity: .4;
      cursor: not-allowed;

      > .minus, > .plus {
        pointer-events: none;
      }

      > input:disabled {
        background-color: inherit;
      }
    }
  }

  @media all and (max-width: 63.9375em) {

    label {
      flex-direction: column;
      align-items: flex-start;
      border: 0;
      flex: 1 1 160px;

      .step-buttons {
        margin-left: 0;
        width: 100%;

        input {
          width: inherit;
          min-width: 90px;
          height: 40px;
        }
      }
    }
  }

  &.warning {

    input {
      border-color: $brand-orange;
      background: $light-yellow;
    }

    @media all and (max-width: 63.9375em) {
      &:not(.always-show-warning) {
        .warning-icon {
          display: none;
        }
      }

    }
  }
}
