
.treatment-conflict-modal {

  .treatment-modal-body {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 40em) {
    height: 100%;
    margin: 15px 0;
    display: flex;
    flex-direction: column;

    .modal-body {
      flex: 1;
    }
  }

  .treatment-modal-body {
    font-size: 18px;
  }

  .modal-footer {
    display: flex;
    padding: 0;

    .button {
      flex: 1 1 100%;
      padding: 20px;
      font-size: 18px;
      margin: 0;
      border: none;
      border-radius: 0;
      min-height: 70px;
    }
  }
}
