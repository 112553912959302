@import '../../../styles/variables';

.CheckInMap {
  height: 100vh;

  .map-marker {
    opacity: .5;
    transition: opacity .3s linear;

    &.active,
    &:hover {
      opacity: 1;
    }
  }

  .checkin-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    height: 100%;

    .checkin-map-wrapper {
      flex: 1 1 200px;
      position: relative;
    }
  }

  .checkin-dropdown {
    .dropdown-menu {
      min-width: 250px;
    }
  }

  .right-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .checkins-count {
      text-transform: uppercase;
      margin-right: 25px;
      text-align: center;
      font-weight: 600;
      line-height: 1.4;

      .checkins-count-label {
        color: $dark-gray;
        font-size: 13px;
      }

      .checkins-count-value {
        color: $black;
        font-size: 18px;
      }
    }
  }

  .preloader {
    z-index: 2;
    margin-top: 100px;
  }
}
