@import "../../../../../styles/variables";

.InventoryConflictModal {

  .modal-body {
    padding: 20px 30px;

    .message {
      font-size: 18px;
    }
  }

  .modal-footer {
    text-align: right;
  }
}
