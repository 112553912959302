@import "../../../../styles/variables";

.NotificationItemBody {
  &.hasTopDivider {
    border-top: 1px solid $medium-gray;
  }

  .content-block {
    display: flex;
    flex: 1;
    background-color: $white;
    position: relative;
    padding: 15px;
    border-radius: 5px;

    .notify-icon {
      font-size: 40px;

      &.isDropdownView {
        font-size: 30px;

        &.fa-exclamation-triangle {
          font-size: 25px;
        }
      }

      &.fa-ep-groups {
        color: $brand-blue;
      }

      &.fa-clock {
        color: $dark-gray;
      }

      &.fa-warning,
      &.fa-exclamation-triangle {
        color: $brand-orange;
      }

      &.fa-site-visit-report {
        color: $medium-gray;
        font-size: 40px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .dc-mention {
      color: $brand-blue;
      position: relative;
      font-size: 50px;

      &.isDropdownView {
        font-size: 30px;
      }

      &-icon {
        position: absolute;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 50%;
        height: 50%;
        right: 0;
        bottom: 0;
        color: $white;
        background-color: $brand-orange;
      }
    }

    .svr-mention {
      position: relative;
      color: $medium-gray;
      font-size: 40px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.isDropdownView {
        font-size: 30px;
      }

      &-icon {
        position: absolute;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 50%;
        height: 50%;
        right: 0;
        bottom: 0;
        color: $white;
        background-color: $brand-orange;
      }
    }

    .congrats-icon {
      max-width: 50px;
    }

    .trophy-icon {
      max-width: 35px;
    }

    .encourage-icon {
      max-width: 35px;
    }
  }

  .notification-icon-block {
    margin-top: 5px;
  }

  .notification-block {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 15px;
    font-size: 14px;

    .notification-caption {
      .color-primary {
        display: inline-block;
        color: $primary;
        padding: 0;
        font-size: 14px;
      }
    }

    .notification-content {
      color: $dark-gray;
      word-break: break-word;

      .diagnoses {
        padding: 5px 0;

        div {
          margin-bottom: 5px;
        }
      }

      a {
        display: inline-block;
        padding: 5px 0;
        color: $primary;
        font-weight: 400;
      }
    }

    .notification-footer {
      &.desktop {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
      }

      .notification-time {
        color: $dark-gray;
      }

      .notification-actions {
        display: flex;
        color: $primary;

        a {
          display: inline-block;
        }

        span {
          cursor: pointer;
        }

        .primary-link {
          color: $primary;
          font-size: 14px;
          font-weight: 400;
          padding: 0;

          &:hover,
          &:active {
            color: $primary;
          }
        }
      }
    }
  }
  .mobile-actions {
    display: flex;

    .button {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: none;
      border-radius: 0;
      border-width: 1px;
      margin: 0 -1px -1px;
    }

    a.button {
      border-bottom-left-radius: 5px;
      border-right: none;
    }

    button.button {
      border-bottom-right-radius: 5px;
    }
  }
}
