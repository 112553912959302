@import '../../../styles/variables';

$sidebar-expanded: calc(50% + 120px);
$sidebar-collapsed: calc(50% + 30px);
$label-top: calc(55px - 50%);

.FarmFeedNewLabel {
  position: fixed;
  left: $sidebar-expanded;
  width: 160px;
  top: 0;
  transform: translate(-50%, -100%);
  visibility: hidden;
  z-index: 9;
  overflow: hidden;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  transition: .35s;
  will-change: transform, left;

  @supports (top: env(safe-area-inset-top)) {
    top: env(safe-area-inset-top);
  }

  &.active {
    transform: translate(-50%, $label-top);
    visibility: visible;
  }

  &.sidebar-collapsed {
    left: $sidebar-collapsed;
  }

  @media screen and (max-width: 63.9375em) {
    left: 50% !important;

    &.active {
      transform: translate(-50%, 60px);

      &.header-hidden {
        transform: translate(-50%, 5px);
      }
    }
  }

  .message {
    text-align: center;
    background-color: #0E6A9F;
    padding: 6px 12px;

    color: #fff;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;

    i {
      margin-right: 5px;
    }
  }
}
