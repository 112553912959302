.mobile-header {
  width: 100%;
  z-index: 3;
  min-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-blue-dark);
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: 1px solid var(--color-black);
}

.back-link {
  font-weight: 600;
  color: var(--color-primary);
  font-size: 20px;
  position: absolute;
  left: 15px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
  max-width: calc(100% - 150px);
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}

.right-action {
  min-width: 20px;
  position: absolute;
  right: 15px;
}
