.cropper-container {

  .cropper-crop-box {

    .cropper-dashed {
      border: 0 solid #fff;
      opacity: 1;

      &.dashed-h {
        width: 94%;
        left: 3%;
        border-top-width: 1px;
        border-bottom-width: 1px;
      }

      &.dashed-v {
        border-right-width: 1px;
        border-left-width: 1px;
        height: 94%;
        top: 3%;
      }
    }

    .cropper-center {
      display: none;
    }

    .cropper-line,
    .cropper-point {
      display: none;
    }

    .cropper-view-box {
      outline: none;
      border-radius: 50%;
      border: 3px solid #fff;
    }

    .cropper-face.cropper-move {
      background-color: transparent;
    }

    .cropper-modal {
      opacity: .8;
    }
  }
}

.guides-off {
  pointer-events: none;

  .cropper-dashed {
    display: none;
  }

  .cropper-view-box {
    border: none !important;
  }
}
