.HintTooltip {
  position: absolute;
  display: table;
  padding: 0 10px;
  line-height: 30px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 600;
  color: white;
  background-color: #1e253d;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s, transform .3s;
  will-change: transform, opacity;
  left: 50%;
  z-index: 6;
  white-space: nowrap;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 0;


  &.arrowed:not(.showBelow) {
    margin-bottom: 5px;
  }
  
  &.arrowed:before {
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #1e253d;
    position: absolute;
    top: 100%;
    right: 50%;
    transform: translate(50%);
  }
  
  &.showBelow:before {
    border-top: 10px solid transparent;
    border-bottom: 10px solid #1e253d;
    top: -20px;
  }

  :hover > &:not(.attachToBody) {
    opacity: 1;
    transform: translateY(0px);
  }

  &.attachToBody {
    transform: translateY(-100%);
    margin-top: 5px;
    transition: opacity .3s, margin .3s;
    will-change: opacity, margin;

    &.arrowed {
      margin-top: 0;
    }

    &.visible {
      opacity: 1;
      margin-top: -5px;
      
      &.arrowed:not(.showBelow) {
        margin-top: -10px;
      }

      &.showBelow {
        margin-top: 10px;
      }
    }
  }
}
