@import 'styles/variables';

.plus-menu {
  width: 50px;

  .menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: $main-footer-height;
    right: 0;
    background-color: rgba(30, 38, 62, 0.5);
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s linear;

    @media (orientation: landscape) {
      bottom: $main-footer-height-shrinked;
    }

    &.is-visible {
      opacity: 1;
      pointer-events: all;
    }
  }

  .plus-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    bottom: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $primary;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform .2s, background-color .2s;

    .icon {
      margin: 0;
      font-size: 20px;
      color: white;
      transition: transform .2s linear;
    }
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
    left: 50%;
    top: -10px;
    z-index: 2;
    position: absolute;
    min-width: 250px;
    background-color: white;
    transform: translate(-50%, -100%);
    border-radius: 5px;
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.2);
    padding: 15px 10px;

    @media (orientation: landscape) {
      top: -20px;
    }

    @media screen and (max-height: $mobile-width-large) {
      flex-wrap: nowrap;
    }

    &:before {
      content: '';
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      border-bottom: none;
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translate(-50%, 100%);
    }
  }

  .menu-square-item {
    width: 135px;
    height: 135px;
    padding: 10px;
    border-radius: 5px;
    background-color: $background;
    border: 1px solid $alto;
    margin: 15px;
    cursor: pointer;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: background-color .2s ease-in, border-color .2s ease-in;

    &:hover {
      background-color: $primary;
      border-color: $primary;

      .menu-square-label,
      .menu-square-icon {
        color: white;
      }
    }

    .new-badge {
      padding: 2px 4px;
      border-radius: 3px;
      background-color: $alice-blue;
      font-size: 8px;
      color: $brand-blue;
      font-weight: bold;
      text-transform: uppercase;
      position: absolute;
      align-self: flex-end;
    }

    .menu-square-label {
      font-size: 12px;
      text-align: center;
      padding: 10px 0;
      transition: color .2s ease-in;
    }

    .menu-square-icon {
      font-size: 30px;
      color: #1e263e;
      margin: 25px 0 0;
      transition: color .2s ease-in;
    }

    @media screen and (max-width: $mobile-width-medium), screen and (max-height: $mobile-width-large) {
      width: 120px;
      height: 120px;
      margin: 5px;

      .menu-square-icon {
        font-size: 24px;
      }
    }
  }

  &.is-opened {
    .plus-btn {
      background-color: #1e263e;

      .icon {
        transform: rotate(45deg);
      }
    }
  }
}
