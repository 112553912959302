@import '../../../styles/variables';

.FarmFeedActivityPlaceholder {
    margin: 10px;
    background: $white;
    border-radius: 5px;
    border: 1px solid $light-gray;

    .row-divider {
      border-bottom: 1px solid $light-gray;
      flex-basis: 100%;
    }

    .direction-column {
      flex-direction: column;
    }

    .null-state-row {
      display: flex;
      margin: 10px;

      &.border {
        border: 1px solid $light-gray;
        border-radius: 5px;
      }

      .row-item {
        .item-block {
          margin: 10px;
          display: flex;

          &.border {
            border: 1px solid $light-gray;
            border-radius: 5px;
          }
        }
      }

      .square {
        border-radius: 50%;
        flex-shrink: 0;
        height: 40px;
        width: 40px;
        background: $light-gray;
        &.little {
          height: 25px;
          width: 25px;
        }
      }

      .single-line-block {
        padding: 15px 10px;
        &.center {
          display: flex;
          justify-content: center;
        }
      }

      .single-line-column {
        margin-left: 10px;
        flex-basis: 100%;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        border: 1px solid $light-gray;
        border-radius: 5px;
      }

      .lines-block {
        flex-basis: 100%;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;

        &.big {
          padding: 15px 10px;
        }
      }

      .line {
        height: 8px;
        background: $light-gray;
      }
      .long {
        width: 50%;
      }
      .normal {
        width: 35%;
      }
      .small {
        width: 20%;
      }
      .extra-small {
        width: 5%;
      }
    }
  }
