@import "styles/variables";

.report-card {
  flex-direction: column;
  position: relative;
  max-width: 195px;
  flex: 1 1 195px;
  height: 200px;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 0 10px 15px 10px;
  background-image: url("../../../../public/images/svr-preview.png");
  background-size: cover;
  overflow: hidden;
  background-position: right;
  transition: box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
  }

  &.flagged {
    border-color: $brand-orange;
  }

  @media screen and (max-width: 40em) {
    max-width: 145px;
    flex: 1 0 100%;
    height: 145px;
  }

  .top-content {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.flagged {
      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 15px 0 0;
        border-color: $brand-orange transparent transparent transparent;
      }
    }
  }

  .info-block {
    padding-top: 8px;
    font-size: 12px;
    padding-left: 10px;
    border-top: 1px solid #ddd;
    background-color: $background;
    border-radius: 0 0 6px 6px;
    margin-top: auto;
    height: 50px;
    width: 100%;

    .title {
      font-weight: 600;
    }
  }

  .status-badge {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    border: 1px solid white;
    border-radius: 3px;
    padding: 5px;
  }
}
