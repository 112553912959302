@import "../../../../../styles/variables";

.modal-content {
  padding: 0 25px;
  min-width: 325px;
  justify-content: center;
  display: flex;
  pointer-events: none;
  position: relative;
}

.question-box {
  min-width: 325px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  position: relative;

  .preview-layout {
    width: 100%;
    position: relative;
    height: 67px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #e6e6e6;
    border-top: 0;
    margin-bottom: 20px;

    &::before {
      position: absolute;
      bottom: 0;
      content: '';
      display: block;
      width: 50%;
      height: 40px;
      border-top: 1px solid $light-gray;
    }

    &::after {
      position: absolute;
      bottom: 0;
      right: 0;
      content: '';
      display: block;
      width: 50%;
      height: 40px;
      border-top: 1px solid $light-gray;
      border-left: 1px solid $light-gray;
    }
  }

  &::after {
    content: '';
    width: 100%;
    margin-top: 20px;
    height: 68px;
    border: 1px solid $light-gray;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .survey-question {
    border: 1px solid $light-gray;
    border-bottom: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 20px 30px;
    word-break: break-all;
    width: 100%;
    align-self: center;
    text-align: center;
  }

  .btn-group {
    width: 100%;
    display: flex;
    align-self: center;

    .answer-btn {
      width: 50%;

      &:last-child {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }

      &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
