@import '../../../../styles/variables';

.uploads-list {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  min-width: 240px;
  margin: 15px;
  border-radius: 5px;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 540px; // 10 items
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;

  @media screen and (max-width: $mobile-width) {
    bottom: 120px;
  }

  @media screen and (max-width: $mobile-width-medium) {
    max-width: 280px;
  }

  @media screen and (max-height: 768px) {
    max-height: 290px; // 5 items
  }

  @media screen and (max-height: 500px) {
    max-height: 140px; // 2 items
    bottom: 90px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 12px;
    background-color: #1e263e;
    font-size: 13px;
    color: #ffffff;
    font-weight: 600;
    position: sticky;
    top: 0;
  }

  .close-icon,
  .cancel-upload-icon {
    font-size: 16px;
    cursor: pointer;
  }

  .asset-wrapper {
    height: 50px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    background-color: $white;

    &:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }
  }

  .file-icon-col {
    flex: 0 0 30px;
  }

  .uploading-asset-info {
    flex: 1;
    padding-right: 10px;

    .asset-name {
      font-size: 12px;
      color: $primary;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 220px;

      &.not-uploaded {
        color: $black;
        max-width: 130px;
      }
    }

    .asset-size {
      font-size: 11px;
      color: $dark-gray;
    }
  }

  .upload-progress {
    background-color: $medium-gray;
    height: 5px;
    width: 100%;
    margin: 5px auto;
    position: relative;

    .progress-value {
      background-color: $primary;
      height: 5px;
      width: 0;
      transition: width .4s ease;
    }
  }
}
