@import '../../../styles/variables';

.Inventory {

  .dropdown-icon {
    color: $brand-gray;
    font-size: 25px;
    margin: 0 auto;
    cursor: pointer;
  }
}
