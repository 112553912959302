@import 'styles/variables';

.UserProfileHeader {
  background-color: $white;
  padding: 15px;
  border-bottom: 1px solid $light-gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  min-height: 90px;
  position: relative;

  .user-info-block {
    display: flex;
    flex: 1;
  }

  .user-profile-dropdown {
    padding: 0;
    margin-bottom: 0;
    margin-left: 10px;

    i {
      padding: 6px;
      font-size: 25px;
      color: $dark-gray;
    }
  }

  @media screen and (min-width: 64em) {
    &.isSticky {
      position: sticky;
      position: -webkit-sticky;
      z-index: 2;
      top: 0;
    }
  }

  @media screen and (max-width: 63.9375em) {
    flex-direction: column;
    height: 100%;

    .user-info-block {
      flex-direction: column;
      align-items: center;
    }

    .user-actions {
      margin: 10px auto 0;
      width: 100%;
      flex-direction: column;
    }

    .user-information {
      text-align: center;
    }

    .user-info-wrapper {
      justify-content: center;
    }

    .user-profile-dropdown {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  .UserAvatar {
    flex-shrink: 0;
  }

  .user-information {
    margin-left: 10px;

    .user-name {
      font-size: 22px;
      margin-right: 10px;
    }
  }

  .user-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

.UserMiniProfileHeader {
  height: 100%;

  .user-name {
    font-size: 14px;
    margin: 0 10px;
  }

  .vertical-divider {
    border-left: 1px solid $light-gray;
    height: 75%;
    margin: 0 20px;
  }
}
