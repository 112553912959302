@import '../../../styles/variables';

.StickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  flex: 0 0 auto;
  transform: translateY(0px);
  transition: transform .35s;
  z-index: 3;

  &.offline {
    top: $network-notif-height;
  }

  &.header-hidden {
    transform: translateY(-$main-header-height);
  }
}
