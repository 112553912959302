@import '../../../../styles/variables';

.autoreport-container {
  max-width: 1440px;
  margin: 0 auto;
}

.autoreport-table {
  @media screen and (max-width: 63.9375em) {
    &.top-indent {
      margin-top: -20px;
    }
  }

  &:global(.DataTable .table-row) {
    font-size: 13px;
  }

  :global(.FlexTableWrapper.scrollable .FlexTable) {
    min-width: 2500px;
  }
}

.report-label-box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 63.9375em) {
    flex-direction: column;

    .label {
      margin-bottom: 10px;
    }
  }

  .label {
    font-size: 20px;
  }
}

.current-deaths {
  background-color: rgb(255, 246, 201);
}

.mortality-graph {
  flex: 1;

  svg {
    width: 100%;
    max-height: 50px;
  }
}

.first-source {
  word-break: break-word;
  text-decoration: underline;
  color: $brand-red;
}

.source-count {
  margin-left: 5px;
  position: relative;
  color: $primary;
}

.source-list {
  font-size: 12px;
  white-space: pre-wrap;
}

.source-icon {
  font-weight: bold;
  color: $light-black;
  margin-left: auto;
  margin-bottom: auto;
  padding: 3px 0;
  cursor: pointer;
  transition: transform .35s ease;

  &.opened {
    transform: rotate(90deg);
  }
}
