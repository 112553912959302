@import '../../../../../styles/variables.scss';

.ModeSwitcher {
  border: 1px solid $light-gray;
  border-radius: 3px;

  a {
    padding: 11px;
    display: inline-block;
    line-height: 1;
    color: $brand-gray;

    &:not(:last-child) {
      border-right: 1px solid $light-gray;
    }

    &.active, &:hover {
      background-color: $almost-white;
      color: inherit;
    }
  }
}
