@import '../../../../styles/variables';

.last-comment-info {
  font-size: 12px;
  margin: 5px 10px;
  border-bottom: 1px solid $light-gray;
  padding: 5px 0 10px;

  a {
    color: $primary;
    font-weight: 600;
  }
}
