@import "../../styles/variables";

.CircularProgressbar {
  width: 20%;

  &.video-progress {
    width: 100px;
  }

  .CircularProgressbar-path {
    stroke: $primary;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  .CircularProgressbar-trail {
    stroke: #d6d6d6;
  }

  .CircularProgressbar-text {
    fill: $primary;
    font-size: 18px;
    dominant-baseline: middle;
    text-anchor: middle;
  }

  .CircularProgressbar-background {
    fill: #d6d6d6;
  }

  .CircularProgressbar-inverted .CircularProgressbar-background {
    fill: $primary;
  }

  .CircularProgressbar-inverted .CircularProgressbar-text {
    fill: #fff;
  }

  .CircularProgressbar-inverted .CircularProgressbar-path {
    stroke: #fff;
  }

  CircularProgressbar-inverted .CircularProgressbar-trail {
    stroke: transparent;
  }
}
