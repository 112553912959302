@import '../../../../styles/variables';

.ai-percent {
  font-size: 12px;
  color: $white;
  background-color: $primary;
  border-radius: 5px;
  padding: 0 5px;
  font-weight: 600;
}
