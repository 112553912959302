@import '../../../../styles/variables';

.FarmFeedFarmSelect {

  .farm-line {
    min-height: 25px;
    margin-left: 15px;
  }

  .farm-search {
    display: flex;
    padding: 15px;

    .select-badge {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      font-weight: 600;
      cursor: pointer;
      flex: 0 0;
      padding: 0 10px;
      display: flex;
      background-color: $light-gray;
      align-items: center;
      justify-content: center;
    }

    .farm-select {
      flex: 1;

      .Select-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .farm-suggestion-item {
        display: flex;
        align-items: center;

        .semibold {
          margin-left: 15px;
        }
      }
    }
  }
}
