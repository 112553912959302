@import '../../../../styles/variables';

.LifecycleDeathTrendChart {
  width: 100%;

  .header-title {
    display: flex;
    align-items: center;

    @media screen and (max-width: 39.3975em) {
      flex-direction: column;
      align-items: left;

      .lighter {
        margin-bottom: 10px;
      }
    }
  }

  .period-buttons {
    margin-left: 10px;
  }

  .period-button {
    margin-right: 10px;
    padding: 10px;
    color: $dark-gray;
    border-radius: 5px;
    transition: all .2s;
    font-weight: 600;
    font-size: 13px;

    &.active {
      color: $primary;
      background-color: $light-blue;
    }

    &:hover {
      color: $primary;
    }
  }

  .chart-box {

    .chart-box-body {
      min-height: 330px;
    }
  }

  .summary-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .data-block {
      font-size: 16px;
      font-weight: 600;
    }

    .current-range {
      font-size: 12px;
      color: $dark-gray;
    }
  }

  .graph-tooltip {
    min-width: 130px;

    .day {
      font-size: 14px;
      color: $black;
    }

    .death-value {
      font-weight: 600;
      line-height: 18px;
    }

    .acute {
      color: #FC5E3B;
    }

    .chronic {
      color: #1EB0FC;
    }

    .euthanasia {
      color: #FDB12B;
    }
  }

  .chart-legend {
    display: flex;
    flex-direction: row;

    .chart-legend-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-right: 15px;

      .category-name {
        text-transform: capitalize;
        color: $almost-black;
        font-size: 11px;
        margin-right: 5px;
      }

      .color-box {
        width: 12px;
        height: 12px;
        margin-right: 8px;
        border-radius: 2px;

        &.acute {
          background-color: #FC5E3B;
        }

        &.chronic {
          background-color: #1EB0FC;
        }

        &.euthanasia {
          background-color: #FDB12B;
        }
      }
    }
  }
}
