.Subnavigation-wrapper {
  z-index: 3;

  @media screen and (min-width: 64em) {

    &.is-sticky {
      @supports ((position:sticky) or (position:-webkit-sticky)) {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        transform: translateZ(0);
      }
    }
  }
}

.Subnavigation {
  display: flex;
  background-color: white;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 40em) {
    display: block;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    font-weight: 600;
    font-size: 13px;
    padding: 0;
    margin-right: 20px;
    white-space: nowrap;
    cursor: pointer;
    color: var(--color-gray-dark);
    border-bottom: 3px solid transparent;

    &:hover {
      color: var(--color-primary);
    }

    &.active {
      color: var(--color-primary);
      border-bottom: 3px solid var(--color-primary);
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: var(--color-primary);
    }

    .badge {
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      padding: 1px 4px;
      border-radius: 3px;
      background-color: var(--color-white-almost);
      border: 1px solid var(--color-gray-light);
      color: var(--color-blue-brand);
      margin-left: 5px;
    }

    &:hover {
      color: var(--color-primary);
    }
  }

  .badge-counter {
    margin-left: 5px;
    border-radius: 15px;
    background-color: var(--color-primary);
    color: white;
    font-size: 11px;
    min-width: 20px;
    text-align: center;
    display: inline-block;
    transition: .3s;
  }

  .subnav-children {
    display: flex;
    margin-left: 10px;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 40em) {
      margin: 10px 0;

      .button {
        width: 100%;
      }
    }
  }

  &.inner {
    background-color: transparent;
    padding: 0 10px;
  }
}
