@import "../../../styles/variables";

.roles-fields {

  .label {
    font-size: 16px;
    font-weight: 600;
  }

  .description {
    font-size: 16px;
    margin-left: 30px;
  }

  .disabled {
    color: $dark-gray;
  }
}
