@import "styles/variables";

.mortality-suggestion-text {
  font-size: 24px;
  padding: 0 30px 20px;

  & > div {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .brand-red {
    color: $brand-red;
  }

  .brand-orange {
    color: $brand-orange;
  }

  .brand-blue {
    color: $brand-blue;
  }
}

.explanation {
  margin-top: 10px;
  font-size: 14px;
}

.magic-loop-img {
  width: 30px;
  margin-right: 5px;
}

.suggestion-timestamp {
  padding: 10px 0 20px;
  font-size: 12px;
  color: $dark-gray;
}

.suggestion-question {
  display: flex;
  color: $dark-gray;
  font-size: 14px;
  align-items: center;
  margin-bottom: 24px;
}

.suggestion-answer-btn {
  width: 58px;
  height: 30px;
  border: 1px solid $dark-gray;
  border-radius: 25px;
  transition: 0.3s;
  font-size: 12px;
  text-transform: uppercase;
  color: $dark-gray;
  font-weight: 600;
  text-decoration: none solid rgb(163, 163, 163);
  text-align: center;
  margin-left: 9px;

  &:hover {
    background-color: $dark-gray;
    color: #fff;
  }
}

.suggestion-feedback {
  display: flex;
  color: $dark-gray;
  font-size: 14px;
  align-items: center;
  margin-bottom: 36px;
}

.suggestion-change-btn {
  margin-left: 5px;
  color: $primary;
}

.ai-suggestion-collapse-box {
  margin: 15px 0;

  .ai-suggestion-group-info {
    padding: 7px 10px;
  }
}
