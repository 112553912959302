.app-version-container {
  display: flex;
  justify-content: center;
  position: relative;

  .snackbar {
    position: absolute;
    background: var(--color-blue-brand);
    text-transform: uppercase;
    top: -10px;
    padding: 2px;
    color: white;
    font-size: 10px;
    z-index: 1;
  }

  .app-version {
    color: var(--color-gray-nice);
    border: 1px solid var(--color-gray-nice);
    border-radius: 30px;
    font-size: 10px;
    padding: 5px 0;
    min-height: 27px;
    position: relative;
    max-width: 130px;
    width: 100%;

    .version {
      position: absolute;
      width: 100%;
      left: 10px;
    }

    .copy-icon {
      position: absolute;
      right: 15px;
      fill: var(--color-gray-nice);
    }
  }

  &.sidebar-view {
    .app-version {
      color: rgba(255, 255, 255, 0.5);
      border: none;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: solid 1px transparent;
        border-radius: 30px;
        z-index: -1;
        transition: border 0.2s linear;
      }

      .version {
        transition: left 0.2s linear;
        left: 0;
      }

      .copy-icon {
        position: absolute;
        right: 15px;
        opacity: 0;
        transition: opacity 0.2s linear;
      }

      &:hover {
        cursor: pointer;

        &:after {
          border: 1px solid rgba(255, 255, 255, 0.5);
        }

        .version {
          left: -10px;
        }

        .copy-icon {
          opacity: 1;
        }
      }
    }

    .snackbar2 {
      position: absolute;
      background: var(--color-blue-brand);
      text-transform: uppercase;
      top: -10px;
      padding: 2px;
    }
  }
}
