@import "../../../../styles/variables";

.DateRangePickerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  .DayPickerNavigation {

    &_button {
      padding: 8px;

      &:hover {
        background-color: $primary;
        border-color: $primary;
        color: $white;

        svg {
          fill: $white;
        }
      }
    }
  }

  .DateRangePicker {
    flex: 1 1 auto;

    .DateRangePickerInput {
      width: 100%;
      min-width: 220px;
      position: relative;
      display: block;
      box-sizing: border-box;
      height: 2.4375rem;
      padding: 0;
      border: 2px solid $light-gray;
      margin: 0;
      font-family: inherit;
      font-size: 1rem;
      color: #303030;
      background-color: #fff;
      box-shadow: none;
      border-radius: 3px;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      -webkit-appearance: none;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;

      .DateRangePickerInput_arrow {
        height: 25px;
        margin: 0 2px;
      }

      .DateInput {
        width: auto;

        &_input {
          height: 29px;
          max-width: 90px;
          font-weight: 600;
          font-size: 14px;
          color: $black;
          background: $white;
          border: 0;
          box-shadow: none;
          margin: 3px;
          padding: 5px;

          &:focus {
            background-color: rgb(255, 232, 223);
          }

          &__readOnly {
            cursor: default;
          }
        }

        svg {
          top: 40px !important;
        }
      }

      .input-arrow {
        font-size: 14px;
      }
    }

    .DateRangePicker_picker {
      top: 50px !important;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.3) 0 5px 18px;

      .CalendarMonth {

        .js-CalendarMonth__grid {

          td:not(.CalendarDay) {
            border-bottom: none;
          }
        }

        .CalendarDay {
          border: 1px solid $light-gray;
          padding: 0;
          box-sizing: border-box;
          color: #565a5c;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;

          &:hover {
            background: rgb(255, 232, 223);
          }

          &__hovered_span {
            background: #FED2BB;
          }

          &__blocked_out_of_range {
            color: $medium-gray;
            background: $background;
            pointer-events: none;
            border: 1px solid $light-gray;
          }

          &__selected_span {
            background: #FED2BB;
            border: 1px double $dark-gray;
            color: #fff;

            &:hover {
              background: $primary;
            }
          }

          &__selected_start,
          &__selected_end {
            background: $primary;
            border: 1px double $primary;
            color: $white;
            z-index: 2;

            &:hover {
              background: $primary;
            }
          }
        }
      }
    }
  }

  .calendar-icon-wrapper {
    width: 50px;
    min-width: 50px;
    height: 2.4375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-smoke;
    border: 2px solid $light-gray;
    border-left: none;
    color: $primary;
    font-size: 18px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
  }

  &.focused {

    .DateRangePicker {
      width: 100%;

      .DateRangePickerInput {
        border-color: $primary;
      }
    }
  }

  .mobile-overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;

    &.active {
      display: block;
    }
  }

  &.isMobile {

    .DateRangePicker {

      .DateRangePickerInput {

        .DateInput_input {
          margin: 2px;
          max-width: 95px;
          height: 29px;

          @media screen and (max-width: 22em) {
            max-width: 90px;
          }
        }
      }
    }
  }
}
