@import '../../../../styles/variables';

.group-source-block {
  background-color: $background;

  > div {
    border: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:not(:first-child) {
      border-top: none;
    }
  }
}

.block-title {
  font-weight: 600;
  background-color: $almost-white;
}

.source-name-row {
  color: $dark-gray;
}

.source-badge {
  font-size: 9px;
  text-align: center;
  font-weight: 600;
  border-radius: 3px;
  color: $white;
  background-color: $brand-gray;
  text-transform: uppercase;
  padding: 2px 4px;

  &.commingled {
    border: 1px solid rgb(221, 221, 221);
    background-color: $white;
    color: $dark-gray;
  }
}

.color-primary {
  color: $primary;
}
