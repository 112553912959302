@import 'styles/variables';

.SourceCommingledEditForm {

  .sources-list {
    margin-left: 40px;
  }

  .select-commingle-label {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 14px;
  }

  @media screen and (max-width: 64em) {
    .sources-list {
      margin-left: 0;
    }
  }
}
