@import "../../../styles/variables";

.BirthdayModal {
  width: 685px;
  max-width: 685px;

  .modal-body {
    padding: 0;
  }

  .birthdate-field {

    &.selected {

      input:not(.mobileDateInput) {
        border-color: $primary;
      }
    }

    input {
      border-radius: 5px;
    }
  }

  .section-block {
    background-image: linear-gradient(rgb(255, 255, 255) 3%, rgb(230, 242, 250) 103%);
    background-repeat: repeat;
    background-position: center center;
    border-radius: 10px;

    .dr-bot-body {
      padding: 30px;
      text-align: center;

      b {
        font-weight: 600;
      }

      .dr-bot-doc-image {
        height: 100px;
        margin-bottom: -10px;
      }

      .dr-bot-doc-name {
        padding: 2px 10px;
        width: auto;
        display: inline;
        background-color: #1e253d;
        border-radius: 10px;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
      }

      .title-block {
        margin-top: 25px;
        font-size: 24px;
        color: $brand-blue;
        padding: 0 5%;
      }
    }

    .content-block {
      margin: auto;
      width: 380px;
      padding-top: 25px;

      .rdtPicker {
        width: 380px;
        margin-left: 50%;
        transform: translateX(-50%);
      }

      .field-block {
        position: relative;
        width: 100%;

        .birthdate-label {
          display: flex;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .birthdate-field {

          input {
            height: 45px;
          }
        }
      }
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: center;

    button {
      width: 160px;
    }
  }

  &.mobile-view {
    width: 100%;
    overflow-y: auto;

    .section-block {
      border-radius: 0;
      .title-block {
        font-size: 20px;
      }
      .content-block {
        width: 300px;
      }
    }
  }
}
