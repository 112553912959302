@import '../../../../styles/variables';

.group-sales-transfers {

  .movement-date-box {
    display: flex;
    align-items: center;

    .movement-icon {
      border-radius: 50%;
      background-color: $dark-gray;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;

      i {
        font-size: 10px;
        font-weight: 600;
      }
    }

    .movement-date {
      margin-left: 5px;
      font-weight: 600;
    }
  }

  .create-transfer-box {
    margin: 10px;
  }

  .create-transfer-btn {
    width: 100%;
  }
}
