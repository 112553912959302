@import '../../../styles/variables';

.AnalyticsPanel {
  border-radius: 5px;
  border: 1px solid $light-gray;
  margin: 0 5px 10px;
  width: 375px;
  min-width: 300px;
  background: $white;

  @media (max-width: 40em) {
    margin: 0 0 10px;
  }

  .analytics-panel-body {
    padding: 15px 20px 20px 25px;
    position: relative;
    flex-grow: 1;
    min-height: 360px;
    display: flex;
    flex-direction: column;

    .title-block {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .title-text {
        flex: 1 0 150px;
      }

      .range {
        color: $dark-gray;
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-top: 10px;

        .horizontal-divider {
          margin: 0 2px;
          background-color: #2170D1;
          height: 2px;
          width: 8px;

          &:first-child {
            margin-left: 10px;
          }
        }
      }

      .fa-exclamation-circle {
        font-size: 20px;
        color: $dark-gray;
        font-weight: 600;
        cursor: pointer;
      }

      .dropdown-info-item {
        &.dekstop {
          min-width: 300px;
        }

        &:hover {
          background-color: inherit;
        }

        a {
          white-space: pre-line;
          cursor: default;
          padding: 5px 15px;
          font-size: 14px;
        }
      }
    }

    .total-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 0;
      flex: 1 auto;
      font-size: 18px;

      .percent {
        margin-left: 10px;
        min-height: 21px;
      }

      .total-number-block .total-number {
        text-align: center;
        font-weight: 100;
        font-size: 64px;
        line-height: 1;
      }
    }

    .chart-block {
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding: 15px 0;
    }

    .legend-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      font-size: 12px;

      &.in-row {
        flex-direction: row;
      }
    }

    .stats-block {
      min-height: 70px;
    }
  }

  &.full-row {
    flex-grow: 1;

    .analytics-panel-body {
      min-height: 420px;
    }
  }

  .analytics-panel-footer {
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px 0 25px;
    border-top: 1px solid $light-gray;
    background-color: $background;

    .timeago {
      margin: auto;
    }
  }
}
