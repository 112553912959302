@import '../../styles/variables';

.labeled-input-wrapper {
  position: relative;

  .labelled-input {
    height: 55px;
    margin: 0;
    font-size: 16px;
    border: 2px solid $medium-gray;

    &:focus {
      border-color: $primary;
    }
  }

  .password-icon {
    color: $dark-gray;
    position: absolute;
    top: 50%;
    right: 15px;
    font-size: 20px;
    transform: translateY(-50%);
  }
}
