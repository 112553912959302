@import "../../../styles/variables";

.reported-treatment-efficacies {

  .treatment-efficacy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border-top: 1px solid $light-gray;

    &.efficient-no-change {
      background-color: $light-yellow;
    }

    &.efficient-poorly {
      background-color: $light-orange;
    }
  }

  .efficient-img-wrapper {
    display: inline-block;
    padding-right: 10px;
    flex-shrink: 0;

    img {
      width: 25px;
    }
  }

  .efficient-description {
    justify-content: flex-start;
    flex: 1;
  }

  .buttons-segment {

    .edit-button {
      margin: 0 0 0 10px;
      padding: 8px 12px;
      border: 1px solid $light-gray;
    }
  }
}
