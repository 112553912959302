@import '../../../../styles/variables';

.UsersCheckins {

  .FlexTableItem {
    .collapsible-value {
      .value-amount .user-info .avatar {
        width: 35px;
        min-width: 35px;
        height: 35px;
      }
    }
  }

  @media screen and (max-width: 39.9375rem) {
    .ModeSwitcher {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}
