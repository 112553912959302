@import "../../../../styles/variables";

.DailyCheckupsFarmRow {
  border: 1px solid $light-gray;
  border-radius: 5px;
  color: $black;
  font-size: 0.75rem;
  background-color: white;
  margin-bottom: 20px;

  .action-required {
    background: $light-yellow;
    padding: 0.35rem 0.5rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid $light-gray;

    .action-required-icon {
      color: $brand-orange;
      margin-right: 5px;
    }
  }

  .row-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;

    .section-divider {
      flex: 0 0 1px;
      border-right: 1px solid $light-gray;
      margin-left: 15px;
      margin-right: 15px;
      height: 40px;
    }

    .pending-checkups {
      flex: 1 1 150px;
    }

    .status {
      font-size: 14px;
      font-style: italic;
      color: $dark-gray;
    }

    .icon-section {
      flex: 0 0 35px;
      display: flex;
      justify-content: center;
      margin-right: 5px;
    }

    .farm-info {
      flex: 0 0 25%;

      .farm-name {
        display: block;
        font-size: 14px;
        line-height: 1.4;
        font-weight: 600;
        word-break: break-word;
      }

      .farm-premise {
        color: $dark-gray;
      }
    }

    .farm-progress {
      flex: 1 1 150px;
    }

    .buttons-section {
      flex: 0 0 280px;
      text-align: right;

      > .button {
        font-weight: 600;
        margin: 5px;
        min-width: 120px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: auto;
      }
    }
  }

  &.is-new {
    background-color: $background;
  }

  &.no-groups {
    background-color: $background;

    .pending-checkups,
    .buttons-section,
    .divider-2 {
      display: none;
    }
  }

  .red-marker {
    display: block;
    background-color: $primary;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .status-icon {
    font-size: 25px;
    color: $medium-gray;

    &.blue {
      font-size: 30px;
      color: $brand-blue;
    }
  }

  @media screen and (max-width: 63.9375em) {
    .row-content {
      display: block;
      padding: 0;

      .icon-section,
      .pending-checkups,
      .section-divider {
        display: none;
      }

      .row-section {
        padding: 10px;

        &:not(:last-child) {
          border-bottom: 1px solid $light-gray;
        }
      }

      .farm-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 45px;

        .farm-name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .farm-progress {
        padding: 10px;

        .farm-progress-bar {
          align-items: center;
          flex-direction: row;

          .pb-text {
            white-space: nowrap;
            margin-bottom: 0;
          }
        }
      }

      .buttons-section {
        display: -ms-flexbox;
        display: flex;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow: hidden;
        padding: 0;
        margin: -1px;
        min-height: 45px;
      }

      .buttons-section {
        border: 1px solid rgb(221, 221, 221);
        border-top-color: transparent;

        .button {
          flex: 1 1 100%;
          border-radius: 0;
          border-width: 0;
          margin: 0;
        }
      }
    }

    &.no-groups {
      background-color: $background;

      .farm-progress {
        border-bottom: 0 !important;
      }

      .buttons-section {
        display: none !important;
      }
    }
  }
}
