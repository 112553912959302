@import "../../../styles/variables";

.DailyCheckupFarmGroups {
  transform: translateZ(0);

  .farm-details {
    color: $dark-gray;
  }

  .checkin-btn {
    color: $primary !important;
    padding: 10px;
  }

  .on-time-progress {
    margin-bottom: 5px;

    .pb-text {
      margin-right: 0;
      margin-bottom: 7px;
      font-size: 11px;
      min-width: 110px;
    }
  }

  .view-scheduled-groups {
    color: $dark-gray;
    font-size: 14px;
    text-align: center;
    margin: 0;
    padding: 10px 0;
    cursor: pointer;
    transition: .3s;

    .fa {
      transition: transform .35s ease;
    }

    &.is-open {

      .fa {
        transform: rotate(180deg);
      }
    }
  }

  .divider {
    height: 1px;
    margin: 0 -20px -1px;
    background-color: $light-gray;
  }

  .checked-in-state {
    text-align: center;
    line-height: 34px;
    color: $white;
    background-color: transparent;
    background-image: linear-gradient(90deg, $secondary 3%, $aquamarine 103%);
    font-size: 14px;
    font-weight: 600;

    i {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 64rem) {

    .column {
     padding: 0;
    }

    .panel-wrapper {
      margin: 0 !important;

      .panel {
        border-radius: 0;
        border: 0;

        .panel-heading {
          display: none;
        }

        .panel-body {
          margin-top: 10px;
        }
      }

      .checkin-btn {
        width: 100%;
      }
    }
  }
}
