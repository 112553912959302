@import '../../../styles/variables';

.table-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $light-gray;

  @media screen and (max-width: 63.9375em) {
    flex-direction: column;
    align-items: stretch;
    border-bottom: none;
  }
}

.filters-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 63.9375em) {
    border-bottom: 1px solid $light-gray;
  }
}

.filter {
  font-weight: 600;
  font-size: 14px;
  line-height: 50px;
  padding: 0;
  margin-right: 20px;
  white-space: nowrap;
  cursor: pointer;
  color: $dark-gray;

  &:hover {
    color: $primary;
  }

  &.active {
    color: $primary;
    border-bottom: 3px solid $primary;
  }

  @media screen and (max-width: 63.9375em) {
    line-height: 50px;
  }
}

@media screen and (max-width: 63.9375em) {

  .filter-buttons {
    margin: 10px 0;
    display: flex;
    width: 100%;

    :global(.button) {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    :global(button.button) {
      width: 100%;
      display: block;
    }
  }
}
