.ReactSelect {
  &.is-focused > .Select-control {
    border: 2px solid var(--color-primary) !important;
    background-color: white;
    outline: none;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;

    .Select-arrow-zone {
      border-left: 2px solid var(--color-primary);
    }
  }

  .Select-control {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border: 2px solid var(--color-gray-light);
    margin: 0 0 15px;
    font-family: inherit;
    font-size: 14px;
    color: var(--color-black);
    background-color: white;
    border-radius: 3px;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    cursor: pointer;
    box-shadow: none;
    -webkit-appearance: none;

    .Select-placeholder {
      color: var(--color-gray-medium);
    }

    .Select-arrow-zone {
      background-color: var(--color-gray-light);
      padding-left: 10px;
      padding-right: 10px;
      pointer-events: none;
      border-left: 2px solid var(--color-gray-light);
      transition: border-color 0.25s ease-in-out;

      i {
        font-weight: bold;
        color: var(--color-primary);
      }
    }

    .Select-clear-zone {
      color: var(--color-black-light);
      padding-right: 5px;

      .Select-clear {
        font-size: 26px;
      }
    }
  }

  .Select-menu-outer {
    margin-top: 3px;
  }

  .Select-option {
    border-bottom: 1px solid var(--color-gray-light);
    padding: 10px 15px;
    font-size: 14px;
    color: initial;
    cursor: pointer;

    &:hover {
      background-color: var(--color-white-bg);
    }

    &.is-disabled:not(.is-selected) {
      color: var(--color-gray-medium);
    }

    &.is-selected {
      position: relative;
      background-color: var(--color-blue-light);

      &:after {
        content: '\f012';
        position: absolute;
        right: 15px;
        color: var(--color-blue-brand);
        font: normal normal normal 16px/1 'EveryPig';
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 600;
      }
    }
  }

  .ReactSelect--option {
    .description {
      color: var(--color-gray-dark);
    }
  }

  &.isIOS {
    .Select-control * {
      font-size: 16px;
    }
  }

  &.noInputMargin {
    .Select-control {
      margin: 0 !important;
    }
  }
}

.has-error {

  .Select-control {
    border: 2px solid var(--color-red-brand);
    background-color: var(--color-orange-light);
  }
}

.Select {
  .Select-menu-outer {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  }
}
