@import "../../../styles/variables";

.collapsible-filter {
  transition: background-color .35s ease;
  background-color: $background;
  border-bottom: 1px solid $light-gray;

  &.opened {
    background-color: $white;

    .arrow {
      transform: rotate(90deg);
      color: $black;
    }
  }
}

.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;

  .arrow {
    transition: transform .35s ease, color .35s ease;
    font-size: 13px;
    font-weight: 600;
    color: $dark-gray;
    min-width: 13px;
    padding-left: 3px;
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
}

.body-box {
  padding: 10px;
}
