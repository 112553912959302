.save-filters-modal {
  width: auto;
  min-width: 520px;
  max-width: 800px;

  .filter-checkbox {
    display: block;
    margin-bottom: 15px;

    &.not-checked {
      color: var(--color-gray-dark);
    }
  }

  .input {
    max-width: 520px;
  }

  .selected-sections {
    display: flex;
    flex-wrap: wrap;
  }
}
