
.trash-button {

  .icon {
    padding: 9px 11px;
    border-radius: 5px;
    transition: background-color .2s ease;
    cursor: pointer;
    font-size: 22px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
