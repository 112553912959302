@import 'styles/variables';

.uploadLabelBlock {

  .uploadTitle {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;

    span {
      font-size: 18px;
    }

    .uploadDuplicate i {
      font-size: 12px;
    }
  }
}

.CSVUploader {
  border-radius: 5px;

  .uploader-body {
    position: relative;
    background-color: $background;
    border: 2px dashed #E8E8E8;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: border-color .3s ease;
    width: 700px;
    height: 120px;


    &.border-none {
      border: none;
    }

    &.dragOver {
      border-color: $primary;
    }

    @media screen and (max-width: 63.9375em) {
      &.empty {
        display: none;
      }
    }

    .wrapperAssetUpload {
      height: 100%;

      .asset-actions {
        display: flex;
        width: 100%;
        padding: 20px 20px 0 0;
        justify-content: flex-end;

        .delete-img {
          cursor: pointer;
          height: 15px;
          width: 15px;
        }
      }

      .assets-list {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 80%;
        width: 100%;


        .asset-wrapper {
          border-bottom: 1px solid $light-gray;
          display: flex;
          justify-content: center;
          width: 100%;

          &:last-child {
            .assets-item {
              border-bottom: none;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }

        .assets-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 40%;

          .asset-preview {
            position: relative;
            margin-right: 5px;

            .image {
              width: 70px;
              height: 45px;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              border: 1px solid $light-gray;
              padding: 1px;
              cursor: pointer;

              &.loading {
                background-size: contain;
              }

              &:hover {
                opacity: .75;
              }
            }
          }

          .asset-info {
            flex-grow: 1;
            min-width: 0;
            margin-right: 5px;
            align-self: flex-start;

            .asset-description {
              display: flex;
              flex-direction: column;
              text-align: center;
              font-weight: normal;
              align-self: flex-start;
              font-size: 16px;
              color: $black;
              text-decoration: none solid rgb(48, 48, 48);
            }
          }
        }
      }
    }

    .dropzone {
      position: absolute;
      opacity: .5;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      background-color: #FFF;
      color: #000;
      min-height: 0;
      font-size: 13px;

      &.active {
        z-index: 1;
      }
    }

    .dropzone-placeholder {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      color: $dark-gray;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: all .3s ease;
      width: 700px;
      height: 120px;

      .contentUpload {
        width: 60%;
        display: flex;
        justify-content: space-evenly;

        .wrapperInfo {
          display: flex;
          flex-direction: column;
        }
      }


      i {
        font-size: 48px;
        margin-bottom: 15px;
        color: $medium-gray;
        display: block;
      }

      a {
        text-decoration: underline;
        color: $primary;
      }

      &.minimized {
        margin: 0 auto;
        min-height: 50px;
        flex-direction: row;
        border-bottom: 1px solid $light-gray;

        i {
          font-size: 32px;
          margin-bottom: 0;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }

  .uploader-footer {
    display: flex;
    flex: 1 1 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    border-top: 1px solid $light-gray;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button {
      height: 40px;
      width: 100%;
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 24px;
      border-radius: 0;
      border-top: none;

      &.hide {
        position: absolute;
        visibility: hidden;
      }

      &:not(:last-child) {
        border-right: none;
      }

      .fa.fa-mic {
        font-size: 28px;
      }
    }
  }

  .disabled-uploader-overlay {
    background-color: rgba(100, 100, 100, .75);
    color: $white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 20px;
  }
}
