@import '../../../../styles/variables';

.preload-row {
  cursor: default;
  pointer-events: none;

  .preload-column {
    align-items: center;
    padding: 7px 0;
  }

  .mobile-preload-label {
    display: none;
  }

  .mobile-preload-label,
  .preloader-line {
    width: 50%;
    min-height: 20px;
    margin-left: 10px;
    border-radius: 10px;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 50px;
  }

  @media screen and (max-width: $mobile-width) {

    .preload-column:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }

    .mobile-preload-label {
      display: block;
      width: 25%;
    }

    .preloader-line {
      width: 50%;
      margin: 0 auto;
    }
  }
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
