@import "../../../styles/variables";

.PigsUnderCareLine {
  border-bottom: 1px solid $light-gray;
  background-color: $almost-white;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.mobile {
    background-color: #131830;
    color: $white;
    border-bottom: none;
    max-height: 38px;

    .icon {
      display: none;
    }
  }

  @media(max-width: 40em) {
    padding: 10px;
  }

  .left-value {
    display: flex;
    justify-content: center;

    .icon {
      font-size: 25px;
      margin-right: 10px;
    }
  }
}
