@import '../../../../../styles/variables';

.Medications {
  .support-link {
    color: $black;
    font-weight: 600;
    text-decoration: underline;

    &,
    &:link,
    &:visited {
      color: $black;
    }
  }

  .warning-box {

    .MessageBox__message {
      display: flex;
      justify-content: center;
    }
  }

  .routes-hint {
    cursor: pointer;
  }

  .treatment-name {

    .value-amount {
      display: flex;
    }
  }

  .product-name-column {
    display: flex;
    align-items: center;

    .icon {
      font-size: 24px;
      margin: 0 10px 0 5px;
    }

    .color-primary {
      color: $primary;
    }
  }

  .route-column {
    word-break: break-all;
  }

  .plus-route {
    color: $primary;
  }
}
