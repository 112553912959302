@import 'styles/variables';

.DailyCheckupReportMovements {

  .head-treated-warning strong {
    color: $brand-red;
  }

  .schedule-link {
    color: $primary;
  }

  .migration-block {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $light-gray;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    .migration-input-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 10px;

      .index-cell {
        flex: 0 0 50px;
        display: flex;
        align-items: center;
        align-self: center;
      }

      .input-wrapper {
        flex: 1 1 160px;
        align-items: center;
      }

      .trash-cell {
        flex: 0 0 45px;
      }

      @media all and (max-width: 63.9375em) {
        .input-wrapper {
          flex-wrap: wrap;

          .input-label {
            flex: 0 0 100%;
            text-align: left;
            margin-bottom: 5px;
          }

          .migration-input {
            flex: 1;
          }
        }
      }
    }

    .row-index {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #333;
      border-radius: 50%;
      font-size: 14px;
      color: #333;
    }

    .trash-icon {
      padding: 9px;
      margin-left: 5px;
      border-radius: 5px;
      transition: background-color .2s ease;
      cursor: pointer;
      font-size: 22px;
      color: $light-black;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .input-wrapper {
      display: flex;
      font-size: 14px;
      border: none;

      .input-label {
        flex: 0 0 25%;
        font-weight: 600;
        text-align: right;
        line-height: 18px;
        padding-right: 10px;
      }

      .migration-input {
        width: 130px;
        margin-bottom: 0;

        &.wide {
          flex: 1;
        }
      }
    }

    .migration-inputs {

      .add-location {
        margin-left: 160px;
        cursor: pointer;
        color: $brand-blue;
        line-height: 30px;
        font-size: 14px;
      }

      @media all and (max-width: 63.9375em) {

        .add-location {
          margin-left: 0;
        }
      }

      .location-row {
        display: flex;
        width: 100%;
      }
    }
  }

  .add-migration-button {
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    color: $brand-blue;
    background-color: $light-blue;
    line-height: 40px;
  }

  .movement-type-select {
    flex: 1;

    .Select-menu-outer,
    .Select-menu {
      max-height: none;
    }

    .ReactSelect--option {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: black;

      i {
        font-size: 20px;

        &.fa-exclamation-triangle {
          color: #FCB040;
        }
      }
    }
  }

  .normalized {

    input {
      width: 60px;
      border-color: $light-gray;
      margin: 0;
      font-weight: 600;
      font-size: inherit;
      text-align: right;
      transition: all .25s;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }

      &:focus {
        border-color: $primary;
      }
    }
  }

  .warning-title {
    display: flex;
    align-items: center;

    .warning-icon {
      color: $brand-orange;
      margin-right: 10px;
      font-size: 20px;
    }

    .bold {
      color: $brand-orange;
      font-weight: 600;
    }
  }
}
