.strength {
  .scale {
    position: relative;
    margin: 12px 0;
    width: 100%;
    height: 5px;
    background: var(--color-gray-light);

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      left: calc(25% - 1px);
      right: calc(25% - 1px);
      height: 100%;
      border-left: 2px solid white;
      border-right: 2px solid white;
    }

    &:after {
      right: calc(50% - 1px);
      z-index: 2;
    }

    .value {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 0;
    }
  }

  &.weak .value {
    width: 25%;
    background-color: #ec5840;
  }

  &.medium .value {
    width: 50%;
    background-color: var(--color-red-brand);
  }

  &.strong .value {
    width: 75%;
    background-color: #0c6aa0;
  }

  &.excellent .value {
    width: 100%;
    background-color: var(--color-green);
  }

  .description {
    display: inline-block;
    font-size: 12px;
    line-height: 1.2;
    color: var(--color-gray-dark);
  }
}
