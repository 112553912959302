@import '../../../../styles/variables';

.ArrivalConditionChart {

  .chart-box {

    .chart-box-header {
      height: 65px;
    }

    .chart-box-body {
      min-height: 330px;
    }
  }

  .summary-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .data-block {
      font-size: 16px;
      color: #ffb200;

      .color-poor {
        color: $brand-red;
      }

      .color-avg {
        color: #ffb200;
      }

      .color-good {
        color: $brand-blue;
      }
    }

    .range-type {
      font-size: 12px;
      color: $dark-gray;
      text-align: right;
      white-space: nowrap;
    }
  }

  .chart-legend {
    display: flex;
    flex-direction: column;
    bottom: 5px;
    left: 20px;

    .legend-item {
      font-size: 12px;
      color: $light-black;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 3px;

      .graph-color {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 8px;
        border-radius: 3px;

        &.poor {
          background-color: $brand-red;
        }

        &.avg {
          background-color: $brand-orange;
        }

        &.good {
          background-color: $brand-blue;
        }
      }
    }
  }
}
