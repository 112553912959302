@import '../../../../styles/variables';

.congrats-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(rgb(255, 255, 255) 3%, rgb(230, 242, 250) 103%);
  justify-content: center;
  padding: 20px;
  border-radius: 5px;

  .label-block {
    display: flex;
    align-self: flex-end;
    margin-bottom: 15px;

    @media screen and (max-width: 39.9375em) {
      align-self: center;
    }
  }

  .label {
    font-size: 10px;
    background-color: white;
    color: $dark-gray;
    border: 1px solid $dark-gray;
    padding: 0 5px;
    border-radius: 3px;
    text-transform: uppercase;
  }
}
