@import '../../../styles/variables';

.UserPanel {
  padding: 10px 15px;

  .user-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }

  .user-avatar {
    flex: 0 0 auto;
    margin-right: 10px;
  }

  .user-description {
    flex: 1;
    margin-right: 10px;
  }

  .user-actions {

    .user-dropdown {

      i {
        color: $brand-gray;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }

  .timestamp {
    color: $light-black;
  }
}
