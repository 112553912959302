@import "../../../../../styles/variables";

.ExpiredCheckupModal {

  .modal-body {
    padding: 20px 30px;

    p {
      font-size: 20px;
      min-height: 100px;
    }
  }

  .modal-footer {
    display: flex;
    padding: 0;

    .button {
      flex: 1 1 100%;
      padding: 20px;
      font-size: 18px;
      margin: 0;
      border: none;
      border-radius: 0;
    }
  }
}
