.ProgressPercent {
  &.alert {
    color: var(--color-red-brand);
  }

  &.warning {
    color: var(--color-orange-brand);
  }

  &.success {
    color: var(--color-blue-brand);
  }
}
