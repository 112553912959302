.row-body {
  margin: 5px 0;
  font-size: 13px;
  min-height: 35px;
  justify-content: space-between;
  border: 1px solid var(--color-gray-light);
  display: flex;
  border-radius: 5px;
  align-items: center;
  padding: 0 10px;
  background-color: white;
  cursor: pointer;
  transition: .3s;

  .column {
    width: 200px;

    &:first-child {
      width: 300px;
    }
  }

  .icon {
    font-size: 16px;
    margin-right: 5px;
    color: var(--color-primary);
  }

  &.selected {
    box-shadow: 0 4px 12px rgba(255,94,49,0.2);
    border-color: var(--color-primary);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    &:not(.disabled) {
      background-color: var(--color-white-almost);
    }
  }
}
