@import '../../../styles/variables';

.comment-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  font-size: 14px;

  .add-note {
    display: flex;
    color: $primary;
    user-select: none;
    padding: 10px 0;
    cursor: pointer;
  }

  .textarea-wrapper {

    textarea {
      resize: none;
      display: block;
      box-sizing: border-box;
      width: 100%;
      padding: 8px;
      border: 1px solid $alto;
      font-family: inherit;
      font-size: 14px;
      color: $black;
      border-radius: 5px;
      transition: box-shadow .5s, border-color .25s ease-in-out;
      box-shadow: 0 1px 2px rgba(48, 48, 48, .1);
      -webkit-appearance: none;

      &:focus {
        outline: none;
      }
    }

    .display-text {
      width: 100%;
      padding: 8px;
      max-height: 123px;
      overflow: scroll;
      white-space: pre-wrap;
      cursor: pointer;
    }
  }
}
