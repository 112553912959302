@import "styles/variables";

.multi-select {
  .select-title {
    font-size: 14px;
    font-weight: 600;
  }

  .select-label {
    font-size: 12px;
  }

  .selected-box {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    &.scrollable {
      max-height: 310px;
      overflow: scroll;
      margin: 5px 0;
    }

    .selected-item {
      margin: 0 10px 10px 0;
      background-color: $primary;
      padding: 6px;
      color: #fff;
      font-size: 12px;
      border-radius: 3px;
      animation: scale-up-center 0.1s linear both;
      display: flex;
      align-items: center;

      .remove-btn {
        padding-left: 6px;
        cursor: pointer;
        font-size: 10px;
      }
    }
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}
