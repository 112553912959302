@import "../../../../styles/variables";

.header-stats {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 63.9375em) {
    justify-content: center;
    align-items: stretch;
  }
}

.stat-box {
  border-right: 1px solid $light-gray;
  text-align: center;
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 11px;
  min-width: 110px;
  font-weight: 600;

  &:last-child {
    border-right: 0;
  }
}

.stat-value {
  color: $black;
  font-size: 16px;

  small {
    margin-left: 5px;
  }
}

@media all and (max-width: $mobile-width-large) {

  .stat-box {
    font-size: 10px;
    min-width: 70px;
    padding: 0 2px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .stat-value {
    font-size: 14px;
  }

  .stat-label {
    flex: 1;
  }
}
