@import "styles/variables";

.container {
  font-size: 13px;
  display: flex;
  color: $dark-gray;
  text-transform: uppercase;
  align-items: center;
  font-weight: 600;
  padding: 10px 0;

  .date {
    padding-right: 10px;
    white-space: nowrap;
  }

  .line {
    height: 1px;
    width: 100%;
    background-color: #ddd;
  }
}
