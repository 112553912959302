
.adminImports {

  display: flex;
  flex-direction: column;
  padding: 0 19px;

  .wrapperStartBtn {
    display: flex;
    justify-content: flex-end;
    padding: 25px 0;

    a {
      width: max-content;
    }
  }

  .wrapperCards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 25px;
    //:nth-child(-n+2) {
    //  margin-right: 31px;
    //}
  }

  //.main-header {
  //  .header-navigation {
  //    .navigation-link {
  //      .active {
  //        background-color: #ff5e31;
  //      }
  //    }
  //  }
  //}
}
