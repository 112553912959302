.badge {
  display: inline-block;
  position: relative;
  padding: 2px 6px;
  background-color: var(--color-orange-brand);
  color: white;
  border-radius: 3px;

  .badge-text {
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &.small {
    padding: 1px 4px;

    .badge-text {
      font-size: 10px;
      line-height: 14px;
    }
  }
}
