@import '../../../../styles/variables';

.AllCaughtUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 2;
  margin: 40px 10px;
  text-align: center;

  .check-icon {
    color: $primary;
    font-size: 40px;
    margin-bottom: 10px;
  }

  .caption {
    font-weight: 700;
  }
}
