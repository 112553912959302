@import '../../../../../styles/variables';

.MedicationInfo {
  font-size: 14px;

  .divider {
    display: flex;
    justify-content: center;
    font-size: 20px;
  }
}
