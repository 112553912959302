@import '../../../styles/variables';

.roles-manage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 auto;
  min-height: 100%;
  width: 100%;

  .message-box-btn {
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
    text-decoration: underline;
    margin-right: 10px;
    margin-left: auto;
  }

  .roles-manage-section {
    flex: 1 auto;

    @media (min-width: 64em) {
      margin-top: 0;
    }
  }

  .roles-manage-body {
    padding: 0 20px;

    @media screen and (max-width: 63.9375em){
      padding: 0 10px;
    }
  }
}
