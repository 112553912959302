@import "../../styles/variables";

.Translation {
  .translation-text {
    display: block;
    border-left: 2px $dark-gray solid;
    padding-left: 10px;
    margin-top: 10px;

    .google-translate {
      color: $dark-gray;
      font-style: italic;
    }
  }

  .translation-btn {
    display: block;
    color: $primary;
    margin-top: 10px;
    cursor: pointer;
  }
}
