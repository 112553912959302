.CommentBoxComponent {
  padding: 5px 10px;
  margin: 20px 0;
  border: 1px solid var(--color-gray-light);
  border-radius: 3px;
  font-size: 14px;
  background: var(--color-white-almost);

  .CommentBoxComponent__wrapper {
    position: relative;

    .comment {
      overflow: hidden;

      textarea {
        resize: none;
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 8px;
        border: 2px solid var(--color-gray-medium);
        font-family: inherit;
        font-size: 14px;
        color: var(--color-black);
        border-radius: 3px;
        transition: box-shadow .5s,border-color .25s ease-in-out;
        box-shadow: 0 1px 2px rgba(48, 48, 48, .1);
        -webkit-appearance: none;

        &:focus {
          border: 2px solid var(--color-primary);
          background-color: white;
          outline: none;
          box-shadow: 0 0 4px #ff8664;
        }

        &:disabled, &[readOnly] {
          background-color: var(--color-gray-light);
          cursor: not-allowed;
        }

        &::placeholder {
          color: var(--color-gray-medium);
        }
      }

      .CommentBox__textArea {
        height: 140px;
        margin: 8px 0;
      }
    }

    .MentionsInputBox {
      position: unset;
    }

    .MentionsInput {
      position: unset !important;
      background-color: white;

      .MentionsInput__control {
        position: relative;
        height: 140px;
        margin: 10px 0;
        border-radius: 0;

        .MentionsInput__input {
          border-radius: 3px;
        }

        .MentionsInput__highlighter {
          padding: 10px;

          strong {
            color: transparent;
            background-color: var(--color-orange-light);
            border-radius: 5px;
          }
        }
      }

      .MentionsInput__suggestions {
        bottom: 100%;

        @media screen and (max-width: 40em) {
          left: -20px;
          width: calc(100% + 40px);
        }

        .MentionsInput__suggestions__list {
          border: 1px solid var(--color-gray-light);
          border-radius: 10px;
          max-height: 200px;
          overflow-y: auto;

          .MentionsInput__suggestions__item {
            margin: 0;
            padding: 5px 10px;
            font-size: 14px;
            line-height: 20px;

            .icon {
              display: inline-block;
              width: 25px;
              text-align: center;
              font-size: 22px;
              color: var(--color-black);
              margin-right: 10px;
            }

            &.MentionsInput__suggestions__item--focused {
              background-color: var(--color-blue-light);
            }
          }
        }
      }
    }
  }

  .comment-box-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .remove-comment {
    color: var(--color-primary);
    opacity: 0;
    transition: opacity .2s linear;

    &.visible {
      opacity: 1;
    }
  }

  .closed {
    height: 0;
    transition: height 0.6s;
  }

  .opened {
    height: 160px;
    transition: height 0.6s;
  }

  a {
    text-decoration: none;
    font-size: 14px;
    padding: 3px;
  }
}
