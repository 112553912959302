.SingleDatePickerMobile {

  .header-dates {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    min-height: 60px;

    .selected-date {
      text-align: center;
      font-size: 18px;
    }
  }
}
