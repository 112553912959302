@import '../../../../styles/variables';

.activity-header {
  display: flex;
  padding: 10px;
  min-height: 35px;

  &.column {
    flex-direction: column;
  }

  .header-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 25px;
    background-color: $white;
    font-size: 12px;
    padding: 0 10px;
    color: #757575;
    min-height: 35px;
    user-select: none;

    &.centered {
      margin: 0 auto;
    }

    .icon {
      color: $brand-orange;
      font-size: 15px;
      margin-right: 5px;
    }
  }

  .action-column {
    position: absolute;
    right: 10px;
  }

  .dropdown {
    width: 35px;
    height: 35px;
    font-size: 22px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    color: $dark-gray;
    transition: background-color .2s ease;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, .05);
    }
  }
}
