.checkup-segment {
  border: 1px solid var(--color-gray-light);
  border-radius: 5px;
  color: var(--color-black);
  font-size: 0.75rem;
  background-color: white;
  margin-bottom: 20px;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  position: relative;

  &.is-disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &.no-data {
    background-color: var(--color-white-bg);
  }

  &.without-bottom-border {
    border-bottom: none;
  }

  &.has-warning {
    .checkup-segment-content {
      padding: 12px 10px 2px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .segment-preloader {
    top: 7px;
  }

  .checkup-segment-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px;

    .segment-icon-column {
      flex: 0 0 30px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-right: 5px;

      &.fadeInLeft {
        animation-name: fadeInLeft;
        animation-duration: .5s;
      }

      @media screen and (max-width: 63.9375em) {
        display: inline-flex;
        min-height: 45px;
        align-items: center;
        min-width: 35px;
      }

      .status-icon {
        font-size: 25px;
        color: var(--color-blue-brand);
      }
    }

    &.mobile-empty-segment {
      padding: 0 10px;

      .segment-icon-column {
        min-height: 50px;
      }
    }

    .segment-info-column {
      flex: 1 1 50%;

      .segment-title {
        font-size: 14px;
        font-weight: 600;
        padding: 5px 2px;
      }

      @media screen and (max-width: 63.9375em) {
        display: inline-flex;
        min-height: 45px;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .warning-text {
          text-align: center;
        }

        &.isReported {
          justify-content: start;

          .segment-title {
            margin: auto 0;
            text-align: left;
          }

          .warning-text {
            display: none;
          }
        }

        .segment-title {
          padding-left: 10px;
          text-align: center;

          @media screen and (max-width: 40em) {
            margin: auto;
          }
        }
      }
    }

    .segment-buttons-column {
      flex: 1 0 335px;
      text-align: right;

      .button {
        margin: 0 5px;

        &.fadeInLeft {
          animation-name: fadeInLeft;
          animation-duration: .5s;
        }

        &.report-button {
          min-width: 180px;
          color: var(--color-primary);
          border-left: 1px solid var(--color-gray-light);

          &:not(:last-child) {
            border-right: 1px solid var(--color-gray-light);
          }

          &.primary {
            min-width: 90px;
            color: white;
          }
        }

        &.action-button {
          min-width: 90px;
          transition: background-image .25s;

          &:not(:last-child) {
            border-right: 1px solid var(--color-gray-light);
          }

          &:hover {
            background-image: linear-gradient(0deg, var(--color-primary) 3%, #f78445 103%);
            color: white;
          }
        }

        &.mobile-edit-btn {
          padding: 7px 11px;
        }
      }
    }
  }

  .warning-segment,
  .warning-text {
    padding: 2px 10px 10px;

    .warning-icon {
      margin-right: 5px;
      color: var(--color-orange-brand);
    }
  }

  @media screen and (max-width: 63.9375em) {
    &.reported {
      .checkup-segment-content {
        .segment-buttons-column {
          flex: none;
        }
      }

      .segment-info-column {
        .segment-title {
          padding-left: 2px;
        }
      }

      .warning-segment {
        padding: 2px 15px 10px;
      }
    }

    &:not(.reported) {
      .checkup-segment-content {
        flex-wrap: wrap;
        padding: 0;
      }

      .warning-segment {
        display: none;
      }

      .segment-buttons-column {
        display: flex;
        flex: 1 1 100%;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow: hidden;
        border-top: 1px solid var(--color-gray-light);

        .button {
          flex: 1 1 100%;
          border-radius: 0;
          border-width: 0;
          margin: 0;
        }
      }
    }
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
