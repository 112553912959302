@import '../../../../styles/variables';

.AnalyticsPage {

  .analytics-container {

    .analytics-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 15px 0 0;

      @media (min-width: 64rem) {
        &.isTablet {
          flex-wrap: nowrap;
        }
      }
    }
  }
}
