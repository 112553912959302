@import "../../../../styles/variables";

.RootNodePortal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-white-bg);
  z-index: 32; // more then (20) modals container, more then (30) footer
  transform: translate(-110%);
  transition: .3s transform ease-in-out;
  will-change: transform;
  pointer-events: none;

  &.right-to-left {
    transform: translate(110%);
  }

  @media screen and (min-width: 64em) {
    // TODO: needed rebuild logic on symptoms, medications, mortality reasons selectors
    // display: none;
  }

  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    padding-bottom: env(safe-area-inset-bottom);
  }

  &.isOpen {
    transform: translate(0);
    pointer-events: all;
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 30px;

    &.has-footer-area {
      box-shadow: none;
    }
  }

  &.has-footer-area {
    bottom: $main-footer-height;
    box-shadow: none;
    padding-bottom: 0;
    z-index: 30;

    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      margin-bottom: env(safe-area-inset-bottom);
    }

    @media (orientation: landscape) {
      bottom: $main-footer-height-shrinked;
    }
  }
}
