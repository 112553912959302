@import '../../../styles/variables';

.direct-post-box {
  border: 1px solid $light-gray;
  margin: 0 10px;
  font-size: 14px;
  border-radius: 5px;
  background: #fff;
  padding: 15px;

  .message {
    white-space: pre-wrap;
    word-break: break-word;
    min-height: 105px;

    &.large-message {
      font-size: 14px;
    }

    &.small-message {
      font-size: 24px;
    }

    .see-more {
      color: $primary;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
