@import "../../../styles/variables";

.MobilePortalSelector {
  background-color: $background;
  height: 100%;
  position: relative;

  .mobile-selector-data {
    overflow: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: $main-header-height;
    bottom: 0;

    .mobile-selector-search {
      padding: 10px;
      background-color: $sidebar-color;
      position: relative;
      right: 0;
      left: 0;

      @supports ((position:sticky) or (position:-webkit-sticky)) {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
      }

      .mobile-selector-search-box {
        margin: 0;
        width: 100%;
        max-width: 100%;
      }
    }

    .options-list-wrapper {
      overflow: hidden;
      background-color: #fff;
      border-bottom: 1px solid $light-gray;
    }
  }
}
