.checkboxes {
  padding: 10px 15px;

  .checkbox-item {
    display: block;
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .checkbox-label {
    opacity: 0.5;
    font-size: 16px;
    color: var(--color-black);
    font-weight: 600;
    padding-left: 5px;

    &.is-checked {
      opacity: 1;
    }
  }

  .name-row {
    & > div {
      margin-bottom: 5px;
      font-size: 16px;
    }

    &.is-placeholder {
      & > div {
        color: var(--color-gray-medium);
      }
    }
  }

  .input-row {
    display: flex;
    align-items: center;

    .from-or-to {
      margin-bottom: 5px;
      min-width: 100px;
      text-align: right;
      padding-right: 15px;
    }

    .input-row-label {
      margin-bottom: 5px;
      margin-left: 10px;
      white-space: nowrap;
    }
  }

  .conditions-sub-title {
    padding-bottom: 5px;
    padding-top: 5px;
    color: var(--color-black);
    font-weight: 600;
  }

  .selected-item {
    margin: 10px 0 10px 0;
    background-color: var(--color-primary);
    padding: 5px;
    color: white;
    border-radius: 3px;
    display: flex;
    align-items: center;

    .name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 5px;
      flex: 1;
    }

    .icon {
      padding: 2px;
    }
  }
}

.boolean-condition-row {
  padding: 20px 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  position: relative;
  border-top: 1px solid var(--color-gray-light);

  .check-icon {
    margin-left: auto;
  }

  &.is-selected {
    background-color: var(--color-orange-light);
    color: var(--color-primary);
  }
}
