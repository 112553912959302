@import "../../../styles/variables";

.SidebarGroup {
  .group-list {
    li {
      padding: 0;
      cursor: pointer;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      border: 0;
      position: relative;

      .item-name {
        font-size: 15px;
        opacity: 1;
        transition: 0.3s all;
        display: inline-block;
        transform: scale(1);
        max-width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .item-icon {
        font-size: 18px;
        transition: 0.3s;
      }

      svg {
        margin-right: 15px;
        color: inherit;
        max-height: 18px;
        transition: 0.3s;
      }

      .sidebar-tooltip {
        opacity: 0;
        position: absolute;
        pointer-events: none;
        top: 2px;
        left: 220px;
        white-space: nowrap;
        padding: 10px;
        background-color: var(--color-blue-sidebar);
        color: white;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 600;
        box-shadow: rgba(0, 0, 0, 0.4) 0 10px 18px;
        z-index: 3;
        transition: 0.35s transform;
        will-change: transform;
      }

      &:hover {
        .showTooltip {
          opacity: 1;
          transform: translate(25px);
        }
      }

      &.active {
        color: var(--color-primary);
      }

      > a,
      .more {
        display: flex;
        align-items: center;
        padding: 7px 10px;
        overflow: visible;
        position: relative;
        color: rgba(white, 0.5);
        width: 100%;
        transition: 0.35s padding, 0.35s opacity;
        will-change: padding, opacity;

        .counter-badge {
          position: absolute;
          top: 12px;
          right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.35s top;
          min-width: 16px;
          padding: 0 4px;
          border-radius: 3px;
          font-size: 10px;
          height: 16px;
          color: white;
          background-color: var(--color-primary);

          &.yellow-theme {
            background-color: rgb(252, 176, 64);
            color: rgb(30, 38, 62);
          }
        }

        .user-filter-badge {
          display: none;
        }

        &:hover,
        &:focus,
        &.active {
          color: white;
          opacity: 1;
        }

        &.active {
          background-color: rgba(97, 104, 121, 0.4);
          border-radius: 5px;

          &.not-highlighted {
            background-color: inherit;
          }
        }

        &.action-required:after {
          content: " ";
          display: block;
          position: absolute;
          transform: translate(190px);
          width: 8px;
          height: 8px;
          background-color: var(--color-primary);
          border-radius: 50%;
          transition: 0.3s transform;
          will-change: transform;
        }
      }

      .more-menu {
        width: 265px;
        background-color: white;
        border-radius: 5px;
        overflow-y: auto;
        max-height: calc(5 * #{$sidebar-more-item-height});
        box-shadow: 2px 5px 30px rgba(0, 0, 0, 0.2);

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-clip: padding-box;
          background-color: rgba(#5e5e5e, 0.7);
          border-radius: 8px;
        }

        .more-item {
          display: flex;
          height: $sidebar-more-item-height;
          line-height: 47px;
          padding: 0 10px;
          justify-content: space-between;
          font-weight: 400;
          border-bottom: 1px solid #d8d8d8;

          &.active {
            cursor: default;
            background-color: var(--color-gray-light);

            &:hover {
              background-color: var(--color-gray-light);
            }
          }

          &:hover {
            background-color: #f3f3f3;
            color: var(--color-black);
          }

          i {
            font-size: 18px;
            margin: 0 10px 0 5px;
          }
        }

        :last-child {
          border-bottom: none;
        }
      }

      &.divider {
        transform: translateZ(0);
        height: 2px;
        margin: 20px 2px;
        opacity: 0.5;
        background-color: var(--color-gray-dark);
      }
    }

    &.is-collapsed {
      li {
        > a,
        .more {
          padding: 2px 6px;

          .counter-badge {
            font-size: 9px;
            top: 4px;
          }

          .user-filter-badge {
            display: flex;
            position: absolute;
            top: 4px;
            right: 12px;
            font-size: 10px;
          }
        }

        .item-icon {
          font-size: 20px;
        }

        svg {
          min-height: 20px;
        }

        svg,
        .item-icon {
          transform: translate(183px);
          padding: 10px;
          box-sizing: content-box;
        }

        .item-name {
          opacity: 0;
          transform: scale(1.2);
        }

        > a,
        .more {
          &.active:before {
            transform: translate(177px, 7px);
            z-index: 1;
            box-shadow: 0 0 2px 3px var(--color-blue-sidebar);
          }

          &.active {
            background-color: transparent;

            svg,
            .item-icon {
              background-color: rgba(97, 104, 121, 0.4);
              border-radius: 5px;
            }
          }

          &.action-required:after {
            transform: translate(210px);
            border: 0.2px solid #191f2f;
          }
        }

        &:hover {
          .sidebar-tooltip:not(.isTablet) {
            opacity: 1;
            transform: translate(25px);
          }
        }

        &.divider {
          width: 40px;
          margin-left: 190px;
        }
      }
    }
  }

  .more-dropdown {
    position: initial;

    .dropdown {
      border: none;
      transform: translate(30%, 0);
      z-index: 3;
    }

    .trigger-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.isOpenAbove {
      .dropdown {
        border: none;
        transform: translate(30%, -100%);
        z-index: 3;
      }

      &.isCollapsed {
        .dropdown {
          transform: translate(95%, -90%);
        }
      }
    }

    &.isCollapsed {
      .dropdown {
        transform: translate(95%, 0);
      }
    }
  }
}
