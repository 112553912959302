@import '../../../../../../styles/variables';

.MortalityReasonForm {

  .mr-name-row-title, .mr-name-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .mr-name-label,
    .mr-lang {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .mr-name-label,
    .mr-name {
      flex: 3;

      .mr-input {
        margin: 0;
      }
    }

    .mr-lang {
      flex: 2;
      margin: 0 0 0 10px;
    }

    .mr-lang-remove {
      flex: 0 0 40px;
      margin-left: 5px;
    }
  }

  .mr-name-row {
    margin-bottom: 5px;
  }

  .add-translation {
    color: $brand-blue;
    font-size: 14px;
    float: right;
    cursor: pointer;
    margin-bottom: 5px;

    &.indent {
      margin-right: 45px;
    }
  }
}
