.pdf-wrapper {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  overflow: auto;

  .shown-page {
    margin: 10px auto;
  }

  .react-pdf__Page__canvas {
    margin: auto;
  }
}
