.category-row {
  &:first-child {
    border-top: 1px solid #ddd;
  }

  .category-header {
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    padding: 20px 20px;

    .collapse-icon {
      transition: transform 0.35s ease;
      padding-left: 15px;

      &.opened {
        transform: rotate(90deg);
      }
    }
  }
}
