.choose-filter-wrapper {
  font-size: 13px;
  color: var(--color-gray-dark);
  cursor: pointer;
  font-weight: 600;
  position: relative;
  margin: 13px 0 13px auto;

  .angle-icon {
    margin-left: 5px;
    font-size: 12px;
  }

  .filter-dropdown-menu {
    top: 100%;
    margin-top: 10px;
    left: auto;
    right: 0;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.3) 0 -1px 12px 0;
    background-color: white;
    overflow-y: auto;
    max-height: 320px;
    min-width: 170px;
  }

  .list-item {
    min-height: 40px;
    background-color: white;
    display: flex;
    align-items: center;
    transition: background-color 0.3s, color 0.3s ease;
    border-bottom: 1px solid var(--color-gray-light);
    color: var(--color-black);
    font-weight: 400;
    white-space: nowrap;

    .option-label {
      flex: 1;
      padding: 0 10px;
    }

    &:hover {
      background-color: var(--color-orange-light);
      color: var(--color-primary);

      .selected-icon {
        opacity: 0;
        transform: translateX(0px);
      }

      .remove-icon {
        opacity: 1;
        visibility: visible;
      }
    }

    &.active {
      color: var(--color-primary);
    }

    &:last-child {
      border-bottom: none;
    }

    .selected-icon {
      opacity: 1;
      transition: opacity 0.5s, transform 0.3s ease-in-out;
      transform: translateX(13px);
    }

    .option-icon,
    .selected-icon {
      font-size: 15px;
    }

    .remove-icon {
      font-size: 12px;
      opacity: 0;
      visibility: hidden;
      margin-right: 10px;
      transition: opacity 0.3s, visibility 0.3s ease-in-out;
    }
  }

  .no-filters-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color: var(--color-gray-dark);
    text-align: center;
  }

  .info-icon {
    font-size: 25px;
    width: 25px;
    margin-bottom: 10px;
  }
}
