@import '../../../styles/variables';

.ScrollableWrap {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;

  .scrollable-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: visible;
    transition: transform .4s ease;
  }

  .scroll-button {
    position: absolute;
    padding: 8px 10px;
    border-radius: 50%;
    background-color: rgba(90, 90, 90, 0.5);
    color: $white;
    transition: all .3s ease;
    opacity: 0;
    cursor: pointer;
    display: none;
    top: 50%;

    &._hidden {
      opacity: 0;
      pointer-events: none;
    }

    &.scroll-right {
      transform: translate(10px, -50%);
      right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.scroll-left {
      transform: translate(-10px, -50%);
      left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover {
      background-color: rgba(90, 90, 90, 0.7);
      transform: translate(0, -50%);
    }
  }

  &:hover {
    .scroll-button {
      opacity: 1;
    }
  }

  .inset-shadow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    transition: box-shadow .4s;
    transition-delay: .2s;
  }

  &.scrollable-left {
    .scroll-left {
      display: block;
    }

    .inset-shadow.left {
      box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.35);
    }
  }

  &.scrollable-right {
    .scroll-right {
      display: block;
    }

    .inset-shadow.right {
      box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.35);
    }
  }

  &:hover {
    .scroll-button {
      opacity: 1;
    }
  }

  &.isMobile {
    .scrollable-content {
      overflow-x: auto;
    }

    .scroll-button {
      display: none;
    }

    .inset-shadow {
      display: none;
    }
  }

}

