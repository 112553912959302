@import '../../../../styles/variables';

.activity-author-line {
  display: flex;
  flex-direction: row;
  padding-right: 35px;

  .user-actions-desc {
    flex: 1 auto;
    align-self: center;
    margin: 0 10px;
    font-size: 14px;
  }

  .timeago {
    display: block;
    color: $dark-gray;
  }

  &.isViewed {
    color: #8c8c8c;
  }

  &.minimized {

    .user-actions-desc {
      font-size: 13px;
    }

    .timeago {
      display: inline-block;
    }
  }
}
