@import '../../../../../styles/variables';

.GroupMarketingPanel {
  padding: 13px 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
  border: 1px solid $light-gray;
  background-color: $white;

  .logo {
    flex: 0 0 auto;
    margin-right: 10px;
    padding: 9px;
    line-height: 22px;
    font-size: 22px;
    color: $white;
    background-color: $brand-orange;
    border-radius: 50%;
  }

  .description {
    flex: 1;
    margin-right: 10px;
  }

  .actions {

    .dropdown {

      i {
        color: $brand-gray;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }

  .timestamp {
    color: $light-black;
  }
}
