@import '../../../../../styles/variables';

.field-body {
  max-width: 530px;
}

.field-body-title {
  font-weight: 600;
  padding-top: 15px;
  font-size: 14px;
}

.lock-group-info {
  font-weight: normal;
  font-size: 14px;
}

.loads-body {
  max-width: 800px;

  .loads-list {
    overflow: auto;

    .wrapper {
      max-height: 250px;
      overflow: auto;
      min-width: 500px;
    }
  }
}

.source-badge {
  font-size: 10px;
  color: $dark-gray;
  font-weight: 600;
  text-transform: uppercase;
  padding: 1px 4px;
  background-color: $almost-white;
  border-radius: 3px;
  border: 1px solid rgb(221, 221, 221);
  margin-left: 5px;
}
