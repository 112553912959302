.BarnSheetsDiagnoses {
  max-width: 612px;
  margin: 0 auto 50px;

  .placeholder {
    color: var(--color-gray-medium);
    font-size: 25px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  .waypoint {
    display: block;
    width: 100%;
    position: relative;
    height: 100px;
  }

  .preloader {
    margin-top: 10px;
  }
}
