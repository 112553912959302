@import '../../../styles/variables';

.company-profile {

  .mobile-body-box {
    padding: 10px 10px 20px;
  }

  .progress-box {
    margin: 0 0 10px;
  }

  @media screen and (min-width: 64em) {
    .mobile-body {
      border: none;
      background-color: transparent;

      .mobile-body-box {
        padding: 0;
      }
    }
  }
}
