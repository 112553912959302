@import '../../../../styles/variables';

.RolesManageFixedHeader {
  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
  top: 90px;

  &.empty-table {
    .manage-header-panel {
      box-shadow: none;

      .manage-header-body {
        border: none;
      }
    }
  }

  .header-bg {
    background-color: $almost-white;
  }

  .manage-header-panel {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.2);
    margin-top: 20px;

    .manage-header-body {

      .RolesManageTableHeader {
        min-height: 0;
      }

      .FlexTable {
        margin-bottom: 0;

        .table-row {
          border-bottom: none;

          .FlexTableItem {

            &:not(:last-child) {
              // border-right: 1px solid $light-gray;
            }

            .collapsible-name {
              border-bottom: none;

              &.item-name {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 10px 10px 20px;
              }

              i {
                font-size: 12px;
                margin-left: 10px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
