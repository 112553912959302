/**
 * Foundation for Sites by ZURB
 * Version 6.2.4
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.layout-default {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; }
  .layout-default .bodybag {
    position: absolute;
    top: 0;
    bottom: 50px;
    padding-top: 55px;
    padding-bottom: 0;
    width: 100%;
    height: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: var(--color-white-almost); }
    @media (orientation: landscape) {
      .layout-default .bodybag {
        bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .layout-default .bodybag {
        position: absolute;
        left: 240px;
        top: 55px;
        right: 0;
        bottom: 0;
        padding-top: 0;
        width: auto;
        transition: .3s left, .3s top;
        will-change: left; }
        .layout-default .bodybag.is-tablet {
          left: 60px;
          opacity: .9; } }
    .layout-default .bodybag > .content-wrapper {
      transition-property: visibility, opacity;
      transition-duration: .5s;
      margin-left: 0; }
      .layout-default .bodybag > .content-wrapper.is-offline {
        height: calc(100% - 35px); }
  .layout-default.has-ff-filters .bodybag {
    top: 0;
    padding-top: 0; }
  .layout-default.is-dropdown-open .bodybag {
    -webkit-overflow-scrolling: auto; }
  .layout-default.is-edit-mode .bodybag {
    left: 0 !important;
    bottom: 0 !important; }
  .layout-default.is-super-admin-mode .bodybag {
    left: 0 !important; }
  .layout-default.is-fullscreen-mode .bodybag {
    top: 0;
    left: 0 !important;
    padding-top: 0; }
  .layout-default.with-tenant-notification .bodybag {
    top: 35px; }
  @media screen and (min-width: 1024px) {
    .layout-default.with-tenant-notification:not(.is-fullscreen-mode) .bodybag {
      top: 90px; } }
  .layout-default.with-tenant-notification .TenantNotificationBox {
    height: 35px;
    visibility: visible; }
  .layout-default.with-tenant-notification .mediaViewer {
    top: 35px; }
  .layout-default.with-tenant-notification .sidebar,
  .layout-default.with-tenant-notification .main-header {
    top: 35px; }
  @media screen and (max-width: 63.9375em) {
    .layout-default.with-tenant-notification .main-header.sticky-hidden,
    .main-header.force-sticky-hidden .layout-default.with-tenant-notification {
      transform: translateY(-90px); } }
  @media screen and (min-width: 1024px) {
    .layout-default.sidebar-collapsed .bodybag, .layout-default.is-tablet .bodybag {
      left: 60px; } }
  .layout-default .column .column {
    padding-left: 0;
    padding-right: 0; }
  .layout-default .column .row .column {
    flex: 1 1 0px;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    min-width: initial; }
    @media screen and (min-width: 40em) {
      .layout-default .column .row .column {
        padding-left: 0.9375rem;
        padding-right: 0.9375rem; } }
  .layout-default .content {
    padding: 20px; }

.content-wrapper {
  height: 100%; }
