@import 'styles/variables';

.Genetics {

  .genetic-name-column {
    display: flex;
    align-items: center;

    .icon {
      font-size: 24px;
      margin: 0 10px 0 5px;
    }

    .color-primary {
      color: $primary;
    }
  }

  .route-column {
    word-break: break-all;
  }
}
