.selected-section-row {
  margin: 10px 0 5px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid var(--color-gray-light);
  padding: 12px 10px;
  background-color: var(--color-gray-light);
  display: flex;
  align-items: center;
  position: relative;

  .section-icon {
    font-size: 20px;
    margin-right: 5px;
    text-align: center;
    width: 25px;
    min-width: 25px;
  }

  .condition-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .backdrop {
    border-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0);
    transition: background-color .3s;

    &.is-active {
      background: rgba(0, 0, 0, 0.5);

      .actions-block {
        transform: translate(-100%, -50%);
        visibility: visible;
        pointer-events: auto;
      }
    }

    .actions-block {
      display: flex;
      position: absolute;
      align-items: center;
      top: 50%;
      left: 100%;
      visibility: hidden;
      transform: translate(-85%, -50%);
      transition: visibility .2s ease, transform .2s ease;
      color: white;
      height: 100%;
      pointer-events: none;
    }

    .edit-icon {
      height: 100%;
      padding: 5px;
    }

    .remove-icon {
      height: 100%;
      padding: 10px 10px 10px 5px;
    }
  }
}
