@import '../../../../styles/variables';

.field-label {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;

  @media screen and (max-width: 40em) {
    justify-content: flex-start;
    margin: 0 0 10px;
  }
}

.field-select-row {
  display: flex;
  align-items: center;

  .field-select {
    width: 100%;
    margin-bottom: 15px;
  }

  :global(.Select-control) {
    margin: 0;
  }
}

.add-field-row {
  margin: -10px 0 20px;
}

.add-field {
  color: $brand-blue;
  font-size: 14px;
  cursor: pointer;
}

.field-remove-btn {
  padding: 9px;
  border-radius: 5px;
  transition: background-color .2s ease;
  cursor: pointer;
  font-size: 22px;
  margin: 0 0 15px 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
