@import "../../../styles/variables";

.ContentOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $sidebar-color;
  pointer-events: none;
  opacity: 0;
  transition: .35s opacity;
  will-change: opacity;
  z-index: 31;

  &.isMobile {
    bottom: $main-footer-height;

    &.visible {
      pointer-events: none;
    }

    @media (orientation: landscape) {
      bottom: $main-footer-height-shrinked;
    }
  }

  &.visible {
    pointer-events: all;
    opacity: .8;
  }
}
