@import "../../../styles/variables";

.ProgressBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;

  &.inline {
    flex-direction: row;
    align-items: center;

    .pb-text {
      margin-bottom: 0;
    }
  }

  &.inline-flex {
    display: inline-flex;
  }

  .pb-text {
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    margin-bottom: 3px;
  }

  .pb-description {
    margin-left: 5px;
  }

  .pb-scale {
    width: 100%;
    overflow: hidden;
    background-color: $light-gray;
    border-radius: 5px;
    height: 10px;
  }

  .pb-meter {
    height: 100%;
  }

  &.danger {
    .pb-text {
      color: $medium-gray;
    }

    .pb-meter {
      background-color: $medium-gray;
    }
  }

  &.alert {
    .pb-text {
      color: $brand-red;
    }

    .pb-meter {
      background-color: $brand-red;
    }
  }

  &.warning {
    .pb-text {
      color: $brand-orange;
    }

    .pb-meter {
      background-color: $brand-orange;
    }
  }

  &.success {
    .pb-text {
      color: $brand-blue;
    }

    .pb-meter {
      background-color: $brand-blue;
    }
  }


  &.grayscale {
    .pb-text {
      color: $light-black;
    }

    .pb-meter {
      background-color: $dark-gray;
    }
  }
}
