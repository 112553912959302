@import "../../../styles/variables";

.DrBotAvatar {
  position: relative;

  img {
    width: 100%;
  }

  span {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 10px);
    font-size: 12px;
    background-color: rgba(48, 48, 48, .9);
    color: $white;
    border-radius: 10px;
    padding: 2px 8px;
    white-space: nowrap;
  }
}
