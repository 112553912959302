@import '../../../styles/variables';

.tenant-setting-panel {
  background-color: $white;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  .metric-radio-group {
    min-width: 250px;
  }

  @media screen and (max-width: 40em) {
    flex-direction: column;

    .title-wrap {
      text-align: center;
    }

    .metric-radio-group {
      margin: 10px;
    }
  }
}

.bordered {
  border-radius: 5px;
  border: 1px solid $light-gray;
}