@import "../../../../styles/variables";

.ModalsContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 0;
  transition: z-index .3s linear;

  @supports (padding-bottom: env(safe-area-inset-top)) {
    padding-bottom: env(safe-area-inset-top);
  }

  .ModalsContainer__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(30, 38, 62, 0.7);
    opacity: 0;
    transition: opacity .3s linear;
  }

  .modal-wrapper-panel {
    transition: transform .5s;
  }

  &.isOpen {
    z-index: 31;
    pointer-events: all;

    .ModalsContainer__backdrop {
      opacity: 1;
      pointer-events: all;
    }

    .modal-wrapper-panel {
      transform: translateX(-$right-sidebar-width);
    }
  }

  .modal-wrapper {
    position: relative;
    z-index: 9;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    padding: 1%;
    pointer-events: none;

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    &.position-bottom {
      justify-content: flex-end;
      margin-top: 0;
    }

    &.position-center {
      justify-content: center;
      margin-top: 0;
    }

    @media screen and (min-width: 40em) {
      justify-content: flex-start;
      margin-top: 10vh;
    }

    >div {
      box-shadow: 3px 3px 10px rgba(51, 51, 51, .5);
    }
  }
}
