
.type-select {
  max-width: 250px;
}

.type-input-label {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
