@import 'styles/variables';

.BarnSheetsGroupProfile {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $mobile-width) {
    .GroupProfileHeader {
      padding: 15px 5px;
    }
  }

  .page-warn {
    text-align: center;
    line-height: 34px;
    color: white;
    background-color: var(--color-primary);
    background-image: linear-gradient(90deg, hsl(12, 100%, 50%) 3%,  hsl(12, 100%, 60%) 103%);
    font-size: 14px;
    font-weight: 600;

    i {
      font-size: 18px;
    }
  }

  .StickyFooter {

    button.submit-btn {
      padding: 15px 0;
      width: 80%;
      border-radius: 50px;
      box-shadow: rgba(0, 0, 0, 0.3) 0 1px 6px 1px;
    }
  }
}
