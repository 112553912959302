@import "styles/variables";

.question {
  display: flex;
  background-color: white;
  min-height: 50px;
  padding: 15px;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);

  .question-input {
    padding-left: 0;
    display: block;
    border: none;
    width: 100%;
  }

  .control-btn-group {
    padding-left: 15px;
    z-index: 1;
    margin-left: auto;
    display: flex;
    align-items: center;
    transition: opacity 0.3s, transform 0.3s;

    .icon-btn {
      color: $nice-grey;
      cursor: pointer;
      margin-right: 20px;
      font-size: 20px;

      &:hover {
        color: $primary;
      }
    }

    .toggler {
      z-index: 1;
    }
  }

  .dnd-icon {
    min-width: 10px;
    margin-right: 15px;
  }

  &.editing {
    .control-btn-group {
      opacity: 0;
      transform: translateX(20px);
    }
  }

  &.disabled {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .toggler {
    margin-left: auto;
    z-index: 1;
  }
}
