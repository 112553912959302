@import "styles/variables";

.question-row {
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
  position: relative;

  .comments {
    padding-left: 50px;

    .comment-wrapper {
      padding-left: 10px;
      padding-right: 10px;

      &.isMention {
        animation: mention-bg-animation 1.5s ease-out;
      }
    }

    @media (max-width: 40em) {
      padding-left: 0;
    }
  }

  .header {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .status {
      min-width: 70px;
      text-align: right;
      font-size: 14px;
      margin-left: auto;
      text-transform: uppercase;
      font-weight: 800;

      &.pass {
        color: $brand-blue;
      }

      &.fail {
        color: $brand-red;
      }

      &.not_available {
        color: $dark-gray;
      }
    }

    .info-column {
      display: flex;
      align-items: center;

      .question {
        margin-left: 10px;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 25px;
        height: 25px;
        font-size: 15px;
        border: 1px solid #ddd;
        border-radius: 50%;
        color: #ddd;
        margin-right: 10px;

        &.fail {
          color: white;
          border: none;
          background-color: $brand-red;
        }
      }
    }
  }
}

// MOBILE

.question-row {
  @media (max-width: 40em) {
    .header {
      display: flex;
      flex-direction: column;

      .info-column {
        flex-direction: column;

        .question {
          margin-left: 0;
          text-align: center;
        }

        .icon {
          margin-right: 0;
          margin-bottom: 10px;

          &.pass {
            border: none;
            background-color: $brand-blue;
            color: white;
          }
        }
      }

      .status {
        margin: auto;
        margin-top: 15px;
      }
    }
  }
}

@keyframes mention-bg-animation {
  0% {
    background-color: rgba($primary, 0.25);
  }
  100% {
    background-color: transparent;
  }
}
