@import '../../../../styles/variables';

.group-feed-panel {
  border: 1px solid $light-gray;
  border-radius: 5px;
  background-color: $background;
  overflow: visible;
  position: relative;
  padding: 0;

  .headline {
    color: $white;
    font-size: 11px;
    padding: 7px 10px;
    font-weight: 600;
    border-radius: 5px 5px 0 0;

    i {
      font-weight: 600;
      margin-right: 5px;
    }

    &.dark-gray { background-color: $dark-gray; }

    &.brand-orange { background-color: $brand-orange; }

    &.brand-blue { background-color: $brand-blue; }
  }

  .group-feed-body {
    background-color: $white;
    border-radius: 0 0 5px 5px;

    &.no-headline {
      border-radius: 5px;
    }
  }
}
