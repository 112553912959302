@import 'styles/variables';

.MedicationSettings {

  .CustomSelect {
    margin-bottom: 15px;
  }

  .type-select {
    max-width: 250px;
  }

  .field-label {
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 5px;
  }

  .us-input,
  .export-input {
    min-width: 140px;
  }

  .us-input {
    margin-right: 20px;
  }

  .withdrawal-inputs {
    display: flex;
    margin-top: 7px;

    @media screen and (max-width: $laptop-width) {
      flex-direction: column;

      .us-input {
        margin-right: 0;
      }
    }
  }

  .withdrawal {
    display: flex;
    align-items: flex-end;
    margin-left: 20px;
    flex-wrap: wrap;

    .duration-period {
      min-width: 100px;
    }
  }

  .note-input {

    input {
      margin-bottom: 5px;
    }
  }

  .notes-field {
    position: relative;

    .note-characters {
      position: absolute;
      right: 0;
      top: 5px;
      color: $dark-gray;
      font-size: 14px;
    }
  }

  label {

    .label-tag {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .divider {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
  }

  .notesTip {
    display: inline-block;
    margin: 0 0 25px;
    font-size: 14px;
    font-style: italic;
    color: $dark-gray;
  }

  .rules-wrapper {
    margin-bottom: 15px;
  }

  .description {
    margin: 0 0 25px;
    font-size: 14px;
    font-style: italic;
    color: $dark-gray;
  }
}
