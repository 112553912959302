@import "../../../styles/variables";

.GradientProgressBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;

  .gpb-scale {
    width: 100%;
    height: 8px;
    opacity: .7;

    &.gray {
      background-color: rgb(232, 232, 232);
    }

    &.low {
      background: #DE0202;
      background-image: linear-gradient(270deg, rgb(232, 232, 232) 3%, rgb(232, 232, 232) 53%, rgb(222, 2, 2) 103%);
    }

    &.average-low {
      background: #FCAD03;
      background-image: linear-gradient(270deg, rgb(232, 232, 232) 3%, rgb(252, 173, 3) 53%, rgb(222, 2, 2) 103%);
    }

    &.average {
      background: #FCAD03;
      background-image: linear-gradient(270deg, rgb(232, 232, 232) 3%, rgb(252, 173, 3) 53%, rgb(232, 232, 232) 103%);
    }

    &.average-high {
      background: #FCAD03;
      background-image: linear-gradient(270deg, rgb(0, 105, 161) 3%, rgb(252, 173, 3) 53%, rgb(232, 232, 232) 103%);
    }

    &.high {
      background: #0069A1;
      background-image: linear-gradient(270deg, rgb(0, 105, 161) 3%, rgb(232, 232, 232) 53%, rgb(232, 232, 232) 103%);
    }
  }

  .gpb-meter {
    height: 100%;
    background-color: transparent;
    position: relative;
    transition: width .3s ease;

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      right: 0;
      top: -4px;
      height: 16px;
      width: 2px;
      background-color: $black;
    }
  }
}
