.CheckupLogsList {
  font-size: 14px;
  color: var(--color-black);

  .activity-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-light);
    padding: 10px 20px;

    &:last-child {
      border-bottom: 0;
    }

    .user-avatar {
      flex: 0 0 auto;
      margin-right: 10px;
      align-self: flex-start;
    }

    .log {
      flex: 1;

      .description {

        .variety {
          font-weight: 400;

          &:last-child {
            .comma {
              display: none;
            }
          }
        }
      }

      .time {
        color: var(--color-gray-dark);
        font-size: 12px;
      }
    }
  }
}
