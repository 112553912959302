.farmfeed-filter {
  border-bottom: 0 solid var(--color-alto);
  background-color: white;
  z-index: 4;
  position: sticky;
  top: 0;

  &.opened {
    border-bottom-width: 1px;
  }
}
