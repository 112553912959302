@import "../../styles/variables";

.nothing-box {
  margin-bottom: 1rem;
  padding: 50px 20px 60px;
  border: 1px dashed $medium-gray;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  .nothing-box-icon {
    color: $medium-gray;
    font-size: 48px;
    margin-bottom: 15px;
  }

  .nothing-box-title, h1,
  .nothing-box-text, p {
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;
  }

  .nothing-box-title, h1 {
    font-size: 20px;
    font-weight: bold;
  }

  .nothing-box-text, p {
    font-size: 16px;
  }

  .button {
    margin-bottom: 20px;
  }
}
