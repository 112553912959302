@import "../../../styles/variables";

.DropdownSelect {
  position: relative;

  .dropdown-container {
    width: 100%;
  }

  .pseudo-select {
    height: 2.4375rem;
    padding: 0 10px;
    line-height: 2.4375rem;
    font-size: 0.9rem;
    font-weight: normal;
    color: $black;
    border-radius: 3px;
    margin: 0;
    border: none;
    position: relative;
    background-image: url('./arrow.svg');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 12px;
    background-color: $light-gray;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 40px;
    transition: background-color .2s;

    .count {
      color: $dark-gray;
      margin-left: 5px;
    }
  }

  .dropdown {
    top: 100%;
    right: 0;
    width: 100%;
    margin: 0;
    opacity: 0;
    transform: translateY(-15px);
    transition: transform .35s ease-in-out, opacity .4s ease;
    will-change: transform, opacity;

    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    &.above {
      margin: 0 0 10px !important;
      bottom: 100%;
      top: inherit !important;
    }
  }


  .dropdown-menu {
    z-index: 1000;
    padding: 0;


    .dropdown-option {
      border-bottom: 1px solid $light-gray;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 10px 15px;

      &.active-option {
        background-color: $background;
      }

      .option-label {
        flex: 1 auto;
        max-width: 100%;

        .count {
          color: $dark-gray;
          margin-left: 5px;
        }
      }

      &:last-child {
        border: none;
      }

      .fa-check {
        color: $brand-blue;
        padding: 3px 0;
      }
    }
  }

  &.is-mobile {
    .dropdown-container {
      .responsive-dropdown {
        transform: translateX(0);
        left: 0;
        top: 100%;
        margin: 10px 0 0;
        z-index: 9;
        width: 100%;

        &:before {
          content: '';
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid $white;
          position: absolute;
          bottom: 100%;
          left: 20px;
        }

        &.above:before {
          content: '';
          border-top: 10px solid $white;
          border-bottom: none;
          position: absolute;
          top: 100%;
          left: 20px;
        }

        &.center:before {
          content: '';
          border-bottom: 10px solid transparent;
          border-top: 10px solid transparent;
          border-right: 10px solid $white;
          border-left: none;
          position: absolute;
          bottom: 50%;
          left: -10px;
        }
      }
    }
  }

  &.is-open {
    .pseudo-select {
      background-color: $medium-gray;
    }
  }
}
