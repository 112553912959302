@import "styles/variables";

.category-stepper {
  height: 40px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 64em) {
    display: none;
    align-items: center;
  }

  @media (max-width: 40em) {
    margin-bottom: 0;
    width: 100%;
    padding: 0 20px;
    display: flex;
  }

  .step {
    flex: 1;
    max-width: 250px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      .circle-wrapper {
        &::after {
          display: none;
        }
      }
    }

    .circle-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .name {
        position: absolute;
        top: 100%;
        width: max-content;
        text-align: center;
        font-weight: 600;
        font-size: 10px;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;

        @media (max-width: 40em) {
          display: none;
        }
      }

      &.filled {
        &::after {
          background-color: $primary;
        }
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        height: 1px;
        background-color: #ddd;
        left: 50%;
        top: 10px;

        @media (max-width: 40em) {
          top: 7px;
        }
      }

      .step-circle {
        background-color: white;
        z-index: 1;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        color: white;
        font-weight: 600;

        @media (max-width: 40em) {
          width: 14px;
          height: 14px;
          font-size: 8px;
        }

        &.filled {
          background-color: $primary;
          border-color: $primary;
        }

        &.touched {
          border-color: $primary;
        }
      }
    }
  }
}
