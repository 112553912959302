@import "../../../styles/variables";

.nav-button {
  position: absolute;
  width: 45px;
  height: 90px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 15px;
  z-index: 5;
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 600;
  color: $black !important;
  transition: none !important;

  &.left {
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.right {
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

