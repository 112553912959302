
.phone-input-field {

  .phone-input-label {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
  }
}
