@import '../../../../styles/variables';

.checkup-box {
  border: 1px solid $light-gray;
  margin: 0 10px;
  font-size: 14px;
  border-radius: 5px;
  background: #fff;

  .checkup-box-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    margin: 10px 0;

    .farm-info {
      flex: 1 auto;
      margin: 0 10px;

      .manager {
        color: $dark-gray;
      }
    }

    .farm-label {
      font-size: 10px;
    }

    .farm-actions {
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .button {
        width: auto;
      }
    }
  }

  .checkup-box-body {
    margin: 0 10px;
  }

  .checkup-box-footer {
    display: flex;
  }
}
