@import "styles/variables";

.svr-header {
  margin: auto;
  width: 100%;
  padding: 15px 25px;
  justify-content: space-between;
  display: flex;
  align-items: center;

  .close-icon {
    display: none;
  }

  .farm-info {
    display: flex;

    .farm-name {
      &:hover {
        color: $black;
      }
    }

    .vertical-divider {
      padding: 0 5px;
    }
  }

  @media (max-width: 40em) {
    justify-content: center;
    flex-wrap: wrap;
    position: relative;

    .close-icon {
      z-index: 1;
      display: block;
      cursor: pointer;
      color: $primary;
      font-size: 18px;
      top: 20px;
      left: 25px;
      position: absolute;
    }

    .farm-info {
      position: relative;
      font-size: 16px;
      text-align: center;
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      font-weight: 600;

      .farm-name {
        font-size: 10px;
        font-weight: normal;
      }

      .vertical-divider,
      .svr-title {
        display: none;
      }
    }
  }
}
