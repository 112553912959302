@import '../../../../styles/variables';

.MatchColumnItem {
  flex: 0 0 220px;
  width: 220px;
  margin-right: 10px;
  border: 2px solid $primary;
  border-radius: 5px;

  .select-block {
    padding: 10px;
    height: 150px
  }

  .unmatch-btn {
    color: $primary;
    cursor: pointer;
  }

  .data-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;

    .column-item {
      font-weight: 600;
      padding: 14px;
      min-height: 48px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      background-color: #FFE8DF;
      border-top: 1px dotted $primary;
    }

    .data-item {
      padding: 10px;
      min-height: 40px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      background-color: $light-orange;
      border-top: 1px dotted $primary;
    }
  }

  .active-block {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .unmatch-block {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .skip-button {
    cursor: pointer;
  }

  .automatch-caption {
    font-size: 14px;
    font-weight: 600;
  }

  &.active-state {
    border: 2px solid $aquamarine;
    border-radius: 5px;

    .skip-button {
      color: $brand-blue;
    }

    .data-block {
      .column-item {
        border-top: 1px dotted $aquamarine;
        background-color: #E3EFF5;
      }

      .data-item {
        background-color: $light-blue;
        border-top: 1px dotted $aquamarine;
      }
    }
  }

  &.matched {
    border: 2px solid $dark-gray;

    .matched-block {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .data-block {
      .column-item {
        border-top: 1px dotted $dark-gray;
        background-color: $background;
      }

      .data-item {
        background-color: $white;
        border-top: 1px dotted $dark-gray;
      }
    }
  }

  &.skipped {
    border: 2px solid $brand-red;

    .skipped-block {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .skipped-caption {
        color: $brand-red;
      }
    }

    .data-block {
      .column-item {
        border-top: 1px dotted $brand-red;
        background-color: $background;
      }

      .data-item {
        background-color: $white;
        border-top: 1px dotted $brand-red;
      }
    }
  }
}
