@import "styles/variables";

.trucking-route-wrapper {
  position: relative;

  .divider {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    border-top: 1px solid $alto;

    @media screen and (max-width: 48em) {
      display: none;
    }
  }

  .trucking-route {
    flex-wrap: wrap;
    font-size: 13px;
    min-height: 80px;
    display: flex;
    border: 1px solid $alto;
    border-radius: 5px;
    align-items: center;

    @media screen and (max-width: 48em) {
      flex-wrap: wrap;
    }

    .route {
      align-items: center;
      min-height: 88px;
      color: $black;
      padding: 15px;
      display: flex;
      align-self: center;
      width: 50%;

      &:last-child {
        .arrow {
          display: none;
        }
      }

      @media screen and (max-width: 48em) {
        width: 100%;
        border-top: 1px solid $alto;

        &:first-child {
          border-top: none;
        }
      }
    }

    .arrow {
      margin-left: auto;
      margin-right: 15px;
      width: 25px;
      height: 25px;
      background-color: $brand-blue;
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      align-self: center;

      &.sale {
        background-color: $primary;
      }

      &.arrival {
        background-color: $brand-blue;
      }

      &.transfer {
        background-color: #96adbd;
      }

      &.in_transfer {
        background-color: $brand-orange;
      }

      &.out_transfer {
        background-color: #96adbd;
      }
    }
  }
}
