@import "../../../styles/variables";

.DailyCheckupEdit {
  height: 100%;
  display: flex;
  flex-direction: column;

  .Subnavigation-wrapper {
    z-index: 1;
  }

  .checkin-btn {
    color: $primary !important;
    margin-right: 10px;
  }

  .checked-in-state {
    text-align: center;
    line-height: 34px;
    color: $white;
    background: transparent linear-gradient(90deg, $secondary 3%, $aquamarine 103%);
    font-size: 14px;
    font-weight: 600;

    i {
      font-size: 18px;
    }
  }

  .group-location {
    font-size: 12px;
    background-color: $light-yellow;
    color: $black;
    text-align: center;
    line-height: 25px;
    border-bottom: 1px solid $light-gray;
  }

  .group-info {
    display: flex;

    .checkup-body {
      flex: 1 1 auto;
      margin-top: 20px;
      margin-bottom: 20px;

      &.pb-100 {
        padding-bottom: 100px;
      }
    }
  }

  .warning-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;

    .warning-icon {
      color: $brand-orange;
      margin-right: 10px;
      font-size: 20px;
    }
  }

  .checkup-subnav {

    .scrollable-content {
      width: 100%;

      .flex {
        width: 100%;
      }

      .item {
        flex: 1;
        text-align: center;
      }
    }
  }
}
