
.BirhdateField {
  font-size: 14px;

  .bithday-input-label {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 600;
  }
}
