@import '../../../styles/variables';

a.view-btn {
  margin: -1px;
  flex: 1 auto;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  span {
    margin: 0 auto;
  }
}

