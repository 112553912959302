@import 'styles/variables';

.wrapper {
  display: flex;
  align-items: stretch;
  position: relative;
  background-color: inherit;

  .content {
    width: 100%;
  }

  .marker-wrapper {
    background-color: inherit;
    display: flex;
    height: 25px;

    &.end {
      &::after {
        display: none;
      }

      .marker {
        color: $primary;
      }
    }

    .marker {
      text-align: center;
      font-size: 20px;
      z-index: 1;
      width: 20px;
      margin-right: 15px;
      background-color: inherit;
      color: #ddd;

      &.source {
        border: 4px solid #ddd;
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }

    }

    &::after {
      content: '';
      background-position: center;
      width: 20px;
      background-repeat: repeat-y;
      background-image: url('./circle.svg');
      height: 100%;
      position: absolute;
      left: 0;
    }
  }
}
