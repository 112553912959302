@import "../../styles/variables";

.MessageBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  min-height: 50px;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 20px;
  border: 1px solid $light-gray;

  &.no-border {
    border: none;
  }

  .MessageBox__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    font-size: 24px;
    background-color: $brand-orange;
    color: $white;
    min-height: 50px;
  }

  .MessageBox__message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 1 1 auto;
    font-size: 14px;
    color: $black;
    background-color: transparent;
    padding: 10px;
  }

  &.type-info {
    border-color: $light-gray;
    background-color: $background;

    .MessageBox__icon {
      background-color: $brand-orange;
    }
  }

  &.type-warning {
    border-color: $brand-orange;
    background-color: $light-yellow;

    .MessageBox__icon {
      background-color: $brand-orange;
    }
  }

  &.type-alert {
    border-color: $brand-red;
    background-color: $light-orange;

    .MessageBox__icon {
      background-color: $brand-red;
    }
  }

  &.type-success {
    border-color: $brand-blue;
    background-color: $light-blue;

    .MessageBox__icon {
      background-color: $brand-blue;
    }
  }

  &.type-default {
    background-color: $almost-white;

    .MessageBox__icon {
      background-color: $almost-white;
      color: $black;
    }
  }
}

