@import '../../../../styles/variables';

.ShareActivityPortal {
  height: 100%;

  .post-content {
    height: calc(100% - 56px);
    overflow-y: auto;
    background-color: $white;
    padding: 15px 10px;

    .user-name {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .mention-line {
    margin-top: 5px;
    width: 100%;
  }

  .text-block {
    display: block;
    width: 100%;
    resize: none;
    padding: 10px 0 0 0;
    font-size: 14px;
    border: none;
    box-shadow: none;
    min-height: 40px;
    margin: 10px 0 0;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .activity-preview {
    position: relative;

    .backdrop-gradient {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    }

    .no-pointer-events {
      pointer-events: none;
      margin: 15px 0 0;
    }

    &.truncated {
      height: 250px;
      overflow: hidden;
    }
  }
}
