@import '../../../styles/variables';

.InputFile {
  max-width: 100%;

  .input-field {
    display: flex;
    align-items: center;
    height: 40px;

    input[readOnly] {
      flex: 1;
      height: 40px;
      border: 2px solid $light-gray;
      cursor: pointer;
      padding-left: 10px;
    }
  }

  .file-type {
    display: none;
  }

  .label-block {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .label-box {
    font-size: 14px;
    color: $black;
    background-color: $light-gray;
    border: 2px solid $light-gray;
    border-left: none;
    align-self: stretch;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
  }

  .explanatory-text {
    margin-top: 5px;
    font-size: 12px;
    color: $dark-gray;
    padding: 4px 0;
    font-weight: 600;
  }

  .explanatory-subtext {
    font-size: 12px;
  }
}
