@import 'styles/variables';

.GeneticHeader {
  z-index: 1;
  background-color: $white;
  padding: 15px;
  border-bottom: 1px solid $light-gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  height: 90px;
  position: sticky;
  top: 0;

  .genetic-info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
  }

  .genetic-information {
    margin-left: 10px;
  }

  .genetic-name {
    font-size: 22px;
    margin-right: 5px;
  }

  .genetic-info {
    color: $almost-black;

    .type-string {
      color: $primary;
    }
  }

  .header-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 63.9375em) {
    flex-direction: column;
    height: 100%;

    .header-actions {
      margin: 10px auto 0;
    }

    .genetic-information {
      margin: 5px 0 0;
      text-align: center;
    }

    .genetic-info-wrapper {
      justify-content: center;

      .genetic-name {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .avatar-wrapper {
    width: 40px;
    height: 40px;
    background-color: #e8e8e8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .header-avatar {
      font-size: 24px;
    }
  }
}
