@import '../../../styles/variables';

.legend-item {
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 2px;

  &.is-reverse {
    flex-direction: row-reverse;
  }
}

.legend-label {
  color: #8c8c8c;
}

.square {
  width: 11px;
  height: 11px;
  border-radius: 2px;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 12px solid #1E263E;
}
