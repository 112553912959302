@import "../../../styles/variables";

.ConditionRadioButtons {
  display: flex;
  align-items: center;

  .condition-type {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 600;
    color: $dark-gray;
    cursor: pointer;
    border: 2px solid $light-gray;
    background-color: $background;
    border-right: none;

    &:last-child {
      border-radius: 0 3px 3px 0;
      border-right: 2px solid $light-gray;
    }

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    i {
      font-size: 20px;
      margin-right: 5px;
    }

    &.poor-condition {
      i { color: $brand-red; }

      &.active {
        background-color: $brand-red;
        border-color: $brand-red;
      }
    }

    &.avg-condition {
      i { color: $brand-orange; }

      &.active {
        background-color: $brand-orange;
        border-color: $brand-orange;
      }
    }

    &.good-condition {
      i { color: $brand-blue; }

      &.active {
        background-color: $brand-blue;
        border-color: $brand-blue;
      }
    }

    &.active {
      color: $white;

      i { color: $white; }
    }
  }
}
