@import '../../../../styles/variables';

.diseases-list-wrapper {
  position: absolute;
  border: 1px solid $light-gray;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.4);
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  min-width: 300px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border-top: 0;
    border-left: 0;
    top: 0;
    left: 50%;
    background-color: $white;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: -1;
  }

  .search-disease-box {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid $light-gray;
    padding: 10px;
    background-color: $white;

    .search-disease {
      max-width: 100%;
    }
  }

  .diseases-list {
    max-height: 210px;
    background-color: white;
    overflow-y: auto;

    .line {
      padding: 10px 20px;
      border-bottom: 1px solid $light-gray;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        cursor: pointer;
        background-color: $almost-white;
      }
    }

    .magic-icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
}
