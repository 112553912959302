@import "../../../../../styles/variables";

.shipment-form {

  .shipment-field {

    :global(.label-wrapper) {
      font-weight: 600;
      font-size: 14px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 10px 0;

      @media screen and (max-width: 40em) {
        justify-content: flex-start;
        margin: 0 0 10px;
      }
    }

    :global(.field-input) {
      font-size: 14px;
      font-weight: 600;
      text-align: right;
      border-color: $light-gray;
      margin: 0;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }

      &:focus {
        border-color: $primary;
      }
    }
  }

  .conditions {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .condition-type {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 7px 5px;
    font-size: 14px;
    font-weight: 600;
    color: $dark-gray;
    cursor: pointer;
    border: 2px solid $light-gray;
    background-color: $background;
    border-right: none;

    &:last-child {
      border-radius: 0 3px 3px 0;
      border-right: 2px solid $light-gray;
    }

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    i {
      font-size: 20px;
      margin-right: 5px;
    }

    &.poor-condition {

      i { color: $brand-red; }
    }

    &.avg-condition {

      i { color: $brand-orange; }
    }

    &.good-condition {

      i { color: $brand-blue; }
    }

    &.active {
      color: $white;

      i { color: $white; }
    }

    &.active.poor-condition {
      background-color: $brand-red;
      border-color: $brand-red;
    }

    &.active.avg-condition {
      background-color: $brand-orange;
      border-color: $brand-orange;
    }

    &.active.good-condition {
      background-color: $brand-blue;
      border-color: $brand-blue;
    }
  }
}

@media screen and (max-width: 40em) {
 .shipment-form {
  :global(.Select-menu) {
    max-height: 130px;
  }
 }
}
