@import '../../../styles/variables';

.tenant-status-row {
  border-top: 1px solid $light-gray;
  margin-top: 20px;
  padding-top: 20px;

  .time-periods {
    margin-left: 30px;
    margin-top: 10px;
    font-size: 14px;
  }

  .description {
    font-size: 14px;
    margin-left: 30px;
  }

  .expires-days-select {
    margin-top: 5px;
    display: inline-block;

    :global(.Select-control) {
      width: 150px;
    }

    :global(.Select-menu-outer) {
      width: 150px;
      min-width: 0;
    }
  }
}
