@import "styles/variables";

.progress-container {
  color: white;
  font-size: 12px;
  font-weight: 600;
  margin: 0 25px;
  max-width: 650px;
  width: 100%;

  .progress-bar {
    margin-top: 5px;
    width: 100%;
    height: 2px;
    background-color: rgba($primary, 0.2);

    .progress {
      background-color: $primary;
      height: 100%;
    }
  }

  @media (max-width: 40em) {
    max-width: 125px;

    .progress-bar {
      height: 4px;
    }

    .label {
      display: none;
    }
  }
}
