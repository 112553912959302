@import '../../styles/variables';

.DiagnosisBadge {
  display: inline-block;
  color: $white;
  border-radius: 3px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 3px 5px;
  background-color: $brand-orange;

  &.dismissed {
    background-color: $dark-gray;
  }

  &.large-size {
    font-size: 14px;
    line-height: 35px;
    border-radius: 5px;
  }

  .fa {
    margin-right: 5px;
  }

  &.diagnosed {
    background-color: $aquamarine;
  }
}
