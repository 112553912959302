
.ShareActivityModal {

  &.modal {

    .modal-header {
      padding: 5px 20px;
    }

    .modal-body {
      padding: 15px 5px;
      overflow-y: auto;
      max-height: 600px;
    }

    .close-button {
      top: 7px;
      padding: 10px;
    }

    .modal-footer {
      text-align: right;
      padding: 5px 10px;
    }
  }

  .share-icon {
    margin-right: 10px;
  }

  .type-message-block {
    display: flex;
    padding: 0 15px 10px;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;

    .user-photo {
      align-self: flex-start;
      margin-right: 10px;
    }
  }

  .select-data-box {
    position: relative;
    z-index: 2;

    .company-line {
      padding: 0 15px;
    }
  }

  .text-block {
    resize: none;
    padding: 10px 0 0 0;
    font-size: 14px;
    border: none;
    box-shadow: none;
    min-height: 40px;
    flex: 1;
    margin: 0;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .activity-preview {
    position: relative;

    .backdrop-gradient {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    }

    .no-pointer-events {
      pointer-events: none;
    }

    &.truncated {
      height: 250px;
      overflow: hidden;
    }
  }
}
