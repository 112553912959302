
.CustomColumn {
  text-align: left;

  .center-text {
    text-align: center;
  }

  @media screen and (min-width: 64em) {
    .right-text {
      text-align: right;
    }
  }

  .flex-row {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  @media screen and (max-width: 63.9375em) {
    &.is-checkbox {
      padding: 0;
    }
  }
}
