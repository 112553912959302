@import '../../../styles/variables';

.FarmFeedAddComment {

  .add-comment-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $background;
    border-top: 1px solid $light-gray;
    padding: 10px;
    position: relative;
    border-radius: 0 0 5px 5px;

    .avatar {
      margin-right: 10px;
      flex-shrink: 0;
    }

    .post-button {
      margin-left: 10px;
    }

    .comment-text {
      flex: 1 auto;

      textarea {
        resize: none;
        padding: 5px 25px 5px 10px;
      }
    }
  }

  .post-comment-button {
    padding: 8px;
    color: $white;
    background-color: $primary;
    cursor: pointer;
    border-radius: 50%;
    font-size: 15px;

    &.profile-button {
      width: 40px;
      height: 40px;
      padding-left: 10px;
      padding-top: 9px;
      font-size: 22px;
    }
  }

  .desktop-post-button {
    max-width: 65px;
    padding: 9px 12px;
    margin: 0 0 0 10px;
    display: block !important;
  }
}
