@import '../../../styles/variables';

.ImageCropperModal {
  width: 690px;
  display: flex;
  flex-direction: column;

  &.mobile {
    border-radius: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    animation: scale-in-center 0.2s cubic-bezier(.25, .46, .45, .94) both;

    .loading-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
      display: block;
      background-color: rgba(black, .4);
    }

    .cropper-preloader {
      top: 30%;
    }

    .image-cropper {
      flex: 1;
      background-color: #000;
    }

    .mobile-header {
      height: 55px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      color: #fff;
      font-size: 16px;
      justify-content: space-between;
      font-weight: 600;
      background-color: $primary;

      &.view-mode {
        background-color: #000;
      }

      .header-title {
        font-size: 16px;
      }
    }
  }

  .rotate-btn {
    position: absolute;
    color: #fff;
    top: 12%;
    padding: 22px;
    right: 0;
    z-index: 1;
    font-size: 25px;
    transform: rotate(90deg);
    cursor: pointer;

    &.mobile {
      position: relative;
    }
  }

  .image-cropper {
    width: 100%;
    height: 360px;
    background-color: $black;

    &.view-mode {
      pointer-events: none;
    }
  }

  .edit-panel {
    position: relative;
    border-top: 1px solid $black;
    height: 80px;
    background-color: black;
    color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    i {
      font-size: 25px;
    }

    .image-label {

      input {
        display: none;
      }
    }
  }

  .actions-panel {
    padding: 17px 20px 13px;
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    justify-content: flex-end;

    .remove-btn {
      margin-right: auto;
    }

    .image-label {

      input {
        display: none;
      }
    }
  }
}

@keyframes scale-in-center {

  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
