@import "../../../styles/variables";

.date-picker-mobile-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.picker-header {
  flex: 0 0 auto;
  border-bottom: 1px solid $light-gray;
  background-color: $background;
  min-height: 60px;
  padding: 10px;
  width: 100%;
}

.header-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;
  margin-bottom: 10px;
}

.close {
  padding: 12px;
  border-radius: 50%;
  transition: background-color .2s;
  font-size: 18px;
  color: $almost-black;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}

.header-dates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;
  min-height: 60px;

  .active {
    color: $primary;
  }
}

.picker-body {
  flex: 1 1 100%;
  background-color: $white;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.picker-footer {
  flex: 0 0 auto;
  border-top: 1px solid $light-gray;
  background-color: $background;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;

  .save-button {
    font-size: 18px;
    padding: 16px;
  }
}
