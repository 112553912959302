@import '../../../../../styles/variables';

.vaccine {
  color: $primary;
}

.vaccine-form {
  border-bottom: 1px dashed $alto;
  padding: 10px 0;
  font-size: 14px;
}

.vaccine-line {
  display: flex;
  align-items: center;

  &.disabled {
    opacity: .5;
  }
}

.vaccine-select-line {
  display: flex;
  height: 40px;

  .vaccine-title {
    padding-right: 30px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
}

.remove-btn {
  margin-left: auto;
  color: $alto;
  height: 100%;
  display: flex;
  align-items: center;
  width: 40px;
  justify-content: center;
  cursor: pointer;
  transition: all .3s ease;
  font-size: 22px;
  padding: 6px 8px;
  border-radius: 5px;
  color: $almost-black;
  

  &:hover {
    background: rgba(0, 0, 0, 0.1);

  }
}
