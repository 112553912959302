@import '../../../styles/variables';

.email-check-icon {
  &.color-warning {
    color: $brand-orange;
  }

  &.color-secondary {
    color: $secondary;
  }

  &.input-icon {
    font-size: 18px;
    font-weight: bold;
  }

  &.loader-svg {
    width: 22px;
    height: 22px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
