@import "../../../../styles/variables";

.expand-container {
  .expand-line {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 10px;
    
      p {
        margin: 0;
        padding: 0 20px;
      }
  
      .button {
        margin-left: auto;
        width: 40px;
        height: 40px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }
  
  .row-expand {
    max-height: 0;
    transition: all 0.6s;
    overflow: hidden;

      p {
        word-break: break-all;
        padding: 15px;
        border-radius: 0 0 10px 10px;
        max-height: 150px;
        overflow: scroll;
        margin: 0;
      }
    }

    .expanded {
      max-height: 150px;
      transition: all 0.6s;
    }
}

.green {
  color: $green;
}

.red {
  color: $brand-red;
}

.modal-body {
  padding-bottom: 25px !important;
}
