@import '../../../../../styles/variables';

.MedicationFeedbackHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;
  padding: 15px;
  border-bottom: 1px solid $light-gray;
  font-size: 14px;
  min-height: 80px;
  position: relative;

  .left-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;

    .medication-icon {
      font-size: 24px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 50%;
      overflow: hidden;
      background-color: $light-gray;
      color: $black;
      flex-shrink: 0;
    }

    .medication-title {
      margin-left: 10px;

      .name {
        font-size: 22px;
      }

      .description {
        font-size: 14px;
        color: $light-black;
      }
    }
  }

  .right-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .stat-box {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      padding: 5px 15px;
      color: $light-black;

      .stat-label {
        text-transform: uppercase;
      }

      .value {
        font-size: 16px;
      }

      &:not(:first-child) {
        border-left: 1px solid $light-gray;
      }
    }
  }

  @media screen and (min-width: 64em) {
    &.isSticky {
      position: sticky;
      position: -webkit-sticky;
      z-index: 2;
      top: 0;
    }
  }

  @media screen and (max-width: 63.9375em) {
    flex-direction: column;
    height: auto;
    text-align: center;

    .left-side {
      flex-direction: column;

      .medication-title {
        margin-left: 0;
        margin-bottom: 10px;

        .description {
          font-size: 12px;
        }
      }
    }

    .right-side {
      .stat-box {
        font-size: 11px;
        padding: 5px 10px;
      }
    }

    .medication-feedback-dropdown {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}
