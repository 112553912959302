@import '../../../../styles/variables';

.SourceOverview {
  padding: 0 10px;

  .chart-box {
    border-radius: 5px;
    border: 1px solid $light-gray;
    min-width: 300px;
    background: $white;
    display: flex;
    flex-direction: column;
    position: relative;

    .chart-box-header {
      background-color: $white;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 15px 20px 5px;

      .header-right {
        margin-left: auto;
      }
    }


    .chart-box-body {
      position: relative;
      flex: 1;
      padding: 0 10px;
    }

    .chart-box-footer {
      background-color: $background;
      font-size: 14px;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 20px;
      border-top: 1px solid $light-gray;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .preloader {
      top: 30%;
    }
  }
}
