@import "styles/variables";

.farm-details {
  color: var(--color-gray-dark);
}

.panel-body {
  margin-top: 20px;
  padding: 0 20px;
  background-color: #fff;
}

.protocol-icon {
  color: var(--color-black-light);
}
