@import '../../../../styles/variables';

.TreatmentFeedbackHome {

  .treatments-table-section {
    @media screen and (max-width: 63.9375em) {
      padding: 0;
      margin-top: 0;

      .treatments-table-header {
        border-top: none;
      }

      .treatments-table-body {
        border-bottom: none;
      }

      .treatments-table-header,
      .treatments-table-body {
        border-radius: 0;
        border-left: none;
        border-right: none;
      }
    }
  }

  .product-name-column {
    display: flex;
    align-items: center;

    .icon {
      font-size: 24px;
      margin: 0 10px 0 5px;
    }
  }

  .medication-icon {
    margin-right: 10px;
    font-size: 24px;
    color: $black;
    min-width: 25px;
  }

  .mobile-treatment-name {
    font-size: 14px;
    font-weight: 600;
    flex: 1;
  }
}
