.treatment-step-card {
  border-radius: 8px;
  border: 1px solid var(--color-alto);
  font-size: 14px;
  overflow: hidden;

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .status-line {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    height: 25px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-alto);
    background-color: #ffdb4b;
    color: #745902;

    &.completed {
      background-color: white;
      color: var(--color-black);
    }
  }

  .card-header {
    display: flex;
    align-items: center;
    height: 65px;
    padding: 0 20px;

    @media (max-width: 40em) {
      padding: 0 10px;
    }

    .complete-icon {
      font-size: 25px;
      color: var(--color-blue-brand);
    }

    .active-icon {
      min-width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--color-primary);
    }

    .btn-group {
      margin-left: auto;
      display: flex;
      align-items: center;
      padding-left: 15px;
    }

    .submit-button {
      min-width: 130px;
    }

    .complete-label,
    .submit-button {
      margin-left: auto;
      margin-right: 20px;

      @media (max-width: 40em) {
        display: none;
      }
    }

    .complete-label {
      font-style: italic;
      color: var(--color-gray-nice);
    }

    .collapse-icon {
      margin-left: auto;
      padding: 5px;
      color: var(--color-gray-dark);
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      transition: .3s;

      &.opened {
        transform: rotate(90deg);
      }

      @media screen and (max-width: 40em) {
        display: none;
      }
    }
  }

  .treatment-timeline {
    background-color: var(--color-white-bg);
    margin-left: 45px;
    flex: 1 1 auto;
    max-width: 450px;
    min-width: 380px;

    @media screen and (max-width: 60em) {
      max-width: unset;
      min-width: unset;
      width: 100%;
      margin-left: 0;
    }

    .end-icon {
      background-color: transparent;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
    }

    .duration-wrapper {
      min-height: 30px;
    }

    .duration {
      border-top: 1px solid var(--color-alto);
      border-bottom: 1px solid var(--color-alto);
      padding: 10px 0;
      margin: 15px 0;

      @media screen and (max-width: 60em) {
        border: none;
        padding: 0;
        margin: 10px 0;
      }
    }
  }

  .collapse-content {
    background-color: var(--color-white-bg);
    padding: 30px;
    border-top: 1px solid var(--color-alto);

    @media screen and (max-width: 40em) {
      display: none;
      padding: 10px;
    }

    .general-info {
      display: flex;
      padding-bottom: 35px;
      justify-content: space-between;

      @media screen and (max-width: 60em) {
        flex-direction: column-reverse;
      }

      @media screen and (max-width: 40em) {
        font-size: 13px;
        padding-bottom: 0;
      }

      .product-info {
        margin-left: 100px;
        max-width: 350px;
        min-width: 250px;
        flex: 1;

        @media screen and (max-width: 60em) {
          margin-left: 0;
          padding-bottom: 20px;
        }
      }
    }
  }

  .mobile-content {
    @media screen and (min-width: 40em) {
      display: none;
    }

    .submit-button {
      width: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .treatment-timeline {
      border-top: 1px solid var(--color-alto);
      padding: 15px 10px;
    }
  }

  .card-footer {
    border-top: 1px solid var(--color-alto);
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
    background-color: var(--color-white-bg);
    transform: scaleY(1);
    transform-origin: 0 100%;
    transition: .3s;

    &.hidden {
      height: 0;
      transform: scaleY(0);
      opacity: 0;
    }

    @media screen and (max-width: 40em) {
      display: none;
    }
  }
}

.treatment-product-info {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    width: 100%;

    &.header {
      font-weight: 600;

      .cell {
        border-top: none;
      }
    }

    .cell {
      border-right: 1px solid var(--color-alto);
      border-top: 1px solid var(--color-alto);
      padding: 15px 10px;

      &:last-child {
        border-right: none;
      }
    }
  }

  @media screen and (max-width: 40em) {
    display: none;
  }
}

