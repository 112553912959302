@import 'styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
  position: relative;
	width: 249px;
	height: 250px;
	border-radius: 5px;
	border: $alto solid 1px;
	margin-right: 22px;
  cursor: pointer;

  &.is-selected {
    background-color: rgba(255, 94, 49, 0.05);
    border-color: $primary;

    img {
      width: 30px;
      height: 30px;
    }
  }

  &.is-disabled {
    background: #e8e8e8;
    border-color: #e8e8e8;
    cursor: not-allowed;
  }

  &.is-suggested {
    border-color: $primary;
  }

  &.is-large {
    height: 290px;

    .image img {
      width: 185px;
      height: 185px;
      margin-top: 15px;
    }
  }

  &.is-medium {

    .title {
      width: 100%;
      position: absolute;
      top: 145px;
    }
  }

	.check-icon {
		display: flex;
    position: absolute;
		justify-content: flex-end;
		padding: 18px 18px 0 0;
    right: 0;
	}

	.image {
		display: flex;
		justify-content: center;
		align-items: center;

		.multipleCard {

			div {
				display: flex;
				flex-direction: row;
				justify-content: center;
			}

			img {
				width: 50px;
				height: 50px;
				padding-right: 6px;
			}
		}

		img {
			width: 163px;
			height: 163px;
      margin-top: 5px;
		}
	}


	.icon {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 2px solid $dark-gray;
	}

	.title {
		display: flex;
    padding: 0 13px 0 13px;
		flex-direction: column;
		text-align: center;

		h3 {
			font-weight: normal;

			span {
				font-size: 18px;
				line-height: 22px;
			}
		}

		span {
			color: $black;
			font-size: 13px;
			line-height: 18px;
		}
	}
}
