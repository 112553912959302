@import '../../../styles/variables';

.comment-box {
  padding: 15px 0;
  font-size: 14px;

  .add-note-btn {
    padding-left: 10px;
    color: $primary;
    user-select: none;
    cursor: pointer;
  }

  textarea {
    resize: none;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border: 1px solid $alto;
    font-family: inherit;
    font-size: 14px;
    color: $black;
    border-radius: 5px;
    transition: box-shadow .5s, border-color .25s ease-in-out;
    box-shadow: 0 1px 2px rgba(48, 48, 48, .1);
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }
}

.display-text {
  width: 100%;
  padding: 8px;
  max-height: 123px;
  overflow-y: scroll;
  white-space: pre-wrap;
  cursor: pointer;
  border-radius: 5px;
  
  &:hover {
    background-color: $light-gray;
  }
}
