@import 'styles/variables';

.group-flow-diagram {
  overflow: auto;
  padding: 30px 25px;

  foreignObject {
    transform: translateX(-5px);

    div {
      width: 230px;
    }
  }

  #group-flow {
    margin: 0 auto;
  }

  .name-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 0 15px;
    font-weight: 600;
  }

  .farm-name-address {
    font-size: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .address {
      font-weight: normal;
    }

    .status {
      display: none;
      margin-right: auto;
      border-radius: 3px;
      color: white;
      border: 1px solid white;
      padding: 0 5px;
      font-size: 11px;
      text-transform: uppercase;

      &.opened,
      &.closed,
      &.draft,
      &.scheduled_delivery,
      &.request_close,
      &.pending_close,
      &.awaiting_delivery {
        display: block;
      }
    }
  }

  .plant-badge {
    display: none;

    &.plant {
      display: block;
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      border-radius: 50%;
      color: #96adbd;
      background-color: white;
      margin-right: 10px;
    }
  }

  .farm-type-badge {
    width: 28px;
    height: 28px;
    line-height: 28px;
    background-color: white;
    text-transform: uppercase;
    text-align: center;
    color: $black;
    border-radius: 3px;
    font-size: 14px;
    display: none;

    &.su,
    &.n,
    &.f,
    &.wf,
    &.ff {
      display: block;
    }

    &.su {
      color: #f74d8e;
    }

    &.n {
      color: $brand-blue;
    }

    &.f,
    &.wf,
    &.ff {
      color: #00aeff;
    }
  }
}
.amcharts-SankeyNode-group.node-card {

  &.hovered:hover {
    cursor: pointer;
  }
}
