@import 'styles/variables';

.CompleteRegistration {

  .phone-input-info .phone-input,
  .input-info {
    font-size: 16px;
    height: 55px;
    margin: 0;
    border: 2px solid $medium-gray;

    &:focus {
      border: 2px solid $primary;
    }
  }

  .phone-input-info {
    .country-select .dialling-code {
      font-size: 16px;
    }
  }

  h1.title {
    max-width: 100%;
  }

  .button.next {
    margin-top: 50px;
  }

  &.choose-lang {
    .choose-lang-box {
      font-size: 16px;
      font-weight: 600;
      color: $dark-blue;

      .choose-lang-label {
        padding: 5px 0;
      }

      .Select, .Select-control {
        font-size: 14px;
      }
    }
  }

  .back-icon {
    position: absolute;
    margin-top: 20px;
    cursor: pointer;

    @media (max-width: 39.975em) {
      top: -80px;
    }

    .fa {
      font-weight: 400;
      color: $primary;
      font-size: 20px;
    }
  }

  .label-username {
    position: relative;

    input {
      padding-left: 35px;
    }

    .input-username {
      position: absolute;
      bottom: 15px;
      left: 12px;
      font-size: 22px;
      color: $medium-gray;
    }
  }

  .description-username, .description-phone {
    font-size: 14px;
    color: $dark-gray;
  }

  .upload-label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 30px auto;

    .drop-area {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $background;
      width: 150px;
      height: 150px;
      margin: 0;
      border-radius: 50%;
      border: 2px dashed $medium-gray;
      cursor: pointer;

      &.border {
        border: none;
      }

      .file {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
      }

      .cta {
        font-size: 14px;
        color: $medium-gray;
        max-width: 120px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
        z-index: 2;
      }

      .upload-progress {
        background-color: $medium-gray;
        height: 5px;
        width: 100%;
        max-width: 110px;
        border-radius: 2px;
        position: relative;

        .progress-value {
          background-color: $primary;
          height: 5px;
          width: 0;
          transition: width .4s ease;
        }
      }

      .remove-photo {
        font-size: 25px;
        color: $white;
        z-index: 2;
        transition: color .3s ease;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);

        &:hover {
          color: $primary;
        }
      }

      .avatar {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
        background: center center no-repeat;
        background-size: cover;
        overflow: hidden;
      }

      .camera {
        width: 40px;
        height: 40px;
        position: absolute;
        bottom: 20px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        left: 50%;
        transform: translate(-50%, 0);

        &:hover {
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 1);
        }

        .fa-camera-fa {
          color: white;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 21px;
          transform: translate(-50%, -50%);
        }
      }
    }

    .rotate90 {
      transform: rotate(90deg);
    }

    .rotate180 {
      transform: rotate(180deg);
    }

    .rotate270 {
      transform: rotate(270deg);
    }
  }
}
