@import '../../../../styles/variables';

.select-type-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .select-type-cell {
    flex: 1;
  }

  .trash-icon {
    padding: 9px 11px;
    margin-left: 5px;
    border-radius: 5px;
    transition: background-color .2s ease;
    cursor: pointer;
    font-size: 22px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.index-cell {
  flex: 0 0 50px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.trash-cell {
  flex: 0 0 45px;
  align-self: flex-start;
}

.row-index {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #333;
  border-radius: 50%;
  font-size: 14px;
  color: #333;
}

.source-type-label,
.source-group-label {
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0 5px;
}

.add-source {
  color: $brand-blue;
  font-size: 14px;
  cursor: pointer;
}

.source-block {
  font-size: 14px;
  margin-left: 30px;
  margin-bottom: 15px;
}
