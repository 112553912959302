@import '../../../../../styles/variables';

.CreateFarmForm {

  .coords-select-form {
    display: flex;
    flex-direction: column;
  }

  .form-content-block {
    display: flex;
    padding-top: 10px;

    .Select-arrow-zone {
      background-color: #F7F7F7;
      border-left: 2px solid $light-gray;
    }
  }

  .farm-form-create {
    flex: 0 0 35%;
  }

  .map-block {
    flex: 1 1 100%;
    margin: .94rem .94rem .94rem 0;
    z-index: 1;
    max-height: 700px;
  }

  .checkbox-container {
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    margin-left: 0.94rem;
    margin-right: 0.94rem;
  }

  .checkbox-row {
    font-size: 14px;
    padding: 15px 0;
    background-color: $background;
    border-bottom: 1px solid $light-gray;

    &.selected {
      background-color: initial;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .button-submit {
    margin: .94rem;
  }

  @media screen and (max-width: 64em) {

    .form-content-block {
      display: block;
    }

    margin-left: 0.625rem;
    margin-right: 0.625rem;

    .map-block {
      height: 400px;
      margin: .625rem;
    }

    .button-submit {
      margin: .625rem;
    }
  }
}
