@import "styles/variables";

.trucking-line {
  font-weight: 600;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  text-transform: uppercase;
  padding: 5px 10px;
  background-color: $alice-blue;
  border: 1px solid #ddd;
  color: $brand-blue;
  font-size: 10px;

  .outgoing {
    transform: rotate(180deg);
  }

  &.blue-theme {
    background-color: $alice-blue;
    color: $brand-blue;
  }

  &.orange-theme {
    color: $primary;
    background-color: $light-orange;

    .load-arrow {
      transform: rotate(180deg);
      color: $primary;
    }
  }

  &.yellow-theme {
    color: $black;
    background-color: #fff3bb;

    .load-arrow {
      color: $black;
    }
  }

  .load-arrow {
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }
}
