@import '../../../styles/variables';

.diagnosis-field {

  &.first-suggested {
    margin-top: 0;

    .start {
      display: none;
    }
  }

  &:hover {
    .suggested-divider {
      z-index: 1;
      border-color: #00aeff;
    }
  }

  .suggested-divider {
    margin-top: 0;
    position: relative;
    border-color: #ddd;
    margin-left: -20px;
    margin-right: -20px;

    &.start {
      margin-top: -1px;
    }

    &.end {
      margin-bottom: 0;
    }
  }

  .magic-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  .buttons-row {
    display: flex;
    align-items: center;
    padding: 5px 0;
    justify-content: space-between;

    .action-btn {
      flex: 1;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .label {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .search-select {
    display: flex;
    align-items: center;
    justify-content: space-between;

    :global(.Select) {
      flex-grow: 1;
    }
  }

  .remove-icon-block {
    margin-left: 5px;
    font-size: 22px;
    border-radius: 5px;

    .remove-icon {
      padding: 9px 11px;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .diagnosis-type {
    margin: 10px 0;

    label {
      width: 50%;
    }

    .type-label {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .types-radio-group {
    margin-bottom: 5px;
    padding: 0;
  }

  .notify-users {
    margin: 15px 0;
  }

  .note-field {

    :global(.CommentBoxComponent)  {
      margin: 0;
    }
  }

  .divider {
    margin: 15px -20px;

    hr {
      display: block;
      margin: 0;
      padding: 0;
      border: 0;
      height: 1px;
      background: #ddd;
      width: 100%;
    }
  }
}

.add-diagnosis {
  color: $brand-blue;
  font-size: 14px;
  margin-bottom: 15px;

  & > span:hover {
    cursor: pointer;
  }
}
