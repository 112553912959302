
.csv-import-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  background: #1e263e;
  min-height: 80px;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 20px;

  .steps-wrapper {
    width: max-content;
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      height: 22px;
      width: 22px;
      cursor: pointer;
    }

    .vertical-line {
      display: flex;
      flex-direction: row;
      border: 0.5px solid white;
      min-height: 35px;
      margin: 0 12px;
    }
  }
}
