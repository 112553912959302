@import "../../../styles/variables";

.filter-panel {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .reset-btn {
    font-size: 12px;
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
  }
}

.filter-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0;

  @media screen and (max-width: 39.9375em) {
    overflow-y: auto;
  }
}

.mobile-title-box {

  .title {
    font-weight: bold;
    text-align: center;
    position: relative;
    padding: 15px 10px;
    border-bottom: 1px solid $light-gray;
  }

  .close-btn {
    position: absolute;
    cursor: pointer;
    right: 10px;
    padding: 3px 0;
  }

  .reset-btn {
    color: $primary;
    font-size: 12px;
    text-align: center;
    text-decoration: underline;
    padding: 15px;
    background-color: $white;
    border-bottom: 1px solid $light-gray;
  }
}

.filter-item {
  border-bottom: 1px solid $light-gray;
}

.apply-filter {
  z-index: 4;
}
