@import '../../../../styles/variables';

.MedicationHeader {
  background-color: $white;
  padding: 15px;
  border-bottom: 1px solid $light-gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  height: 90px;
  position: relative;

  .medication-info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
  }

  .bg-blue {
    background-color: $secondary;
  }

  @media screen and (min-width: 64em) {

    &.isSticky {
      position: sticky;
      position: -webkit-sticky;
      z-index: 2;
      top: 0;
    }
  }

  .medication-name {
    font-size: 22px;
    margin-right: 5px;
  }

  .medication-info {
    color: $almost-black;

    .type-string {
      color: $primary;
    }
  }

  .medication-information {
    margin-left: 10px;
  }

  .header-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 63.9375em) {
    flex-direction: column;
    height: 100%;

    .header-actions {
      margin: 10px auto 0;
    }

    .medication-information {
      margin: 5px 0 0;
      text-align: center;
    }

    .medication-info-wrapper {
      justify-content: center;

      .medication-name {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
