@import '../../../styles/variables';

.checkbox-input {
  display: none;

  &:checked + .round-slider {
    background-color: $primary;

    &::before {
      content: '\f012';
      color: $primary;
      transform: translateX(20px);
      box-shadow: 1px 4px 7px rgba(0, 0, 0, 0.3);
      font-weight: 600;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  margin: 0 15px 0 0;
  width: 45px;
  min-width: 45px;
  height: 25px;

  &.isDisabled .round-slider {
    cursor: not-allowed;
  }
}

.round-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $brand-gray;
  transition: .4s;
  border-radius: 25px;

  &::before {
    position: absolute;
    height: 23px;
    width: 23px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    border-radius: 50%;
    transition: .4s;
    box-shadow: -2px 4px 7px rgba(0, 0, 0, 0.3);
    font: normal normal normal 14px/1 'EveryPig';
    font-size: 9px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    vertical-align: middle;
    line-height: 23px;
    content: '\f01a';
    color: $brand-gray;
  }
}
