@import '../../../styles/variables';

.input-wrapper {
  position: relative;

  &.iconLeft {

    input {
      padding-left: 30px;
    }

    .input-wrapper-icon {
      right: auto;
      left: 10px;
      top: 10px;
      transform: translate(0, 0);
    }
  }

  .input-wrapper-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
}
