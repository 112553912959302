
.Templates {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ModalTemplates {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: #1e263e;
  }

  .Board {
    position: absolute;
    width: 580px;
    height: 440px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    background-color: #ffffff;

    .header {
      text-decoration: none solid rgb(48, 48, 48);
      min-height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      border: 1px solid #e8e8e8;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #f5f5f5;
      font-family: Open Sans;
      font-size: 18px;
      color: #303030;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .bodyTemplates {
      display: flex;
      padding: 0 50px;
      height: max-content;
      flex-wrap: wrap;

      > :nth-child(3) {
        margin: 30px 0 0 0;
      }

    }
  }

}







