@import '../../../../styles/variables';

.diagnoses-body {
  padding: 0 10px 10px;

  .diagnosis-panel {
    padding: 5px 10px 0;

    .time-ago {
      color: $dark-gray;
    }
  }

  .diagnosis-panel:not(:last-child) {
    margin-bottom: 10px;
  }

  .diagnosis-info-row,
  .diagnosis-note-row {
    border-top: 1px solid $light-gray;
    margin: 0 -10px;
    padding: 4px 10px;
  }

  .diagnosis-info-row {
    display: flex;
    justify-content: space-between;
  }

  .diagnosis-note-row {
    background-color: $light-yellow;
    border-radius: 0 0 5px 5px;
  }
}
