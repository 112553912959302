@import "../../../styles/variables";

.statistics-panel {
  border-radius: 5px;
  border: 1px solid $light-gray;
  min-width: 300px;
  background: $white;
  display: flex;
  flex-direction: column;
}

.statistics-panel-title {
  padding: 15px 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .title {
    display: flex;
    justify-content: space-between;
  }

  .current-data-block {
    display: flex;
    justify-content: flex-end;
  }

  .mobile-dropdown {
    font-size: 14px;
  }

  @media screen and (max-width: 39.9375em) {
    padding: 15px;
    flex-direction: column;

    .title {
      align-items: baseline;
    }

    .current-data-block {
      justify-content: flex-start;
    }
  }

  @media screen and (min-width: 40em) {
    .title-box {
      flex: 1 0 210px;
    }

    .current-data-block {
      flex: 1 0 250px;
      margin-bottom: 15px;
    }

    .mobile-dropdown {
      display: none;
    }
  }

  .option {
    border-bottom: 1px solid $light-gray;
    padding: 11px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.active-option {
      background: $background;
    }

    .option-label {
      flex: 1 auto;
      padding-left: 10px;
    }

    &:last-child {
      border: none;
    }

    .selected {
      color: $brand-blue;
      padding: 3px 0;
    }
  }
}

.statistics-panel-body {
  flex: 1;
}

.statistics-filter-line {
  font-size: 14px;
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 10px 20px;
  border-top: 1px solid $light-gray;
  background-color: $background;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &.mobile {
    background-color: white;
    margin-top: 10px;
    padding: 10px 0;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .date-range {
      margin: 10px 0;
      z-index: 2;
    }
  }

  .date-range {
    padding-left: 15px;
    z-index: 2;
  }
}

.trigger {
  background-color: $background;
  color: $primary;
  font-weight: normal;
  padding: 5px 20px 5px 10px;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px;
  position: relative;
  cursor: pointer;
}

.dots {
  position: absolute;
  right: 5px;
  top: 3px;
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 2px;
  margin: 1px;
  background-color: rgb(221, 221, 221);

  &.active {
    background-color: $primary;
  }
}
