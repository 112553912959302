@import "../../../styles/variables";

.reported-symptoms {
  font-size: 14px;

  .symptom-row {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border-top: 1px solid $light-gray;

    &.highlighted {
      background-color: $light-yellow;
    }

    .name {
      display: inline-block;
      font-weight: 400;
    }

    .value {
      display: inline-block;
      font-weight: 600;
    }
  }

  .symptoms-audios {
    border-top: 1px solid $light-gray;

    .asset-wrapper {
      border-bottom: 1px solid $light-gray;

      &:last-child {
        border-bottom: none;
      }
    }

    .assets-item {
      padding: 5px 10px;
    }
  }
}
