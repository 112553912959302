.DataTablePagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 10px;

  .pagination-stats {
    font-size: 14px;

    .small {
      font-size: 13px;
      font-weight: 400;
    }
  }
}
