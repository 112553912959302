@import '../../../styles/variables';

.DiagnosisSidebar {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;

  .sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: $main-header-height + 1px;
    border-bottom: 1px solid #ddd;

    .cancel-button {
      font-size: 12px;
      cursor: pointer;
    }
  }

  .sub-navigation {
    box-shadow: none;
    position: relative;

    &::before {
      content: '';
      background: #ddd;
      position: absolute;
      height: 4px;
      top: 46px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    .scrollable-content {
      width: 100%;

      .flex {
        width: 100%;
        align-items: stretch;

        .item {
          font-size: 14px;
          flex: 1;
          align-items: center;
          margin: 0;

          &.active {
            border-width: 4px;
          }
        }
      }
    }
  }

  .sidebar-tabs {
    display: flex;
    height: 100%;
    overflow-x: hidden;

    .diagnose-tab,
    .group-info-content {
      width: 350px;
      min-width: 350px;
      transition: transform .3s ease-in-out;
    }

    .diagnose-tab {
      transform: translateX(-350px);
      visibility: hidden;

      &.active {
        transform: translateX(0px);
        visibility: visible;
      }
    }

    .group-info-content {
      transform: translateX(0px);
      visibility: hidden;

      &.active {
        transform: translateX(-350px);
        visibility: visible;
      }
    }
  }

  .group-info-content {
    overflow-y: auto;
    background-color: $white;

    .diagnose-collapse {
      font-size: 14px;
    }

    .group-info-collapse {
      font-size: 14px;
    }

    .group-info-tab {
      border-radius: 0;
      border-top: none;
    }
  }
}
