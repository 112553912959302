@import '../../../styles/variables';


.SourceGroups {

  @media screen and (min-width: 64em) {
    .FlexTableWrapper.scrollable .FlexTable {
      min-width: 1200px;
    }
  }

  .fa-dots-three-horizontal {
    color: $brand-gray;
    font-size: 25px;
    cursor: pointer;
  }

  .average-condition-column {
    .value-amount {
      display: flex;
      align-items: center;
      .arrival-condition-content {
        display: flex;
        align-items: center;
      }
    }
  }

  .mortality-to-date-column {
    display: flex;
  }

  .Subnavigation {
    .flex {
      padding: 0 8px;
    }
  }

  .condition-label {
    font-weight: 600;
    margin-left: 10px;
    text-transform: capitalize;
    font-size: 12px;

    &.null-state {
      color: $dark-gray;
    }

    &.poor {
      color: $brand-red;
    }

    &.average {
      color: $brand-orange;
    }

    &.good {
      color: $brand-blue;
    }
  }

  .group-id-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-download-btn {
    display: flex;
    justify-content: center;
  }

  .opened {
    background-color: $brand-blue;
  }

  .request_close {
    background-color: $brand-red;
  }

  .awaiting_delivery,
  .scheduled_delivery {
    background-color: $brand-orange;
  }

  .draft {
    background-color: $almost-white;
    color: $dark-gray;
  }

  .primary-link {
    color: $primary;
    font-size: 14px;
    font-weight: 400;
    padding: 0;

    &:hover, &:active {
      color: $primary;
    }
  }

  @media screen and (max-width: 63.9375em) {
    margin-top: 0;
  }
}
