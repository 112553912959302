.BarnSheetsContainer {
  display: flex;
  flex-direction: column;

  .Subnavigation-wrapper {
    z-index: 2;

    @media screen and (min-width: 64em) {
      z-index: 4;
    }
  }

  .bs-sub-nav {
    padding: 0 10px;
  }

  &.barn-sheets {
    .bs-filters {
      padding: 0 15px;
      z-index: 1;
      position: relative;
      margin-top: 10px;
    }
  }

  .bs-table-panel {
    position: relative;
    z-index: 0;
    margin: 15px 0 20px 0;
  }

  .companies-panel {
    margin-top: 15px;
    margin-bottom: 20px;

    @media screen and (min-width: 64em) {
      margin-top: 20px;
    }
  }

  .panel-padding {
    padding: 0 10px;
  }

  @media screen and (min-width: 40em) {
    .bs-sub-nav,
    .panel-padding {
      padding: 0 15px;
    }
  }

  .primary-link {
    color: var(--color-primary);
  }

  .fa-dots-three-horizontal {
    color: var(--color-gray-brand);
    font-size: 25px;
    cursor: pointer;
  }

  &.is-desktop {
    .bs-table-panel {
      margin-top: 5px;
    }
  }
}
