.question-status {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;

    &.fail {
        color: var(--color-red-brand);
    }

    &.pass {
        color: var(--color-blue-brand);
    }

    &.not-available {
        color: var(--color-gray-dark);
    }

    .icon {
      font-size: 12px;
      margin-right: 5px;
    }
  }