@import "../../../styles/variables";

.collapses-box {
  border: 1px solid $light-gray;
  border-radius: 5px;

  .collapses-box-item {
    border-top: 1px solid $light-gray;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-top: none;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
