@import "styles/variables";

.header {
  align-items: center;
  display: flex;
}

.read-more {
  color: var(--color-primary);
  cursor: pointer;
}

.info-block {
  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .value {
      font-weight: 600;
      font-size: 20px;

      @media screen and (max-width: $mobile-width-large) {
        font-size: 16px;
      }
    }

    .label {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.load-details {
  font-size: 14px;
}

.travel-info {
  display: flex;
  align-items: center;

  .icon {
    font-size: 18px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-alto);
}

.add-to-calendar {
  font-size: 14px;
  text-align: center;

  &.is-mobile {
    text-transform: uppercase;
    text-decoration: underline;
    color: var(--color-primary);
    font-size: 13px;
  }
}

.btn-group {
  z-index: 1;
  justify-content: center;
  margin: 20px 20px 0;
  display: flex;
  position: sticky;
  bottom: 20px;
  border: none;

  .button {
    height: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-size: 16px;
    flex: 1;

    @media screen and (min-width: $mobile-width-large) {
      max-width: 165px;
    }

    &.decline {
      box-shadow: 0 1px 9px rgba(220, 53, 34, 1);
    }

    &.accept {
      box-shadow: 0 1px 9px rgba(0, 105, 161, 1);
    }
  }
}
