@import 'styles/variables';

.step-dots {
  padding: 10px 0;
  text-align: center;

  .step-dots-item {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: $medium-gray;

    &.active {
      background-color: $dark-blue;
    }
  }
}
