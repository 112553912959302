@import "styles/variables";

.DailyCheckupReportProtocolTreatment {

  .mobile-treatment {
    margin-bottom: 10px;
    position: relative;
    pointer-events: none;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(white, 0.5);
    }

    .treatment-name {
      border-radius: 2px;
      padding: 7px 30px 7px 7px;
      border: 2px solid $light-gray;
      color: $black;
      font-size: 14px;
    }

    .change-treatment-icon {
      position: absolute;
      right: 0;
      top: 0;
      padding: 11px;
    }
  }

  .oral-attention {
    display: block;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .select-values-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .treatment-details {
      width: 100%;

      label {
        border: none;
      }

      .treatment-details-row {
        display: flex;
        justify-content: flex-end;

        .icon {
          flex: 0 0 50px;
          visibility: hidden;

          &.visible {
            padding: 6px 10px 6px 0;
            align-self: flex-end;
            text-align: center;
            line-height: 40px;
            color: $orange;
            visibility: visible;
          }
        }

        @media all and (max-width: 63.9375em) {

          .treatment-number-input {
            width: 100%;
          }
        }
      }
    }
  }

  .treatment-efficacy-survey {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 0;
    padding: 6px 0;

    @media screen and (min-width: 64em) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .tes-label {
      font-weight: 600;
      font-size: 14px;
    }

    .tes-options {
      width: 100%;

      @media screen and (min-width: 64em) {
        margin-left: 10px;
        width: 234px;
      }

      label input[value=false]:checked + span {
        background: $dark-gray;
        border-color: $dark-gray;
      }
    }
  }
}
