
.bodyRedirect {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 42px 0 15px;

  .wrapperInfoText {

  }

  .wrapperButtons {
    display: flex;
    justify-content: flex-end;
    width: calc(100% + 30px);

    button{
      width: 90px;
      height: 40px;
      border: 1px none #dddddd;
      border-radius: 3px;
      background-color: #d8d8d8;
      font-family: Open Sans;
      font-size: 14px;
      color: #303030;
      text-decoration: none solid rgb(48, 48, 48);
      text-align: center;
      margin-right: 15px;
    }
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 115px;
      height: 39px;
      border: 1px solid #ff5e31;
      border-radius: 3px;
      background-image: linear-gradient(0deg, #ff5e3a 3%, #f78445 100%);
      background-position: center center;
      font-family: Open Sans;
      font-size: 14px;
      color: #ffffff;
      -webkit-text-decoration: none solid white;

    }
  }

}
