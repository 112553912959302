.BarnSheetsFarmGroups {

  @media screen and (min-width: 64em) {
    .FlexTableWrapper.scrollable .FlexTable {
      min-width: 1350px;
    }
  }

  .fa-dots-three-horizontal {
    color: var(--color-gray-brand);
    font-size: 25px;
    cursor: pointer;
  }

  .group-id-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
