@import '../../../styles/variables';

.scroll-hint {
  position: absolute;
  z-index: 2;
  font-size: 14px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  background-color: rgb(30, 38, 62);
  color: $white;
  font-weight: bold;
  padding: 12px 10px;
  text-align: center;
  min-width: 160px;
  border-radius: 25px;
  border: 3px solid;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 12px;
  opacity: 0;
  visibility: hidden;
  transition: visibility .7s, opacity .7s, top .7s;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}
