@import '../../../../styles/variables';

.diagnosis-block {
  margin: 20px 0;

  &:first-child {
    margin-top: 0;
  }
}

.UserPanel.user-details {
  font-size: 14px;

  .user-modify {
    margin: 5px 5px;
    padding-bottom: 7px;
    border-bottom: 1px solid $light-gray;
    font-size: 12px;

    a {
      color: $primary;
    }
  }

  .user-bar {
    margin-top: 10px;
  }

  .diagnose-name {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border-top: 1px solid $light-gray;

    .name {
      font-weight: 600;
    }
  }

  .diagnose-note {
    padding: 5px 10px;
    background-color: #fffcec;
    border-top: 1px solid $light-gray;

    .note {
      font-weight: 600;
    }

    .italic {
      font-style: italic;
    }
  }

  .add-diagnose {
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    background-color: #fff4f0;

    .diagnosis {
      flex: 1;
    }

    .diagnose-plus {
      margin-right: 10px;
      color: #e95c5c;
      font-size: 18px;
    }
  }

}
