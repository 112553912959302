@import "../../../styles/variables";

.MediaGallery {
  background-color: $almost-white;
  min-height: 100%;
  height: 100%;

  .gallery-layout {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 100%;

    .filters-panel {
      flex: 0 0 240px;
      border-right: 1px solid $light-gray;
      background-color: $background;
      min-height: 100%;
      z-index: 3;
    }

    .gallery-filters {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }

    .gallery-images {
      flex: 1;
      padding: 10px 20px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      min-width: 700px;
      max-width: 1400px;

      @media screen and (max-width: 63.9375em) {
        padding: 10px;
        min-width: 0;
      }
    }

    .gallery-placeholder {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: $light-black;
      font-weight: 600;

      i {
        font-size: 50px;
        color: $medium-gray;
        margin-bottom: 20px;
      }
    }
  }

  .mobile-search {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid $light-gray;
  }

  .waypoint {
    display: block;
    width: 100%;
    position: relative;
    height: 100px;
  }

  .preloader {
    margin-top: 10px;
  }
}
