.SetPasswordMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .checked-icon {
    background-color: #e3eff5;
    color: var(--color-blue-brand);
    font-size: 28px;
    padding: 30px;
    border-radius: 50%;
    margin-top: 25px;
  }

  .success-message {
    margin-top: 25px;
    text-align: center;
  }

  .login-link {
    margin-top: 35px;
  }
}
