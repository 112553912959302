@import '../../../../styles/variables';

.resources-sub-nav {
  height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 0 20px;
  border-bottom: 1px solid #ddd;

  .files-search {
    min-height: 35px;
  }

  .actions-section {
    display: flex;
    align-items: center;

    .view-type-switcher {
      border: 1px solid #ddd;
      border-radius: 3px;
    }
  }

  .view-type-icon {
    font-size: 18px;
    padding: 8px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: #d1d1d1;
    }
  }

  .add-btn {
    padding: 8px 20px;
    margin-left: 15px;
  }

  .plus-icon {
    margin-right: 5px;
    font-size: 16px;
  }

  .add-btn-label {
    font-size: 14px;
    font-weight: 600;
  }

  @media screen and (max-width: $mobile-width) {
    padding: 0 10px;
    min-height: 55px;
    height: 55px;

    .actions-section {
      display: none;
    }
  }
}
