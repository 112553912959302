.preloader {
  $preloaderDiameter: 50px;

  position: absolute;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  margin: 0 auto;
  border: 4px solid rgba(255, 94, 49, 0.2);
  border-left: 4px solid var(--color-primary);
  border-radius: 50%;
  width: $preloaderDiameter;
  height: $preloaderDiameter;
  opacity: 0;
  transition: opacity .4s linear;
  will-change: transform, opacity;
  pointer-events: none;

  &.is-active {
    opacity: 1;
    animation: preloader 1.1s infinite linear;
  }

  &.is-centered {
    top: calc(50% - #{$preloaderDiameter}/2);
  }

  &.is-small {
    $smallDiameter: 30px;
    width: $smallDiameter;
    height: $smallDiameter;

    &.is-centered {
      top: calc(50% - #{$smallDiameter}/2);
    }
  }
}

@keyframes preloader {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}
