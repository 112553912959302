
.cardLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 429px;
  height: 410px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #ffffff;

  img {
    width: 185px;
    height: 185px;
    margin-top: 15px;
  }


  .infoCard {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 150px;
    padding: 0 50px;
    justify-content: space-around;


    span {
      font-family: Open Sans;
      font-size: 14px;
      color: #303030;
      text-decoration: none solid rgb(48, 48, 48);
      line-height: 24px;
      text-align: center;
    }

    h2 {
      span {
        font-weight: 600;
        font-size: 16px;

      }
    }

    .links {
      span {
        color: #ff5e31;
        text-decoration: none solid rgb(255, 94, 49);
      }

      img {
        width: 21px;
        height: 21px;
        margin: 0 0 0 5px;
      }
    }
  }
}

