@import "../../../styles/variables";

$panel-body-xs-padding: 10px;
$panel-body-sm-padding: 20px;

.DailyCheckupHome {

  .farms-panel-title {
    min-height: 60px;

    h1 {
      font-size: 22px;
    }
  }

  .farms-panel-title,
  .farms-panel-body {
    padding: 15px $panel-body-sm-padding;
  }

  @media screen and (max-width: 40em) {
    .farms-panel-title {
      padding: $panel-body-xs-padding;

      h1 {
        font-size: 18px;
      }
    }

    .farms-panel-body {
      padding: 15px $panel-body-xs-padding;
    }
  }

  .role-group-header {
    color: $dark-gray;
    font-size: 14px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative;
    line-height: 1;

    &:after {
      content: '';
      border-bottom: 1px solid $light-gray;
      position: absolute;
      left: -$panel-body-sm-padding;
      right: -$panel-body-sm-padding;
      bottom: 0;

      @media screen and (max-width: 40rem) {
        left: -$panel-body-xs-padding;
        right: -$panel-body-xs-padding;
      }
    }
  }
}
