@import '../../../styles/variables';

.Avatar {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  color: $white;

  &.farm-icon,
  &.group-icon,
  &.packing-company-icon,
  &.packing-plant-icon {
    background-color: $brand-blue;
  }

  &.company-icon,
  &.everypig-icon {
    background-color: $primary;
  }

  &.trucking-company-icon {
    background-color: $purple;
  }

  &.shareholder-group-icon,
  &.source-icon {
    background-color: rgb(0, 174, 255);
  }

  &.injectable-icon,
  &.topical-icon,
  &.water-soluble-icon {
    color: $black;
  }

  &.treatment-protocol-icon {
    background-color: $regent-gray;
  }
}
