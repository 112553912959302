@import '../../../../styles/variables';

.BarnsheetsWaterUsage {
  

  .download-button {
    margin-left: 20px;
  }

  .filters-wrap {
    display: flex;
    justify-content: flex-end;
    
    .select-period {
      z-index: 3;
      width: 200px;
      margin-left: 20px;
    }

    .date-picker {
      z-index: 3;
      width: 270px;
    }

    @media screen and (max-width: 63.9375em) {
      flex-direction: column;
      justify-content: center;

      .select-period,
      .date-picker,
      .download-button {
        width: 100%;
        margin: 10px 0;
      }
    }
  }

}
