.TimeToReconcileModal {
  text-align: center;

  .modal-body {
    h1 {
      font-size: 32px;
      margin: 30px auto;
    }

    img {
      max-width: 350px;
    }

    p {
      font-size: 22px;
      margin: 20px;
    }
  }

  .modal-footer {
    display: flex;
    padding: 0;

    .button {
      flex: 1 1 100%;
      padding: 20px;
      font-size: 18px;
      margin: 0;
      border: none;
    }
  }
}
