@import '../../../styles/variables';

.reported-segment {
  font-size: 14px;

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 5px 15px;
    border-top: 1px solid $light-gray;

    &:last-child {
      margin-bottom: -1px;
    }
  }
}
