@import '../../../styles/variables';

.source-type {
  margin-left: 5px;
  padding: 1px 4px;
  background-color: $brand-gray;
  color: $white;
  text-transform: capitalize;
  font-size: 10px;
  border-radius: 3px;
}
