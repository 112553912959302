@import '../../../styles/variables';

.line {
  padding: 4px 10px;
  border-bottom: 1px solid $light-gray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: pre-wrap;

  &.isHeaderLine {
    padding: 8px 10px;
  }

  &.isEmptyLine {
    color: $medium-gray;
    background-color: $white;
  }

  &.hasTopBorder {
    border-top: 1px solid $light-gray;
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 5px 5px;
  }

  &.alignLeft {
    justify-content: flex-start;
  }

  &.bolded {
    font-weight: 600;
  }

  &.lightYellow {
    background-color: $light-yellow;
  }

  &.almostWhite {
    background-color: $background;
  }

  &.lightBlue {
    background-color: $light-blue;
  }

  &.lightOrange {
    background-color: $light-orange;
  }

  .migration-time {
    font-weight: normal;
  }
}
