@import '../../../styles/variables';

.modal {
  position: relative;
  max-width: 100%;
  background-color: $white;
  border-radius: 5px;
  width: 580px;
  margin: 0 auto;
  pointer-events: all;

  section {
    padding: 15px 20px;
    margin: 0;

    &.modal-header {
      background-color: #fafafa;
      border-bottom: 1px solid #e6e6e6;
      border-radius: 5px 5px 0 0;
      overflow: hidden;

      .title {
        line-height: 40px;
        min-height: 40px;
        font-size: 20px;

        img {
          max-width: 40px;
          max-height: 40px;
          margin-right: 10px;
        }
      }
    }

    &.modal-footer {
      background-color: #fafafa;
      border-top: 1px solid #e6e6e6;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
    }
  }

  .close-button {
    position: absolute;
    top: 13px;
    right: 10px;
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color .2s;
    cursor: pointer;
    color: $black;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &.drBot {
    .modal-header {
      color: $white;
      background-image: linear-gradient(0deg, rgb(255, 94, 58) 3%, rgb(247, 132, 69) 103%);
    }

    .close-button {
      color: $white;
    }
  }

  @media screen and (max-width: 40em) {
    max-height: 100%;
    max-width: 100%;
    overflow: auto;

    .sticky-on-mobile {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
    }
  }
}
