.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 60px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  .angelImg {
    width: 12px;
    height: 12px;
  }

  .backBtn,
  .nextBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 92px;
    min-height: 35px;
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    font-size: 14px;
    text-decoration: none solid rgb(255, 94, 49);
    text-align: center;
  }

  .backBtn {
    margin-left: 19px;
    background-color: #ffffff;
    color: var(--color-primary);

    span {
      padding-left: 10px;
    }
  }

  .nextBtn {
    margin-right: 16px;
    background-image: linear-gradient(0deg, #ff5e3a 3%, #f78445 100%);
    color: #ffffff;

    span {
      padding-right: 10px;
    }
  }

  .disableBtn {
    opacity: .5;
  }
}
