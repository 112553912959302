@import "styles/variables";

.load-more-option {
  position: relative;
  padding: 10px 15px;
  font-size: 14px;
  color: $primary;
  text-align: center;

  &.is-loading {
    padding: 0;
    min-height: 44px;
  }

  .preloader {
    top: 7px;
    width: 30px;
    height: 30px;
  }

  .load-more-label {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
