.cardCSV {
  display: flex;
  flex-direction: row;

  .checkCard {
    background-color: rgba(255, 94, 49, 0.05);

    img {
      width: 30px;
      height: 30px;
    }
  }

  .wrapperCard {
    display: flex;
    flex-direction: column;
    width: 249px;
    height: 290px;
    border-radius: 5px;
    border: #ff5e31 solid 1px;
    margin-right: 22px;

    .wrapperCheck {
      display: flex;
      justify-content: flex-end;
      padding: 18px 18px 0 0;
    }

    .wrapperImage {
      display: flex;
      justify-content: center;
      align-items: center;

      .wrapperMulti {

        div {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }

        img {
          width: 50px;
          height: 50px;
          padding-right: 6px;
        }
      }

      img {
        width: 91px;
        height: 91px;
      }
    }


    .icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid #a3a3a3;
    }

    .wrapperTitleCard {
      display: flex;
      padding: 37px 13px 0 13px;
      flex-direction: column;
      text-align: center;

      h3 {
        font-weight: normal;

        span {
          font-size: 18px;
          line-height: 22px;
        }
      }

      span {
        color: #303030;
        font-size: 13px;
        line-height: 18px;
        font-family: Open Sans;
      }
    }
  }
}
