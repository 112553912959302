@import '../../../styles/variables';

.FarmFeedRowAudioAssets {

  .asset-wrapper {
    border-bottom: 1px solid $light-gray;

    .assets-item {
      padding: 5px 10px;
      background-color: #FFFCEB;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
