@import 'styles/variables';

.tenants-list-box {
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: $main-header-height;
  bottom: 0;
}

.tenant-search {
  padding: 10px;
  background-color: white;
  position: relative;
  right: 0;
  left: 0;
  border-bottom: 1px solid var(--color-gray-light);

  @supports ((position:sticky) or (position:-webkit-sticky)) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .tenant-search-box {
    margin: 0;
    width: 100%;
    max-width: 100%;
    background-color: rgb(250, 250, 250);
  }
}

.tenants-list {
  overflow: hidden;
  background-color: white;
}

.tenant-row {
  display: flex;
  align-items: center;
  padding: 12px 10px;
  border-bottom: 1px solid var(--color-gray-light);
  cursor: pointer;

  &:hover {
    background-color: rgb(250, 250, 250);
  }
}

.tenant-name {
  flex: 1;
  padding: 0 10px;
  font-size: 14px;
}

.check-icon {
  color: var(--color-primary);
  padding: 0 10px;
  font-weight: 600;
}
