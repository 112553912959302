@import '../../../styles/variables';

.MobileDiseasesSelector {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .mobile-select-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: $white;
    transition: transform .3s ease-in;
    transform: translateX(0);

    &.isHidden {
      transform: translateX(-100%);
    }
  }

  .disease-selector-header {
    z-index: 4;
    flex: 0 0 $main-header-height + 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $dark-blue;
    border-bottom: 1px solid rgba(150, 150, 150, 0.3);

    @supports ((position:sticky) or (position:-webkit-sticky)) {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }

    .back-link {
      font-weight: 400;
      color: $primary;
      font-size: 20px;
      margin-left: 10px;
    }

    .title {
      font-weight: 700;
      font-size: 18px;
      white-space: nowrap;
      max-width: calc(100% - 150px);
      overflow: hidden;
      text-overflow: ellipsis;
      color: $white;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }

    .mobile-question {
      position: absolute;
      right: 0;
      top: 5px;

      .intercom-icon {
        padding: 10px;
        font-size: 25px;
        font-weight: normal;
        color: $white;
      }
    }
  }

  .select-diseases-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .select-diseases-title {
      color: $primary;
      font-size: 12px;
    }
  }

  .sub-navigation {
    box-shadow: none;
    position: relative;

    &::before {
      content: '';
      background: #ddd;
      position: absolute;
      height: 4px;
      top: 46px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    .scrollable-content {
      width: 100%;

      .flex {
        width: 100%;

        .item {
          font-size: 14px;
          flex: 1;
          align-items: center;
          border-width: 4px;
          margin-right: 0;
        }
      }
    }
  }

  .diagnose-tab {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .diseases-list {
      padding: 20px 10px;
      flex-grow: 1;
    }

    .add-disease {
      font-size: 14px;
      color: $brand-blue;
      background-color: $light-blue;
      border-radius: 3px;
      text-align: center;
      padding: 10px;
    }

    .btn-block {
      transform: translateZ(0);
    }
  }

  .group-info-content {
    overflow-y: auto;

    .group-info-tab {
      border-radius: 0;
      font-size: 14px;
      border: none;
      border-bottom: 1px solid $light-gray;
    }
  }
}
