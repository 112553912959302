@import "../../../../styles/variables";

.tag-edit-tooltip {
  padding: 5px 10px;

  .diagnosis-line {
    display: flex;
    padding: 10px 0;
    align-items: center;
  }

  .name {
    margin-right: 5px;
    flex: 1;
  }

  .del-tag-icon:hover {
    cursor: pointer;
  }
}

