@import "../../../styles/variables";

.stat-dropdown-box {

  .stat-dropdown {
    transform: translateX(100%);
    width: 300px;
    z-index: 2;

    &.mobile-mode {
      transform: translateX(0);
      top: 100%;
      margin: 5px 0 0;
    }
  }

  .responsive-dropdown {

    .responsive {
      transform: translateX(0);
      top: 100%;
      margin-right: 20px;
      z-index: 9;
      width: calc(100vw - 40px);

      &.mobile-mode {
        width: calc(100vw - 60px);
        margin-right: 30px;
        left: auto;

        &:before {
          left: auto;
          right: 20px;
        }
      }

      &:before {
        content: '';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
        position: absolute;
        bottom: 100%;
      }

      &:global(.above):before {
        content: '';
        border-top: 10px solid $white;
        border-bottom: none;
        position: absolute;
        top: 100%;
      }

      &:global(.center):before {
        display: none;
      }
    }
  }

  .stat-dropdown {
    &:global(.above) {
      margin-bottom: 10px;
      bottom: 100%;
      top: inherit;
    }
  }

  .stat-dropdown-menu {
    z-index: 1000;
    padding: 0;

    .menu-item {
      border-bottom: 1px solid $light-gray;
      padding: 11px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &.active-option {
        background: $background;
      }

      .option-label {
        flex: 1 auto;
      }

      &:last-child {
        border: none;
      }

      .selected {
        color: $brand-blue;
        padding: 3px 0;
      }

      .view-more-icon {
        font-weight: 600;
        font-size: 11px;
        padding: 9px 7px 5px;
      }
    }
  }
}

.dropdown-trigger {
  display: flex;
  align-items: center;
  cursor: pointer;

  i {
    padding: 9px 7px 5px;
    border-radius: 50%;
    margin-left: 3px;
    transition: background-color .2s ease;
    font-weight: 600;
    font-size: 11px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
