@import '../../../../styles/variables';

.SourceProfileHeader {
  background-color: $white;
  padding: 15px;
  border-bottom: 1px solid $light-gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  height: 90px;
  position: relative;

  .dropdown-button {
    padding: 10px;
    margin-bottom: 0;
    margin-left: 10px;

    i {
      font-size: 18px;
      color: $dark-gray;
    }
  }

  .source-info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
  }

  .commingled-label {
    background-color: $almost-white;
    border: 1px solid $light-gray;
    color: $dark-gray;
    margin-right: 5px;
  }

  @media screen and (min-width: 64em) {

    &.isSticky {
      position: sticky;
      position: -webkit-sticky;
      z-index: 2;
      top: 0;
    }
  }

  .source-name {
    font-size: 22px;
    margin-right: 5px;
  }

  .source-info {
    color: $almost-black;
  }

  .source-information {
    margin-left: 10px;
  }

  .header-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .header-dropdown {
    position: relative;
    z-index: 4;
  }

  @media screen and (max-width: 63.9375em) {
    flex-direction: column;
    height: 100%;

    .header-actions {
      margin: 10px auto 0;
    }

    .source-information {
      margin: 5px 0 0;
      text-align: center;
    }

    .source-info-wrapper {
      justify-content: center;

      .source-name {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .header-dropdown {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}
