.TreatmentsEfficacyModal {

  .body-title {
    margin: 10px 0;
    text-align: center;
    font-weight: normal;
  }

  .custom-radio {
    border-color: var(--color-gray-light);
    margin: 50px 0 40px;

    label {
      height: 70px;
      border-color: var(--color-gray-light);
      flex-basis: 20%;

      > span {
        line-height: 70px;
        background-image: linear-gradient(0deg, hsla(0, 5%, 93%, 0.68) 3%, hsla(0, 0%, 100%, 0.68) 103%);
        cursor: pointer;
        color: inherit;
        font-weight: 600;

        &:hover,
        &:focus {
          box-shadow: inset 2px 2px 5px 0 rgba(165, 165, 165, .625), inset -2px -2px 5px 0 rgba(165, 165, 165, .625);
        }

        img {
          margin-right: 10px;
          width: 25px;
        }
      }

      input:checked + span {
        background: var(--color-blue-dark);

        &:hover, &:focus {
          box-shadow: none;
        }
      }
    }

    @media all and (max-width: 39.9375rem) {
      align-items: stretch;
      flex-direction: column;

      label {
        border-left: none;
        border-top: 1px solid var(--color-gray-light);
        flex-basis: auto;

        > span {
          text-align: left;
          padding-left: 50px;
        }

        &:first-child {
          border-top: none;
        }
      }
    }

    @media all and (max-height: 30rem) {
      margin: 10px 0 0;
    }
  }

  .dotnav li {
    width: 8px;
    height: 8px;
    margin: 0 3px;
  }

  .modal-footer {
    display: flex;
    padding: 0;

    .button {
      flex: 1 1 50%;
      padding: 20px;
      font-size: 18px;
      margin: 0;
      border: none;
      border-radius: 0;

      .fa {
        font-size: 14px;

        &.fa-arrow-left {
          margin-right: 15px;
        }

        &.fa-arrow-right {
          margin-left: 15px;
        }
      }
    }
  }

  @media all and (max-width: 63.9375em) {

    .modal-header.flex {
      justify-content: center;
    }
  }

}
