
.progress-block {
  height: 30px;
  width: 32%;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: none;
  position: relative;
  margin: 15px 0 0 5px;
  transition: background-color 3s ease-in-out;
  overflow: hidden;

  &.completed {
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 9px;

    .progress-bar {
      background-color: rgb(250, 250, 250);
    }
  }
}

.item {
  padding: 5px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
}

.remove-icon {
  cursor: pointer;
  padding: 5px;
}

.progress-bar {
  padding: 0;
  margin: 0;
  display: block;
  background-color: #FFE8DF;
  height: 100%;
  position: absolute;
  z-index: -1;
  transition: width .6s ease-in;
}
