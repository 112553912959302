@import '../../../../styles/variables';

.NotificationBadge {
  .dropdown-header {
    position: relative;

    .count-value {
      position: absolute;
      top:2px;
      left: 15px;
      font-size: 10px;
      padding: 0 3px 0 3px;
      background-color: $white;
      color: $primary;
      border-radius: 3px;
    }
  }

  .dropdown.active {
    top: 35px;
    z-index: 2;
  }

  .dropdown-body {
    width: 420px;
    font-size: 14px;

    .header-block {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $medium-gray;

      .items-count {
        color: $white;
        background-color: $primary;
        padding: 1px 5px;
        border-radius: 3px;
        margin-left: 5px;
        font-size: 10px;
        font-weight: 600;
      }

      .action-buttons {
        color: $primary;

        span {
          cursor: pointer;
        }

        a, &:hover, &:active {
          color: $primary;
          padding: 0;
          display: inline-block;
          text-decoration: none;
          font-weight: 400;
        }
      }
    }

    .content-block {
      max-height: 600px;
      overflow-y: auto;

      .NotificationItemBody:not(:last-child) {
        border-bottom: 1px solid $medium-gray;
      }

      .NotificationItemBody {
        margin-bottom: 0;
      }
    }

    @media screen and (max-height: $tablet-width) {

      .content-block {
        max-height: 450px;
      }
    }

    .footer-block {
      padding: 5px;
      display: flex;
      justify-content: center;
      border-top: 1px solid $medium-gray;
      background-color: $background;
      border-radius: 0 0 5px 5px;

      .primary-link {
        font-weight: 400;
      }
    }
  }

  .primary-link {
    color: $primary;

    &:hover, &:active {
      color: $primary;
    }
  }
}
