@import '../../../../styles/variables';

.StepperCSV {
  display: flex;
  color: black;
  justify-content: center;
  width: max-content;

  .stepCSV {
    color: #a3a3a3;
    margin-right: 222px;

    .checkStep {
      border: 2px solid #ff5e31 !important;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin: 0 7px;
      border-radius: 50%;
      position: relative;
      border: 2px solid #a3a3a3;
      display: flex;
      align-items: center;
      justify-content: center;


      img {
        position: absolute;
        height: 20px;
        width: 20px;
        max-width: none;
      }

      i {
        font-size: 22px;
        font-weight: 100;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .checkStepDivider {
        background-color: #ff5e31 !important;
      }

      .divider {
        position: absolute;
        top: 50%;
        width: 237px;
        left: 18px;
        height: 2px;
        background-color: #a3a3a3;


      }
    }

    .stepLabel {
      padding-top: 2px;
      font-size: 10px;
      max-width: 54px;
      text-align: center;
      font-weight: 600;
      color: white;
    }

    &:last-child {
      margin-right: 0;

      .icon .divider {
        display: none;
      }
    }

    &.completed {
      color: $brand-blue;

      .icon {
        border: 2px solid $brand-blue;

        i {
          font-size: 16px;
          font-weight: 600;
        }

        .divider {
          background-color: $brand-blue;
        }
      }
    }
  }
}
