@import '../../../styles/variables';

.product-name-column {
  display: flex;
  align-items: center;

  i {
    font-size: 24px;
    margin: 0 10px 0 5px;
  }

  @media screen and (max-width: 63.9375em) {
    display: inline-flex;
    align-items: baseline;

    i {
      font-size: 18px;
    }
  }
}
