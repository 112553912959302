@import "../../../styles/variables";

.DataTable {
  position: relative;

  .FlexTableWrapper {
    max-height: 100%;
    transition: max-height 0.3s ease;
    display: flex;

    .FlexTable {
      flex-grow: 1;
      height: 100%;
    }
  }

  &.desktop-view {
    .FlexTableWrapper.scrollable {
      overflow-x: auto;
      overflow-y: hidden;
    }

    .FlexTable {
      min-width: 1000px;

      &.is-tablet {
        min-width: 900px;
      }

      .FlexTableItem {
        &.fixed-column {
          background-color: inherit;
          position: sticky;
          position: -webkit-sticky;
          transform: translateZ(0);
          right: 0;
          z-index: 2;
          transition: box-shadow 0.2s linear;

          &:first-child {
            left: 0;
          }

          &:last-child {
            right: 0;
          }
        }
      }

      &.scrolledLeft .FlexTableItem.fixed-column {
        box-shadow: -1px 0 2px $light-gray;
      }

      &.scrolledRight .FlexTableItem.fixed-column {
        box-shadow: 1px 0 2px $light-gray;
      }

      &.scrolledLeft .FlexTableItem.fixed-column:first-child {
        box-shadow: 7px 0 5px $light-gray;
      }

      &.scrolledRight .FlexTableItem.fixed-column:last-child {
        box-shadow: -7px 0 5px $light-gray;
      }
    }
  }

  .table-row {
    z-index: 1;

    &.sortable-ghost {
      opacity: 0.4;
    }

    &.pink {
      background-color: $light-orange;

      @media screen and (min-width: 64rem) {
        .table-row-item.sorted {
          background-color: #fff0db;
        }
      }
    }

    .table-row-item {
      min-height: 45px;
      min-width: 0;
    }

    .collapse-btn {
      font-size: 12px;
      transition: transform 0.5s ease;
    }

    &.selected {
      z-index: 2;

      .collapse-btn {
        transform: rotate(180deg);
      }
    }

    &.expandable.selected {
      background-color: $light-blue;

      .table-row-item {
        background-color: $light-blue;
      }
    }

    &.isPinnedHeader {
      @supports ((position: sticky) or (position: -webkit-sticky)) {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 3;
        box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.2);
      }
    }

    .expandable-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      padding: 5px 10px;
      margin: 0;

      .button {
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: 63.9375em) {
    &:not(.desktop-view) {
      .table-row {
        margin: 10px;
        position: initial;
        overflow: visible;

        .table-row-item {
          min-height: 0;
        }
      }
    }
  }

}
