@import '../../../../styles/variables';

.BarnsheetsCompanyFarms {

  .primary-link {
    color: $primary;
  }

  .fa-dots-three-horizontal {
    color: $brand-gray;
    font-size: 25px;
    cursor: pointer;
  }

}

@media screen and (max-width: 63.9375em) {
  .BarnsheetsCompanyFarms {
    .FlexTableWrapper.scrollable .FlexTable {
      min-width: auto;
    }
  }
}
