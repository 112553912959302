@import "../../../styles/variables";

.AudioPreview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: background-color .3s linear;

  .audio-control-button {
    color: $white;
    border-radius: 50%;
    text-align: center;
    margin: 0;
    cursor: pointer;
    font-size: 24px;
    transition: background-color .3s ease;
    background-color: $primary;

    &.play {
      background-color: $primary;
    }

    &.stop {
      background-color: $secondary;
    }

    &:hover {
      opacity: .8;
    }
  }

  .soundwave {
    background-repeat: repeat-x;
    background-position: center;
    flex: 1 1 auto;
    min-height: 50px;
    margin: 0 10px;
    position: relative;

    .audio-progress {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      background-color: rgba(255, 94, 49, .2);
      width: 0;
      transition: all .5s linear;
      max-width: 100%;

      &.visible {
        opacity: 1;
      }

      &.in-progress {
        left: 0;
        right: auto;
      }
    }
  }

  .action {
    display: flex;
    align-self: center;

    .action-button {
      font-size: 22px;
      padding: 6px 8px;
      border-radius: 5px;
      cursor: pointer;
      color: $almost-black;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    &.hidden {
      display: none;
    }
  }

  &.playing {
    background-color: rgba(255, 94, 49, .05);
  }
}
