@import '../../../styles/variables';

i.dropdown-icon {
  color: $brand-gray;
  font-size: 25px;
  cursor: pointer;
}

button.dropdown-button {
  padding: 5px;
  margin-bottom: 0;

  .icon {
    font-size: 24px;
    color: $dark-gray;
    cursor: pointer;
  }
}
