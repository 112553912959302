@import '../../../../styles/variables';

.modal-msg {
  color: $brand-orange;
}

.custom-name-label {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
}

.custom-name {
  margin-top: 5px;
  position: relative;

  input {
    font-size: 14px;
    border-color: $light-gray;
    box-shadow: none;
  }

  .input-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 8px;
  }
}

.check-icon-success {
  color: $brand-blue;
  font-weight: 600;
}

.check-icon-warning {
  color: $brand-orange;
  font-weight: 600;
}

.loader-svg {
  width: 22px;
  height: 22px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
