@import "../../../../styles/variables";

.GalleryFilters {

  .filters-header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    border-bottom: 1px solid $light-gray;
    padding: 10px;
    background: $white;

    .heading {
      font-size: 16px;
      font-weight: 600;
    }

    .reset-filters {
      font-size: 12px;
      color: $primary;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .filters-body {
    font-size: 14px;

    .gallery-search {
      border-bottom: 1px solid $light-gray;
      padding: 10px;
    }

    .gallery-filter {
      border-bottom: 1px solid $light-gray;
    }

    .death-type {
      display: block;
      padding: 5px 0;
    }
  }
}
