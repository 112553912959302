@import '../../../../styles/variables';

.line {
  display: flex;
  align-items: center;
  padding: 9px;

  &.isDisabled {
    background-color: $almost-white;

    &:hover {
      cursor: not-allowed;
    }

    .name {
      color: $brand-gray;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgb(216, 216, 216);
  }

  &:hover {
    cursor: pointer;
    background-color: $almost-white;
  }
}

.name {
  color: $black;
  font-size: 14px;
  margin: 0 10px;
}

.badge {
  margin-right: 5px;
}

.status {
  margin-left: auto;
}
