@import 'styles/variables';

.FarmEditForm {

  .is-disabled {
    opacity: 0.5;
  }

  .form-content-block {
    display: flex;
    padding-top: 10px;

    .Select-arrow-zone {
      background-color: #F7F7F7;
      border-left: 2px solid $light-gray;
    }
  }

  .farm-form-edit {
    flex: 0 0 35%;
  }

  .map-block {
    flex: 1 1 100%;
    margin: .94rem .94rem .94rem 0;
    z-index: 1;
    max-height: 647px;
  }

  .button-submit {
    margin: .94rem;
  }

  .coords-select-form {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 64em) {

    .form-content-block {
      display: block;
    }

    .map-block {
      height: 400px;
      margin: .625rem;
    }

    .button-submit {
      margin: .625rem;
    }
  }
}
