@import '../../../../../styles/variables';

.dropzone {
  min-height: 300px;
  border-radius: 5px;
  border: 3px dashed $alto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $nice-grey;

  &.isDragOver {
    border-color: $primary;
  }

  .upload-icon {
    margin-bottom: 30px;
    color: $alto;
  }
}

.asset-wrapper {
  border-bottom: 1px solid $light-gray;

  &:last-child {

    .assets-item,
    .asset-note {
      border-bottom: none;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.assets-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;

  .asset-preview {
    position: relative;
    margin-right: 5px;

    .image {
      width: 70px;
      height: 45px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid $light-gray;
      padding: 1px;
      cursor: pointer;
      color: $nice-grey;
      display: flex;
      justify-content: center;
      align-items: center;

      &.loading {
        background-size: contain;
      }

      &:hover {
        opacity: .75;
      }
    }
  }

  .asset-info {
    flex-grow: 1;
    min-width: 0;
    margin-right: 5px;
    align-self: flex-start;

    .asset-name {
      font-size: 13px;
      font-weight: 600;
      color: $primary;
      min-width: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 2px;
    }

    .asset-description {
      font-size: 11px;
      font-weight: normal;
      color: $almost-black;
      align-self: flex-start;
    }

    .upload-progress {
      background-color: $medium-gray;
      height: 5px;
      width: 100%;
      margin: 5px auto;
      position: relative;

      .progress-value {
        background-color: $primary;
        height: 5px;
        width: 0;
        transition: width .4s ease;
      }
    }

    .uploading-asset-info {
      display: flex;
      flex-direction: row;
      flex: 1 auto;

      .asset-name {
        flex-grow: 1;
        min-width: 0;
        color: $black;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .asset-size {
        flex: 0 0 auto;
        margin-left: 5px;
        min-width: 0;
      }
    }
  }

  .asset-actions {
    display: flex;
    align-self: center;

    i {
      font-size: 22px;
      padding: 6px 8px;
      border-radius: 5px;
      cursor: pointer;
      color: $almost-black;
      transition: all .3s ease;
      user-select: none;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.asset-note {
  width: 100%;
  color: $almost-black;
  font-size: 14px;

  .title {
    font-weight: 600;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    padding: 4px 10px;
  }

  .description {
    font-style: italic;
    padding: 6px 10px;
    background-color: $white;
    color: $light-black;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}

.assets-list {
  padding-bottom: 10px;
}
