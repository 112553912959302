@import 'styles/variables';

.RadioSelectButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-image: linear-gradient(0deg, rgba(245, 245, 245, 0.68) 3%, rgba(255, 255, 255, 0.68) 100%);
  border: .5px solid $alto;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  margin: 5px 0;
  transition: .3s all ease;
  min-width: 200px;
  max-width: 340px;
  width: 100%;

  &:active {
    border-color: $primary;

    .label {
      color: $primary;
    }
  }

  &.isActive {
    border-color: $primary;
    box-shadow: 0 0 8px rgba(255, 94, 49, 0.5);

    .label {
      color: $primary;
    }
  }

  .icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: $primary;
    transition: .3s all ease;
    animation: fadeIn;
    animation-duration: .3s;
  }
}

@keyframes fadeIn {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
