.FilePreview {
  display: flex;
  align-items: center;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 9px;
  height: 80px;
  width: 535px;
  max-width: 100%;
  margin: 10px auto;
  transition: all .2s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  }

  .fa-text-document {
    font-size: 32px;
    margin-right: 5px;
    margin-left: 10px;
    color: var(--color-gray-medium);
  }

  .file-name {
    max-width: 390px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .download-link {
    color: var(--color-primary);
    margin-right: 5px;
    flex: 1 0 150px;
  }
}
