.picker-component {
  background-color: var(--color-white-bg);
  height: 100%;
  position: relative;

  .mobile-picker-data {
    overflow: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: var(--mobile-header-height);
    bottom: 0;

    &.has-search {
      .resources-list {
        &.has-placeholder {
          height: calc(100% - 52px);
        }
      }
    }

    .mobile-picker-search {
      padding: 10px;
      background-color: var(--color-blue-sidebar);
      position: relative;
      right: 0;
      left: 0;

      @supports ((position:sticky) or (position:-webkit-sticky)) {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      .mobile-picker-search-box {
        margin: 0;
        width: 100%;
        max-width: 100%;
      }
    }

    .resources-list {
      overflow: hidden;
      background-color: white;
      border-bottom: 1px solid var(--color-gray-light);

      &.has-placeholder {
        height: 100%;
      }
    }
  }
}
