.activity-search-mobile {
  overflow-y: auto;
  height: 100%;

  .search-block .search-input input {
    font-size: 20px !important;
    font-weight: 600;
  }
}

.close-icon {
  margin-bottom: 5px;
}

.search-block {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  padding: 10px 20px;
  border-bottom: 1px solid var(--color-gray-light);
}

.search-input {
  border: none;
  padding: 5px 0;
  font-weight: normal;
}

.search-suggestions {
  padding-bottom: 50px;
}

.suggestion-item {
  padding: 9px 10px;
  background-color: white;
  border-top: 1px solid var(--color-gray-light);
  font-size: 14px;

  &.divider {
    border-bottom: 1px solid var(--color-gray-light);
    margin-bottom: 10px;
  }

  &.first-item {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid var(--color-gray-light);
  }
}
