@import '../../../styles/variables';

.UserProfilePQADate {
  padding-bottom: 15px;

  .input-label {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
  }

  input {
    margin-bottom: 0;
  }

  .nickname-icon {
    font-size: 18px;
    color: $medium-gray;
  }

  input[name="pqa_date.month"],
  input[name="pqa_date.day"] {
    width: 50px;
  }

  input[name="pqa_date.year"] {
    width: 60px;
  }

  .manage-link {

    a {
      color: $primary;
      text-decoration: underline;
    }
  }
}
