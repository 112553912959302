@import '../../../styles/variables';

.stats {
  display: flex;
  flex-wrap: wrap;

  .stat-item {
    border-left: 1px solid $light-gray;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 100px;
    padding-left: 10px;
    margin-bottom: 10px;

    .stat-title {
      color: #8c8c8c;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .stat-number {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
