@import 'styles/variables';

.main-footer {
  transition-property: height, margin-top;
  transition-duration: .3s;
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  margin-top: -$main-footer-height;
  left: 0;
  right: 0;
  display: flex;
  height: $main-footer-height;
  align-items: stretch;
  justify-content: center;
  color: $dark-blue;
  border-top: 1px solid $medium-gray;
  background: $white;
  max-height: 55px;
  z-index: 31;

  .count-value {
    position: absolute;
    top: 2px;
    left: 50%;
    font-size: 9px;
    padding: 0 3px 0 3px;
    background-color: $primary;
    color: $white;
    border-radius: 3px;
  }

  .badge-icon {
    position: relative;

    &.active {
      .count-value {
        background-color: $primary;
        color: $white;
      }
    }
  }

  @media (orientation: landscape) {
    margin-top: -$main-footer-height-shrinked;
    height: $main-footer-height-shrinked;
  }

  a {
    color: $dark-blue;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: -1px;
    max-width: 120px;
    padding: 5px;
    text-align: center;
    flex: 1 0 60px;
    border-top: 1px solid transparent;

    &:hover,
    &:focus {
      color: $dark-blue;
    }

    @media (orientation: landscape) {
      padding: 2px 0 0;
    }

    &.active {

      .fa, .title {
        color: $primary;
      }
    }
  }

  .fa {
    margin-bottom: 3px;
    font-size: 18px;
    color: $dark-blue;

    @media (orientation: landscape) {
      margin-bottom: 2px;
      font-size: 10px;
    }
  }

  @media (orientation: landscape) {
    .count-value {
      font-size: 7px;
    }
  }

  .title {
    font-size: 9px;

    @media screen and (min-width: 40em) {
      font-size: 12px;
    }
  }
}
