@import "../../../styles/variables";

.MediaViewerAttachedItems {
  width: 100%;
  top: auto;
  left: 0;
  bottom: 0;
  background-color: $sidebar-color;
  transform: translateX(240px);
  transition: transform .3s, top .3s;
  will-change: transform;

  @media (max-width: 1023px) {
    display: none;
    transform: translateX(0);
    padding-left: 0;
  }

  &.is-collapsed {
    transform: translateX(60px);
  }

  &.is-fullscreen,
  &.is-fullscreen.is-collapsed {
    transform: translateX(0);
  }

  .items-row-wrapper {
    padding-left: 0;

    .items-row-title {
      padding: 8px 0;
      margin-left: 10px;
      display: block;
      color: white;
      font-size: 12px;
      cursor: pointer;

      .fa {
        transition: transform .4s ease;

        &.rotated {
          transform: rotate(180deg);
        }
      }
    }

    .items-row-files {
      width: 100%;
      height: 0;
      overflow: hidden;
      transition: height .3s ease;

      .scrollable-assets {
        padding: 3px 5px;
      }

      &.is-open {
        height: 75px;
      }

      .asset-preview {
        display: inline-block;
        margin-right: 10px;
        position: relative;
        width: 90px;
        height: 60px;
        background-color: white;

        .ai-icon {
          pointer-events: none;
          position: absolute;
          width: 35px;
          height: 35px;
          z-index: 1;
          background-color: white;
          border-radius: 50%;
          padding: 7px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          padding: 1px;
          cursor: pointer;

          &:hover {
            opacity: .9;
          }

          &.active {
            box-shadow: 0 0 2px 1px rgba(255, 255, 255, .75);
            opacity: .9;
          }
        }
      }
    }
  }
}
