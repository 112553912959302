@import '../../../styles/variables';

.FarmFeedRowSegment {
  margin: 15px 0;

  .segment {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;

    .segment-header {
      font-size: 14px;
      font-weight: 600;
      padding: 8px 10px;
      border-bottom: 1px solid $light-gray;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      .is-trigger {
        color: $brand-orange;
        margin-right: 5px;
      }

      .edited-label {
        background: $dark-gray;
        color: $white;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        padding: 2px 4px;
        border-radius: 3px;
      }
    }

    .subtitle {
      padding: 4px 10px;
      border-bottom: 1px solid $light-gray;
      background: $background;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .segment-body {
      .item {
        padding: 4px 10px;
        border-bottom: 1px solid $light-gray;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        white-space: pre-wrap;

        &.positive {
          background-color: $light-blue;
        }

        &.negative {
          background-color: $light-orange;
        }

        &.highlighted {
          background-color: $light-yellow;
        }

        .value {
          font-weight: 600;

          &.normal {
            font-weight: normal;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .comment-box {
      .comment-head {
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        padding: 4px 10px;
        background: $background;
        font-weight: 600;
      }

      .comment-body {
        font-style: italic;
        line-height: inherit;
        padding: 8px 10px;
        word-wrap: break-word;
        white-space: pre-wrap;
        background-color: $light-yellow;
      }
    }
  }
}
