.no-segments-box {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;

  .info-icon {
    margin-bottom: 15px;
    color: var(--color-gray-dark);
  }
}

.segment-icon {
  flex-shrink: 0;
}
