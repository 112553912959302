@import '../../../../styles/variables';

.recommended-set-point {
  min-height: 60px;
  border-radius: 50px;
  padding: 5px 25px;
  display: flex;
  align-items: center;

  &.darker-blue {
    background-image: linear-gradient(90deg, rgb(247, 247, 247) 3%, rgb(202, 230, 250) 103%);
  }

  &.lighter-blue {
    background-image: linear-gradient(90deg, rgb(247, 247, 247) 3%, rgb(220, 239, 250) 103%);
  }

  &.yellow {
    background-image: linear-gradient(90deg, rgb(247, 247, 247) 3%, rgb(250, 246, 220) 103%);
  }

  &.pink {
    background-image: linear-gradient(90deg, rgb(247, 247, 247) 3%, rgb(252, 223, 220) 103%);
  }

  &.grayscale {
    background: $background;
  }

  .label {
    max-width: 120px;
    font-size: 14px;
  }

  .value-block {
    margin-left: auto;
    position: relative;
  }

  .value {
    font-size: 35px;
    font-weight: 300;
    margin-right: 15px;
  }

  .desc {
    top: 8px;
    right: 0;
    position: absolute;
    font-size: 14px;
  }
}
