.system-switcher {
  height: 100%;
  background-color: var(--color-white-almost);
}

.list {
  background-color: white;
  border-top: 1px solid var(--color-gray-light);
  border-bottom: 1px solid var(--color-gray-light);
  margin: 20px 0;

  .switch-app-row {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:last-child {
      .app-name {
        border-bottom: none;
      }
    }
  }

  .left-icon {
    font-size: 22px;
    padding: 0 30px 0 25px;
    color: var(--color-gray-dark);
  }

  .app-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 20px 10px 20px 0;
    font-size: 14px;
    border-bottom: 1px solid var(--color-gray-light);
  }

  .check-icon {
    color: var(--color-primary);
    padding: 0 10px;
    font-weight: 600;
  }
}
