@import "../../../../styles/variables";

.BarnsheetsDailyCheckup {

  .UserPanel.user-details {
    font-size: 14px;

    .user-bar {
      margin-top: 10px;
    }

    .diagnose-name {
      display: flex;
      justify-content: space-between;
      padding: 5px 7px;

      .name {
        font-weight: 600;
      }
    }

    .diagnose-note {
      padding: 5px 7px;
      background-color: #fffcec;
      border-top: 1px solid $light-gray;

      .note {
        font-weight: 600;
      }
    }
  }

  .movements-info {
    font-size: 14px;

    > div {
      padding: 5px 10px;
      border-top: none;
      border-bottom: 1px solid $light-gray;
    }
  }

  .deaths-section {

    .Header {
      border-bottom: none;
    }
  }

  .checkup-column {
    padding-bottom: 15px;

    @media(min-width: 75em) {
      padding-right: 15px !important;
    }

    .updated-at {
      text-align: center;
      color: #B5B5B5;
      font-size: 14px;
      font-style: italic;
    }

    .assets {
      justify-content: flex-start;
      flex-wrap: wrap;

      .asset-preview {
        display: inline-block;
        margin-right: 10px;
        position: relative;
        width: 90px;
        height: 60px;

        .image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border: 1px solid $light-gray;
          padding: 1px;
          cursor: pointer;
          transition: opacity .2s;

          &:hover {
            opacity: .75;
          }
        }
      }
    }
  }

  .edit-report {
    padding: 10px 15px;
  }

  .panel {

    .group-block {
      margin: 0 0 20px 0;
      font-size: 14px;
    }

    .panel-heading.main-panel {
      font-size: 20px;
      text-transform: capitalize;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      border-radius: 5px 5px 0 0;

      .media-label {
        padding: 2px 6px;
        font-size: 10px;
        border-radius: 3px;
        border: 1px solid rgb(209, 209, 209);
        background-color: $almost-white;
        margin-left: 10px;
        color: $dark-gray;
        vertical-align: middle;
      }

      .close-checkup {
        font-size: 16px;
        color: $black;
        padding: 15px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.05);
        cursor: pointer;
        display: flex;
        margin-right: -10px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .comments-count {
      display: flex;
      justify-content: space-between;
    }

    .comments-count {
      font-size: 12px;
      border-top: 1px solid $light-gray;
      background-color: $background;
      padding: 5px 10px;

      .right-value {
        color: #9B9B9B;
      }

      .primary {
        color: $primary;

        &:hover {
          color: $black;
        }
      }
    }
  }

  .audio-asset-wrapper {
    border-bottom: 1px solid $light-gray;

    .audio-asset {
      padding: 5px 10px;
    }

    .asset-note {
      width: 100%;
      color: $almost-black;
      font-size: 14px;

      .title {
        font-weight: 600;
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        padding: 4px 10px;
      }

      .description {
        font-style: italic;
        padding: 6px 10px;
        background-color: $white;
        color: $light-black;
        word-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }

  .right-column {
    .panel {
      .panel-heading {
        height: 50px;
      }
    }
  }

  &.isEditMode {
    .right-column {
      display: none;
    }

    .checkup-column {
      float: none;
      margin: 0 auto;
    }
  }

  .panel {

    .panel-body {

      .highlighted {
        background-color: $light-yellow;
      }
    }
  }
}
