@import "../../../../styles/variables";

.DatePickerMobile {

  .DayPicker {
    height: 100%;
    width: 100%;
    position: static;

    .DayPickerNavigation_container {
      box-shadow: none;
      border-top: 1px solid $light-gray;
      background-color: rgba(255, 255, 255, .9);
      height: 45px;

      button {
        border-radius: 0;
        border: none;
        background-color: transparent;

        svg {
          width: 35px;
          height: 35px;
        }

        &.DayPickerNavigation_button:first-child {
          border-right: 1px solid $light-gray;
        }
      }
    }

    .CalendarMonthGrid__vertical {

      .CalendarMonth_caption {
        padding: 10px 0 30px;
      }
    }

    .CalendarDay {
      border: 1px solid $light-gray;
      padding: 0;
      box-sizing: border-box;
      color: #565a5c;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        background: rgb(255, 232, 223);
      }

      &__blocked_out_of_range {
        color: $medium-gray;
        background: $background;
        pointer-events: none;
        border: 1px solid $light-gray;
      }

      &__selected,
      &__selected_start,
      &__selected_end,
      &__selected_span {
        background: $primary;
        border: 1px double $primary;
        color: $white;
        z-index: 2;

        &:hover {
          background: $primary;
        }
      }
    }

    .DayPicker_weekHeader {
      top: 35px;
    }

    .DayPickerKeyboardShortcuts__show {
      display: none;
    }
  }
}
