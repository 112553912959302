.MediaViewerHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 240px);
  height: 56px;
  min-height: 56px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  text-align: left;
  text-decoration: inherit;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 10px;
  border-bottom: 1px solid rgb(232, 232, 232);
  background-color: #fff;
  box-sizing: border-box;
  transform: translateX(240px);
  transition: transform .3s, top .3s;
  will-change: transform;

  @media (max-width: 812px) {
    background: var(--color-black);
    border: none;
  }

  @media (max-width: 1023px) {
    transform: translateX(0);
    width: 100%;
  }

  &.is-collapsed {
    transform: translateX(60px);
    width: calc(100% - 60px);
  }

  &.is-fullscreen,
  &.is-fullscreen.is-collapsed {
    transform: translateX(0);
    width: 100%;
  }

  .header-data {
    flex-grow: 1;

    .header-data__inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      margin: 10px 0;

      .header-data__info {
        flex: 1 auto;
        margin: 0 10px 0 0;

        .header-data__info-title {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          color: var(--color-blue-brand);
          font-size: 13px;
          font-weight: 600;
          margin-right: 5px;
        }

        .header-data__info-date {
          color: var(--color-gray-dark);
          font-size: 13px;
        }
      }
    }
  }

  .header-actions {
    display: flex;
    align-items: center;

    .diagnose-btn-block {
      position: relative;
      margin-right: 60px;
    }

    .header-btn__comments,
    .header-btn__close {
      cursor: pointer;
      font-size: 20px;
      color: var(--color-gray-dark);
      margin: 5px;
      padding: 10px 14px 10px 19px;
      transition: color .2s;

      @media (min-width: 812px) {
        &:hover {
          color: var(--color-primary)
        }
      }
    }

    .header-btn__comments {
      font-size: 26px;
      padding: 10px 5px;

      &.disabled,
      &.disabled:hover {
        color: var(--color-gray-light);
      }
    }

    .header-btn__close {
      border-left: 1px solid #eee;
      @media (max-width: 812px) {
        padding: 0 .1rem 0 0;
        border: none;
      }
    }
  }

  &.is-mobile {
    background-color: var(--color-black);
    border-bottom-color: var(--color-black);

    .header-btn__comments {
      display: none;
    }

    .header-data {
      .header-data__info-title,
      .header-data__info-date {
        color: white !important;
      }
    }

    .header-actions {
      .header-btn__close {
        border-left: none;
        color: white;
      }
    }

    @media (max-width: 86rem) {
      left: 0;
      width: 100%;
    }

  }
}
