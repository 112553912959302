@import "../../../styles/variables";

.add-diagnose {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 600;
  color: $white;
  cursor: pointer;

  .diagnose-plus {
    font-size: 15px;
    margin-right: 10px;

    & + span {
      line-height: 15px;
    }
  }

  &.primary {
    height: 40px;
    border: 1px solid $primary;
    background-color: $white;
    font-size: 13px;
    font-weight: 600;
    color: $primary;
    transition: background-color, color ease .3s;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}
