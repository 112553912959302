.svr-category-collapse {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  color: var(--color-black);
  font-weight: 600;
  font-size: 14px;
  flex-wrap: wrap;
  border-bottom: none;
  overflow: hidden;

  &.failed {
    .collapse-header {
      background-color: var(--color-orange-light);
    }
  }

  .collapse-header {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    width: 100%;
    transition: 0.3s;
    cursor: pointer;
    background-color: #fbfbfb;

    &.is-opened {
      background-color: white;

      .collapse-icon {
        transform: rotate(90deg);
      }
    }

    .category-name {
      display: flex;
      align-items: center;
    }

    .fail-icon {
      color: var(--color-red-brand);
      font-size: 20px;
      margin-right: 10px;
    }

    .counter-badges {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    .collapse-icon {
      font-weight: 600;
      margin-left: 5px;
      transition: 0.3s;
      font-size: 11px;
      color: var(--color-gray-dark);
    }

    @media (max-width: 40em) {
      position: relative;
      flex-wrap: wrap;

      .category-name {
        width: 100%;
      }

      .counter-badges {
        margin-top: 10px;
        margin-left: 30px;
      }

      .collapse-icon {
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }
  }

  .collapse-content {
    width: 100%;

    .svr-body {
      flex: 1;
      width: 100%;
    }
  }
}
