.virtual-visit-form {
  .user-row {
    position: relative;
    z-index: 11;
  }

  .farm-row {
    position: relative;
    z-index: 12;
  }
}
