@import "../../../styles/variables";

.ResponsiveSelect {

  .Select {
    cursor: pointer;

    .Select-value {
      cursor: pointer;
      font-size: 14px;
    }

    .Select-control {
      transition: all .25s ease-in-out;

      .Select-arrow-zone {
        background-color: transparent;

        .Select-arrow {
          background-image: url('./arrow.svg');
          background-size: 14px;
          background-position: 50% 50%;
          background-origin: content-box;
          background-repeat: no-repeat;
          border-width: 0;
          height: 100%;
          width: 20px;
          top: 2px;
          transition: all .5s ease;
        }
      }
    }

    .Select-menu-outer {
      margin: 5px 0 10px;
      border-radius: 5px;
      min-width: 300px;
      max-height: 300px;
      overflow: hidden;
      z-index: 2;
      animation: fadein .3s;

      .Select-menu {

        .Select-option {
          border-bottom: 1px solid $light-gray;
          padding: 10px 15px;
          font-size: 14px;
          color: initial;
          cursor: pointer;

          &:hover {
            background-color: $background;
          }

          &.is-selected {
            position: relative;
            background-color: $background;

            &:after {
              content: '\f012';
              position: absolute;
              right: 15px;
              color: $brand-blue;
              font: normal normal normal 14px/1 'EveryPig';
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              top: 50%;
              transform: translateY(-50%);
              font-weight: 600;
            }
          }
        }
      }
    }

    &.is-open {

      .Select-control .Select-arrow-zone .Select-arrow {
        transform: rotate(180deg);
        top: 5px;
      }
    }
  }

  &.grey .Select {

    .Select-control {
      border-color: $light-gray;
    }

    &.is-focused:not(.is-open) > .Select-control {
      box-shadow: none;
    }

    .Select-value {
      background-color: $light-gray;
    }

    .Select-placeholder {
      background-color: $light-gray;
    }
  }

  &.up {

    .Select-menu-outer {
      top: auto;
      bottom: 100%
    }

    &.isMobile .Select .Select-menu-outer:before {
      border-top: 15px solid $white;
      border-bottom: none;
      bottom: unset;
      top: calc(100% - 1px);
    }
  }

  &.center {

    .Select-menu-outer {
      transform: translateY(-50%);
      top: 50%;
    }

    &.isMobile .Select .Select-menu-outer:before {
      content: none;
    }
  }

  &.isMobile {

    .Select {

      .Select-menu-outer {
        overflow: unset;
        z-index: 5;

        &:before {
          content: '';
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-bottom: 15px solid $white;
          position: absolute;
          bottom: 100%;
          right: 9px;
        }
      }

      &.is-open + .backdrop {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s linear, opacity .5s linear;
      }
    }

    .backdrop {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      overflow-y: auto;
      right: 0;
      background-color: rgba(30, 38, 62, 0.7);
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s linear .5s, opacity .5s linear;
      pointer-events: none;
      z-index: 4;
    }
  }

  &.disabled {

    .Select {
      cursor: not-allowed;

      > .Select-control {
        pointer-events: none;

        .Select-arrow-zone {
          opacity: .4;
        }

        .Select-value .Select-value-label {
          color: $dark-gray;
        }
      }
    }
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
