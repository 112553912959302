@import "../../../../styles/variables";

.image-tag {
  border: 1px solid $light-gray;
  position: absolute;
  border-radius: 5px;
  font-size: 14px;

  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -ms-user-select: none; /* IE10+ */

  &.draggable {
    &:hover {
      cursor: -webkit-grab;
    }
    &:active {
      cursor: -webkit-grabbing;
    }
  }

  &.clickable {
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    z-index: 2;
  }

  $drag-handle-width: 9px;
  $drag-handle-height: 9px;

  $drag-handle-background-colour: rgba(0, 0, 0, 0.2);
  $drag-handle-border: 1px solid rgba(255, 255, 255, 0.7);

  .drag-handle {
    position: absolute;
    width: $drag-handle-width;
    height: $drag-handle-height;
    background-color: $drag-handle-background-colour;
    border: $drag-handle-border;
    box-sizing: border-box;

    // This stops the borders disappearing when keyboard
    // nudging.
    outline: 1px solid transparent;
  }

  .ord-nw {
    top: 0;
    left: 0;
    margin-top: -(ceil($drag-handle-height / 2));
    margin-left: -(ceil($drag-handle-width / 2));
    cursor: nw-resize;
  }
  .ord-n {
    top: 0;
    left: 50%;
    margin-top: -(ceil($drag-handle-height / 2));
    margin-left: -(ceil($drag-handle-width / 2));
    cursor: n-resize;
  }
  .ord-ne {
    top: 0;
    right: 0;
    margin-top: -(ceil($drag-handle-height / 2));
    margin-right: -(ceil($drag-handle-width / 2));
    cursor: ne-resize;
  }
  .ord-e {
    top: 50%;
    right: 0;
    margin-top: -(ceil($drag-handle-height / 2));
    margin-right: -(ceil($drag-handle-width / 2));
    cursor: e-resize;
  }
  .ord-se {
    bottom: 0;
    right: 0;
    margin-bottom: -(ceil($drag-handle-height / 2));
    margin-right: -(ceil($drag-handle-width / 2));
    cursor: se-resize;
  }
  .ord-s {
    bottom: 0;
    left: 50%;
    margin-bottom: -(ceil($drag-handle-height / 2));
    margin-left: -(ceil($drag-handle-width / 2));
    cursor: s-resize;
  }
  .ord-sw {
    bottom: 0;
    left: 0;
    margin-bottom: -(ceil($drag-handle-height / 2));
    margin-left: -(ceil($drag-handle-width / 2));
    cursor: sw-resize;
  }
  .ord-w {
    top: 50%;
    left: 0;
    margin-top: -(ceil($drag-handle-height / 2));
    margin-left: -(ceil($drag-handle-width / 2));
    cursor: w-resize;
  }
}

