.plant-line, .plant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 5px 0;
  border-bottom: 1px dashed var(--color-alto);
}

.plant-header {
  height: 35px;
}

.plant-line {
  position: relative;

  &.is-loading {
    opacity: 0.7;
  }

  .plant-line-preloader {
    font-size: 8px;
  }
}

.preloader {
  height: 100px;
  align-items: center;
  display: flex;
  width: 100%;
  position: relative;
}

.plant-column {
  flex: 2 1 200px;
}

.rate-data-column {
  flex: 1 1 150px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
