@import '../../../../styles/variables';

.divider {
  position: absolute;
  height: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $light-gray;
}
