@import '../../../styles/variables';

.Notifications {
  position: relative;

  .action-block {
    cursor: pointer;
    font-size: 14px;
    color: $primary;
    background-color: $white;
    padding: 15px 0 15px 15px;
    box-shadow: 0 1px 1px $light-gray;
  }

  .block-content {
    padding: 20px;
  }
}
