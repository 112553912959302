@import 'styles/variables';

.tenant-avatar {
  border-radius: 50%;
  min-width: 36px;
  width: 36px;
  height: 36px;
  background-color: $dark-gray;
  color: $white;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  margin: 0 auto;

  &.highlighted {
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(255, 94, 58) 3%, rgb(255, 140, 59) 103%);
  }
}
