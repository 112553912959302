.StickyFooter {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;

  flex: 0 0 auto;
  width: 100%;
  text-align: center;
  border-top: 1px solid var(--color-gray-light);
  background-color: rgba(250, 250, 250, 0.85);
  transition: .3s all ease-in-out;
  opacity: 1;
  padding: 15px;
  z-index: 3;
  transform: translateZ(0);

  &._hidden {
    opacity: 0;
    pointer-events: none;
  }

  .button {
    font-size: 1.1em;
    min-width: 300px;
    margin: 0;
  }

  @media (max-width: 64em) {
    background-color: rgba(250, 250, 250, 1);
  }

  @media screen and (max-width: 40em) {
    padding: 10px 10px 20px;

    .button {
      width: 100%;
      font-size: 1em;
    }
  }
}
