// Colors
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$brand-gray: #bababa;
$dark-gray: #a3a3a3;
$light-black: #474747;
$almost-black: #5e5e5e;
$black: #303030;
$white: #fff;
$white-silver: #5F6877;
$background: #fafafa;
$white-smoke: #fcfcfc;
$almost-white: #f5f5f5;
$transparent: #fff;
$green: #00a169;
$purple: #c129cb;
$brand-purple: #c6c;
$orange: #ff8f0e;
$primary: #ff5e31;
$secondary: #0063a1;
$light-yellow: #fffceb;
$brand-blue: #0069a1;
$light-blue: #f2f7fa;
$dark-blue: #1F273D;
$sidebar-color: #20283D;
$brand-orange: #FFB200;
$light-orange: #fff5f0;
$brand-red: #DC3522;
$dark-orange: #ff5f33;
$aquamarine: #00aeff;
$lochmara: #0184cc;
$nice-grey: #8c8c8c;
$icon-green: #215A25;
$alto: #DDD;
$alice-blue: #e3eff5;
$regent-gray: #7D90A2;
$alto: #d2d2d2;
$punch: #DB3827;
$app-palette: (
  'light-gray': $light-gray,
  'medium-gray': $medium-gray,
  'dark-gray': $dark-gray,
  'black': $black,
  'white': $white,
  'transparent': $transparent,
  'green': $green
);

$bg-palette: (
  'pink': #FF947F,
  'yellow': #F79A2C,
  'blue': #62C4D9,
  'orange': #FF8533
);

// Sizing
$main-header-height: 55px;
$main-footer-height: 50px;
$main-footer-height-shrinked: 40px;
$header-notification-height: 35px;
$sidebar-width: 240px;
$sidebar-more-item-height: 48px;
$sidebar-collapsed-width: 60px;
$network-notif-height: 35px;

$mobile-width: 63.9375em;
$mobile-width-large: 425px;
$mobile-width-medium: 375px;
$mobile-width-small: 320px;
$tablet-width: 768px;
$laptop-width: 1024px;

$right-sidebar-width: 350px;
