$border-color: var(--color-gray-light);
$color-gray-dark: var(--color-gray-dark);

.mobile-menu-section {
  background-color: white;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  margin: 20px 0;
}

.section-row {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {

    .title-column {
      border-bottom: 1px solid $border-color;
    }
  }
}

.icon-column {
  flex: 0 0 60px;
  text-align: center;
}

.title-column {
  padding: 20px 20px 20px 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: block;
  font-size: 14px;

  .count-badge {
    padding: 1px 5px;
    border-radius: 3px;
    font-size: 10px;
    color: white;
    background-color: var(--color-primary);
    margin-left: 10px;
  }
}

.subtitle {
  font-size: 12px;
  color: $color-gray-dark;
}

.action-icon {
  color: $color-gray-dark;
}
