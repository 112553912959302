.table-pagination-wrapper {

  .pagination-container {
    min-height: 25px;
    min-width: 1px;
  }

  .pagination-data {
    clear: both;
    width: 100%;
  }

  .pagination-stats {
    font-size: 14px;

    .small {
      font-size: 13px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 63.9375em) {
    .table-pagination-wrapper {
      margin-top: 30px;
    }
  }
}
