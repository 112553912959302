.TreatmentsPanel {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  justify-content: space-between;
  min-height: 300px;

  .tooltip-wrapper {
    font-size: 12px;
    min-height: 50px;
  }

  .treatments-chart {
    border-radius: 5px;
    border: 1px solid var(--color-gray-light);
    min-width: 300px;
    background: white;
    display: flex;
    flex-direction: column;
    flex: 1;

    &-title {
      padding: 15px 20px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
    }

    &-body {
      position: relative;

      .legends-line {
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        color: var(--color-gray-nice);
        font-size: 12px;
        margin: 0 15px 20px;
      }
    }
  }
}
