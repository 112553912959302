@import "../../../styles/variables";

.CountryPickerMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: $white;

  .search-box-wrapper {
    width: 100%;
    background-color: $dark-blue;
    padding: 10px;
  }

  .countries-list-wrapper {
    width: 100%;
    overflow-y: scroll;
    flex: 1;

    .select-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 48px;
      border-top: 1px solid $light-gray;
      background-color: $white;
      font-size: 14px;

      &:last-child {
        border-bottom: 1px solid $light-gray;
      }

      &.country {
        padding: 0 15px;

        .country-name {
          padding: 0 10px;
        }

        .country-code {
          flex: 1;
          opacity: 0.8;
          padding: 0 5px;
        }

        .select-btn {
          margin-bottom: 0;
          padding: 10px 7px;
        }
      }

      &.selected {
        background-color: $light-orange;
        pointer-events: none;
      }
    }
  }
}
