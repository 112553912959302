@import '../../../styles/variables';

.info-row {
  padding: 5px 15px;
  border-top: 1px solid $light-gray;

  &.positive {
    background-color: $light-blue;
  }

  &.negative {
    background-color: $light-orange;
  }
}

.condition {

  .value {
    color: #fff;
    min-width: 50px;
    padding: 2px 5px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 11px;
    text-align: center;
    font-weight: 600;

    &.poor {
      background-color: $brand-red;
    }

    &.avg {
      background-color: $brand-orange;
    }

    &.good {
      background-color: $brand-blue;
    }
  }
}
