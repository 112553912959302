@import "../../../../styles/variables";

.DailyCheckupsGroupRow {
  border: 1px solid $light-gray;
  border-radius: 5px;
  color: $black;
  font-size: 0.75rem;
  background-color: white;
  margin-top: 20px;

  .action-required,
  .request-close-today {
    background: $light-yellow;
    padding: 0.35rem 0.5rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid $light-gray;
  }

  .action-required-icon {
    color: $brand-orange;
    margin-right: 5px;
  }

  .row-content {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    font-size: 14px;
    color: $black;

    .icon-section {
      flex: 0 0 35px;
      display: flex;
      justify-content: center;
      margin-right: 5px;
      font-size: 14px;
    }

    .group-info {
      flex: 3 0 150px;

      .group-name {
        font-size: 14px;
        font-weight: 600;
        word-break: break-all;
      }

      .group-barns {
        font-size: 13px;
        color: $dark-gray;
      }
    }

    .group-status {
      flex: 1 1 120px;
      font-size: 14px;
      font-style: italic;
      color: $dark-gray;
    }

    .pigs-count {
      flex: 1 1 120px;
    }

    .group-progress {
      flex: 1 1 150px;
    }

    .buttons-section {
      flex: 0 0 280px;
      text-align: right;

      > .button {
        font-weight: 600;
        margin: 5px;
        min-width: 120px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: auto;
      }

      .reconcile-btn {
        background-color: $brand-orange;
        color: $white;

        &:hover {
          background-color: scale-color($brand-orange, $lightness: -10%);
        }
      }
    }

    .section-divider {
      flex: 0 0 1px;
      border-right: 1px solid $light-gray;
      margin-left: 15px;
      margin-right: 15px;
      height: 40px;
    }
  }

  @media screen and (max-width: 63.9375em) {
    background-color: inherit !important;

    .row-content {
      min-height: initial;
      display: block;
      padding: 0;

      .icon-section,
      .group-status,
      .pigs-count,
      .section-divider {
        display: none;
      }

      .row-section {
        padding: 10px;

        &:not(:last-child) {
          border-bottom: 1px solid $light-gray;
        }
      }

      .group-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 45px;

        .group-name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .buttons-section {
        display: -ms-flexbox;
        display: flex;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow: hidden;
        padding: 0;
        margin: -1px;
        min-height: 45px;
      }

      .buttons-section {
        border: 1px solid rgb(221, 221, 221);
        border-top-color: transparent;

        .button {
          flex: 1 1 100%;
          border-radius: 0;
          border-width: 0;
          margin: 0;
        }
      }
    }
  }


  &.is-new {
    background-color: $background;
  }

  &.is-action-required {
    background-color: $background;

    .group-status,
    .pigs-count,
    .section-divider,
    .group-progress,
    .without-buttons {
      display: none !important;
    }
  }

  .red-marker {
    display: block;
    background-color: $primary;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .status-icon {
    font-size: 25px;
    color: $medium-gray;

    &.blue {
      font-size: 30px;
      color: $brand-blue;
    }
  }
}
