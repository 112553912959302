@import "../../../styles/variables";

.Checkins {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .nav-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: $white;
    border-bottom: 1px solid $light-gray;

    a {
      flex: 1 1 50%;
      line-height: 45px;
      font-size: 15px;
      font-weight: 600;
      text-align: center;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      color: $light-black;
      transition: .2s all ease;

      &:not(:last-child) {
        border-right: 1px solid $light-gray;
      }

      &:hover {
        color: $primary;
        background-color: $background;
      }

      &.active {
        color: $primary;
        background-color: #FFF5F0;
        border-bottom: 3px solid $primary;
      }
    }
  }
}

.CheckinsTable {
  margin: 0 -20px;

  .table-row .dropdown {
    display: none;

    &.active {
      display: block;
    }
  }

  .mobile-table {
    margin-bottom: 20px;

    .mobile-row {
      padding: 10px 15px 10px 10px;
      display: flex;
      border-bottom: 1px solid $light-gray;

      .Avatar, .avatar {
        margin-right: 10px;
        align-self: center;
      }

      .info {

        .name {
          font-size: 14px;
          font-weight: 600;
        }

        .description {
          font-size: 11px;
          color: $dark-gray;

          time {
            white-space: nowrap;
          }
        }
      }

      .dropdown-icon {
        color: $brand-gray;
        font-size: 25px;
        cursor: pointer;
        margin-left: auto;
        align-self: center;
      }
    }
  }
}

.CheckinsPanelSection {
  padding: 0 15px;

  @media screen and (max-width: $mobile-width) {
    margin: -20px 0 0;
    padding: 0;

    &.farm-checkins {
      margin: 0;
    }

    .checkins-panel {
      .checkins-panel-header,
      .checkins-panel-body {
        border-radius: 0;
      }

      .checkins-panel-body {
        .float-right {
          padding-right: 10px;
        }

        .float-left {
          padding-left: 10px;
        }
      }
    }
  }
}

.CheckinsSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .right-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .checkins-count {
      text-transform: uppercase;
      margin-right: 25px;
      text-align: center;
      font-weight: 600;
      line-height: 1.4;

      .checkins-count-label {
        color: $dark-gray;
        font-size: 13px;
      }

      .checkins-count-value {
        color: $black;
        font-size: 18px;
      }
    }

    .checkin-dropdown {
      .dropdown-menu {
        min-width: 250px;
      }
    }
  }

  .user-stats {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (max-width: 63.9375em) {
      justify-content: center;
    }

    .user-stats-item {
      display: flex;
      flex-direction: column;
      border-right: 1px solid $light-gray;
      text-align: center;
      padding: 0 15px;
      text-transform: uppercase;
      font-size: 10px;
      flex: 0 0 100px;
      font-weight: 600;

      &:last-child {
        border-right: 0;
      }

      .user-stats-item-label {
        flex: 1 auto;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .user-stats-item-count {
        color: $black;
        font-size: 16px;
      }
    }
  }
}

@media all and (max-width: 63.9375rem) {

  .CheckinsTable {
    margin: 0;
  }

  .CheckinsSection {

    & .checkins-table-wrapper, > .column {
      padding: 0;

      & > section {
        margin-top: 0;

        .pagination-data {
          margin: 0 -20px;
          width: auto;
          overflow-y: auto;

          @media all and (max-width: 39.9375em) {
            margin: 0 -10px;
          }
        }
      }
    }

    &.isMobile > .column, .checkins-table-wrapper, > section .pagination-data {
      overflow-y: visible;
    }
  }
}
