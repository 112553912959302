@import '../../../../styles/variables';

.trigger-icon {
  color: $brand-orange;
}

.notes-badge {
  border: 1px solid $dark-gray;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  background: transparent;
  color: $dark-gray;
  padding: 0 3px;
  margin-left: 7px;
}

.edited-badge {
  background: $dark-gray;
  color: $white;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  padding: 0 3px;
  border-radius: 3px;
  margin-left: 5px;
}
