@import '../../../styles/variables';

.flex-data-table {
  margin-bottom: 20px;

  .placeholder {
    text-align: center;
    line-height: 100px;
    color: $dark-gray;
  }

  .table-item {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $light-gray;
    font-size: 14px;

    @media (min-width: 64em) {
      &:hover {
        background-color: $background;
      }
    }

    @media (max-width: 63.9375em) {
      font-size: 13px;
      margin-bottom: 20px;
      border: 1px solid $light-gray;
      border-radius: 5px;
      overflow: hidden;
    }

    .table-item-block {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      @media (min-width: 64em) {

        &.column-buttons {
          flex-basis: 120px;

          .collapsible-value {
            padding-right: 0;
          }

          &.wide {
            flex-basis: 240px;
          }

          &.medium-wide {
            flex-basis: 190px;
          }
        }
      }

      .collapsible-name {
        font-weight: 600;
        border-bottom: 3px solid $light-gray;
        padding: 10px 5px 5px 10px;
        width: 100%;
        min-height: 38px;
      }

      .collapsible-value {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 10px;

        @media (max-width: 63.9375em) {
          width: 100%;
          flex-direction: row-reverse;
          border-bottom: 1px solid $light-gray;
          border-right: none;
          font-weight: 600;
          padding: 7px;
        }

        &.no-bottom-border {
          border-bottom: none;
        }

        .value-amount {
          min-height: 20px;
          width: 100%;
          flex-grow: 1;
          min-width: 50px;
          font-weight: normal;

          time {
            display: block;
          }

          @media (max-width: 63.9375em) {
            width: auto;
            padding-left: 3px;

            &.title {
              line-height: 30px;
              font-size: 14px;
            }

            time {
              display: inline-block;
              margin-left: 5px;
            }
          }
        }
      }

      .button-column {
        display: block;

        .button {
          margin-bottom: 0;

          &.small {
            margin-bottom: 0;
            font-size: 14px;
            padding: 10px;
          }
        }

        @media (max-width: 63.9375em) {
          display: flex;
          flex-direction: row;
          border-top-color: transparent;
          padding: 0;

          .button {
            flex: 1 1 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: none;
            border-radius: 0;
            border-width: 1px;
            margin: 0 -1px -1px;
          }
        }
      }
    }
  }

  .email {
    word-break: break-all;
  }

  .table-item-block {

    &.checked {
      background-color: $light-orange;
    }

    &.disabled {
      cursor: not-allowed;
      background: $almost-white;
    }

    .CircleCheckbox {
      &.roles-manage {
        margin: 0 auto;

        .icon {
          .fa {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .checkbox-label {
          margin-left: 0;

          @media all and (min-width: 64em) {
            .sub-label {
              margin-top: 22px;
              text-align: center;
            }
          }
        }
      }
    }

    @media screen and (max-width: 63.9375em) {
      .collapsible-value.with-checkbox {
        padding: 0;

        .value-amount {
          padding: 0;
        }

        .CircleCheckbox {
          width: 100%;
          position: relative;
          cursor: pointer;

          &.disabled {
            cursor: not-allowed;
          }

          .checkbox-label {
            margin: 0;
            width: calc(100% - 60px);
            border-right: 1px solid $light-gray;
            padding: 9px 15px;
          }

          .icon {
            position: absolute;
            left: calc(100% - 40px);
            top: calc(50% - 10px);

            .fa {
              left: 0;
              top: 0;
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
}
