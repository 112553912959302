@import '../../../../styles/variables';

.CloseGroupModal {
  overflow: hidden;
  bottom: 10px;
  width: 400px;
  max-width: calc(100% - 10px);
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 38px;
  border-radius: 8px;
  margin: 0 auto 50px;

  .modal-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 30px 15px 15px;

    .avatar-wrapper {
      flex: 1 0 50px;
      align-self: center;
    }

    .content-wrapper {
      margin-left: 15px;
      flex-grow: 1;
      font-size: 16px;
      line-height: 20px;
      align-self: center;
    }
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    padding: 0;

    .button {
      flex: 1 1 50%;
      padding: 15px;
      font-size: 14px;
      margin: 0;
      border: none;
      border-radius: 0;
      margin: -1px;
    }
  }

  .close-button {
    padding: 10px;
    top: 3px;
    right: 3px;
  }
}
