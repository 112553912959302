@import "../../../../../styles/variables";

.Section {
  .content-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $light-gray;
  }

  .notes {
    font-style: italic;
    text-align: justify;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .none {
    color: #C4BCC4;
  }

  &.panel {
    border-bottom: none;
    .panel-heading,
    .panel-body {
      padding: 5px 10px;
      font-size: 14px;
      background-color: $white;

      &.notes {
        border-bottom: 1px solid $light-gray;
        &.light-yellow-bg {
          background-color: $light-yellow;
        }
      }

    }

    .panel-heading {
      flex-direction: row;
    }

    .blue {
      background-color: $light-blue;
    }

    .red {
      background-color: $light-orange;
    }

    .gray {
      background-color: $background;
    }

    margin-bottom: 20px;
  }

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px !important;

    &.highlightEmpty {
      background-color: $background;
    }

    .with-icon {
      display: flex;
      justify-content: center;

      .icon {
        color: #DBDBDB;
        font-size: 25px;
        margin-right: 10px;
      }
    }
  }
}
