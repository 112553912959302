/**
 * Foundation for Sites by ZURB
 * Version 6.2.4
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.35s, left 0.3s;
  position: relative;
  z-index: 5;
  height: calc(var(--mobile-header-height) + 1px);
  background: var(--color-primary);
  border-bottom: 1px solid rgba(150, 150, 150, 0.3); }
  .main-header .with-scroll {
    overflow-y: scroll;
    height: 0; }
    @media screen and (max-width: 63.9375em) {
      .main-header .with-scroll {
        display: none; } }
  .main-header .pushed-by-search {
    width: calc((100% - 590px) / 2); }
  .main-header .user-brief {
    display: flex;
    width: calc((100% - 590px) / 2);
    height: 45px;
    justify-content: flex-end;
    align-items: stretch;
    padding: 0 15px 0 5px; }
    .main-header .user-brief .open-filter-icon {
      margin-right: auto;
      border-radius: 5px; }
      .main-header .user-brief .open-filter-icon.active {
        background-color: #eb4101; }
  .main-header .user-brief .right-actions-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 45px;
    color: white;
    font-size: 25px;
    cursor: pointer; }
  .main-header .header-navigation {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1;
    margin-left: 10px; }
    .main-header .header-navigation .navigation-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      height: 40px;
      color: var(--color-white-almost);
      font-size: 14px;
      font-weight: 600;
      margin-right: 10px;
      cursor: pointer;
      transition: color 0.2s linear;
      padding: 0 9px;
      border-radius: 3px; }
      .main-header .header-navigation .navigation-link:last-child {
        margin-right: 0; }
      .main-header .header-navigation .navigation-link.active {
        background-color: #eb4200; }
      .main-header .header-navigation .navigation-link:hover {
        opacity: 0.8; }
      .main-header .header-navigation .navigation-link .badge {
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        padding: 1px 4px;
        border-radius: 3px;
        background-color: var(--color-white-almost);
        border: 1px solid var(--color-gray-light);
        color: var(--color-blue-brand);
        margin-left: 5px; }
  .main-header.is-super-admin-mode .super-admin-mode-off,
  .main-header.is-super-admin-mode .navigation-link {
    color: white; }
  .main-header.is-super-admin-mode .header-navigation .navigation-link {
    color: white; }
  .main-header.is-super-admin-mode .mobile-question {
    display: none; }
  .main-header .mobile-question {
    position: absolute;
    right: 0;
    top: 5px; }
  .main-header .mobile-btn {
    position: absolute;
    right: 8px;
    top: 10px;
    z-index: 2; }
    .main-header .mobile-btn .right-icon {
      color: white;
      font-size: 21px;
      margin: 6px; }
    .main-header .mobile-btn button {
      margin: 0; }
  .main-header .fa.mobile-logo {
    color: var(--color-primary);
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 46px;
    padding: 0;
    top: 50%; }
  .main-header .back-link {
    cursor: pointer;
    color: var(--color-primary);
    margin-left: 5px; }
    .main-header .back-link .fa {
      font-weight: normal;
      color: var(--color-primary);
      font-size: 20px;
      margin-left: 10px; }
  .main-header .close-mobile-menu {
    cursor: pointer; }
  .main-header .super-admin-mode-off {
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    padding: 8px 20px;
    z-index: 1; }
    .main-header .super-admin-mode-off span {
      display: block;
      font-size: 11px;
      line-height: 10px; }
  .main-header .edit-mode-overlay {
    background-color: var(--color-blue-dark);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 18px;
    padding: 10px 20px;
    height: var(--mobile-header-height)1px; }
    .main-header .edit-mode-overlay .edit-mode-off {
      cursor: pointer;
      text-align: center;
      font-size: 20px; }
      .main-header .edit-mode-overlay .edit-mode-off span {
        display: block;
        font-size: 11px;
        line-height: 10px; }
  @media (max-width: 63.9375em) {
    .main-header {
      background: var(--color-blue-sidebar); }
      .main-header.sticky-hidden,
      .force-sticky-hidden .main-header {
        transform: translateY(calc(var(--mobile-header-height) * -1)); } }
  @media screen and (min-width: 64em) {
    .main-header {
      position: absolute;
      left: 240px;
      right: 0;
      top: 0; } }
  .main-header .title {
    transition-property: opacity, visibility;
    transition-duration: 0.5s;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%; }
    .main-header .fade-out.title {
      opacity: 0;
      visibility: hidden; }
  .main-header .title {
    font-weight: bold;
    font-size: 18px;
    white-space: nowrap;
    max-width: calc(100% - 150px);
    overflow: hidden;
    text-overflow: ellipsis; }
  .main-header .hidden-by-default {
    transition-property: opacity, visibility;
    transition-duration: 0.2s;
    opacity: 0;
    visibility: hidden; }
    .main-header .hidden-by-default.active {
      opacity: 1;
      visibility: visible; }

@media screen and (min-width: 64em) {
  .layout-default.sidebar-collapsed .main-header, .layout-default.is-tablet .main-header {
    left: 60px; }
  .layout-default.is-edit-mode .main-header {
    left: 0 !important; }
  .layout-default.is-super-admin-mode .main-header {
    left: 0 !important; } }
