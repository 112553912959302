@import "styles/variables";

.status-header {
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 18px;
  padding: 10px 20px;
  color: white;

  @media screen and (max-width: $mobile-width-large) {
    font-size: 14px;
  }

  &.brand-orange {
    background-color: $brand-orange;
  }

  &.primary {
    background-color: $primary;
  }

  &.brand-blue {
    background-color: $brand-blue;
  }

  &.gray {
    background-color: #96adbd;
  }

  .icon {
    font-size: 35px;
    margin-right: 20px;

    &.rotated {
      transform: scale(-1, 1);
    }
  }
}
