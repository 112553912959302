@import '../../../styles/variables';

.AntibioticChart {
  margin: 20px 0;
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: 5px;

  .graph-title {
    font-size: 18px;
  }

  .label-block {
    font-size: 12px;
    display: inline-flex;
  }

  .grows-label {
    font-size: 12px;
    color: $dark-gray;
    text-align: right;
  }

  .caption-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 20px 0;

    .average-amount {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .item-value {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  @media screen and (max-width: $tablet-width) {

    .caption-block {
      flex-direction: column;

      .average-amount {
        justify-content: center;

        .item-value {
          font-size: 16px;
        }
      }
    }

    .grows-label,
    .graph-title {
      text-align: center;
    }
  }
}
