@import "../../../styles/variables";

.attention-box {
  background-color: $light-gray;
  color: $black;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  i {
    font-size: 18px;
  }
}
