@import '../../../../styles/variables';

.BarnsheetsGroupCheckups {

  .FlexTableWrapper {

    &.scrollable {
      @media (min-width: 64em) {

        .FlexTable {
          min-width: 1300px;
        }
      }
    }
  }

  .FlexTableItem {

    .collapsible-column-name {
      font-size: 12px;
    }

    .collapsible-value {
      padding: 4px 5px 4px 10px;

      .icon {
        font-size: 24px;
        margin-right: 10px;
      }

      .alert {
        color: $brand-red;
      }

      .name-icon {
        display: flex;
        align-items: center;
      }

      .media-label {
        padding: 2px 6px;
        font-size: 10px;
        border-radius: 3px;
        border: 1px solid rgb(209, 209, 209);
        background-color: $almost-white;
        margin-left: 10px;
        color: $dark-gray;
        vertical-align: middle;
      }

      .value-amount {
        .corrected {
          background-color: #fff2be;
          padding: 1px;
        }
      }

      &.actions {
        display: flex;
        align-items: center;
        justify-content: center;

        .fa-dots-three-horizontal {
          color: $brand-gray;
          font-size: 25px;
          cursor: pointer;
        }
      }

    }
  }

  @media screen and (max-width: $mobile-width) {
    .FlexTableItem .collapsible-value {
      padding: 10px;

      &.button-column {
        padding: 0;
      }

      .value-amount {
        .flex-row {
          justify-content: space-between;
        }
      }
    }
  }

  &.isTouchable {

    .FlexTableItem .title .icon {
      font-size: 16px;
    }
  }

  .color-grey {
    color: $almost-black;
  }

  .grey {
    background-color: $background;
  }

  .pink {
    background-color: $light-orange;
  }
}
