@import "styles/variables";

.category-row {
  position: relative;

  &:first-child {
    border-top: 1px solid #ddd;
  }

  &.flagged {
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 15px 0 0;
      border-color: $brand-orange transparent transparent transparent;
    }
  }

  .category-counters {
    display: flex;
    margin-left: 15px;

    @media (max-width: 40em) {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .category-header {
    position: relative;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    padding: 13px 20px;
    cursor: pointer;

    .collapse-icon {
      transition: transform 0.35s ease;

      &.opened {
        transform: rotate(90deg);
      }
    }
  }
}

// MOBILE

.category-row {
  @media (max-width: 40em) {
    .category-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .collapse-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;

        &.opened {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
  }

  .category-info-block {
    font-size: 16px;

    @media (max-width: 40em) {
      padding-right: 30px;
    }
  }
}
