@import "../../../styles/variables";

.collapse {
  padding: 2px;
  background: linear-gradient(0deg, rgba(245, 245, 245, 0.68) 3%, rgba(255, 255, 255, 0.68) 103%);

  &.isOpened {
    background: $white;
  }
}

.collapse-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 15px;

  .icon {
    transition: transform .35s ease;
    font-size: 13px;
    font-weight: 600;
    color: $dark-gray;
    min-width: 13px;
    padding-left: 3px;

    &.isOpened {
      transform: rotate(90deg);
    }
  }
}
