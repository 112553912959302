@import "styles/variables";

.wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  animation: fadeInOpacity ease-in 0.5s;

  &.loading {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(white, 0.5);
      z-index: 2;
    }
  }

  .buttons-section {
    display: flex;
    align-items: center;
  }

  .icon {
    font-size: 22px;
    cursor: pointer;
  }

  .edit-icon {
    padding: 5px 15px;
  }

  .close-icon {
    padding: 5px 15px;
    border-left: 1px solid #ddd;
  }

  .download-icon {
    margin: 5px 15px;
  }

  .save-close-btn {
    border: 1px solid $primary;
    border-radius: 3px;
    background-color: transparent;
    color: $primary;

    &:hover,
    &:focus {
      background-color: $primary;
      color: white;
    }
  }

  @media (max-width: 40em) {
    .close-icon {
      display: none;
    }

    .save-close-btn {
      display: none;
    }
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.svr-footer,
.svr-content {
  margin: auto;
  width: 100%;
}

.svr-content {
  height: 100%;
  display: flex;
  border-top: 1px solid #ddd;
  flex: 1;
  overflow: auto;

  .farm-bar {
    max-width: 315px;
    flex: 1 0 315px;
    overflow-x: auto;
  }

  .content-wrapper {
    height: 100%;
    width: 100%;
    overflow: auto;
    border-left: 1px solid #ddd;

    .content {
      padding: 20px 30px;
      width: 100%;
    }
  }
}

.svr-footer {
  z-index: 1;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #1e263e;

  .stepper {
    display: none;
  }

  .category-info {
    display: none;
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .back {
    border: 1px solid $primary;
    background-color: transparent;
    color: $primary;

    &:hover,
    &:focus {
      background-color: $primary;
      color: white;

      &:disabled {
        color: $primary;
        background-color: transparent;
      }
    }
  }

  .btn {
    max-width: 110px;
    flex: 1 0 120px;
    padding: 5px 15px;
    height: 35px;
  }
}

.general-section {
  padding-left: 20px;
  margin-top: 45px;
}

.section-title {
  font-weight: 600;
  font-size: 18px;
}

// TABLE

.wrapper {
  @media (max-width: 64em) {
    .farm-bar {
      max-width: 215px;
      height: auto;
    }
  }
}

// MOBILE

.wrapper {
  @media (max-width: 40em) {
    .svr-content {
      flex-wrap: wrap;

      .content {
        padding: 0;
        max-width: 100%;
      }

      .farm-bar {
        flex: 1;
        max-width: 100%;
        height: auto;
        overflow-x: visible;
      }

      .content-wrapper {
        overflow: visible;
      }
    }

    .stepper {
      display: none;
    }

    .general-section {
      padding-right: 20px;
    }

    .svr-footer {
      position: relative;
      box-shadow: 0 1px 11px rgba(0, 0, 0, 0.2);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 100px;
      background-color: white;

      .progress-footer {
        display: none;
      }

      .stepper {
        display: flex;
      }

      .actions {
        align-self: flex-end;
        padding-bottom: 5px;
      }

      .category-info {
        width: 100%;
        text-align: center;
        display: block;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
        white-space: nowrap;
      }

      .btn {
        max-width: 90px;
        padding: 5px 10px;
        height: 40px;
        border-radius: 22px;
      }

      .back {
        &:disabled {
          background-color: transparent;
          color: #ddd;
          border-color: #ddd;
        }

        &:focus {
          background-color: transparent;
          color: $primary;
          border-color: $primary;
        }
      }

      .back {
        &:disabled {
          background-color: transparent;
          color: #ddd;
          border-color: #ddd;
        }

        &:hover,
        &:focus {
          background-color: transparent;
          color: $primary;
          border-color: $primary;

          &:disabled {
            color: #ddd;
            border-color: #ddd;
            background-color: transparent;
          }
        }
      }
    }
  }
}
