@import '../../../../../styles/variables.scss';

.CheckinsMap {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  align-items: stretch;
  justify-content: flex-start;

  .preloader {
    z-index: 2;
    margin-top: 100px;
  }

  .content-wrapper {
    display: flex;
    position: relative;
    flex: 1 1 200px;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    .map-wrapper {
      flex: 1 1 200px;
      position: relative;
    }

    .form-wrapper {
      flex: 0 0 235px;
      background-color: $white;
      border-right: 1px solid $light-gray;
    }
  }

  .filter-button {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;

    .button {
      width: 100%;
      padding: 15px;
      font-size: 16px;
    }
  }

  .map-marker {
    opacity: .5;
    transition: opacity .3s linear;

    &.visible,
    &.active,
    &:hover {
      opacity: 1;
    }
  }
}
