@import "../../../styles/variables";

.dropdown-layout {
  position: absolute;
  top: 20px;
  right: 9999px;
  background-color: $white;
  z-index: 4;
  text-align: left;
  box-shadow: 0 5px 8px rgba(0, 0, 0, .2);
  transition: opacity .2s;
  border: 1px solid $light-gray;
  border-radius: 5px;
  opacity: 0;
  margin-right: 10px;

  $right: var(--triangleOffset, 25px);

  &.stack-order {
    z-index: 5;
  }

  &.isOpen {
    opacity: 1;
    min-width: 180px;

    &.isMobile {
      z-index: 110;
      right: 0;
      margin-top: 10px;

      &:before {
        content: '';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
        position: absolute;
        bottom: 100%;
        right: $right;
      }

      &.isOpenAbove {
        margin-top: 0;
        margin-bottom: 10px;

        &:before {
          content: '';
          border-top: 10px solid $white;
          border-bottom: none;
          position: absolute;
          top: 100%;
        }
      }

      @media screen and (max-width: $mobile-width-large) {
        width: calc(100% - 20px);
        right: 0;
      }

      @media screen and (max-width: $tablet-width) and (min-width: calc(#{$mobile-width-large} + 0.5px)) {
        right: $right;

        &:before {
          right: 10px;
        }
      }

      @media screen and (max-height: $mobile-width-large * 2) and (min-height: calc(#{$mobile-width-large} + 0.5px)) {
        .list-ul {
          max-height: 175px;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
        }
      }

      @media screen and (max-height: $mobile-width-large) {
        .list-ul {
          max-height: 125px;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
        }
      }
    }
  }

  .list-ul {

    .list-item-li:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }

    .list-item-li {

      &:hover {
        background-color: #f3f3f3;
      }

      .item-icon {
        font-size: 22px;
        margin: auto 10px;
        vertical-align: middle;
        width: 25px;
      }

      a {
        display: block;
        width: 100%;
        padding: 10px;
        font-weight: 400;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.description-item {
          max-width: 280px;
          font-size: 14px;
          white-space: inherit;
          overflow: inherit;
          text-overflow: inherit;
          cursor: inherit;
          background-color: $white;
        }

        &:hover, &:focus {
          color: inherit;
        }
      }
    }
  }
}
