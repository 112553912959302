.dropdown-list {
  position: absolute;
  z-index: 1;
  top: 100%;
  margin-top: 10px;
  left: 0;
  transition: opacity .3s ease;
  border-radius: 5px;
  opacity: 0;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0 -1px 12px 0;
  visibility: hidden;
  background-color: white;
  overflow-y: auto;
  max-height: 320px;

  &.opened {
    visibility: visible;
    opacity: 1;
  }

  .item-search {
    min-height: 40px;
    border: none;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid var(--color-gray-light);
    font-weight: normal;
  }

  .item-search-box {
    :global {
      .SearchBox input[type='search'] {
        font-size: 13px;
      }
    }
  }

  .list-item {
    min-height: 40px;
    background-color: white;
    display: flex;
    align-items: center;
    transition: background-color .3s, color .3s ease;
    border-bottom: 1px solid var(--color-gray-light);
    color: var(--color-black);
    font-weight: 400;
    white-space: nowrap;

    .option-label {
      flex: 1;
      padding: 0 10px;
    }

    &:hover {
      background-color: var(--color-orange-light);
      color: var(--color-primary);
    }

    &.active {
      background-color: var(--color-primary);
      color: white;
    }

    &.not-available {
      background-color: var(--color-orange-light);
      color: var(--color-gray-dark);
      cursor: not-allowed;
    }

    &:last-child {
      border-bottom: none;
    }

    .option-icon {
      padding-left: 10px;
    }

    .selected-icon {
      padding-right: 10px;
    }

    .option-icon,
    .selected-icon {
      font-size: 15px;
    }
  }
}
