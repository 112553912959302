@import '../../../styles/variables';

.profile-messaging {
  background-color: $white;
  font-size: 14px;

  .link {
    color: $primary;
    text-decoration: underline;
  }

  .email-preference {
    display: flex;
    flex-direction: column;

    .email-manage {
      .checkbox-label {
        margin-left: 40px;
        font-size: 14px;
      }
    }
  }
}
