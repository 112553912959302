@import "../../../styles/variables";

.browser-instruction-modal {
  width: 600px;
  max-width: 600px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  .content-title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .instructions-line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 0;
    font-size: 16px;

    .icon-wrapper {
      padding: 0 5px;
      font-size: 18px;

      .lock-icon {
        color: $icon-green;
      }
    }

    .number {
      text-align: center;
      height: 40px;
      width: 40px;
      line-height: 40px;
      align-items: center;
      margin-right: 10px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 50%;
      background-color: $light-gray;
    }
  }
}
