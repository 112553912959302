.question-line {
  border-top: 1px solid #ddd;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fbfbfb;

  &.failed {
    background-color: var(--color-orange-light);
  }
}

.comments {
  border-top: 1px solid #ddd;
  padding: 0 15px;

  .show-more-comments {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    margin: 0 -15px;
    padding: 5px 10px;
    font-size: 12px;
    border-top: 1px solid var(--color-gray-light);
    background: var(--color-white-bg);
  }

  .toggle-comments {
    color: var(--color-primary);
    cursor: pointer;
  }
}
