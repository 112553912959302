@import "styles/variables";

.Popover {
  opacity: 1 !important;

  &.rc-tooltip-placement-topLeft,
  &.rc-tooltip-placement-bottomLeft {
    .rc-tooltip-arrow {
      left: 36px;
    }
  }

  &.rc-tooltip-placement-topLeft,
  &.rc-tooltip-placement-top {
    .rc-tooltip-arrow {
      border-top-color: white;
      border-width: 12px 10px 0;
      bottom: -3px;

      &::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background: white;
        transform: rotate(45deg);
        top: -20px;
        left: -8px;
        z-index: -1;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
      }
    }
  }

  &.rc-tooltip-placement-bottomLeft,
  &.rc-tooltip-placement-bottom {
    .rc-tooltip-arrow {
      top: -3px;
      border-bottom-color: white;
      border-width: 0 10px 12px;

      &::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background: white;
        transform: rotate(45deg);
        top: 5px;
        left: -8px;
        z-index: -1;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
      }
    }
  }

  .rc-tooltip-content {
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 15px;
    font-size: 14px;
    width: 375px;
    overflow: hidden;

    @media screen and (max-width: $mobile-width-large) {
      width: 300px;
    }

    .rc-tooltip-arrow {
      border-bottom-color: white;
      border-top-color: white;
    }

    .rc-tooltip-inner {
      background-color: white;
      color: $black;
      padding: 13px;
    }
  }

  &.rc-tooltip-placement-right {
    .rc-tooltip-arrow {
      border-bottom-color: transparent;
      border-top-color: transparent;
      border-right-color: white;

      &::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background: white;
        transform: rotate(45deg);
        top: -8px;
        left: 3px;
        z-index: -1;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
      }
    }
  }
}
