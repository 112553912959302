@import "../../../../../styles/variables";

.search-box {
  padding: 10px;
}

.farm-type-checkbox {
  :global(.checkbox-label) {
    display: inline-flex;
  }
}

.farm-type-label {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
}

.mark {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-left: 10px;

  &.n-mark {
    background-color: $brand-blue;
  }

  &.wf-mark {
    background-color: $dark-orange;
  }

  &.f-mark {
    background-color: $aquamarine;
  }

  &.ff-mark {
    background-color: $brand-purple;
  }

  &.su-mark {
    background-color: $orange;
  }

  &.m-mark {
    background-color: $dark-gray;
  }
}
