@import '../../../styles/variables';

.greeting-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  .congrat-icon-message {
    width: 54px;
  }
}

.header-text {
  font-size: 34px;
  color: $brand-blue;
  margin-top: 15px;
  font-weight: 300;

  span {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  @media screen and (max-width: 39.9375em) {
    font-size: 32px;
  }
}

.text {
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
  padding: 0 15px;

  span {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  @media screen and (max-width: 39.9375em) {
    font-size: 16px;
  }
}

.congrats-icon-box {
  width: 75px;

  @media screen and (max-width: 39.9375em) {
    width: 60px;
  }
}

.count-boxes-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
