.FarmFeedRowComments {
  border-top: 1px solid var(--color-gray-light);
  background: var(--color-white-bg);
  padding: 0 10px;

  .show-more-comments {
    margin: 0 -10px;
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid var(--color-gray-light);
    background: var(--color-white-bg);

    .comments-collapser {
      color: var(--color-primary);
      cursor: pointer;
    }
  }
}
