.draft-report-card {
  flex-direction: column;
  margin-left: 20px;
  position: relative;
  max-width: 195px;
  flex: 1 0 100%;
  min-height: 100%;
  box-shadow: 0 0 0 1px #ddd inset;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-size: cover;
  background-position: right;
  background-image: url("../../../../../../public/images/svr-preview.png");
  overflow: hidden;

  .bg {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(#1e263e, 0.5);
  }

  &.is-flagged {
    box-shadow: 0 0 0 1px var(--color-orange-brand) inset;

    .top-content::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 15px 0 0;
      border-color: var(--color-orange-brand) transparent transparent transparent;
    }
  }

  @media screen and (max-width: 40em) {
    max-width: 165px;
  }

  .top-content {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;

    .delete-btn {
      font-weight: 600;
      font-size: 18px;
      top: 100%;
      left: 100%;
      z-index: 1;
      cursor: pointer;
      color: var(--color-gray-light);

      &:hover {
        color: white;
      }
    }
  }

  .continue-button {
    z-index: 1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: auto;
    height: 50px;
    width: 100%;
  }

  .status-badge {
    margin-left: auto;
    margin-right: 15px;
    z-index: 1;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    border: 1px solid white;
    border-radius: 3px;
    padding: 5px;
  }
}
