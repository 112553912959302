@import "../../../../styles/variables";

.user-info-block {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .user-name {
    font-size: 12px;
    font-weight: 600;
  }

  .created-at {
    font-size: 12px;
    color: rgb(140, 140, 140);
  }
}

.diagnosis-line {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: rgb(250, 250, 250);
  border: 1px solid $light-gray;
  border-radius: 5px;

  .diagnosis-name {
    flex: 1;
    color: $primary;
  }

  .tag-label-icon {
    font-size: 20px;
    color: $primary;
    padding-right: 10px;
  }

  .del-tag-icon:hover,
  .edit-tag-icon:hover {
    cursor: pointer;
  }

  .edit-tag-icon {
    font-size: 17px;
  }

  .del-tag-icon {
    margin: 0 10px;
    font-size: 19px;
  }
}
