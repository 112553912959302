.DropdownButton {

  .left {
    margin-right: 1px;
    border-radius: 3px 0 0 3px;
  }

  .right {
    border-radius: 0 3px 3px 0;

    i {
      font-weight: 600;
    }
  }

  &.flex {
    display: flex;
    width: 100%;

    .button:first-child {
      flex-grow: 1;
      border-right: 1px solid transparent;
    }
  }

  &.flex-tablet {
    @media screen and (max-width: 63.9375em) {
      display: flex;
      flex: 1 1 100%;
      width: 100%;

      .button:first-child {
        flex-grow: 1;
        border-right: 1px solid transparent;
      }
    }
  }
}
