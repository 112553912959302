@import '../../../../styles/variables';

.activity-actions-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 0 10px;

  .activity-action {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    border-radius: 5px;
    color: $dark-gray;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      background-color: $almost-white;
      cursor: pointer;
    }
    @media screen and (max-width: 35em) {
      .action-icon {
        margin: 0;
      }

      span {
        display: none;
      }
    }
  }

  .action-icon {
    font-size: 22px;
    margin-right: 10px;
  }

  .flagged {
    color: #FEB12B;
  }
}
