@import 'styles/variables';

.source-badge {
  font-size: 14px;
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border: 1px solid $alto;
  border-radius: 5px;
}

.diagnoses-block {
  font-size: 14px;

  .wrapper {
    border-bottom: 1px dashed $alto;

    &:last-child {
      border-bottom: none;
    }
  }

  .diagnose-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
    }

    .value {
      font-size: 14px;
      color: $dark-gray;
      font-weight: 600;

      &.active {
        color: $brand-red;
      }
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $alto;
}
