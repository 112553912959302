@import '../../../../styles/variables';

.group-header {
  display: flex;
  align-items: center;
  min-height: 70px;
  background-color: $white;
  border-bottom: 1px solid rgb(221, 221, 221);
  padding: 10px 15px;

  @media screen and (max-width: 39.9375em) {
    flex-direction: column;

    .name-block {
      margin-bottom: 15px;
    }
  }
}

.name-block {
  flex: 1;
}

.actions-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.name {
  font-size: 20px;
  line-height: 1;
  display: flex;
  align-items: center;

  .badge {
    background-color: $almost-white;
    font-size: 12px;
    color: $dark-gray;
    border-radius: 3px;
    padding: 3px 4px;
    font-weight: 600;
    margin-left: 5px;
  }
}

.add-custom-group-btn {
  color: $primary;
  cursor: pointer;
  font-size: 12px;
}

.finish-later-btn {
  color: $primary;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
}

.field-section {
  padding: 20px;
  border-bottom: 1px solid $light-gray;

  &:last-child {
    border-bottom: none;

    .disabled-overlay {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  &:first-child .disabled-overlay {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
}
