@import '../../../../styles/variables';

.asset-item-box {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 200px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px;
  box-shadow: none;
  transition: box-shadow .4s;
  position: relative;
  background-color: $white;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.3);
  }

  &.isFocused {
    border: 1px solid $primary;

    &:hover {
      box-shadow: none;
    }

    .asset-info-block {
      background-color: $light-orange;
    }
  }

  &.not-viewed {

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 15px solid $primary;
      border-right: 15px solid transparent;
      position: absolute;
      z-index: 1;
    }
  }

  @media screen and (max-width: $mobile-width) {
    width: 165px;
    height: 165px;
  }

  @media screen and (max-width: 370px) {
    width: 140px;
    height: 140px;
  }
}

.asset-thumbnail {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.empty {
    background-color: #ddd;
  }

  &.uploading {
    opacity: .3;
  }

  .img-thumb {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

.upload-progress {
  background-color: $medium-gray;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 50px;
  z-index: 1;

  .progress-value {
    background-color: $primary;
    height: 5px;
    width: 0;
    transition: width .4s ease;
  }
}

.asset-info-block {
  flex: 0 0 50px;
  display: flex;
  align-items: center;
  padding: 0 12px;

  .empty-icon {
    width: 25px;
    height: 25px;
    background-color: #ddd;
    border-radius: 2px;
  }
}

.name {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $primary;
  margin-left: 10px;
  flex: 1;
}

.dots-icon {
  font-size: 19px;
  color: $dark-gray;
}
