@import "styles/variables";

.step-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .text-area {
    resize: none;
    width: 100%;
    min-height: 140px;
    border: 1px solid $alto;
    border-radius: 5px;
    font-family: "Open Sans";
    font-size: 14px;
    padding: 10px;
    opacity: 0.8;

    &:focus {
      border-color: $primary;
      box-shadow: 0 0 8px rgba(255, 94, 49, 0.5);
      outline: none;
    }
  }

  .title {
    padding-bottom: 40px;
    font-size: 20px;
    font-weight: 600;
  }

  .select-wrapper {
    width: 100%;
  }

  .button-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px 30px;

    .button {
      border-radius: 50px;
      font-size: 16px;
      position: relative;
      width: 130px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: unset;
      padding-left: 15px;
      font-weight: 600;

      .icon {
        position: absolute;
        left: 15px;
      }

      &.btn-back {
        .icon {
          transform: rotate(180deg);
        }
      }

      &.btn-next {
        padding-left: unset;
        padding-right: 15px;

        .icon {
          left: unset;
          right: 15px;
        }
      }
    }
  }
}

.description {
  text-align: center;
  font-size: 20px;
}

.body-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 70px 50px;
  width: 100%;
}

.final-step {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ok-btn {
    height: 70px;
    font-size: 20px;
    border-top: 1px solid $alto;
    background-color: $background;
    border-radius: 0 0 5px 5px;
  }

  .icon-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;

    .truck {
      font-size: 100px;
      opacity: 0.3;
    }

    .overflow-icon {
      font-size: 40px;
      position: absolute;
      color: $brand-blue;
    }

    .overflow-icon.decline {
      color: $brand-red;
    }
  }
}
