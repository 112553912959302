@import "../../../styles/variables";

.ReachEndPlaceholder {
  flex: 1;
  display: flex;
  flex-direction: column;

  .pattern {
    background: url('./triangle.svg') repeat-x;
    background-size: 20px;
    height: 10px;
  }

  .body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark-gray;
    padding: 25px;
    background-color: #EBEBEB;
    flex: 1;

    .fa {
      font-size: 24px;
      font-weight: bold;
      padding: 5px;
    }
  }
}

