@import '../../../../styles/variables';

.section-collapse {
  padding: 0;
  border: 1px solid $light-gray;
  border-radius: 5px;
  margin: 15px auto;
  overflow: hidden;

  &.highlighted {
    background: $light-yellow;
  }

  :global(.FarmFeedRowSegment) {
    margin: 0;

    :global(.segment) {
      border: none;
    }
  }

  .no-bottom-border {
    border-bottom: none;
  }
}
