.PaginationDots {
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .page-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 5px;
    background-color: rgba(white, .85);
    transform: scale(1);
    transition: all .3s;
    transition-delay: .1s;

    &.active {
      transform: scale(1.4);
      background-color: white;
    }

    &.small {
      transform: scale(.75);
    }
  }
}
