@import "../../../styles/variables";

.field-wrapper {

  .label-box {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 2px;

    &.horizontal {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 10px 0;

      @media screen and (max-width: 40em) {
        justify-content: flex-start;
        margin: 0 0 10px;
      }
    }
  }

  .required-field-icon {
    color: $brand-orange;
    margin-right: 5px;
    font-size: 12px;
  }
}
