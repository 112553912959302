@import "../../../styles/variables";

.zoom-panel {
  position: absolute;
  display: block;
  height: 50px;
  top: auto;
  bottom: 30px;
  margin: 0 auto;

  .zoom-panel-buttons {
    width: 150px;
    height: 50px;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
    background-color: rgb(30, 38, 62);

    .btn-zoom {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      color: $white;
      font-size: 21px;
      cursor: pointer;

      &.active {
        background: #8598a6;
      }
    }
  }
}
