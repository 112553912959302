@import 'styles/variables';

.react-autosuggest__container {
  position: relative;

  .react-autosuggest__suggestions-container--open {
    position: absolute;
    width: 100%;
    top: 45px;
    left: 0;
    border: 1px solid $medium-gray;
    border-radius: 5px;
    background: $background;
    max-height: 220px;
    overflow-y: auto;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    z-index: 20;
  }
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0;
  border-top: 1px solid $light-gray;
  font-size: 14px;

  .suggestion-item {
    padding: 10px;
  }

  .with-source {
    background: $light-blue;
  }

  .source-assigned-label {
    margin-left: 5px;
    font-size: 12px;
  }

  &:hover {
    background: whitesmoke;
  }

  &--first {
    border-top: 0;
  }
}

.react-autosuggest__section-container {
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
  margin-top: 10px;
  background-color: $white;

  &:first-child {
    border-top: 0;
    margin-top: 0;
  }

  &:last-child {
    border-top: none;
    border-bottom: none;
    margin-top: 0;

    .react-autosuggest__suggestion {
      background-color: $light-blue;
      color: $secondary;
    }
  }
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-container {
  .react-autosuggest__suggestion--highlighted {
    background: whitesmoke;
  }
}
