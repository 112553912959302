.extended-checkup-box {

  .checkup-collapse {
    padding: 7px 10px;
  }

  .dc-expanded-button {
    margin: -1px;
    flex: 1 auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .general-note {
    margin: 15px 0;
    padding: 0;
    border: 1px solid var(--color-gray-light);
    border-radius: 3px;
    background: #fff;
  }
}

.contributors-block {
  position: relative;

  .contributors {
    color: var(--color-primary);
  }
}

.magic-loop-img {
  width: 30px;
  margin-right: 5px;
}
