@import '../../../styles/variables';

.toggle-switcher-row {

  .toggle-switcher-column {
    border-top: 1px solid $light-gray;
    padding: 20px 0;
    min-height: 120px;
  }

  &.first {
    margin-top: 20px;
  }

  &.last {
    margin-bottom: 20px;

    .toggle-switcher-column {
      border-bottom: 1px solid $light-gray;
    }
  }
}
