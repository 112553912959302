@import '../../../styles/variables';

.StatisticSection {
  display: flex;
  align-items: stretch;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;

  & > .section-item {
    flex: 1;
    background-color: $white;
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 130px;

    &:not(:last-child) {
      border-right: 1px solid #ddd;
    }
  }

  @media screen and (max-width: $tablet-width) {
    flex-direction: column;

    & > .section-item {
      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
        border-right: none;
      }
    }
  }

  .caption-text {
    text-transform: uppercase;
    color: $medium-gray;
  }

  .average-amount {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .item-value {
      font-size: 20px;
    }
  }

  &.isMobile {

    .average-amount {
      display: inline-block;

      .grow-span {
        display: flex;
        justify-content: center;
      }
    }
  }

  .not-available {
    background-color: $background;
    color: $medium-gray;
  }
}
