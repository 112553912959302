@import "styles/variables";

.default-centering-section {
  position: relative;
  width: 600px;

  &.disabled {
    &::after {
      cursor: not-allowed;
      top: 0;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(white, 0.5);
    }
  }

  .map-marker {
    font-size: 22px;
  }

  .map-block {
    flex: 1 1 100%;
    margin: 15px 15px 15px 0;
    z-index: 1;
    height: 300px;
    width: 100%;
  }
}
