@import "../../../styles/variables";

.ImageBox {
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid $light-gray;
  border-radius: 5px;
  margin: 10px;
  box-shadow: none;
  transition: box-shadow .4s;
  position: relative;

  .DiagnosisBadge {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  &:hover {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  }

  .ImageBox__preview {
    height: 225px;
    background-color: #d8d8d8;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    position: relative;

    .bg-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      cursor: pointer;
    }

    .image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      z-index: 1;
      background-color: rgba(black, .5);
      opacity: 0;
      transition: opacity .5s ease;
    }

    &:hover {

      .image-overlay {
        opacity: .5;
      }

      .navigation-arrow {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .ImageBox__description {
    height: 100px;
    padding: 10px;
    background-color: $white;
    border-top: 1px solid $light-gray;
    border-radius: 0 0 5px 5px;
    font-size: 14px;
    display: flex;
    flex-direction: column;

    .description {
      flex: 1;
    }

    .statistic {
      flex: 0 0 30px;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $light-gray;
      align-items: center;

      .stat-item {
        margin-top: 5px;

        i {
          font-size: 16px;
          color: $dark-gray;
          line-height: 14px;
        }
      }
    }

    .farm-name {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 3px;
    }

    .type-badge {
      margin-left: 3px;
      border-radius: 4px;
      background-color: $medium-gray;
      color: $white;
      padding: 1px 3px;
      text-transform: capitalize;
      font-size: 10px;
    }

    .group-name {
      font-size: 12px;
      color: $almost-black;
    }

    .time-ago {
      font-size: 12px;
      color: $light-black;
    }
  }

  .author-avatar {
    position: absolute;
    top: 10px;
    left: 10px;
    border: 2px solid rgba(white, .9);
    z-index: 2;
    border-radius: 50%;
  }

  .navigation-arrow {
    position: absolute;
    top: calc(50% - 10px);
    font-size: 20px;
    color: $white;
    cursor: pointer;
    z-index: 2;
    opacity: 0;
    transition: .5s all;
    transform: scale(0.5);

    &.left {
      left: 10px;
    }

    &.right {
      right: 10px;
    }
  }
}
