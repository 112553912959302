@import '../../../styles/variables';

.NetworkNotificationBox {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  visibility: hidden;
  height: 0;
  background-color: $brand-blue;
  z-index: 4;
  font-size: 14px;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s height, .3s visibility, .3s top, .3s background-color;
  will-change: height;

  &.visible {
    visibility: visible;
    height: $header-notification-height;

    &.offline {
      background-color: $dark-gray;
    }
  }

  .redo-icon {
    font-size: 15px;
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  .progress-bar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .progress {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: linear-gradient(to right, $brand-blue, $lochmara, $aquamarine);
    }
  }

  @media (max-width: $mobile-width) {
    top: 0;

    &.stickyMobileView {
      top: -$main-header-height;
    }
  }
}
