@import "styles/variables";

.empty-container {
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  background-color: $background;
  border: 2px dashed #ddd;
  font-size: 13px;
  border-radius: 8px;
  color: $dark-gray;
}
