.my-schedule-page {
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;

  .filters {
    padding: 0 15px;
    z-index: 1;
    position: relative;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .schedule-panel {
    padding: 0 10px;
  }

  &.has-mobile-filters {
    .schedule-panel {
      padding-top: 15px;
    }
  }

  @media screen and (min-width: 40em) {
    .schedule-panel {
      padding: 0 15px;
    }
  }

  @media screen and (max-width: 63.9375em) {
    padding-top: 15px;

    &.has-mobile-filters {
      padding-top: 0;
    }
  }
}
