@import "styles/variables";

.CSVImportPage {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow-y: scroll;

  .bodyCSV {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 100%;
    margin-bottom: 60px;

    &.secondStep {

      .wrapperCardsCSV {
        flex-direction: column;
        width: 100%;
        padding: 0 2rem;
        align-items: center;
        flex: 100%;
        overflow-y: scroll;

        .MatchGroupColumns {
          min-height: 350px;
          width: 100%;
          overflow: hidden;

          .columns-count-status {
            padding-top: 0;
          }

          .content-block-items {
            display: flex;
            overflow: auto;
            padding-bottom: 16px;
          }
        }

      }
    }

    &.resultsStep {

      .import-result-summary {
        border: none
      }

      .wrapperCardsCSV {
        width: 100%;
        justify-content: center;

        .wrapperResultsCsvImports {
          width: 100%;
        }

        .Result {
          width: 100%;
          border: solid #dddddd 1px;
          margin: 0 20px;
          border-radius: 5px;
          height: max-content;


          .DropdownButton {
            button {
              margin-right: 0;
              border: none;
              background-image: linear-gradient(0deg, #ff5e3a 3%, #f78445 100%);
              span,i{
                color: white;
              }

            }

          }
        }
      }
    }

    .wrapperCardsCSV {
      display: flex;
      flex-direction: row;

      height: 100%;

      .uploadContainer {
        flex-direction: column;

        .updateExisting {
          display: flex;
          padding: 20px 0 25px 0;

          > :nth-child(3) {
            padding-left: 5px;
          }

          .icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid #a3a3a3;
            margin-right: 10px;
          }

          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }

          span {
            font-size: 16px;
            color: #303030;
            text-decoration: none solid rgb(48, 48, 48);
            line-height: 22px;

          }

          .updateLink {
            span {
              color: $primary;
              font-size: 16px;
              text-decoration: none solid rgb(255, 94, 49);
              line-height: 22px;
              margin-left: 10px;
            }
          }
        }
      }

    }

    .wrapperInfoBodyCSV {
      min-height: 100px;
      min-width: 589px;
      max-width: 905px;
      padding: 61px 0 54px 0;
      text-align: center;
      font-size: 14px;

      .infoBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 905px;
      }

      .step-title {
        font-size: 28px;
        padding-bottom: 28px;
        line-height: 1;
      }

      .step-description {
        line-height: 22px;
      }
    }
  }

  .csv-footer-content {
    font-size: 14px;

    .skip-unmatched-cards {
      cursor: pointer;
      color: $primary;
    }

    .success-text {
      color: $brand-blue;
      font-weight: 600;
    }
  }
}
