@import "../../../../styles/variables";

.MonthlyPicker {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 4;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 18px;
  width: 340px;
  background-color: $white;
  padding: 20px;

  .mobile-nav-container {
    display: flex;
    box-shadow: none;
    border-top: 1px solid $light-gray;
    background-color: $white;
    height: 45px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .nav-button {
      display: inline-block;
      position: relative;
      height: 100%;
      width: 50%;
      padding: 5px;
      text-align: center;

      &:first-child {
        border-right: 1px solid $light-gray;
      }

      svg {
        fill: #565a5c;
        width: 35px;
        height: 35px;
      }
    }
  }

  .nav-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-button {
      padding: 8px 8px 3px;
      border-radius: 3px;
      cursor: pointer;
      border: 1px solid $light-gray;
      background-color: $white;
      color: #757575;

      svg {
        width: 19px;
        fill: #82888a;
      }

      &:hover {
        background-color: $primary;
        border-color: $primary;
        color: $white;

        svg {
          fill: $white;
        }
      }
    }

    .year {
      color: #565a5c;
      font-size: 18px;
      font-weight: 600;
      width: 100%;
      text-align: center;
    }
  }

  .months-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    .month {
      min-width: 60px;
      min-height: 60px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px;
      border: 1px solid $light-gray;
      color: rgb(48, 48, 48);
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;

      &.selected {
        background: $primary;
        color: $white;
      }

      &:not(.selected) {
        &:hover {
          background: #ffe8df;
        }
      }

      &.blocked {
        color: $medium-gray;
        background: $background;
        pointer-events: none;
      }
    }
  }
}
