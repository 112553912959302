.mediaViewer {
  position: absolute;
  top: 0;
  left: 240px;
  right: 0;
  bottom: 0;
  z-index: 5;
  overflow: hidden;
  background-color: var(--color-white-almost);
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease, background-color .3s;

  .header-btn__dropdown {
    cursor: pointer;
    font-size: 20px;
    color: var(--color-gray-dark);
    margin: 5px;
    transition: color .2s;
    padding: 0;
    z-index: 4;

    position: absolute;
    top: 0;
    right: 60px;

    .icon-dropdown {
      padding: 13px 15px;
    }

    @media (min-width: 812px) {
      &:hover {
        color: var(--color-primary)
      }
    }
  }

  &.is-sidebar-collapsed {
    left: 60px;
  }

  &.is-open {
    opacity: 1;
    pointer-events: all;
  }

  &.is-diagnose-panel-open {
    background-color: rgb(20, 25, 44);
  }

  &.is-mobile {
    background-color: var(--color-black);
    left: 0;
  }

  &.is-tablet {
    background-color: var(--color-black);
    left: 60px;
  }

  &.is-fullscreen,
  &.is-fullscreen.is-sidebar-collapsed,
  &.is-fullscreen.is-tablet {
    left: 0;
  }

  @media screen and (max-width: 63.9375em) {
    left: 0 !important;
    z-index: 32;
  }

  .viewer-layout {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .media-viewer-body {
    $header-height: 56px;
    // connect MediaAttachments component to this vars
    $attachments-block-height: 109px;
    $attachments-block-height-collapsed: 34px;

    display: flex;
    transition: height .3s;
    height: calc(100% - #{$header-height});

    &.is-attachments-opened {
      height: calc(100% - #{$header-height} - #{$attachments-block-height});
    }

    &.is-attachments-collapsed {
      height: calc(100% - #{$header-height} - #{$attachments-block-height-collapsed});
    }
  }

  .diagnose-sidebar-wrapper {
    position: relative;
    height: 100%;
    flex: 1;
    display: flex;
    background-color: #fff;
    width: 0;
    min-width: 0;
    transition: width .3s, min-width .3s;

    &.opened {
      width: 350px;
      min-width: 350px;
    }

    .DiagnoseSideBar {
      width: 100%;
    }
  }

  .backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0);
    pointer-events: none;
    transition: background-color .3s;

    &.active {
      background: rgba(0, 0, 0, 0.5);
      pointer-events: all;
    }
  }

  .media-viewer-footer {
    height: 50px;
    width: 100%;
    z-index: 0;
    position: fixed;
    bottom: env(safe-area-inset-bottom);

    .footer-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 0 20px;

      .footer-btn {
        font-size: 24px;
        color: white;
        cursor: pointer;
        padding: 5px;
        margin-left: 5px;
      }
    }
  }
}

.media-viewer-confirm-modal {
  &.modal {
    width: 320px;

    .close-button {
      display: none;
    }

    .modal-body {
      text-align: center;
    }

    .modal-footer {
      display: flex;
      padding: 0;

      .button {
        flex: 1;
        margin: 0;
        border: none;
        border-radius: 0;
      }
    }
  }
}

