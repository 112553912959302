@import "../../../../../styles/variables";

.DatePickerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border: 2px solid $light-gray;
  border-radius: 3px;
  transition: border-color 0.25s ease-in-out;

  .report-period-box {
    display: flex;
    flex-direction: column;
    border-right: 2px solid $light-gray;
    min-width: 140px;
    padding: 5px 15px 5px 10px;
    transition: border-color 0.25s ease-in-out;
    cursor: pointer;
  }

  .report-period-label {
    font-size: 12px;
  }

  .report-period-value {
    font-weight: 600;
  }

  .calendar-icon-wrapper {
    display: flex;
    align-self: stretch;
    align-items: center;
    background-color: $white-smoke;
    color: $primary;
    font-size: 20px;
    padding: 0 10px;
    transition: background-color 0.25s ease-in-out;
    cursor: pointer;
  }

  .DayPicker {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 4;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 5px 18px;

    .DayPickerNavigation {

      &_button {
        padding: 8px;

        &:hover {
          background-color: $primary;
          border-color: $primary;
          color: $white;

          svg {
            fill: $white;
          }
        }
      }
    }

    .CalendarMonth {

      .js-CalendarMonth__grid {
        td:not(.CalendarDay) {
          border-bottom: none;
        }
      }

      .CalendarDay {
        border: 1px solid $light-gray;
        padding: 0;
        box-sizing: border-box;
        color: #565a5c;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;

        &:hover {
          background: rgb(255, 232, 223);
        }

        &__hovered_offset {
          background: rgb(255, 232, 223);
        }

        &__selected_span,
        &__selected {
          background: $primary;
          border: 1px double $primary;
          color: $white;
          z-index: 2;

          &:hover {
            background: $primary;
          }

          &.CalendarDay__hovered_offset {
            background: $primary;
          }
        }

        &__blocked_out_of_range {
          color: $medium-gray;
          background: $background;
          cursor: default;
          border: 1px solid $light-gray;

          &:hover {
            background: $background;
          }

          &.CalendarDay__hovered_offset {
            background: $background;
          }

          .CalendarDay__button {
            cursor: default;
          }
        }
      }
    }
  }

  &.opened {
    border-color: $primary;

    .report-period-box {
      border-color: $primary;
    }

    .calendar-icon-wrapper {
      background-color: rgb(255, 232, 223);
    }
  }

  @media screen and (max-width: 63.9375em) {
    width: 100%;

    .report-period-box {
      width: 100%;
    }
  }
}
