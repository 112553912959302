.mobile-user-filter {
  position: sticky;
  top: 0;
  transform: translateZ(0);
  background-color: white;
  width: 100%;
  z-index: 3;
  border-bottom: 1px solid var(--color-gray-light);
  padding: 0 10px;

  @media screen and (min-width: 40em) {
    padding: 0 15px;
  }

  .filters-count-block {
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    background-color: var(--color-primary);
    color: white;
    margin: 10px 0;
    padding: 8px 15px;
    font-size: 14px;
    display: inline-block;
  }
}
