@import '../../../styles/variables';

.shape {
  background-color: $light-gray;
  position: relative;
  overflow: hidden;

  &.hasShine:after {
    animation: shine 2s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: '';
    position: absolute;
    top: -50%;
    left: -150%;
    width: 15px;
    height: 200%;
    opacity: 1;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.25) 33%,
        rgba(255, 255, 255, 0.5) 66%,
        rgba(255, 255, 255, 0.13) 100%
    );
  }

  &.darker {
    background-color: $almost-black;
  }

  &.centered {
    margin: 0 auto;
  }
}

@keyframes shine {
  10% {
    opacity: 1;
    left: 100%;
    transition-property: left, opacity;
    transition-duration: .7s, .15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    left: 150%;
    transition-property: left, opacity;
  }
}
