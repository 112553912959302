@import '../../../styles/variables';

.ModalSidebar {
  display:block;
  position: relative;
  max-width: 100%;
  height: 100vh;
  background-color: $white;
  width: $right-sidebar-width;
  pointer-events: all;
  right: -100%;
}
