.expired-token-layout {
  display: flex;
  align-items: center;
  flex-direction: column;

  .clock-icon {
    font-size: 86px;
    color: var(--color-gray-dark);
    margin-bottom: 25px;
    margin-top: 30px;
  }

  .try-again-link {
    margin-top: 30px;
  }
}

.set-password-layout {
  h1.title {
    font-size: 20px;
  }
}
