@import "../../../styles/variables";

.SymptomDropdownButton {
  flex: 0 0 300px;

  @media all and (max-width: 40em) {
    flex: 1 250px;
    margin-top: 5px;
  }
}
