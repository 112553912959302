@import "../../../styles/variables";

.MediaViewerViewBox {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 240px;
  transition: margin-left .3s;

  @media (max-width: 1023px) {
    margin-left: 0;
  }

  &.is-collapsed {
    margin-left: 60px;
  }

  &.is-fullscreen,
  &.is-fullscreen.is-collapsed {
    margin-left: 0;
  }

  &.is-mobile {
    .view-box__main .view-box-container .view-box-item .bg-image {
      transition: background-size .2s ease-in-out, width .3s ease-in-out;
    }
  }

  .view-box__main {
    width: 100%;
    height: 100%;

    &.is-video {
      padding: 0 60px;
    }

    .view-box-container {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;

      .DiagnosisBadge,
      .diagnosis-suggestion-badge {
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translateX(-50%);
      }

      .diagnosis-suggestion-badge {
        color: $nice-grey;
        font-weight: 600;
        border: none;
        width: 220px;
      }

      .view-box-item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;

        video {
          max-height: 70vh;

          @media (max-width: 375px) {
            max-height: 100%;
          }

        }

        @media (max-width: 1024px) {
          height: 80% !important;
        }
        align-self: center;
        @media (orientation: landscape) and (max-width: 1024px) {
          height: 65% !important;
          align-self: auto;
        }

        video {
          max-height: 70vh;

          @media (max-width: 375px) {
            max-height: 100%;
          }
        }

        .bg-image {
          background-repeat: no-repeat;
          background-size: 0 0;
          width: 0;
          height: 100%;
          transition: background-size .5s ease-in-out, width .5s ease-in-out;

          &.current-image {
            width: 100%;
          }
        }

        .current-scale {
          position: absolute;
          background-color: rgba(255, 255, 255, .6);
          color: $black;
          font-size: 18px;
          font-weight: 600;
          border-radius: 3px;
          line-height: 38px;
          min-width: 80px;
          text-align: center;
          pointer-events: none;
          opacity: 0;
          transform: scale(.5);
          transition: opacity .3s, transform .3s ease;

          &.visible {
            opacity: 1;
            transform: scale(1);
          }
        }
      }

      .hidden-image {
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        max-height: 100%;
        max-width: 100%;
      }

      .tags-container {
        position: absolute;

        &.moveable {
          cursor: move; /* fallback if grab cursor is unsupported */
          cursor: grab;
          cursor: -moz-grab;
          cursor: -webkit-grab;
        }

        /* Apply a "closed-hand" cursor during drag operation. */
        &.moveable:active {
          cursor: grabbing;
          cursor: -moz-grabbing;
          cursor: -webkit-grabbing;
        }
      }
    }
  }
}
