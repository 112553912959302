@import "../../../styles/variables";

.group-badge {
  :global(&.HintTooltip) {
    z-index: 20;
  }
}

.collapse-body {
  padding: 0 15px;

  .primary-link {
    color: $primary;

    &:hover, &:active {
      color: $primary;
    }
  }

  .group-info-line {
    border-top: 1px solid $light-gray;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 5px 0;
  }

  .puc-label-col,
  .puc-value-col {
    display: flex;
    flex-direction: column;

    .pigs-in,
    .pigs-in-value {
      font-size: 12px;
      color: $dark-gray;
    }

    .pigs-in-value {
      text-align: right;
    }
  }

  .source {
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
      font-weight: 400;
    }
  }

  .source-label {
    text-transform: uppercase;

    &.commingled {
      border: 1px solid rgb(221, 221, 221);
      background-color: $white;
      color: $dark-gray;
    }
  }

  .source-list {
    flex-direction: column;
    border: none;
  }

  .source-list-item {
    border: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    background-color: $background;
    color: $dark-gray;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:not(:first-child) {
      border-top: none;
    }
  }
}
