@import '../../../styles/variables';

.diagnose-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .form-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    overflow: auto;
    padding: 10px 20px 0;
  }

  .button-block {
    border-top: 1px solid #ddd;
    padding: 20px 10px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .button {
      margin: 0 10px 20px;
      flex: 1;
    }
  }
}
