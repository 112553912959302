@import '../../../styles/variables';

.TenantNotificationBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  height: 0;
  background-color: $brand-blue;
  z-index: 5;
  font-size: 12px;
  color: $white;
  padding: 0 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s height, .3s visibility;
  will-change: height;

  &.deactivated {
    background-color: $brand-red;
  }

  &.is-expired {
    background-color: $brand-orange;
  }

  @media screen and (max-width: 39.9375em){
    font-size: 10px;
    padding: 0 30px 0 5px;
  }

  .attention-icon {
    margin-right: 10px;
  }

  .close-icon {
    font-size: 15px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 10px;
    cursor: pointer;
  }

  .support-link {
    text-decoration: underline;

    &:hover {
      color: $white;
    }
  }
}
