.preferences-body {
  padding: 30px 0;

  .unavailable-reminder {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .unavailable-caption {
      text-transform: uppercase;
      color: var(--color-gray-dark);
      font-weight: 600;
    }

    .info-link {
      span {
        color: var(--color-gray-dark);
      }

      a {
        color: var(--color-primary);
        text-decoration: underline;

        span {
          color: var(--color-primary);
        }
      }
    }
  }

  .lang-select {
    max-width: 240px;
    font-weight: 600;
  }

  .ReactSelect {
    .Select-arrow-zone {
      .fa {
        color: var(--color-primary);
      }
    }
  }
}
