@import "styles/variables";

.question-icon {
  height: 35px;
  min-width: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $dark-gray;
  color: $dark-gray;
  font-size: 17px;
  font-weight: 600;

  @media (max-width: 40em) {
    min-width: 25px;
    height: 25px;
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  &.pass {
    color: white;
    border: $brand-blue;
    background-color: $brand-blue;
  }

  &.fail {
    color: white;
    border: $brand-red;
    background-color: $brand-red;
  }

  &.not_available {
    color: white;
    border: $brand-gray;
    background-color: $brand-gray;
  }
}
