@import "../../../styles/variables";

.reported-treatments {
  font-size: 14px;

  .treatment-title,
  .treatment-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .treatment-title {
    border-bottom: 1px solid $light-gray;
    padding: 5px 15px;
    background: #f7f7f7;
    font-weight: 600;

    &:first-child {
      border-top: 1px solid $light-gray;
    }
  }

  .treatment-line {
    font-style: italic;
    line-height: inherit;
    padding: 8px 15px;
    border-bottom: 1px solid $light-gray;

    &:last-child {
      margin-bottom: -1px;
    }
  }
}
