@import '../../../../../styles/variables';

.UpdateDiagnosis {

  .diagnosis-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: $white;
    border-bottom: 1px solid $light-gray;
  }

  .diagnosis-name {
    font-size: 20px;
    margin-right: 10px;
  }

  .field-small {
    width: 90px;
  }

  .sub-panel {
    margin-bottom: 40px;
    font-size: 14px;

    .sub-heading {
      padding: 10px;
      height: 40px;
      h1 {
        font-size: 14px;
      }
    }
  }

  .InfoTabs {
    .InfoTabs__headings {
      display: flex;
      border-bottom: 1px solid $light-gray;

      .InfoTab__heading {
        padding: 0 5px;
        color: $nice-grey;
        display: flex;
        border-bottom: 4px solid transparent;
        margin-left: 9px;
        height: 40px;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        transition: all .25s;

        &:hover {
          color: $primary;
        }
      }

      .InfoTab__active {
        color: $primary;
        border-color: $primary;
      }
    }

    .InfoTabs_content {
      padding: 10px 20px 20px;
    }
  }

  .InfoSource {
    padding: 10px 20px 20px;

    .bold {
      font-weight: 600;
    }

    .InfoSource__link {
      color: $primary;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

}
