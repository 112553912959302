.user-filter-modal {
  width: calc(100% - 30px);
  max-width: 450px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;

  .body {
    padding: 10px;
  }

  .segment-checkbox {
    display: block;
    margin-bottom: 15px;

    &:first-of-type {
      margin-top: 5px;
    }

    &.not-checked {
      color: var(--color-gray-dark);
    }
  }
}

.buttons {
  display: flex;

  .btn {
    flex: 1;
    padding: 15px 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 0;
    transition: all .25s;
  }

  .left-btn {
    background-color: var(--color-gray-light);
  }

  .right-btn {
    color: white;
    background-color: var(--color-primary);
  }
}
