@import '../../../../../styles/variables';

.UserProfileEditForm {
  .section-divider {
    font-size: 14px;
    font-weight: 600;
    color: $dark-gray;
    border-bottom: 1px solid $light-gray;
    padding-bottom: 5px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  hr {
    border-bottom: 1px solid $light-gray;
  }

  .nickname-icon {
    font-size: 18px;
    color: $medium-gray;
  }
}

