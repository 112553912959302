@import "../../../styles/variables";

.ResponsiveDropdown {

  .responsive-dropdown-container {

    .responsive-dropdown {
      right: 0;
      top: 100%;
      z-index: 9;
      width: calc(100vw - 20px);
      max-width: 320px;

      @media screen and (max-width: 40rem) {
        max-width: none;
      }

      &:before {
        content: '';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
        position: absolute;
        bottom: 100%;
        right: 0;
      }

      &.above:before {
        content: '';
        border-top: 10px solid $white;
        border-bottom: none;
        position: absolute;
        top: 100%;
      }

      &.center:before {
        display: none;
      }

      ul {
        padding: 0;
        margin: 0;

        i {
          font-size: 22px;
          margin: auto 10px;
          vertical-align: middle;
          width: 25px;
        }

        li {
          margin: 0;
          display: flex;
          align-items: center;
          padding: 12px 20px;
          border-bottom: 1px solid #d8d8d8;
          cursor: pointer;
          font-size: 14px;
          min-height: 45px;

          & > a {
            flex-grow: 1;
            padding: 0 10px;
          }

          &:last-child {
            border-bottom: none;
          }
        }

        a {
          font-size: 14px;
          padding: 4px 25px 4px 0;
        }
      }
    }
  }

  .dropdown {
    &.above {
      margin-bottom: 10px;
      margin-top: 0;
      bottom: 100%;
      top: inherit;
    }
  }

  .dropdown-menu {
    z-index: 1000;
    padding: 0;

    li {
      border-bottom: 1px solid $light-gray;
      padding: 5px 0 !important;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .default-trigger {
    margin: 0;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    transition: background ease .2s, opacity .3s;
    border-radius: 5px;

    .fa {
      color: $dark-gray;
      font-size: 25px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, .2);
    }
  }
}
