.mobile-menu-box {
  height: 100%;
  background-color: var(--color-white-almost);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;

  .sections {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    :global(.beamer_icon) {
      transform: translateX(120%);
      animation: none;
    }
  }

  .menu-section:first-child {
    border-top: none;
    margin-top: 0;
  }

  .left-icon {
    color: var(--color-gray-dark);
    font-size: 20px;
  }

  .version-line {
    margin: 20px 0;
  }
}

.logout-section {
  font-size: 14px;
  text-align: center;
  padding: 20px;
  background-color: white;
  color: rgb(220, 53, 34);
  border-bottom: 1px solid var(--color-gray-light);
  border-top: 1px solid var(--color-gray-light);
}
