@import 'styles/variables';

.treatments-profile {

  .sub-nav-content {
    display: flex;
    justify-content: center;

    .date-picker {
      z-index: 2;
      margin-right: 15px;
    }

    .select-period {
      width: 170px;
    }

    @media screen and (max-width: $tablet-width) {
      width: 100%;
      flex-direction: column;

      .date-picker {
        margin-right: 0;
      }

      .select-period {
        margin: 10px 0;
        width: 100%;
      }
    }
  }
}
