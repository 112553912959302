.media-asset {
  display: flex;
  flex-wrap: wrap;

  .asset-wrapper {
    margin-bottom: 10px;
    margin-right: 10px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    .preview-image {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.2);
      width: 120px;
      height: 120px;
      position: relative;
      background-size: cover;

      &:hover {
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: rgba(black, 0.3);
        }
      }
    }
  }
}
