.breadcrumbs {
  font-size: 16px;
  color: white;
  padding: 4px 15px;

  .breadcrumbs-tab {
    display: inline-flex;

    a {
      color: var(--color-white-almost);
      font-weight: normal;
      -webkit-text-fill-color: var(--color-white-almost);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 250px;
    }

    &:hover a {
      color: white;
    }

    &:last-child a {
      font-weight: 600;
      color: white;
      -webkit-text-fill-color: white;
    }
  }

  .separator {
    margin: 0 5px;
    color: white;
    font-weight: normal;
  }

  .now-active {
    color: white;
  }
}
