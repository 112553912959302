@import '../../../styles/variables';

$progress-bar-height: 20px;
$half-progress-bar-height: $progress-bar-height / 2;
$toggler-size: 35px;
$margin-for-tooltip: 40px;

.PercentInput {

  .progress-bar {
    position: relative;
    cursor: pointer;
    height: 20px;
    border-radius: $half-progress-bar-height;
    margin: 0 0 $margin-for-tooltip;
    background-color: $light-gray;
    border-color: $light-gray;

    .progress-bar-meter {
      border-radius: $half-progress-bar-height 0 0 $half-progress-bar-height;
      background-image: linear-gradient(90deg, #f78445 3%, #ff5e3a 103%);
      height: 100%;
    }

    .toggler-wrapper {
      height: inherit;
      position: absolute;
      top: 0;
      width: 100%;

      .toggler {
        position: absolute;

        .toggler-btn {
          cursor: pointer;
          border-radius: 50%;
          background-color: $white;
          width: $toggler-size;
          height: $toggler-size;
          transform: translate(-50%, ($progress-bar-height - $toggler-size) / 2);
          border: 1px solid gainsboro;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 4px;

          .toggler-content {
            height: 15px;
            width: 1px;
            margin: 0 1px;
            background-color: $light-gray;
          }

          .toggler-tooltip {
            position: absolute;
            background-color: $primary;
            font-weight: 600;
            color: $white;
            padding: 5px 8px;
            line-height: 1;
            border-radius: 5px;
            font-size: 14px;
            top: 100%;
            margin-top: 10px;
            left: 50%;
            transform: translateX(-50%);

            &:before {
              content: '';
              position: absolute;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: 6px solid $primary;
              bottom: 100%;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    @media all and (max-width: 63.9375rem) {
      margin: $margin-for-tooltip 0 0;

      .toggler-wrapper .toggler .toggler-btn .toggler-tooltip {
        top: unset;
        bottom: 100%;
        margin-bottom: 10px;

        &:before {
          border-bottom: none;
          border-top: 6px solid $primary;
          bottom: unset;
          top: 100%;
        }
      }
    }
  }
}
