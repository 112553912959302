@import "../../../styles/variables";

.DailyCheckupReportSymptoms {

  .symptom-row {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $light-gray;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .select-symptom-line,
    .select-value-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .index-cell {
        flex: 0 0 50px;
        display: flex;
        align-items: center;
        align-self: center;
      }

      .select-symptom-wrapper,
      .select-value-wrapper {
        flex: 1 1 160px;

        .Select-control {
          margin-bottom: 0;
        }

        .mobile-symptom-input {
          position: relative;

          .symptom-name {
            border-radius: 2px;
            padding: 7px 30px 7px 7px;
            border: 2px solid $light-gray;
            color: $black;
            font-size: 14px;
          }

          .change-symptom-icon {
            position: absolute;
            right: 0;
            top: 0;
            padding: 11px;
          }
        }
      }

      .trash-cell {
        flex: 0 0 45px;
      }
    }

    .row-index {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #333;
      border-radius: 50%;
      font-size: 14px;
      color: #333;
    }

    .trash-icon {
      padding: 9px;
      margin-left: 5px;
      border-radius: 5px;
      transition: background-color .2s ease;
      cursor: pointer;
      font-size: 22px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .symptomatic {
      font-style: italic;
      margin-bottom: 10px;
      font-size: 14px;
    }

    .custom-radio {
      height: 40px;
      border-color: $light-gray;
      color: $dark-gray;
      background-color: $background;
      font-size: 15px;

      label {
        border-left-color: $light-gray;

        input+span {
          color: $dark-gray;
        }

        input:checked+span {
          background: $primary;
          color: $white;
        }
      }
    }
  }

  .add-symptom {
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    color: $brand-blue;
    background-color: $light-blue;
    line-height: 40px;
  }
}
