.card-wrapper {
  margin-bottom: 20px;
  position: relative;

  &.is-disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .header-line {
    font-weight: 600;
    width: 100%;
    font-size: 10px;
    text-transform: uppercase;
    padding: 3px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid var(--color-alto);
    background-color: var(--color-white-bg);
    color: var(--color-gray-nice);
    border-bottom: none;

    &.active {
      background-color: #ffdb4b;
      border-color: #ffdb4b;
      color: #745902;
    }
  }

  .answer-icon {
    font-size: 25px;

    &.pass {
      color: var(--color-blue-brand);
    }

    &.fail {
      color: var(--color-red-brand);
    }
  }

  .site-visit-report-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid var(--color-alto);
    overflow: hidden;

    &.answered {
      background-color: var(--color-white-bg);
    }

    .card-content {
      padding: 15px 10px 15px 25px;
      width: 100%;
      display: flex;
      align-items: center;
    }

    .svr-status {
      margin-right: 30px;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--color-primary);
      }
    }

    .text-section {
      .question {
        font-weight: 600;
        font-size: 14px;
      }

      .question-desc {
        font-size: 12px;
      }
    }

    .btn-group {
      display: flex;
      margin-left: auto;

      .btn {
        width: 90px;
        margin-left: 10px;
      }

      .action-btn {
        transition: background-image .25s;

        &:hover {
          background-image: linear-gradient(0deg, var(--color-primary) 3%, #f78445 103%);
          color: white;
        }
      }
    }
  }

  .comments-section {
    width: 100%;
    background-color: var(--color-white-bg);
    padding: 15px 20px 15px 30px;
    border-top: 1px solid var(--color-alto);

    .comment {
      display: flex;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .comment-body {
        font-size: 14px;
        width: 100%;
        padding: 10px 15px;
        margin-left: 15px;
        background-color: white;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }
    }
  }
}

// mobile

.card-wrapper {
  @media (max-width: 40em) {
    .site-visit-report-card {
      .card-content {
        padding: 15px;
        flex-direction: column;
        justify-content: center;

        .btn-group {
          margin: 0 auto;

          .btn {
            &:first-child {
              margin-left: 0;
            }
          }
        }

        .svr-status {
          margin-right: 0;
        }

        .text-section {
          margin: 10px 0;
        }
      }
    }

    .comments-section {
      padding: 15px;

      .comment {
        .comment-body {
          font-size: 13px;
        }
      }
    }
  }
}
