@import '../../../../styles/variables';

.assets-block-list {
  padding: 10px 20px 80px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  :global(.nothing-box) {
    margin: 0 auto;
  }

  .asset-item-box {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 200px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px;
    box-shadow: none;
    transition: box-shadow .4s;
    position: relative;
    background-color: $white;
    overflow: hidden;

    &:hover {
      box-shadow: 0 1px 12px rgba(0, 0, 0, 0.3);
    }
  }

  @media screen and (max-width: $mobile-width) {
    padding: 0;

    .asset-item-box {
      width: 165px;
      height: 165px;
    }
  }

  @media screen and (max-width: $mobile-width-large) {
    justify-content: center;
  }

  .asset-thumbnail {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.empty {
      background-color: #ddd;
    }

    .img-thumb {
      width: 100%;
      height: 100%;
      background-size: cover;
    }

    .doc-icon {
      font-size: 82px;
      color: $brand-blue;
    }

    .pdf-icon {
      font-size: 82px;
      color: $brand-red;
    }

    .link-icon {
      font-size: 60px;
      color: $dark-gray;
    }
  }

  .asset-info-block {
    flex: 0 0 50px;
    display: flex;
    align-items: center;
    padding: 0 12px;

    .empty-icon {
      width: 25px;
      height: 25px;
      background-color: #ddd;
      border-radius: 2px;
    }

    :global(.doc-icon),
    :global(.pdf-icon) {
      max-width: 15px;
    }

    :global(.img-icon) {
      max-width: 25px;
      max-height: 25px;
    }

    :global(.link-icon) {
      font-size: 20px;
      color: $dark-gray;
    }
  }

  .name {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $primary;
    margin-left: 10px;
    flex: 1;
  }

  .dots-icon {
    font-size: 19px;
    color: $dark-gray;
  }

  .load-more-intersection {
    position: absolute;
    bottom: 80px;
  }

  .load-more-preloader {
    bottom: 20px;
  }
}
