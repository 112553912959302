.radio-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 63em) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 20px;

    .radio-label {
      margin-top: 20px;
    }
  }

  .unit-select {
    min-width: 120px;
  }

  .days-input {
    max-width: 40px;
  }

  .label-text {
    min-width: 70px;
    margin-left: 10px;
  }

  .radio-label {
    display: flex;
    align-items: center;

    &.disabled {
      position: relative;

      &::after {
        cursor: not-allowed;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(white, 0.5);
      }
    }
  }
}

// multi step treatment form

.treatment-step {
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  .treatment-counter {
    flex: 0 0 45px;
    align-self: flex-start;
    height: 45px;
    border: 1px solid var(--color-gray-light);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(245, 245, 245, 0.68) 3%, rgba(255, 255, 255, 0.68) 103%);
    margin-right: 15px;

    @media screen and (max-width: 63em) {
      display: none;
    }
  }

  .action-btn-group {
    align-self: flex-start;
    display: flex;
    align-items: center;
    min-width: 100px;
  }

  .collapse {
    padding: 0;
    width: 100%;
    border-radius: 3px;
    border: 1px solid var(--color-gray-light);

    .collapse-content {
      padding: 20px;
      border-top: 1px solid var(--color-gray-light);
    }
  }
}

