@import "../../../../../styles/variables";

$size: 35px;

.ClusterMarker {
  height: $size;
  width: $size;
  cursor: pointer;
  border-radius: 50%;
  background-color: $brand-blue;
  text-align: center;
  font-size: 14px;
  color: $white;
  position: relative;
  z-index: 1;

  &:hover {
    z-index: 2;
  }

  .cluster-size {
    font-weight: 600;
    line-height: $size;
    position: relative;
    z-index: 1;
  }

  .cluster-badge {
    position: absolute;
    top: 100%;
    padding: 1px 9px;
    line-height: 1;
    border-radius: 15px;
    background: $brand-red;
    font-size: 13px;
    z-index: 1;
    font-weight: 600;
    left: 50%;
    transform: translate(-50%, 2px);
  }

  &:after {
    $additionalSize: 10px;

    content: '';
    position: absolute;
    height: $size + $additionalSize;
    width: $size + $additionalSize;
    border-radius: 50%;
    opacity: .3;
    top: -$additionalSize / 2;
    left: -$additionalSize / 2;
  }

  &.cluster-type {
    &-f {
      background-color: $aquamarine;

      &:after {
        background-color: $aquamarine;
      }
    }

    &-n {
      background-color: $brand-blue;

      &:after {
        background-color: $brand-blue;
      }
    }

    &-wf {
      background-color: $dark-orange;

      &:after {
        background-color: $dark-orange;
      }
    }

    &-su {
      background-color: $orange;

      &:after {
        background-color: $orange;
      }
    }

    &-null, &-undefined {
      background-color: $dark-gray;

      &:after {
        background-color: $dark-gray;
      }
    }
  }
}
