@import 'styles/variables';

.FarmMarker {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 0 10px 18px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: $brand-blue;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  text-transform: uppercase;
  color: $white;
  position: relative;
  z-index: 1;

  &.marker-small {
    color: transparent;
    box-shadow: rgba(0, 0, 0, 0.4) 0 5px 8px;

    &:after {
      content: none;
    }
  }

  &.inline {
    box-shadow: none;
  }

  &:hover {
    z-index: 2;
  }

  &.active {
    z-index: 4;
  }

  &:after {
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid $brand-blue;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.farm-type {
    &-f {
      background-color: $aquamarine;

      &:after {
        border-top-color: $aquamarine;
      }
    }

    &-n {
      background-color: $brand-blue;

      &:after {
        border-top-color: $brand-blue;
      }
    }

    &-wf {
      background-color: $dark-orange;

      &:after {
        border-top-color: $dark-orange;
      }
    }

    &-ff {
      background-color: $brand-purple;

      &:after {
        border-top-color: $brand-purple;
      }
    }

    &-su {
      background-color: $orange;

      &:after {
        border-top-color: $orange;
      }
    }

    &-pp {
      background-color: $brand-orange;

      &:after {
        border-top-color: $brand-orange;
      }
    }

    &-null,
    &-undefined,
    &-m {
      background-color: $dark-gray;

      &:after {
        border-top-color: $dark-gray;
      }
    }
  }
}
