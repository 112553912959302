@import '../../../../styles/variables';

.CloseoutMortalityChart {
  width: 100%;

  .chart-box {

    .chart-box-header {
      height: 65px;
    }

    .chart-box-body {
      min-height: 330px;
    }
  }

  .summary-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .data-block {
      display: flex;
      align-items: center;
      justify-content: center;

      .percent-text {
        font-weight: 600;
        font-size: 16px;
      }
    }

    .current-range {
      font-size: 12px;
      color: $dark-gray;
    }
  }
}
