@import '../../../../styles/variables';

.FarmProfileHeader {
  background-color: $white;
  padding: 15px;
  border-bottom: 1px solid $light-gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  height: 90px;
  position: relative;

  .farm-info-block {
    display: flex;
    flex: 1;
    align-items: center;
  }

  @media screen and (min-width: 64em) {

    &.isSticky {
      position: sticky;
      position: -webkit-sticky;
      z-index: 2;
      top: 0;
    }
  }

  .farm-information {
    margin-left: 10px;
  }

  .farm-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .farm-type {
    font-size: 12px;
    margin: 0 10px;
  }


  .farm-info-wrapper {
    h1 {
      font-size: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 250px;
    }
  }

  @media screen and (max-width: $mobile-width) {
    flex-direction: column;
    height: 100%;

    .farm-info-block {
      flex-direction: column;
    }

    .farm-actions {
      margin: 10px auto 0;
    }

    .farm-information {
      margin: 5px 0 0;
      text-align: center;
    }

    .farm-info-wrapper {
      justify-content: center;

      h1 {
        font-weight: 600;
      }
    }
  }
}

.FarmMiniProfileHeader {
  height: 100%;

  .farm-name {
    font-size: 14px;
    margin: 0 5px;
  }

  .SiteTypeLabel {
    margin-right: 5px;
  }

  .vertical-divider {
    border-left: 1px solid $light-gray;
    height: 75%;
    margin: 0 20px;
  }
}

