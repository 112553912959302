@import '../../../../styles/variables';

.TreatmentFeedbackGroup {

  .treatments-table-section {
    @media screen and (max-width: 63.9375em) {
      padding: 0;
      margin: 0;

      .treatments-table-header {
        border-top: none;
      }

      .treatments-table-header,
      .treatments-table-body {
        border-radius: 0;
        border-left: none;
        border-right: none;
      }
    }
  }

  .product-name-column {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      font-size: 24px;
      margin: 0 10px 0 5px;
    }

    .fa-dots-three-horizontal {
      color: $brand-gray;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .feedback-label {
    padding: 2px 8px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $white;

    &.effective {
      background-color: #0E6A9F;
    }

    &.ineffective {
      background-color: #DA372C;
    }
  }

  .ineffective {
    background-color: $light-orange;
  }
}
