@import '../../../../styles/variables';

.activity-box {
  position: relative;
  border-radius: 5px;
  padding: 0;
  margin: 15px 10px;
  border: 1px solid $light-gray ;
  background: $white;

  &.truncated {
    max-height: 200px;
    overflow: hidden;
  }

  .status-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-top-left-radius: 5px;
    transition: height .3s, background-color .3s;
    overflow: hidden;
    z-index: 1;

    .unseen {
      width: 0;
      height: 0;
      border-top: 15px solid $primary;
      border-right: 15px solid transparent;
    }

    .flagged {
      width: 0;
      height: 0;
      border-top: 15px solid $orange;
      border-right: 15px solid transparent;
    }
  }

  .backdrop-gradient {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), white);
  }

  .view-full-event-btn {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    background-color: $brand-blue;
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px 0;
    min-height: 30px;
    color: white;
    font-size: 12px;
    font-weight: 600;
    bottom: 5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
