@import "../../../styles/variables";

.MobileRow {
  border-top: 1px solid $light-gray;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  position: relative;

  &.select-row {
    padding: 7px 10px;
  }

  &.highlighted {
    background-color: $light-orange;
  }

  .disable-for-select {
    color: $brand-gray;
  }

  .movement-type-icon {
    display: inline-flex;
    flex: 0 0 52px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i {
      font-size: 22px;
    }
  }

  .movement-info {
    flex: 1;
    line-height: 1.1;

    i {
      font-size: 22px;
    }

    .movement-description {
      color: $dark-gray;
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .row-info {
    align-items: center;
    display: flex;

    i {
      font-size: 24px;
      width: 25px;
      margin-right: 10px;
    }

    .common {
      color: $dark-gray;
      margin-left: 7px;
    }

    &.disable-for-select .common {
      color: $brand-gray;
    }
  }

  &.divider {
    margin-top: 10px;
    border-top: none;

    &::before {
      content: ' ';
      display: block;
      height: 10px;
      width: calc(100% + 30px);
      background-color: $background;
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
      position: absolute;
      top: -10px;
      left: -10px;
    }
  }

  &.no-events {
    pointer-events: none;

    .icon {
      opacity: .2;
    }
  }

  .select-btn {
    margin-bottom: 0;
    padding: 10px 7px;
  }

  .icon {
    position: relative;
    width: 25px;
    height: 25px;
    color: $dark-gray;

    .fa {
      font-size: 25px;
      position: absolute;
    }

    .checked {
      opacity: 0;
      color: $primary;
    }

    .unchecked {
      opacity: 1;
    }

    &.selected {
      .checked {
        opacity: 1;
      }
      .unchecked {
        color: $primary;
      }
    }
  }
}
