@import 'styles/variables';

.labeled-input {
  display: flex;
  align-items: center;
  max-width: 100%;

  input {
    margin: 0;
    border-radius: 3px 0 0 3px;
    flex: 1;
    height: 100%;
    transition: all .25s;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  &.bolded {
    input {
      font-weight: 600;
      font-size: inherit;
    }
  }

  input[disabled] {
    background: $background;
    color: $dark-gray;
    box-shadow: none;
  }
}

.label-box {
  font-size: 14px;
  color: rgb(140, 140, 140);
  background-color: $light-gray;
  border: 2px solid $light-gray;
  border-left: none;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 0 3px 3px 0;

  &.is-transparent {
    background-color: inherit;
    color: $black;
  }
}
