@import 'styles/variables';

.FarmFeedRowComment {

  &.comment {
    font-size: 14px;
    padding: 10px 0;
    overflow: visible !important;

    .editmode-buttons {
      display: flex;
      justify-content: flex-end;
      height: 0;
      margin-top: 5px;
      overflow: hidden;
      transition: height .25s;
    }

    &.edit-mode {
      .editmode-buttons {
        height: 35px;
      }

      .comment-footer {
        height: 0;
      }
    }

    $avatarSize: 25px;
    $spacing: 10px;

    .avatar {
      position: relative;
      display: inline-block;
      width: $avatarSize;
      height: $avatarSize;
      min-width: $avatarSize;
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
      text-align: center;
      color: $light-gray;

      .fa {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 2px solid $light-gray;
        border-radius: inherit;
        margin: auto;
        line-height: 44px;
        font-size: 30px;
      }

      img {
        display: block;
        position: relative;
        z-index: 1;
      }
    }

    .comment-body {
      padding-left: $avatarSize + $spacing;
      align-items: center;

      .name-and-time {
        display: flex;
        align-items: baseline
      }

      .name {
        color: $black;
        font-weight: bold;
      }

      time {
        margin-left: 5px;
        color: $dark-gray;
        font-size: 12px;
      }
    }

    .comment-footer {
      height: 20px;
      padding-left: $avatarSize + $spacing;
      padding-right: $spacing;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 5px;
      transition: height .25s;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .translated-button {
        color: $primary;
        cursor: pointer;
      }

      .buttons {
        justify-content: flex-start;
      }

      .dot-list-item::before {
        color: $dark-gray;
      }

      .small-button.primary {
        color: $primary;
      }
    }

    .flex-container {
      display: flex;
      flex-direction: column;
    }
  }

  .comment__text {
    white-space: pre-wrap;
    word-break: break-word;

    .translated-text {
      border-left: 2px $dark-gray solid;
      padding-left: 10px;
      margin-top: 10px;

      .google-translate {
        color: $dark-gray;
        font-style: italic;
      }
    }

    .username {
      font-weight: bold;
      margin-right: 5px;
      white-space: nowrap;
    }
  }

  .comment-footer {
    display: block !important;
    font-size: 12px;

    .dot-list-item {
      font-size: 12px;
    }

    .time {
      color: $dark-gray;
    }
  }
}
