@import '../../../styles/variables';

.divider {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: $dark-gray;
  font-size: 13px;
  text-transform: uppercase;

  &.has-padding {
    padding: 10px;
  }

  .horizontal-line {
    width: 100%;
    margin-left: 20px;
    max-width: 100%;
  }
}

.notification-item {
  background-color: $white;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 1px 1px $light-gray;
  margin-bottom: 15px;

  .triangle-mark {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: $primary;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
    border-top-left-radius: 5px;

    &.read {
      visibility: hidden;
      opacity: 0;
    }
  }
}
