.search-filters-header {
  position: sticky;
  top: 0;
  transform: translateZ(0);
  background-color: white;
  width: 100%;
  z-index: 3;
  border-bottom: 1px solid rgb(221, 221, 221);
  border-bottom: 1px solid var(--color-gray-light);
  padding: 0 10px;

  @media screen and (min-width: 40em) {
    padding: 0 15px;
  }
}

.search-filters {
  height: 60px;
  display: flex;
  align-items: center;

  .clear-filters-btn {
    font-size: 12px;
    padding: 0 12px;
    line-height: 35px;
    margin-left: auto;
  }
}

.filter-item {
  padding: 0 12px;
  line-height: 35px;
  margin-right: 15px;
  border: 1px solid var(--color-gray-light);
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;

  &.active {
    color: white;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }

  .clear-dates-icon {
    color: white;
    padding: 10px 0 10px 10px;
    font-weight: bold;
  }
}
