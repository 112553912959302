@import '../../../../styles/variables';

.MatchGroupColumns {
  padding: 15px;

  .text {
    font-size: 14px;
  }

  .columns-count-status {
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .content-block-items {
    display: flex;
  }
}
