@import '../../../../styles/variables.scss';

.WaterUsagePanel {
  flex: 1 1;
  margin-bottom: 25px;
  justify-content: space-between;
  min-height: 300px;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid $light-gray;

  .header {
    font-size: 18px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .grows-label {
      font-size: 12px;
      color: $dark-gray;
      text-align: right;
    }

    .average-amount {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .item-value {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .legend-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    font-size: 12px;
    flex-direction: row;
    padding: 0 10px;
  }

  .graph-wrap {
    display: flex;
  }

  .chart-block {
    flex: 1 1;
    justify-content: center;
  }

}
