@import "styles/variables";

.load-page-content {
  min-height: 100%;
}

.header {
  align-items: center;
  display: flex;
}

.profile-body {
  margin: 20px auto;
  max-width: 780px;
  padding: 0 10px;
}

.read-more {
  color: $primary;
  cursor: pointer;
}

.info-block {

  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .value {
      font-weight: 600;
      font-size: 20px;

      @media screen and (max-width: $mobile-width-large) {
        font-size: 16px;
      }
    }

    .label {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.load-details {
  font-size: 14px;
}

.travel-info {
  display: flex;
  align-items: center;

  .icon {
    font-size: 18px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $alto;
}

