@import "styles/variables";

.category {
  &:not(:first-child) {
    padding-bottom: 30px;
  }

  .questions {
    position: relative;
  }
}
