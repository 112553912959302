@import "../../../styles/variables";

.barnsheets-checkup {

  .list {
    .list-header {
      border-bottom: 2px solid $light-gray;

      .name {
        font-style: normal;
        font-weight: normal;
      }
    }

    &.sublist {
      .list-header {
        border-bottom: 1px dashed $light-gray;
      }
    }

    li {
      font-weight: normal;
    }
  }

  .assets-list {
    padding: 10px 0;
    font-size: 14px;

    .assets-item {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .asset-preview {
        .image {
          width: 70px;
          height: 45px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border: 1px solid $light-gray;
          padding: 1px;
          cursor: pointer;

          &:hover {
            opacity: .75;
          }
        }
      }

      .asset-info {
        flex-grow: 1;
        margin: 0 10px;

        .asset-name {
          font-size: 13px;
          font-weight: 600;
          white-space: pre-wrap;
          word-break: break-all;
          overflow: hidden;
        }

        .asset-description {
          font-size: 11px;
          font-weight: normal;
          color: $dark-gray;
        }
      }

      .asset-actions {
        i {
          font-size: 24px;
          color: $primary;
          padding: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.StickyFooter {

  button.submit-btn.primary {
    padding: 15px 0;
    width: 80%;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 6px 1px;
  }
}
