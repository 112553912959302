@import '../../../../styles/variables';

.preference-collapse {
  transition: background-color .2s;
  background-color: $white;

  &.disabled {
    cursor: not-allowed;
    background-color: rgb(250, 250, 250);
  }
}

.collapse-title-box {
  padding-left: 20px;
  display: flex;
  min-height: 50px;
  align-items: center;
  font-size: 14px;

  .icon-column {
    width: 35px;
    padding-right: 15px;
    color: $dark-gray;

    i {
      font-size: 20px;
    }
  }

  .title-column {
    flex: 1;
    display: flex;
    align-items: flex-start;

    .title {
      min-width: 220px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .subtitle {
      color: #8c8c8c;
    }

    @media screen and (max-width: $tablet-width) {
      flex-direction: column;

      .title {
        min-width: auto;
      }

      .subtitle {
        font-size: 12px;
      }
    }
  }

  .edit-btn-column {
    padding: 0 20px;
  }

  .edit-btn {
    color: $primary;
  }
}
