@import '../../../../styles/variables';

.predicted-tag-dialog {
  display: flex;
  align-items: flex-start;
  padding: 5px 0;
  font-size: 14px;

  .magic-icon {
    width: 19px;
    height: 19px;
  }

  .prediction-label {
    flex: 1;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }

  .prediction-sub-label {
    font-size: 12px;
    color: $dark-gray;
  }
}

.buttons-row {
  display: flex;
  align-items: center;
  padding: 5px 0;
  justify-content: space-between;

  .action-btn {
    flex: 1;
    margin-right: 10px;
    font-size: 13px;

    &:last-child {
      margin-right: 0;
    }
  }
}
