.farmfeed-page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.feed-container {
  max-width: 612px;
  width: 100%;
  margin: 0 auto 100px;
  position: relative;

  .write-message-block {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-bottom: 1px solid var(--color-gray-light);
    color: var(--color-gray-dark);
  }

  .overlay {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: opacity .2s;

    &.visible {
      overflow: hidden;
      z-index: 10;
      opacity: .5;
      pointer-events: auto;
    }
  }

  .not-authorized-alert {
    margin: 10px;
  }
}

