@import '../../../styles/variables';

.FarmFeedExpiredMessage {
  text-align: center;
  padding: 40px 20px 60px;
  border-radius: 5px;
  border: 1px solid $light-gray;
  max-width: 480px;
  margin: 120px auto 0;
  background: $white;

  .attention-icon {
    color: $brand-orange;
    font-size: 25px;
    margin-bottom: 20px;
  }

  .bolded-text {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .text {
    font-size: 16px;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .support-link {
    color: $primary;
    text-decoration: underline;
  }

  @media screen and (max-width: 39.9375em){
    margin: 120px 10px 10px;
  }
}
