@import "styles/variables";

$border-radius: 5px;

.CenterBoxComponent {
  position: relative;
  width: 680px;
  margin: 0 auto 20px;
  padding: 0;
  background: #FFF;
  border: 1px solid $light-gray;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  flex: 1 auto;

  transform: translateY(10vh);

  @media screen and (max-width: 63.9375em) {
    transform: translateY(0);
    width: 100%;
    min-height: 100%;
    margin: 0;
    border: 0;
    border-radius: 0;
    overflow: visible;
  }

  section {
    padding: 20px;
    margin: 0 !important;

    &.center-box-header {
      padding: 25px 20px;
      background-color: $background;
      border-bottom: 1px solid $light-gray;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      overflow: hidden;

      @media screen and (max-width: 40em) {
        padding-left: 10px;
        padding-right: 10px;
      }

      .title {
        font-weight: normal;
        font-size: 1.4rem;
      }

      .subtitle {
        font-size: 13px;
      }

      .close-center-box {
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 15px;
        font-size: 16px;
        transition: background-color .2s;
        cursor: pointer;
        color: $black;
        border-radius: 5px;

        &:hover {
          color: $black;
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    &.center-box-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      @media screen and (max-width: 40em) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &.center-box-footer {
      padding: 25px 20px;
      background-color: $background;
      border-top: 1px solid $light-gray;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;


      @media screen and (max-width: 40em) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
