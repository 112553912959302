@import '../../../../../styles/variables';

.UsersCompanyRolesManageTable {

  .flex-data-table {
    .table-item {
      .table-item-block {
        .collapsible-value {
          padding: 7px 10px;

          &.with-checkbox {
            padding: 0;
          }
        }
      }
    }
  }

  @media (min-width: 64em) {
    margin: 0 -20px 20px;

    .column-company-name {
      flex: 1 1 120px;
    }

    .column-company-role {
      flex: 1 1 80px;
    }
  }
}
