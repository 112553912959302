@import '../../../../styles/variables';

.TenantEdit {

  .tenant-profile-header {
    margin: 0 -20px;

    .tenant-stats {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .tenant-stats-item {
        display: flex;
        flex-direction: column;
        border-right: 1px solid $light-gray;
        text-align: center;
        padding: 0 5px;
        font-size: 10px;
        flex: 0 0 120px;
        font-weight: 600;

        &.no-right-border {
          border-right: 0;
        }

        .tenant-stats-item-label {
          flex: 1 auto;
          color: $dark-gray;
          text-transform: uppercase;
          font-size: 12px;
          align-items: center;
          display: flex;
          justify-content: center;
        }

        .tenant-stats-item-count {
          color: $black;
          font-size: 16px;
        }
      }

      .tenant-dropdown-item {
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        font-weight: 600;
      }
    }

    @media screen and (max-width: 63.9375em) {
      flex-direction: column;
      height: auto;

      .profile-header-actions {
        margin: 10px 0 0 0;

        .tenant-stats {
          justify-content: center;
        }
      }
    }
  }

  .ResponsiveDropdown {

    .dropdown-button {
      padding: 10px 9px;

      i {
        font-size: 18px;
        color: $dark-gray;
      }
    }
  }

  .trial-expired-panel {
    margin-bottom: 20px;
    overflow: visible;

    .panel-heading {
      background-color: $brand-orange;
      height: 35px;
      padding: 0 20px;
      color: $white;
      font-weight: 600;
      font-size: 14px;
      justify-content: flex-start;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .fa {
        margin-right: 5px;
      }
    }

    .panel-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .extend-trial {
        display: flex;
        align-items: center;

        .expires-days-select {
          margin-left: 10px;
          display: inline-block;

          .Select {

            > .Select-control {
              width: 150px;
            }

            .Select-menu-outer {
              width: 150px;
              min-width: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
}
