@import '../../../../styles/variables';

.FlexTableItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  &.checked {
    background-color: $light-orange;
  }

  &.disabled {
    cursor: not-allowed;
    background: $almost-white;
  }

  .collapsible-name {
    font-weight: 600;
    border-bottom: 3px solid $light-gray;
    padding: 10px 5px 5px 10px;
    width: 100%;
    min-height: 38px;
  }

  .collapsible-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 7px 10px;
    width: 100%;

    &.no-bottom-border {
      border-bottom: none;
    }

    .value-amount {
      width: 100%;
      flex-grow: 0;
      min-width: 50px;
      font-weight: normal;

      &.text-left {
        text-align: left !important;
      }

      .user-info {
        height: 100%;

        .name {
          display: flex;
          flex-wrap: wrap;
          line-height: 16px;
          word-break: break-word;
        }
      }

      time {
        display: block;
      }

      @media (max-width: 63.9375em) {
        width: auto;
        padding-left: 3px;

        &.title {
          line-height: 30px;
          font-size: 14px;
        }

        time {
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
  }

  .button-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .fa-dots-three-horizontal {
      font-size: 25px;
    }

    .button {
      margin-bottom: 5px;

      &.small {
        margin-bottom: 0;
        font-size: 14px;

        @media (min-width: 64em) {
          padding: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 63.9375em) {
    &:not(.desktop-view) {
      flex: 0 1 auto !important;
      width: 100%;

      .collapsible-value {
        width: 100%;
        flex-direction: row-reverse;
        border-bottom: 1px solid $light-gray;
        border-right: none;
        font-weight: 600;
        padding: 7px;

        &.no-bottom-border {
          border-bottom: none;
        }

        .value-amount {
          flex: 1 0;
        }
      }

      .button-column {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        border-top-color: transparent;
        padding: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: none;
        overflow: hidden;

        .button {
          flex: 1 1 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: none;
          border-radius: 0;
          border-width: 1px;
          margin: 0 -1px -1px;
        }
      }
    }
  }

  @media screen and (min-width: 64rem) {

    &:hover {
      background-color: $background;
    }

    &.sorted {
      background: $background;
    }

    &.column-buttons {
      flex-basis: 120px;

      &.wide {
        flex-basis: 240px;
      }

      &.medium-wide {
        flex-basis: 190px;
      }
    }

    &.fixed-column {
      background-color: inherit;
      position: sticky;
      position: -webkit-sticky;
      transform: translateZ(0);
      right: 0;
      z-index: 2;
      transition: box-shadow .2s linear;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }
  }
}
