@import '../../../styles/variables';

.CompanySelector {
  margin-top: 30px;
  font-weight: 600;

  .company-item {
    position: relative;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid $light-gray;
    margin-bottom: 15px;
    padding: 14px;
    background-color: $almost-white;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: transparent;
      background-image: linear-gradient(90deg, rgb(255, 94, 58) 3%, rgb(255, 140, 59) 103%);

      .company-avatar {
        background-color: $dark-blue;
      }

      .company-name {
        color: $white;
      }

      .fa {
        color: white;
      }
    }

    .company-avatar {
      border-radius: 50%;
      overflow: hidden;
      font-size: 16px;
      background-color: #bababa;
      color: $white;
      margin-right: 15px;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    .company-name {
      flex: 1 1 auto;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .fa {
      margin-left: 20px;
      flex-shrink: 0;
      font-size: 16px;
      font-weight: bold;
      color: #bababa;
    }
  }

  .back-link {
    color: $primary;
    font-weight: normal;
    display: block;
    text-align: center;
    font-size: 15px;
    margin-top: 30px;
  }
}
