$segment-header-height: 38px;

.header-dropdown {
  ul.menu {
    padding: 0;
  }

  .menu-option {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 240px;
    font-size: 14px;
    height: 40px;
    line-height: 40px;

    &.border-bottom {
      border-bottom: 1px solid var(--color-gray-light);
    }
  }
}

.dots-icon {
  color: white;
  display: block;
  margin-right: -5px;
}

.segment-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #131830;
  color: white;
  max-height: $segment-header-height;
  padding: 10px 15px;

  .filter-icon {
    flex-shrink: 0;
    margin-right: 10px;
  }

  .editing-badge {
    border-radius: 5px;
    background-color: var(--color-alto);
    color: #131830;
    padding: 2px 5px 1px 5px;
    text-transform: uppercase;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 600;
  }
}

.filters-list {
  display: flex;
  flex-direction: column;
  padding: 20px 15px 15px;
  margin: 0 auto;
  height: calc(100% - var(--mobile-header-height));
  overflow: auto;

  &.is-existed-segment {
    height: calc(100% - var(--mobile-header-height) - #{$segment-header-height});
  }
}

.all-or-any-toggle {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 10px;
}

.add-filter-section {
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 10px 0;
  text-align: center;
  color: var(--color-blue-brand);
  background-color: var(--color-blue-light);
  border-radius: 3px;
  font-size: 14px;
}

.clear-all {
  padding: 10px 0;
  color: var(--color-primary);
  background-color: var(--color-orange-light);
  border-radius: 3px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.apply-btn-section {
  position: sticky;
  bottom: 0;
  margin-top: auto;

  button {
    font-size: 16px;
  }
}

.section-icon {
  &:global(.fa) {
    font-size: 25px;
    margin-right: 10px;
    width: 24px;
    text-align: center;
  }
}

.save-segment-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(30, 38, 62, .7);
  opacity: 0;
  z-index: 3;
  transition: opacity .3s linear;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }
}
