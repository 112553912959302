@import '../../../../styles/variables';

.group-shipments {

  .shipment-date-box {
    display: flex;
    align-items: center;

    .shipment-icon {
      border-radius: 50%;
      background-color: $brand-blue;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;

      i {
        font-size: 10px;
        font-weight: 600;
      }
    }

    .shipment-date {
      margin-left: 5px;
      font-weight: 600;
    }
  }

  .create-shipment-box {
    margin: 10px;
  }
}
