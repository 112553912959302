@import "styles/variables";

.content {
  background-color: white;
  border: 1px solid #ddd;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: none;

  .dots-btn {
    top: 0;
    bottom: 0;
    margin: 0 10px;
    right: 0;
    position: relative;
  }

  .header-content {
    font-size: 14px;
    min-height: 65px;
    align-items: center;
    padding: 0 20px;
    display: flex;
    border-top: none;

    @media screen and (max-width: $mobile-width-large) {
      padding: 10px 10px 0;
      min-height: auto;
    }

    .delivery-date {
      font-size: 13px;
    }

    .name-section {
      display: flex;
      flex: 1;
      max-width: 400px;
    }

    .icon {
      padding: 10px;
      transition: 0.3s;
      cursor: pointer;

      &.isOpened {
        transform: rotate(90deg);
      }
    }

    .btn-group {
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    .header-btn-group {
      display: flex;
    }

    .load-status {
      margin-left: 10px;
      margin-right: 105px;
    }
  }

  .add-to-calendar {
    font-size: 13px;
    padding: 0 100px 25px;
  }

  .add-to-calendar-btn {
    color: $primary;
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;
    text-transform: uppercase;
  }

  .trucking-info {
    flex: 1;
    font-size: 13px;
    max-width: 450px;
    min-width: 320px;
    padding-right: 40px;
    background-color: inherit;

    .divider {
      height: 1px;
      width: 100%;
      background-color: #ddd;
    }
  }

  .time-info {
    font-size: 13px;
    display: flex;
    align-items: center;

    .truck {
      font-size: 17px;
    }
  }

  .load-info {
    border-top: 1px solid #ddd;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 25px 100px;
    background-color: white;

    @media screen and (max-width: $tablet-width) {
      padding: 20px;
    }

    @media screen and (max-width: 40em) {
      flex-direction: column-reverse;

      .general-info {
        margin-bottom: 20px;
      }
    }
  }

  .subtitle {
    font-size: 13px;
    color: $dark-gray;
  }

  .confirmation-status {
    font-size: 14px;
    padding-bottom: 20px;
  }

  .trucking-details {
    @media screen and (max-width: $mobile-width-large) {
      overflow: hidden;
      background-color: white;
      padding: 10px;
    }
  }

  .action-btn-group {
    display: flex;

    .btn {
      flex: 1;
      font-size: 13px;

      &:not(:only-child) {
        &:first-child {
          border-radius: 0 0 0 8px;
          margin-left: -1px;
          margin-bottom: -1px;
        }

        &:last-child {
          border-radius: 0 0 8px;
          margin-right: -1px;
          margin-bottom: -1px;
        }
      }

      &:only-child {
        border-radius: 0 0 8px 8px;
        margin: 0 -1px -1px;
      }
    }
  }
}

@media screen and (min-width: $mobile-width-large) {
  .show-for-mobile-only {
    display: none !important;
  }
}

@media screen and (max-width: $mobile-width-large) {
  .hide-for-mobile {
    display: none !important;
  }

  .show-for-mobile-only {
    font-size: 13px;
    display: block;
  }
}
