@import '../../../../styles/variables';

.ImportLayout {
  width: auto;
  height: 100%;
  background-color: $almost-white;
  display: flex;
  flex-direction: column;

  .importGroup-header {
    background-color: $dark-blue;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $white;
    font-size: 16px;
    padding: 0 15px;
    height: $main-header-height;

    .close {
      cursor: pointer;
      text-align: center;
      font-size: 20px;
    }

    .company-avatar {
      display: inline-block;
      flex-shrink: 0;
      width: 35px;
      height: 35px;
      line-height: 35px;
      border-radius: 50%;
      font-size: 13px;
      font-weight: 600;
      color: $white;
      background-image: linear-gradient(90deg, rgb(255, 94, 58) 3%, rgb(247, 132, 69) 103%);
      text-align: center;
      margin-right: 10px;
      transition: .3s;
    }
  }

  .importGroup-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 15px;
    color: white;
    background-color: $dark-blue;
    height: 60px;
    font-size: 14px;

    .next-button {
      font-weight: 600;
    }

    .back-icon {
      margin-right: 10px;
      font-weight: 600;
    }

    a:hover {
      color: white;
    }
  }

  .bodyContent {
    padding: 20px 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}
