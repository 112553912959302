@import '../../../styles/variables';

.DeadPigsBlock {
  font-size: 14px;
  border-bottom: 1px solid $light-gray;

  .reported-deaths {
    font-size: 14px;

    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;
      border-top: 1px solid $light-gray;

      .name {
        display: inline-block;
        font-weight: 400;
      }

      .value {
        display: inline-block;
        font-weight: 600;
      }

      &.highlighted {
        background-color: $light-yellow;

        .value {
          color: $primary;
        }
      }
    }
  }

  .death-collapse-title {
    padding: 3px 15px;
  }

  .reason-collapse {
    border-top: 1px solid $light-gray;

    .reason-body {
      display: flex;
      margin-left: 15px;
      margin-bottom: 5px;

      .item {
        margin-right: 20px;
      }
    }
  }
}
