@import "styles/variables";

.note-section {
  padding-top: 15px;

  .note-actions {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 40em) {
      justify-content: space-between;
    }

    .flag-button {
      color: $dark-gray;
      border: 1px solid #ddd;
      border-radius: 5px;
      font-size: 12px;
      height: 30px;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.flagged {
        border: none;
        background-color: $brand-orange;
        color: white;
      }

      .flag-btn-label {
        margin-left: 10px;
      }

      @media (max-width: 40em) {
        width: fit-content;
        padding: 14px;
        height: 45px;
        border-radius: 50px;
        font-size: 18px;
        display: flex;
        text-align: center;
        justify-content: center;

        .flag-btn-label {
          display: none;
          font-size: 14px;
        }
      }
    }
  }

  .save-btn {
    margin-left: auto;
    height: 35px;
    padding: 0 20px;
  }

  .note-area {
    padding: 10px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 90px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    color: $black;

    @media (max-width: 40em) {
      min-height: 140px;
    }
  }
}
