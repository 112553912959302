@import '../../../../styles/variables';

.collapsible-column-name {
  font-weight: 600;
  padding: 4px 5px 4px 10px;
  width: 100%;
  min-height: 38px;
  background: $background;
  font-size: 11px;
  text-transform: uppercase;

  .pin-icon {
    font-size: 18px;
    text-align: center;
  }

  .desc-icon {
    color: $dark-gray;
    margin-left: 5px;
    cursor: pointer;
    font-size: 11px;
  }

  .sort-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    color: $dark-gray;

    @media screen and (min-width: 64em) {
      &.text-right {
        justify-content: flex-end;
      }
    }
    &.text-center {
      justify-content: center;
    }

    .allow-sort-column {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
