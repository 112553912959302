@import 'styles/variables';

.LoginPage {

  &.login {

    h1 {
      font-size: 30px;
      margin: 10px auto
    }

    .button-loader {
      top: 0;
      border-left: 4px solid #ffffff;
    }

    .recommended-browsers {
      margin-top: 20px;
      text-align: center;
      font-size: 12px;
    }

    .tenants-disabled-message {

      .support-link {
        color: $primary;
        text-decoration: underline;
      }

      .fa-exclamation-triangle-bts {
        margin-bottom: 15px;
        font-size: 26px;
        color: $brand-orange;
      }
    }

    @media screen and (min-width: 40rem) {

      &.titleAbove {
        margin-top: 40px;
        position: relative;

        h1 {
          position: absolute;
          bottom: 100%;
          left: 50%;
          color: $white;
          transform: translateX(-50%);
          width: 100%;
        }
      }
    }
  }
}
