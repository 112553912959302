@import "../../../../styles/variables";

.BatchDownloadModal {
  width: 480px;
  overflow: visible;

  .modal-body {
    label {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 3px;
    }
  }
}
