@import '../../../../../styles/variables';

.EditCompanyForm {
  input {
    font-size: 14px;
  }

  .ReactSelect {
    &.is-focused:not(.is-open) > .Select-control {
      background-color: $light-gray;
    }

    .Select-control {
      font-size: 14px;
      background-color: $light-gray;
      cursor: pointer;

      .Select-arrow-zone {
        i {
          font-size: 12px;
          color: $black;
        }
      }
    }
  }
}
