@import '../../../../styles/variables';

.filter-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 80px;
  height: 35px;
  min-height: 35px;
  position: relative;
  border-radius: 5px;
  border: 1px solid rgb(221, 221, 221);
  padding-left: 15px;
  padding-right: 10px;
  font-size: 13px;
  color: $black;
  background-color: $white;
  margin-left: 10px;
  cursor: pointer;

  &.isDisabled {
    background-color: rgb(245, 245, 245);
    cursor: not-allowed;
  }

  &.isOpened,
  &.has-value {
    border-color: $primary;
    background-color: $primary;
    color: $white;
  }

  .clear-icon {
    padding: 5px 0 5px 10px;
    font-size: 12px;
    font-weight: bold;
  }

  .filter-dropdown {
    position: absolute;
    margin-top: 2px;
    top: 100%;
    left: 0;
  }
}
