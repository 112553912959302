@import "../../../styles/variables";

.DailyCheckupReportWaterUsage {
  .water-usage-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;

    &.desktop {
      display: none;

      .water-usage-time {
        width: 40px;
        text-align: center;
      }

    }

    &.mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      .water-usage-time {
        width: 100%;
        text-align: center;
      }

      img {
        position: absolute;
        width: 32px;
        height: 32px;
        background: #ff5e31;
        border-radius: 50%;
        padding: 3px;
        top: 45%;
        right: 10px;
      }

      input[type=time] {
        height: max-content;
        color: #303030;
        font-size: 32px;
      }

      input[type=time]::-webkit-datetime-edit-ampm-field {
        display: none;
      }

      input[type=time]::-webkit-clear-button {
        display: none;
      }

    }

    @media screen and (min-width: 64em) {
      &.desktop {
        display: flex;
      }

      &.mobile {
        display: none;
      }

    }

    .input-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;
      border: none;
    }

    .input-label {
      font-weight: 600;
      line-height: 18px;
      padding-right: 10px;
    }

    @media all and (min-width: 64em) {
      .water-usage-input {
        width: 200px;
      }
    }

    .colon-separator {
      font-size: 20px;
      font-weight: bold;
      margin: 0 10px;
    }

    .time-select {
      flex: 1;
      max-width: 88px;
      margin-left: 10px;

      .Select-menu-outer,
      .Select-menu {
        max-height: none;
      }

      .ReactSelect--option {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: black;

        i {
          font-size: 20px;

          &.fa-exclamation-triangle {
            color: #fcb040;
          }
        }
      }
    }

    @media all and (max-width: 63.9375em) {
      .input-wrapper {
        flex-direction: column;
        width: 100%;

        .input-label {
          margin-bottom: 5px;
          width: 100%;
        }
      }
    }
  }
}
