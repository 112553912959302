@import '../../../../../styles/variables';

.indicator-block {
  display: flex;
  flex-direction: column;

  .values {
    display: flex;
    align-items: center;
  }

  .value {
    font-size: 16px;
    color: $black;
    margin-right: 5px;
    font-weight: 600;
  }

  .label {
    color: $dark-gray;
    font-size: 12px;
  }
}
