.services-icon {
  display: block;
  cursor: pointer;
  color: white;
}

.help-dropdown {
  display: flex;
  align-items: center;

  .help-menu {
    z-index: 1000;
    padding: 0;

    :global(.beamer_icon) {
      animation: none;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .help-menu-option {
      padding: 11px;
      border-bottom: 1px solid var(--color-gray-light);
      cursor: pointer;
      display: flex;
      align-items: center;

      .option-icon {
        color: var(--color-black);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        width: 25px;
        height: 25px;
        font-size: 18px;

        i {
          text-align: center;
        }
      }

      .option-label {
        font-size: 14px;
        flex: 1 auto;
      }

      &:hover {
        background-color: #f3f3f3;
      }

      &:last-child {
        border: none;
      }
    }
  }
}
