.mobile-input-like-row {
  position: relative;

  .value {
    border-radius: 3px;
    padding: 5px 40px 5px 7px;
    border: 2px solid var(--color-gray-light);
    color: var(--color-black);
    font-size: 14px;
    min-height: 40px;
  }

  .icon {
    position: absolute;
    display: flex;
    align-items: center;
    width: 40px;
    justify-content: center;
    right: 0;
    top: 0;
    background-color: var(--color-white-bg);
    color: var(--color-primary);
    bottom: 0;
    border: 2px solid var(--color-gray-light);
    border-radius: 0 3px 3px 0;
  }
}
