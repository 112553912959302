@import '../../../styles/variables';

.TenantResources {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  .tenant-resources-preloader {
    top: 60px;
  }

  .list-wrapper {
    flex: 1;
  }

  .StickyFooter {

    &.assets-footer {
      background-color: transparent;
      border-top: 1px solid transparent;
      top: 100%;
    }

    .add-btn {
      width: 145px;
      height: 50px;
      min-width: 0;
      border-radius: 33px;
      font-size: 16px;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3);

      @media screen and (max-height: $mobile-width-large) {
        width: 100px;
        height: 30px;
        padding: 0;
        font-size: 14px;
      }
    }
  }
}
