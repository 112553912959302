@import "../../../../../styles/variables";

.FarmMarkerTooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  z-index: 10;
  padding: 10px;
  line-height: initial;
  background-color: $white;
  color: initial;
  text-transform: none;
  border-radius: 10px;
  margin-bottom: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 10px 38px;
  min-width: 300px;
  text-align: left;
  cursor: default;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear .35s, .35s opacity, .35s transform ease;
  transform: translate(-50%, 10px);
  will-change: transform, opacity;

  &.visible {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
    transition: visibility 0s linear, .35s opacity, .35s transform ease;
  }

  &:after {
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid $white;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .farm-main-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .farm-type {
      margin-left: 10px;
    }

    .farm-active {
      display: inline-block;
      color: $white;
      padding: 1px 4px;
      border-radius: 3px;
      text-transform: uppercase;
      font-size: 11px;
      text-align: center;
      font-weight: 600;
      background-color: $brand-blue;
      margin-left: 5px;
    }

    .fa.fa-times {
      cursor: pointer;
    }

    .farm-name {
      vertical-align: middle;
      font-weight: 500;
    }

    .farm-address {
      margin: 5px 0 10px;
      font-size: 12px;
      color: $dark-gray;
    }
  }

  .farm-additional-info {
    font-size: 12px;

    & > section {
      margin: 0 0 3px;
    }

    .name {
      font-weight: 300;
    }

    .value {
      font-weight: normal;
    }

    .farm-info-bottom-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .farm-focus {
        cursor: pointer;
        color: $dark-orange;
        transition: color .3s ease-out;

        &:hover {
          color: $brand-orange;
        }
      }
    }
  }
}
