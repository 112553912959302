.svr-collapse-row,
.svr-category-row {
  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 1px solid #ddd;
  }
}

.svr-category-row {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  color: var(--color-gray-dark);
  font-size: 14px;
  flex-wrap: wrap;
  border-bottom: none;
  overflow: hidden;
  font-weight: normal;
  background-color: var(--color-white-bg);

  .svr-header {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--color-white-bg);
    transition: 0.3s;

    .category-name {
      display: flex;
      align-items: center;
    }

    .counter-badges {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    @media (max-width: 40em) {
      position: relative;
      flex-wrap: wrap;

      .category-name {
        width: 100%;
      }

      .counter-badges {
        margin-top: 10px;
        margin-left: 30px;
      }
    }
  }
}

.note-section {
  margin: 15px 0;
  padding: 0;
  border: 1px solid var(--color-gray-light);
  border-radius: 3px;
  background: #fff;
}
