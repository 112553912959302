@import '../../../../styles/variables';

.encouragement-count-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  height: 150px;
  padding: 30px 15px;
  background-color: white;
  border-radius: 15px;
  margin: 0 10px 20px;
  box-shadow: rgba(0, 105, 161, 0.2) 0 1px 12px 0;
}

.type {
  text-align: center;
  font-size: 14px;
  color: $dark-gray;
  padding: 0 7px;
}

.count {
  font-size: 34px;
}
