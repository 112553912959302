
.wrapperCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 140px;
  height: 140px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  background-size: cover;
  font-family: Open Sans;
  font-size: 13px;
  color: #a3a3a3;
  text-decoration: none solid rgb(163, 163, 163);
  text-align: center;
  margin: 30px 29px 0 0;

  .wrapperImg{
    display: flex;
    align-items: center;
    min-height: 70px;
  }
  .uploadFile {
    width: 42px;
    height: 42px;
    background: no-repeat url("../../../../../../public/images/upload-file.svg");
  }

  span {
    padding-top: 10px;
  }
  &:hover {
    border: 1px solid #ff5e31;
    color: #ff5e31;
    text-decoration: none solid rgb(255, 94, 49);
    box-shadow: 0px 0px 12px 3px rgba(247, 77, 142, 0.2);

    .uploadFile {
      background: no-repeat url("../../../../../../public/images/upload-file-selected.svg");
    }
  }

  .disableClick{
    &:hover{
      border: 1px solid #dddddd;
      color: #a3a3a3;
      text-decoration: none solid rgb(163, 163, 163);
      box-shadow: none;

      .uploadFile{
        background: no-repeat url("../../../../../../public/images/upload-file.svg");
      }
    }
  }
}
