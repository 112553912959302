@import '../../../../../styles/variables';

.DeathAlerts {

  $left-indent: 50px;
  $left-indent-small: 0;

  .setting-section {
    position: relative;
    margin-top: 0;
    padding-bottom: 20px;
    padding-left: $left-indent;

    &:not(:last-of-type)::after {
      display: block;
      position: absolute;
      content: '';
      bottom: 0;
      left: $left-indent;
      right: -10px;
      border-bottom: 1px solid $light-gray;
    }

    @media screen and (max-width: 40em) {
      padding-left: $left-indent-small;

      &:not(:last-of-type)::after {
        left: $left-indent-small;
      }
    }

    .section-description,
    .section-description-mobile {
      font-size: 14px;

      &.disabled {
        color: $brand-gray;
      }
    }

    .section-description-mobile {
      margin-left: -60px;
      margin-top: 20px;
    }

    .death-percent-input {
      width: 60px;
      height: 35px;
      display: inline-block;
      margin: 0 5px;

      &:disabled {
        color: $brand-gray;
      }
    }

    .ResponsiveSelect {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px;

      .Select {

        > .Select-control {
          background-color: $medium-gray;
          border-color: $medium-gray;
          margin: 0;
          width: 80px;
          height: 35px;
        }

        &.is-focused:not(.is-open) > .Select-control {
          background-color: $medium-gray;
        }

        .Select-menu-outer {
          width: 100px;
          max-width: none;
          min-width: 0;
          right: 0;
        }

        .Select-input:focus {
          background-color: $medium-gray;
        }
      }
    }

    .disabled {
      color: $brand-gray;

      .ResponsiveSelect .Select {

        > .Select-control {
          background-color: $light-gray;
          border-color: $light-gray;
        }

        &.is-focused:not(.is-open) > .Select-control {
          background-color: $light-gray;
        }
      }
    }
  }

  .buttons {
    margin-top: 30px;
    margin-bottom: 10px;

    .alerts-save-btn {
      width: 100%;
    }

    @media screen and (min-width: 40em) {
      margin-left: 50px;

      .alerts-save-btn {
        width: auto;
      }
    }
  }
}
