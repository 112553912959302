@import '../../../styles/variables';

.Amchart {
  width: 100%;

  .non-compliant {
    .amcharts-pie-slice {
      fill: url(#non-compliant);
    }
  }

  .compliant {
    .amcharts-pie-slice {
      fill: url(#compliant);
    }
  }

  .avg {
    .amcharts-pie-slice {
      fill: url(#avg);
    }
    .amcharts-legend-marker {
      fill: url(#avg);
    }
  }

  .good {
    .amcharts-pie-slice {
      fill: url(#good);
    }
    .amcharts-legend-marker {
      fill: url(#good);
    }
  }

  .poor {
    .amcharts-pie-slice {
      fill: url(#poor);
    }
    .amcharts-legend-marker {
      fill: url(#poor);
      border: none;
    }
  }

  .amcharts-graph-AmGraph-1 {
    .amcharts-graph-column-front {
      fill: url(#brand-blue);
      stroke-width: 0;
    }
  }

  .amcharts-graph-AmGraph-2 {
    .amcharts-graph-column-front {
      fill: url(#brand-orange);
      stroke-width: 0;
    }
  }

  a {
    display: none!important;
  }
}
