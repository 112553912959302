.mobile-portal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: white;
  transition: visibility .3s ease-out, left .3s ease-out, right .3s ease-out;
  visibility: visible;
  overflow-y: scroll;
  overflow-x: hidden;

  &.isHidden {
    left: 100%;
    right: -100%;
    visibility: hidden;
  }
}
