@import '../../../../styles/variables';

.resources-dropzone {
  display: flex;
  align-items: center;
  margin: 10px;
  border: 3px dashed transparent;
  border-radius: 5px;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;

  @media screen and (max-width: $mobile-width) {
    margin: 0 5px;
  }

  .empty-upload-hint {
    max-width: 350px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
  }

  .upload-circle {
    width: 130px;
    height: 130px;
    background-color: $white;
    transition: background-color .3s ease;
    border-radius: 50%;
    margin: 0 auto 15px;
    position: relative;
  }

  .upload-icon {
    font-size: 50px;
    position: absolute;
    color: $dark-gray;
    transition: color .3s ease;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .drop-files-text {
    min-height: 60px;
  }

  &.isDragOver {
    border-color: $primary;
    z-index: 2;

    .upload-circle {
      background-color: $primary;
    }

    .upload-icon {
      color: $white;
    }
  }

  @media screen and (max-height: $mobile-width-large) {
    .empty-upload-hint {
      max-width: $mobile-width-large;
    }

    .upload-circle {
      width: 60px;
      height: 60px;
      margin-bottom: 5px;
    }

    .upload-icon {
      font-size: 30px;
    }

    .drop-files-text {
      min-height: auto;
      margin-bottom: 50px;
    }
  }
}
