@import '../../../../styles/variables';

.withdrawal-warning {
  font-size: 14px;

  &.no-padding {
    padding: 0;
  }

  .collapse-title {
    padding: 13px 15px;
  }

  .options-header,
  .medication-line {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $light-gray;
    padding: 5px 10px;
  }

  .options-header {
    font-weight: 600;
  }

  .medication-line {
    align-items: center;
    background-color: $light-yellow;

    &:last-child {
      border-radius: 0 0 5px 5px;
    }
  }
}
