@import 'styles/variables';

.FederalPremiseIDField {

  .premise-link {
    color: $primary;
    display: inline-block;
    font-size: 14px;
    margin-top: 0;
  }

  input {
    margin-bottom: 5px;
  }
}
