@import "../../styles/variables";

.SearchBox {
  position: relative;
  max-width: 240px;
  border: 1px solid $light-gray;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  padding: 0 7px;

  &.wide {
    min-width: 100%;
  }

  > i {
    color: $light-gray;
    margin-right: 7px;
  }

  input[type='search'] {
    background-color: transparent;
    color: $black;
    margin: 0;
    height: 30px;
    border: none;
    box-shadow: none;
    font-size: 16px;
    padding: 0;
    min-width: 180px;
  }

  .search-icon {
    font-size: 14px;
  }

  .clear-icon {
    font-size: 10px !important;
    position: absolute;
    cursor: pointer;
    right: 0;
    color: $light-black;
    padding: 3px;
    margin-right: 5px;
  }

  @media screen and (max-width: 63.9375em) {
    max-width: 100%;
    width: 100%;
  }
}

