@import '../../../../styles/variables';

.ff-post-mobile {
  font-size: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
  background-color: $white;

  .post-content-box {
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$main-header-height});
    justify-content: space-between;
  }

  .post-content {
    display: flex;
    padding: 10px;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;

    .user-name {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .mention-line {
    margin-top: 5px;
    width: 100%;

    .mention-item {
      color: $primary;
      display: inline-flex;
      align-items: center;
    }

    .remove-mention-icon {
      font-size: 18px;
      margin-left: 5px;
    }
  }

  .text-block {
    display: block;
    width: 100%;
    resize: none;
    padding: 10px 0 0 0;
    font-size: 14px;
    border: none;
    box-shadow: none;
    min-height: 40px;
    margin: 10px 0 0;

    &.no-margin-top {
      margin-top: 0;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .actions {
    background-color: $white;

    .divider {
      margin: 0 0 15px;
    }
  }

  .action-buttons-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 10px 20px;

    .action-btn:not(:last-child) {
      margin-bottom: 20px;
    }

    .btn-icon {
      font-size: 16px;
      margin-right: 5px;
    }

    .mention-to-continue {
      color: $primary;
      text-align: center;

      &.black { color: $black }

      .icon {
        font-size: 18px;
        margin-right: 5px;
      }
    }
  }

  .post-assets {
    margin-top: 10px;
    text-align: center;
    width: 100%;
  }

  .progress {
    width: 100%;
    margin: 0 auto 10px;
  }

  .asset-preview {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    border-radius: 3px;
    position: relative;

    .asset {
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;

      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(rgba(255, 255, 255, 0) 1%, rgb(0, 0, 0) 101%);
        transform: rotate(180deg);
        opacity: .4;
      }
    }
  }

  .remove-icon {
    font-size: 20px;
    color: $white;
    position: absolute;
    top: 5px;
    right: 5px;
    font-weight: 600;
  }
}
