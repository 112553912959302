.SingleDatePickerWrapper {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  $black: var(--color-black);
  $primary: var(--color-primary);
  $lightGray: var(--color-gray-light);
  $whiteBG: var(--color-white-bg);
  $mediumGray: var(--color-gray-medium);

  .DayPickerNavigation_button {
    padding: 8px;

    &:hover {
      background-color: $primary;
      border-color: $primary;
      color: white;

      svg {
        fill: white;
      }
    }
  }

  .SingleDatePicker {
    flex: 1;

    .SingleDatePicker_picker {
      top: 50px !important;
    }

    .SingleDatePickerInput {
      width: 100%;
      position: relative;
      display: block;
      box-sizing: border-box;
      height: 2.4375rem;
      padding: 0;
      border: 2px solid $lightGray;
      margin: 0;
      font-family: inherit;
      color: $black;
      background-color: white;
      box-shadow: 0 1px 2px rgba(48, 48, 48, 0.1);
      border-radius: 3px;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      -webkit-appearance: none;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;

      .DateInput {
        width: auto;

        &_input {
          height: 29px;
          font-weight: normal;
          font-size: 14px;
          color: $black;
          background: white;
          border: 0;
          box-shadow: none;
          margin: 3px;
          padding: 5px;
          max-width: 90px;

          &:focus {
            background-color: rgb(255, 232, 223);
          }

          &__readOnly {
            cursor: default;
          }

          &__disabled {
            background-color: #f2f2f2;
            cursor: not-allowed;
            font-style: normal;
          }

          &::-webkit-input-placeholder {
            color: $mediumGray;
            font-weight: normal;
          }
        }

        svg {
          top: 40px !important;
        }
      }
    }

    .CalendarDay {
      border: 1px solid $lightGray;
      padding: 0;
      box-sizing: border-box;
      color: #565a5c;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        background: #FFE8E0;
      }

      &__selected {
        background: $primary;
        border: 1px double $primary;
        z-index: 2;
        color: white;

        &:hover {
          background: $primary;
        }
      }

      &__blocked_out_of_range {
        color: $mediumGray;
        background: $whiteBG;
        cursor: default;
        border: 1px solid $lightGray;

        &:hover {
          background: $whiteBG;
        }

        .CalendarDay__button {
          cursor: default;
        }
      }
    }
  }

  &.focused {

    .SingleDatePicker {

      .SingleDatePickerInput {
        border-color: $primary;
      }
    }
  }

  &.isMobile {

    .SingleDatePicker {

      .SingleDatePickerInput {

        .DateInput_input {
          margin: 2px;
          max-width: 100px;
          height: 29px;
        }
      }
    }
  }

  .mobile-overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;

    &.active {
      display: block;
    }
  }

  .picker-label {
    cursor: pointer;
    font-size: 14px;
    color: rgb(140, 140, 140);
    background-color: $lightGray;
    border: 2px solid $lightGray;
    border-left: none;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-radius: 0 3px 3px 0;
    min-width: 45px;
    text-align: center;

    i {
      font-size: 18px;
      color: $primary;
    }
  }
}
