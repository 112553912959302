@import "../../../styles/variables";

.CustomSelect {
  height: 40px;
  padding: 8px;
  font-size: 14px;
  font-weight: normal;
  border: none;
  position: relative;
  background-image: url('./arrow.svg');
  background-size: 12px;
  background-color: $light-gray;
  margin: 0;
  line-height: normal;
  color: $black;
  -webkit-appearance: none;
  background-origin: content-box;
  background-repeat: no-repeat;

  &.isIOS {
    font-size: 16px;
  }
}
